import {Request, RequestParams} from '../request';

/**
 * Represents a color object
 */
export class Order {
  /**
   * Create a new instance of a color
   * @param {object} [data] API response data.
   * @param {number} [data.color_id=0] The color id. i.e. - 10
   * @param {string} [data.color_name] The color name. i.e. - Dark Gray
   * @param {string} [data.color_code] The HTML HEX associated to the color. i.e. - 6b5a5a
   * @param {string} [data.color_type] A group that the color belongs to. i.e. - Solid, Transparent, etc...
   */
  constructor(data) {
    data = data || {};

    this.order_id  = data.order_id  || null;
    this.date_ordered  = data.date_ordered  || null;
    this.date_status_changed  = data.date_status_changed  || null;
    this.seller_name  = data.seller_name  || null;
    this.store_name  = data.store_name  || null;
    this.buyer_name  = data.buyer_name  || null;
    this.buyer_email  = data.buyer_email  || null;
    this.buyer_order_count  = data.buyer_order_count  || null;
    this.require_insurance  = data.require_insurance  || null;
    this.status  = data.status  || null;
    this.is_invoiced  = data.is_invoiced  || null;
    this.is_filed  = data.is_filed  || null;
    this.sent_drive_thru  = data.sent_drive_thru  || null;
    this.remarks  = data.remarks  || null;
    this.total_count  = data.total_count  || null;
    this.unique_count  = data.unique_count  || null;
    this.total_weight  = data.total_weight  || null;
    this.payment = data.payment  || {};
    this.payment.method = data.payment.method || null;
    this.payment.currency_code  = data.payment.currency_code  || null;
    this.payment.date_paid  = data.payment.date_paid  || null;
    this.payment.status  = data.payment.status  || null;
    this.shipping  = data.shipping  || {};
    this.shipping.method  = data.shipping.method  || null;
    this.shipping.method_id  = data.shipping.method_id  || null;
    this.shipping.tracking_no  = data.shipping.tracking_no  || null;
    this.shipping.tracking_link  = data.shipping.tracking_link  || null;
    this.shipping.date_shipped  = data.shipping.date_shipped  || null;
    this.shipping.address  = data.shipping.address  || {};
    this.shipping.address.name  = data.shipping.address.name  || {};
    this.shipping.address.name.full  = data.shipping.address.name.full  || null;
    this.shipping.address.name.first  = data.shipping.address.name.first  || null;
    this.shipping.address.name.last  = data.shipping.address.name.last  || null;
    this.shipping.address.full  = data.shipping.address.full  || null;
    this.shipping.address.address1  = data.shipping.address.address1  || null;
    this.shipping.address.address2  = data.shipping.address.address2  || null;
    this.shipping.address.country_code  = data.shipping.address.country_code  || null;
    this.shipping.address.city  = data.shipping.address.city  || null;
    this.shipping.address.state  = data.shipping.address.state  || null;
    this.shipping.address.postal_code  = data.shipping.address.postal_code  || null;
    this.cost  = data.cost || {};
    this.cost.currency_code  = data.cost.currency_code  || null;
    this.cost.subtotal  = data.cost.subtotal  || null;
    this.cost.grand_total  = data.cost.grand_total  || null;
    this.cost.etc1  = data.cost.etc1  || null;
    this.cost.etc2  = data.cost.etc2  || null;
    this.cost.insurance  = data.cost.insurance  || null;
    this.cost.shipping  = data.cost.shipping  || null;
    this.cost.credit  = data.cost.credit  || null;
    this.cost.coupon  = data.cost.coupon  || null;
    this.cost.vat_rate  = data.cost.vat_rate  || null;
    this.cost.vat_amount  = data.cost.vat_amount  || null;
    this.cost.disp_currency_code  = data.cost.disp_currency_code  || null;
    this.cost.disp_subtotal  = data.cost.disp_subtotal  || null;
    this.cost.disp_grand_total  = data.cost.disp_grand_total  || null;
    this.disp_cost  = data.disp_cost || {};
    this.disp_cost.currency_code  = data.disp_cost.currency_code  || null;
    this.disp_cost.subtotal  = data.disp_cost.subtotal  || null;
    this.disp_cost.grand_total  = data.disp_cost.grand_total  || null;
    this.disp_cost.etc1  = data.disp_cost.etc1  || null;
    this.disp_cost.etc2  = data.disp_cost.etc2  || null;
    this.disp_cost.insurance  = data.disp_cost.insurance  || null;
    this.disp_cost.shipping  = data.disp_cost.shipping  || null;
    this.disp_cost.credit  = data.disp_cost.credit  || null;
    this.disp_cost.coupon  = data.disp_cost.coupon  || null;
    this.disp_cost.vat_rate  = data.disp_cost.vat_rate  || null;
    this.disp_cost.vat_amount  = data.disp_cost.vat_amount  || null;
  }

  /**
   * Method to get specific color details
   *
   * Usage:
   *
   * ```
   * var req = Color.get(10);
   * client.send(req).then(color => console.log(color));
   * ```
   *
   * @param {number} The ID of the order to get.
   * @return {Request} A request that resolves to a {@link Color} instance.
   */
  static get(orderId) {
    let method = Request.GET;
    let uri = `/orders/${orderId}`;

    return new Request(method, uri, null, data => new Order(data));
  }

  /**
   * Get a list of all colors
   *
   * Usage:
   *
   * ```
   * var req = Color.all();
   * client.send(req).then(colors => console.log(colors));
   * ```
   *
   * @return {Request} A request that resolves to an array of {@link Color}.
   */
  static all(options) {
    options = options ? new OrderOptions(options) : new OrderOptions();
    let method = Request.GET;
    let uri = `/orders`;

    return new Request(method, uri, options, data => data.map(d => new Order(d)));
  }
}

/**
 * Options that can be used when make a request for a subset.
 */
export class OrderOptions extends RequestParams {
  /**
   * Create an instance of Price Guide options.
   * @param {object} data Optional parameter data.
   * @param {string|null} The direction of the order to get. Acceptable values are "out": Gets placed orders, "in": Gets received orders.
   * @param {string|null} The status of the order to include or exclude.
                  - If you don't specify this value, this method retrieves orders in any status.
                  - You can pass a comma-separated string to specify multiple status to include/exclude.
                  - You can add a minus(-) sign to specify a status to exclude
   * @param {bool|null} Indicates whether the result retries filed or un-filed orders
   */
  constructor(data){
    super(data);

    data = data || {};
    /** @type {string|null} [data.color_id=null] The color Identification numbe of the item */
    this.direction = data.direction || null;
    /** @type {string|null} [guide_type='stock'] Indicates that which statistics to be provided. Options include "sold" and "stock" */
    this.status = data.status || null;
    /** @type {number|null} [new_or_used='N'] Indicates the condition of items that are included in the statistics. Acceptable values are: "N": new item (default) and "U": used item */
    this.filed = data.filed || false;
  }
}
