import React, { Component } from "react";
import { withAuthenticator } from '@aws-amplify/ui-react';

import Badge from 'react-bootstrap/Badge'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Tab from 'react-bootstrap/Tab'

import RestApi from '../components/restapi'
import StockItem from './StockItem'
import StockItemList from './StockItemList'
import { getColors } from "../components/loaders"

import { UsersApi } from '../components/rbApi'

var CryptoJS = require("crypto-js");
var Promise = require("bluebird");

class Overview extends Component {
  constructor(props) {
    super(props);

    this.sscreds = sessionStorage.getItem('btg.apiCreds');
    if (this.sscreds) {
      // Decrypt
      var bytes  = CryptoJS.AES.decrypt(this.sscreds, props.user.attributes.sub);
      this.creds = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }

    this.state = {
      blinis: [],
      rblinis: [],
      snirb: [],
      snibl: [],
      qdtbl: [],
      qdtrb: [],
    }
  }

  componentDidMount() {
    getColors()
    .then (res => {
      this.colors = res;
      this.refreshAllInconcistencies();
    })
  }

  refreshAllInconcistencies() {
    this.refreshInconcistencies('blinis');
    this.refreshInconcistencies('rblinis');
    this.refreshInconcistencies('clinis');
    this.refreshInconcistencies('snirb');
    this.refreshInconcistencies('snibl');
    this.refreshInconcistencies('qdtbl');
    this.refreshInconcistencies('qdtrb');
    this.refreshInconcistencies('qdtcl');
  }

  refreshInconcistencies(incType) {
    return RestApi.getInconsistencies(this.props.activeStore.storeId, incType.toUpperCase())
    .then(res => {
      const siList = res.data.map(item => {
        const color = this.colors.find(l => l.colorid === item.sCid)
        if (color) {
          item.sCname = color.name;
          item.sCcode = color.code;
        }
        return new StockItem(item);
      })

      this.setState({ [incType]: siList })
      return res;
    })
  }

  handleSelectionChange = (id, ids, checked) => {
    this.setState(state => {
      var affectedItems = state[id].filter(i => ids.includes(i.k))
      affectedItems.forEach(i => { i.chk = checked });
      return { [id]: state[id] };
    })
  }

  // BLINIS
  handleAddBLINIS = async (e) => {
    const data = this.state.blinis;
    var checked = data.filter(item => item.chk === true)

    const storeId = this.props.activeStore.storeId
    return Promise.map(checked, function(inv) {
      return RestApi.createStockFromBlI(
        storeId,
        inv.iId
      )
      .catch(err => {
        console.log(err);
      })
    }, { concurrency: 5 } )
    .then(res => {
      this.refreshInconcistencies('blinis');
      this.refreshInconcistencies('snirb');
      localStorage.setItem('btg.stockNeedsRefresh', JSON.stringify(true));
      return res;
    })
  }

  // SNIRB
  handleDeleteStockSNIRB = async (e) => {
    const data = this.state.snirb;
    var checked = data.filter(item => item.chk === true)

    const storeId = this.props.activeStore.storeId
    return Promise.map(checked, function(item) {
      return RestApi.deleteStock(
        storeId,
        item.sId
      )
      .catch(err => {
        console.log(err);
      })
    }, { concurrency: 5 } )
    .then(res => {
      this.refreshInconcistencies('blinis');
      this.refreshInconcistencies('rblinis');
      this.refreshInconcistencies('clinis');
      this.refreshInconcistencies('snirb');
      this.refreshInconcistencies('snibl');
      localStorage.setItem('btg.stockNeedsRefresh', JSON.stringify(true));
      return res;
    })
  }

  handleLinkSNIRB = async (e) => {
    const data = this.state.snirb;
    var checked = data.filter(item => item.chk === true)
    var canLink = checked.filter(item => item.rlId)

    const storeId = this.props.activeStore.storeId
    return Promise.map(canLink, function(item) {
      return RestApi.setRbListItemStockId(
        storeId,
        item.rId,
        item.sId
      )
      .catch(err => {
        console.log(err);
      })
    }, { concurrency: 5 } )
    .then(res => {
      this.refreshInconcistencies('rblinis');
      this.refreshInconcistencies('snirb');
      localStorage.setItem('btg.stockNeedsRefresh', JSON.stringify(true));
      return res;
    })
  }

  // RBLINIS
  handleAddRBLINIS = async (e) => {
    const data = this.state.rblinis;
    var checked = data.filter(item => item.chk === true)
    var mapped = checked.filter(item => item.sNo)

    const storeId = this.props.activeStore.storeId
    return Promise.map(mapped, function(item) {
      return RestApi.createStockFromRbLI(
        storeId,
        item.rId
      )
      .catch(err => {
        console.log(err);
      })
    }, { concurrency: 5 } )
    .then(res => {
      this.refreshInconcistencies('snirb');
      this.refreshInconcistencies('rblinis');
      localStorage.setItem('btg.stockNeedsRefresh', JSON.stringify(true));
      return res;
    })
  }

  // CLINIS
  handleAddCLINIS = async (e) => {
    const data = this.state.clinis;
    var checked = data.filter(item => item.chk === true)
    var mapped = checked.filter(item => item.sNo)

    const storeId = this.props.activeStore.storeId
    return Promise.map(mapped, function(item) {
      return RestApi.createStockFromCLI(
        storeId,
        item.rId
      )
      .catch(err => {
        console.log(err);
      })
    }, { concurrency: 5 } )
    .then(res => {
      this.refreshInconcistencies('snirb');
      this.refreshInconcistencies('clinis');
      localStorage.setItem('btg.stockNeedsRefresh', JSON.stringify(true));
      return res;
    })
  }

  // SNIBL
  handleDeleteStockSNIBL = async (e) => {
    const data = this.state.snibl;
    var checked = data.filter(item => item.chk === true)

    const storeId = this.props.activeStore.storeId
    return Promise.map(checked, function(item) {
      return RestApi.deleteStock(
        storeId,
        item.sId
      )
      .catch(err => {
        console.log(err);
      })
    }, { concurrency: 5 } )
    .then(res => {
      this.refreshInconcistencies('rblinis');
      this.refreshInconcistencies('snirb');
      this.refreshInconcistencies('snibl');
      localStorage.setItem('btg.stockNeedsRefresh', JSON.stringify(true));
      return res;
    })
  }

  handleCreateBlinvSNIBL = async (e) => {
    const data = this.state.snibl;
    var checked = data.filter(item => item.chk === true)
    console.log(checked);
  }

  // QDTBL
  handleSetStockQtyQDTBL = async (e) => {
    const data = this.state.qdtbl;
    var checked = data.filter(item => item.chk === true)

    const storeId = this.props.activeStore.storeId
    return Promise.map(checked, function(item) {
      return RestApi.setStockFromBlI(
        storeId,
        item.sId,
      )
      .catch(err => {
        console.log(err);
      })
    }, { concurrency: 5 } )
    .then(res => {
      this.refreshInconcistencies('snirb');
      this.refreshInconcistencies('qdtbl');
      this.refreshInconcistencies('qdtrb');
      this.refreshInconcistencies('qdtcl');
      localStorage.setItem('btg.stockNeedsRefresh', JSON.stringify(true));
      return res;
    })
  }

  handleSetStockBlinvQDTBL = async (e) => {
    const data = this.state.qdtbl;
    var checked = data.filter(item => item.chk === true)
    console.log(checked);
  }

  // QDTRB
  handleSetStockQtyQDTRB = async (e) => {
    const data = this.state.qdtrb;
    var checked = data.filter(item => item.chk === true)
    console.log(checked);

    const storeId = this.props.activeStore.storeId
    return Promise.map(checked, function(item) {
      return RestApi.setStockFromRbLI(
        storeId,
        item.sId,
      )
      .catch(err => {
        console.log(err);
      })
    }, { concurrency: 5 } )
    .then(res => {
      // this.refreshInconcistencies('snirb');
      this.refreshInconcistencies('qdtbl');
      this.refreshInconcistencies('qdtrb');
      localStorage.setItem('btg.stockNeedsRefresh', JSON.stringify(true));
      return res;
    })
  }

  handleSetStockRbQDTRB = async (e) => {
    const data = this.state.qdtrb;
    var checked = data.filter(item => item.chk === true)
    console.log(checked);

    var rbUser = new UsersApi(this.creds.rb);

    const storeId = this.props.activeStore.storeId
    return Promise.map(checked, function(item) {
      if (item.sQ > 0) { // Update
        // Part
        if (item.sType === 2) {
          return rbUser.usersPartlistsPartsUpdate(
            item.rlId,
            item.rNo,
            item.rCid,
            item.sQ
          )
          .then(res => {
            return rbUser.usersPartlistsPartsRead(
              item.rlId,
              item.rNo,
              item.rCid
            )
          })
          .then(res => {
            var sNos = null;
            if (res.body.part.external_ids) {
              sNos = JSON.stringify(res.body.part.external_ids.BrickLink);
            }

            const uItem = {
              rlId: item.rlId,
              rQ: res.body.quantity,
              rNo: res.body.part.part_num,
              rCid: res.body.color.id,
              rType: 2, // PART
              sNos: sNos || null,
            }

            return RestApi.setRbListItem(
              storeId,
              null,
              uItem.rlId,
              uItem.rNo,
              uItem.rCid,
              uItem.rType,
              uItem.rQ,
              uItem.sNos,
            )
          })
          .catch(err => {
            console.log(err);
          })
        }

        // Set
        if (item.sType === 3) {
          return rbUser.usersSetlistsSetsUpdate(
            item.rlId,
            item.rNo,
            item.sQ
          )
          .then(res => {
            return rbUser.usersSetlistsSetsRead(
              item.rlId,
              item.rNo
            )
          })
          .then(res => {
            const uItem = {
              rlId: item.rlId,
              rQ: res.body.quantity,
              rNo: res.body.set.set_num,
              rCid: 9999,
              rType: 3, // SET
              sNos: null,
            }

            return RestApi.setRbListItem(
              storeId,
              null,
              uItem.rlId,
              uItem.rNo,
              uItem.rCid,
              uItem.rType,
              uItem.rQ,
              uItem.sNos,
            )
          })
          .catch(err => {
            console.log(err);
          })
        }
      } else { // Delete
        // Part
        if (item.sType === 2) {
          return rbUser.usersPartlistsPartsDelete(
            item.rlId,
            item.rNo,
            item.rCid
          )
          .then(res => {
            return RestApi.deleteRbListItem(storeId, item.rId);
          })
        }
        // Set
        if (item.sType === 3) {
          return rbUser.usersSetlistsSetsDelete(
            item.rlId,
            item.rNo
          )
          .then(res => {
            return RestApi.deleteRbListItem(storeId, item.rId);
          })
        }
      }
    }, { concurrency: 5 } )
    .then(res => {
      this.refreshInconcistencies('snirb');
      this.refreshInconcistencies('qdtbl');
      this.refreshInconcistencies('qdtrb');
      localStorage.setItem('btg.stockNeedsRefresh', JSON.stringify(true));
      return res;
    })


  }

  // QDTCL
  handleSetStockQtyQDTCL = async (e) => {
    const data = this.state.qdtcl;
    var checked = data.filter(item => item.chk === true)

    const storeId = this.props.activeStore.storeId
    return Promise.map(checked, function(item) {
      return RestApi.setStockQty(
        storeId,
        item.sId,
        item.rQ
      )
      .catch(err => {
        console.log(err);
      })
    }, { concurrency: 5 } )
    .then(res => {
      this.refreshInconcistencies('qdtbl');
      this.refreshInconcistencies('qdtrb');
      this.refreshInconcistencies('qdtcl');
      localStorage.setItem('btg.stockNeedsRefresh', JSON.stringify(true));
      return res;
    })
  }

  handleSetStockCLQDTCL = async (e) => {
    const data = this.state.qdtcl;
    var checked = data.filter(item => item.chk === true)

    const storeId = this.props.activeStore.storeId
    return Promise.map(checked, function(item) {
      return RestApi.setStockListItem(
        storeId,
        item.rlId,
        item.rId,
        item.sQ
      )
      .catch(err => {
        console.log(err);
      })
    }, { concurrency: 5 } )
    .then(res => {
      this.refreshInconcistencies('qdtbl');
      this.refreshInconcistencies('qdtrb');
      this.refreshInconcistencies('qdtcl');
      localStorage.setItem('btg.stockNeedsRefresh', JSON.stringify(true));
      return res;
    })
  }


  getIncSumByType(type) {
    return this.state[type] ? this.state[type].length : 0;
  }

  render() {
    const havePrivileges = RestApi.hasPrivilege(this.props.activeStore.privileges, 'StoreUpdateStock')
    const haveBlPrivileges = RestApi.hasPrivilege(this.props.activeStore.privileges, 'StoreUpdateBl')

    const blinisActions = [];
    blinisActions.push({ label: 'Add to stock', cb: this.handleAddBLINIS, enabled: havePrivileges })
    const snirbActions = [];
    snirbActions.push({ label: 'Delete Stock', cb: this.handleDeleteStockSNIRB, enabled: havePrivileges })
    snirbActions.push({ label: 'Link to List Items', cb: this.handleLinkSNIRB, enabled: havePrivileges })
    const rblinisActions = [];
    rblinisActions.push({ label: 'Add to stock', cb: this.handleAddRBLINIS, enabled: havePrivileges })
    const clinisActions = [];
    clinisActions.push({ label: 'Add to stock', cb: this.handleAddCLINIS, enabled: havePrivileges })
    const sniblActions = [];
    sniblActions.push({ label: 'Delete Stock', cb: this.handleDeleteStockSNIBL, enabled: havePrivileges })
    sniblActions.push({ label: 'Create in BL', cb: this.handleCreateBlinvSNIBL, enabled: haveBlPrivileges })
    const qdtblActions = [];
    qdtblActions.push({ label: 'Change Stock', cb: this.handleSetStockQtyQDTBL, enabled: havePrivileges })
    qdtblActions.push({ label: 'Change BL', cb: this.handleSetStockBlinvQDTBL, enabled: haveBlPrivileges })
    const qdtrbActions = [];
    qdtrbActions.push({ label: 'Change Stock', cb: this.handleSetStockQtyQDTRB, enabled: havePrivileges })
    qdtrbActions.push({ label: 'Change RB', cb: this.handleSetStockRbQDTRB, enabled: haveBlPrivileges })
    const qdtclActions = [];
    qdtclActions.push({ label: 'Change Stock', cb: this.handleSetStockQtyQDTCL, enabled: havePrivileges })
    qdtclActions.push({ label: 'Change CL', cb: this.handleSetStockCLQDTCL, enabled: haveBlPrivileges })

    const blinis = this.getIncSumByType('blinis');
    const rblinis = this.getIncSumByType('rblinis');
    const clinis = this.getIncSumByType('clinis');
    const snirb = this.getIncSumByType('snirb');
    const snibl = this.getIncSumByType('snibl');
    const qdtbl = this.getIncSumByType('qdtbl');
    const qdtrb = this.getIncSumByType('qdtrb');
    const qdtcl = this.getIncSumByType('qdtcl');

    return (
      <Tab.Container id="left-tabs-example" defaultActiveKey='blinis'>
        <Row>
          <Col md="auto">
            <Nav variant="pills" className="flex-column" >
              <Nav.Item>
                <Nav.Link disabled={blinis === 0} className="p-1" eventKey="blinis">
                Inventories without stock <Badge className={'float-right mt-1 ml-2'} variant="dark">{blinis}</Badge></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link disabled={snirb === 0} className="p-1" eventKey="snirb">
                Stock not in RB or CL <Badge className={'float-right mt-1'} variant="dark">{snirb}</Badge></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link disabled={rblinis === 0} className="p-1" eventKey="rblinis">
                RB items without stock <Badge className={'float-right mt-1'} variant="dark">{rblinis}</Badge></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link disabled={clinis === 0} className="p-1" eventKey="clinis">
                CL items without stock <Badge className={'float-right mt-1'} variant="dark">{clinis}</Badge></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link disabled={snibl === 0} className="p-1" eventKey="snibl">
                Stock not in BL <Badge className={'float-right mt-1'} variant="dark">{snibl}</Badge></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link disabled={qdtbl === 0} className="p-1" eventKey="qdtbl">
                Stock different to BL <Badge className={'float-right mt-1'} variant="dark">{qdtbl}</Badge></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link disabled={qdtrb === 0} className="p-1" eventKey="qdtrb">
                Stock different to RB <Badge className={'float-right mt-1'} variant="dark">{qdtrb}</Badge></Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link disabled={qdtcl === 0} className="p-1" eventKey="qdtcl">
                Stock different to CL <Badge className={'float-right mt-1'} variant="dark">{qdtcl}</Badge></Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col >
            <Tab.Content>
              <Tab.Pane mountOnEnter unmountOnExit eventKey="blinis">
                <StockItemList id={'blinis'} data={this.state.blinis}
                onChange={this.handleSelectionChange} actions={blinisActions} />
              </Tab.Pane>
              <Tab.Pane mountOnEnter unmountOnExit eventKey="snirb">
                <StockItemList id={'snirb'} data={this.state.snirb}
                onChange={this.handleSelectionChange} actions={snirbActions} />
              </Tab.Pane>
              <Tab.Pane mountOnEnter unmountOnExit eventKey="rblinis">
                <StockItemList id={'rblinis'} data={this.state.rblinis}
                onChange={this.handleSelectionChange} actions={rblinisActions} />
              </Tab.Pane>
              <Tab.Pane mountOnEnter unmountOnExit eventKey="clinis">
                <StockItemList id={'clinis'} data={this.state.clinis}
                onChange={this.handleSelectionChange} actions={clinisActions} />
              </Tab.Pane>
              <Tab.Pane mountOnEnter unmountOnExit eventKey="snibl">
                <StockItemList id={'snibl'} data={this.state.snibl}
                onChange={this.handleSelectionChange} actions={sniblActions} />
              </Tab.Pane>
              <Tab.Pane mountOnEnter unmountOnExit eventKey="qdtbl">
                <StockItemList id={'qdtbl'} data={this.state.qdtbl}
                onChange={this.handleSelectionChange} actions={qdtblActions} />
              </Tab.Pane>
              <Tab.Pane mountOnEnter unmountOnExit eventKey="qdtrb">
                <StockItemList id={'qdtrb'} data={this.state.qdtrb}
                onChange={this.handleSelectionChange} actions={qdtrbActions} />
              </Tab.Pane>
              <Tab.Pane mountOnEnter unmountOnExit eventKey="qdtcl">
                <StockItemList id={'qdtcl'} data={this.state.qdtcl}
                onChange={this.handleSelectionChange} actions={qdtclActions} />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    );
  }
}

export default withAuthenticator(Overview)
