import React, { Component } from "react";
import { withAuthenticator } from '@aws-amplify/ui-react';

// import { Link } from "react-router-dom";

import Button from 'react-bootstrap/Button'
// import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
// import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'

import RestApi from '../components/restapi'

class Stores extends Component {
  constructor(props) {
    super(props);

    const stores = JSON.parse(localStorage.getItem('btg.stores'));
    stores || this.refreshStores();

    this.state = {
      stores: stores || [],
      storeName: '',
    }
  }

  componentWillUnmount() {
    this.props.saveUserPreferences();
  }

      // create store (company)
      // create user_role w/ company id & role Owner
  refreshStores() {
    return RestApi.getStore()
    .then(res => {
      localStorage.setItem('btg.stores', JSON.stringify(res.data));
      this.setState({ stores: res.data });
      // var preferences = JSON.parse(res.data[0].preferences);
      // if (!preferences) { preferences = {}; };
      // localStorage.setItem('btg.userPreferences', JSON.stringify(preferences));
      // delete res.data[0].preferences;
      // this.setState({ user: res.data[0] });
      return res;
    })
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSetActive = async (event) => {
    this.props.setActiveStore(this.state.stores.filter(store => store.storeId.toString() === event.target.id)[0]);
  }

  handleRenameShow = async (event) => {
    const storeName = this.state.stores.filter(store => store.storeId.toString() === event.target.id)[0].storeName
    this.setState({ showRename: true, storeName: storeName, storeid: event.target.id })
  }
  handleRenameClose = async () => { this.setState({ showRename: false, storeName: '', storeid: null }) }
  handleRename = async () => {
    RestApi.setStore(this.state.storeid, this.state.storeName)
    .then(res => {
      this.refreshStores();
    })
    this.setState({ showRename: false, storeName: '', storeid: null })
  }
  renderRenameModal() {
    return (
      <Modal show={this.state.showRename} onHide={this.handleRenameClose}>
        <Modal.Header closeButton>
          <Modal.Title>Rename Store</Modal.Title>
        </Modal.Header>
        <Modal.Body><Form.Control type="text" placeholder="Store Name" id='storeName'
          onChange={this.handleChange} value={this.state.storeName} /></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleRenameClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={this.handleRename}>
            Rename
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }


  handleDeleteShow = async (event) => {
    const storeName = this.state.stores.filter(store => store.storeId.toString() === event.target.id)[0].storeName
    this.setState({ showDelete: true, storeNameToDelete: storeName, storeid: event.target.id })
  }
  handleDeleteClose = async () => { this.setState({ showDelete: false, storeName: '', storeid: null }) }
  handleDelete = async () => {
    RestApi.deleteStore(this.state.storeid)
    .then(res => {
      this.refreshStores();
    })
    this.setState({ showDelete: false, storeName: '', storeid: null })
  }
  renderDeleteModal() {
    return (
      <Modal show={this.state.showDelete} onHide={this.handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Store</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>WARNING! You are about to delete store {this.state.storeNameToDelete}.
          All information about the
          store, including stock, prices, storage locations etc, will be removed
          permanently. This action can not be undone!</p>
          <p>Please enter the store name to continue</p>
          <Form.Control type="text" placeholder="Store Name" id='storeName'
            onChange={this.handleChange} value={this.state.storeName} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleDeleteClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={this.handleDelete}
            disabled={this.state.storeName !== this.state.storeNameToDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  handleAddNewShow = async () => {
    this.setState({ showAddNew: true, storeName: '' })
  }
  handleAddNewClose = async () => {
    this.setState({ showAddNew: false, storeName: '' })
  }
  handleAddNewAdd = async () => {
    RestApi.createStore(this.state.storeName)
    .then(res => {
      this.refreshStores();
    })
    this.setState({ showAddNew: false, storeName: '' })
  }
  renderAddStoreModal() {
    return (
      <Modal show={this.state.showAddNew} onHide={this.handleAddNewClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Store</Modal.Title>
        </Modal.Header>
        <Modal.Body><Form.Control type="text" placeholder="Store Name" id='storeName'
          onChange={this.handleChange} value={this.state.storeName} /></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleAddNewClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={this.handleAddNewAdd}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  renderStoreTable() {
    var storeRows = [];
    const activeStore = this.props.activeStore;

    this.state.stores.forEach(store => {
      const active = activeStore && store.storeId === activeStore.storeId;
      const activeMsg = active ? 'Active' : 'Use';
      const renameEnabled = RestApi.hasPrivilege(store.privileges, 'StoreEditDetails');
      const shareEnabled = RestApi.hasPrivilege(store.privileges, 'StoreShare');
      const deleteEnabled = RestApi.hasPrivilege(store.privileges, 'StoreDelete');
      storeRows.push(
      <tr key={store.storeId}>
        <td>{store.storeId}</td>
        <td>{store.storeName}</td>
        <td>{store.roleName}</td>
        <td className="text-right">
          <Button onClick={this.handleSetActive} variant="outline-dark"
            id={store.storeId} size="sm" active={active}>{activeMsg}</Button>{' '}
          <Button onClick={this.handleRenameShow} disabled={!renameEnabled} variant="outline-dark"
            id={store.storeId} size="sm">Rename</Button>{' '}
          <Button onClick={this.handleShareShow} disabled={!shareEnabled} variant="outline-dark"
            id={store.storeId} size="sm">Share</Button>{' '}
          <Button onClick={this.handleDeleteShow} disabled={!deleteEnabled} variant="outline-danger"
            id={store.storeId} size="sm">Delete</Button>{' '}
        </td>
      </tr>
      )
    });

    return (
      <>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Role</th>
            <th className="text-right">
              <Button onClick={this.handleAddNewShow} variant="outline-dark"
                size="sm">Create New</Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {storeRows}
        </tbody>
      </Table>
      {this.renderAddStoreModal()}
      {this.renderRenameModal()}
      {this.renderDeleteModal()}
      </>
    )
  }

  render() {
    return (
      <Container className='Home'>
        <h1>Your Stores</h1>
        {this.renderStoreTable()}
      </Container>
    );
  }
}

export default withAuthenticator(Stores)
