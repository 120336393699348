import React, { Component } from "react";
import { withAuthenticator } from '@aws-amplify/ui-react';
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

import { firstBy } from "thenby";

import { getOrders } from "../components/loaders"

class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lists: [],
      listItems: {},
      selectedList: 0,
      graphInterval: 3
    }
  }

  componentDidMount() {
    getOrders(this.props.activeStore.storeId).then(res => this.setState({ orders: res }))
  }

  orderSumsDaily() {
    const collection = this.state.orders.map(x => ({ ...x, day: new Date(x.oDate).toISOString().split('T')[0]}));
    console.log(collection);
    const sumPerDay = collection.reduce((acc, cur) => {
      acc[cur.day] = acc[cur.day] || {};
      acc[cur.day].sub = acc[cur.day].sub + cur.sub || cur.sub;
      acc[cur.day].shp = acc[cur.day].shp + (cur.grnd - cur.sub) || (cur.grnd - cur.sub);
      return acc;
    }, {})

    return sumPerDay;
  }

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
        diff = d.getDate() - day + (day === 0 ? -6:1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  orderSumsWeekly() {
    const collection = this.state.orders.map(x => ({ ...x, day: new Date(x.oDate).toISOString().split('T')[0]}));

    const sumPerWeek = collection.reduce((acc, cur) => {
      const monday = this.getMonday(new Date(cur.day));
      const d = monday.toISOString().split('T')[0];
      acc[d] = acc[d] || {};
      acc[d].sub = acc[d].sub + cur.sub || cur.sub;
      acc[d].shp = acc[d].shp + (cur.grnd - cur.sub) || (cur.grnd - cur.sub);
      return acc;
    }, {})

    // console.log(sumPerWeek);
    return sumPerWeek;
  }

  orderSumsMonthly() {
    const collection = this.state.orders.map(x => ({ ...x, day: `${new Date(x.oDate).toISOString().split('-')[0]}-${new Date(x.oDate).toISOString().split('-')[1]}`}));
    // console.log(collection);
    const sumPerDay = collection.reduce((acc, cur) => {
      acc[cur.day] = acc[cur.day] || {};
      acc[cur.day].sub = acc[cur.day].sub + cur.sub || cur.sub;
      acc[cur.day].shp = acc[cur.day].shp + (cur.grnd - cur.sub) || (cur.grnd - cur.sub);
      return acc;
    }, {})

    // console.log(sumPerDay);
    return sumPerDay;
  }

  getOrderData() {
    if (!this.state.orders) return null;

    var orderSums = {};
    if (this.state.graphInterval === 1)
      orderSums = this.orderSumsDaily();
    else if (this.state.graphInterval === 2)
      orderSums = this.orderSumsWeekly();
    else
      orderSums = this.orderSumsMonthly();

    const mappedData = [];
    for (var key in orderSums) {
      mappedData.push({
        Date: key,
        Value: Math.round(orderSums[key].sub, 2),
        Shipping: Math.round(orderSums[key].shp, 2)
      })
    }

    return mappedData.sort(firstBy('Date'));
  }

  handleSetInterval = async (e) => {
    const id = parseInt(e.target.id)
    // console.log(e.target);
    // console.log(id);
    this.setState({ graphInterval: id })
  }

  render() {

    const data = this.getOrderData();

    return (
      <Container className='Home'>
        <Row>
          <Col>
          <ResponsiveContainer width="100%" height={400}>
          <BarChart width={1200} height={500} data={data}
                margin={{top: 20, right: 30, left: 20, bottom: 5}}>
           <CartesianGrid strokeDasharray="3 3"/>
           <XAxis dataKey="Date"/>
           <YAxis/>
           <Tooltip/>
           <Legend />
           <Bar dataKey="Value" stackId="a" fill="#8884d8" />
           <Bar dataKey="Shipping" stackId="a" fill="#82ca9d" />
          </BarChart>
          </ResponsiveContainer>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button onClick={this.handleSetInterval} id={2}>Weekly</Button>
            <Button onClick={this.handleSetInterval} id={3}>Monthly</Button>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withAuthenticator(Reports)
