import React, { Component } from 'react';
// import { Grid, Menu, Icon, Dropdown } from '@material-ui/core';
import { Link } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'

class Header extends Component {

  renderAdminMenu() {
    if (!this.props.activeStore) return
    return(
      <NavDropdown title="Admin" id="basic-nav-dropdown">
        <NavDropdown.Item as={Link} to='/admin/colors'>Colors</NavDropdown.Item>
        <NavDropdown.Item as={Link} to='/admin/categories'>Categories</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item as={Link} to='/'>Mappings</NavDropdown.Item>
        <NavDropdown.Item as={Link} to='/'>Catalog</NavDropdown.Item>
        <NavDropdown.Divider />
      </NavDropdown>
    )
  }

  renderStoreMenu() {
    if (!this.props.activeStore) return
    return(
      <NavDropdown title={this.props.activeStore.storeName} id="basic-nav-dropdown">
        <NavDropdown.Item as={Link} to='/overview'>Overview</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item as={Link} to='/sets'>Sets</NavDropdown.Item>
        <NavDropdown.Item as={Link} to='/setrating'>Set Rating</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item as={Link} to='/reports'>Reports</NavDropdown.Item>
        <NavDropdown.Item as={Link} to='/restock'>Restock</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item as={Link} to='/stock'>Stock</NavDropdown.Item>
        <NavDropdown.Item as={Link} to='/pick'>Picking Lists</NavDropdown.Item>
        <NavDropdown.Item as={Link} to='/backlog'>Backlog</NavDropdown.Item>
        <NavDropdown.Item as={Link} to='/storage'>Storage Locations</NavDropdown.Item>
        <NavDropdown.Item as={Link} to='/'>Sale Templates</NavDropdown.Item>
        <NavDropdown.Item as={Link} to='/custom'>Custom Lists</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item as={Link} to='/syncin'>Sync</NavDropdown.Item>
        <NavDropdown.Item as={Link} to='/orders'>Orders</NavDropdown.Item>
      </NavDropdown>
    )
  }

  renderAccountMenu() {
    if (!this.props.isAuthenticated) {
      return(
        <Nav.Link as={Link} to="/signin">Sign In</Nav.Link>
      )
    }
    return(
      <NavDropdown title="Account" id="basic-nav-dropdown">
        <NavDropdown.Item as={Link} to='/apicred'>Api Credentials</NavDropdown.Item>
        <NavDropdown.Item as={Link} to='/stores'>Stores</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item as={Link} to='/enccred'>Encrypt Credentials</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item onClick={this.props.handleLogout}>Sign Out</NavDropdown.Item>
      </NavDropdown>
    )
  }

  render() {
    return(
      <Navbar className='Header'>
        <Navbar.Brand as={Link} to="/">
          <img
            alt=""
            src="/brickthegap.jpg"
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{' '}
          Brick The Gap
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/sigdnin">Home</Nav.Link>
            <Nav.Link as={Link} to="/">Link</Nav.Link>
          </Nav>
          <Nav>
            {this.renderAdminMenu()}
            {this.renderStoreMenu()}
            {this.renderAccountMenu()}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

export default Header;
