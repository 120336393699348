import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Link } from 'react-router-dom'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'


export default class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: "",
      email: "",
      password: "",
      codeSent: false,
      confirmed: false,
      confirmPassword: "",
      isConfirming: false,
      isSendingCode: false,
      error: false,
    };
  }

  validateCodeForm() {
    return this.state.email.length > 0;
  }

  validateResetForm() {
    return (
      this.state.code.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSendCodeClick = async event => {
    event.preventDefault();

    this.setState({ isSendingCode: true, error: false, errorMessage: '' });

    try {
      await Auth.forgotPassword(this.state.email);
      this.setState({ codeSent: true });
    } catch (e) {
      this.setState({ isSendingCode: false, error: true, errorMessage: e.message });
    }
  };

  handleConfirmClick = async event => {
    event.preventDefault();

    this.setState({ isConfirming: true, error: false, errorMessage: '' });

    try {
      await Auth.forgotPasswordSubmit(
        this.state.email,
        this.state.code,
        this.state.password
      );
      this.setState({ confirmed: true });
    } catch (e) {
      this.setState({ isConfirming: false, error: true, errorMessage: e.message});
    }
  };

  renderRequestCodeForm() {
    return (
      <Container>
      <h2>Reset Password</h2>
      <p>Please enter your email address. A verification code that allows you to reset your password will be sent to that address.</p>
      <Form className="ResetPassword" onSubmit={this.handleSendCodeClick}>
        <Form.Group controlId="email">
          <Form.Label>Email address</Form.Label>
          <Form.Control type='email' placeholder='Email'
            onChange={this.handleChange} value={this.state.email} autoFocus />
        </Form.Group>
        <Button type='submit' disabled={!this.validateCodeForm()}>Send Confirmation</Button>
        <Alert variant="danger" show={this.state.error}>
          <Alert.Heading>Sorry</Alert.Heading>
          <p>{this.state.errorMessage}</p>
        </Alert>
      </Form>
      </Container>
    );
  }

  renderConfirmationForm() {
    return (
      <Container>
      <h2>Reset Password</h2>
      <p>We just sent you a verification code at your registered email address. Please enter that, along with your new password to continue.</p>
      <Form className="ResetPassword" onSubmit={this.handleConfirmClick}>
        <Form.Group controlId="code">
          <Form.Label>Confirmation Code</Form.Label>
          <Form.Control type='tel' onChange={this.handleChange}
            value={this.state.code} autoFocus />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control type='password' placeholder='Password'
            onChange={this.handleChange} value={this.state.password} />
        </Form.Group>
        <Form.Group controlId="confirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control type='password' placeholder='Password'
            onChange={this.handleChange} value={this.state.confirmPassword} />
        </Form.Group>
        <Button type='submit' disabled={!this.validateResetForm()}>Confirm</Button>
        <Alert variant="danger" show={this.state.error}>
          <Alert.Heading>Sorry</Alert.Heading>
          <p>{this.state.errorMessage}</p>
        </Alert>
        <hr />
      </Form>
      </Container>
    );
  }

  renderSuccessMessage() {
    return (
      <Container text>
      <h2>Your password has been reset</h2>
      <p><Link to="/signin">Click here to sign in with your new credentials.</Link></p>
      </Container>
    );
  }

  render() {
    return (
      !this.state.codeSent
        ? this.renderRequestCodeForm()
        : !this.state.confirmed
          ? this.renderConfirmationForm()
          : this.renderSuccessMessage()
    );
  }
}
