import React, { Component } from "react";
import { withAuthenticator } from '@aws-amplify/ui-react';

import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'

import RestApi from '../components/restapi'
import StockItem from './StockItem'
import StockItemList from './StockItemList'
import { StorageLocationEditorModal } from './StorageLocation'
import { cleanUpLoaders, getColors, getRbLists, getPickingLists, refreshPickingLists, getStorage, getBacklog, getOrders } from "../components/loaders"

import { firstBy } from "thenby";

class Pick extends Component {
  constructor(props) {
    super(props);

    cleanUpLoaders();

    getColors().then(res => this.colors = res);

    this.state = {
      lists: [],
      listItems: {},
      selectedList: 0,
    }
  }

  componentDidMount() {
    getRbLists(this.props.activeStore.storeId).then(res => this.setState({ rblists: res }))
    getOrders(this.props.activeStore.storeId).then(res => this.setState({ orders: res }))
    getPickingLists(this.props.activeStore.storeId).then(res => this.setState({ lists: res }))
    getBacklog(this.props.activeStore.storeId).then(res => { this.setState({ backlogs: res }) })
    getStorage(this.props.activeStore.storeId)
    .then(res => {
      const warehouses = [];
      const map = new Map();
      for (const item of res) {
          if(!map.has(item.wId)){
              map.set(item.wId, true);    // set any value to Map
              warehouses.push(item);
          }
      }
      this.setState({ warehouses: warehouses })
    })
  }

  refreshListItems(listId, clearFirst) {
    return RestApi.getPickingListItem(this.props.activeStore.storeId, listId)
    .then(res => {
      this.setState(state => {
        var listItems = state.listItems;
        if (clearFirst) listItems = {};
        listItems[listId] = res.data.map(item => {
          const color = this.colors.find(l => l.colorid === item.sCid)
          if (color) {
            item.sCname = color.name;
            item.sCcode = color.code;
          }
          return new StockItem(item);
        })
        return { listItems: listItems }
      });
      return res;
    })
  }

  calcAutoName(state) {
    var autoName = '';
    if (state.clFrom === 'o') autoName = `Order #${state.clOrder ? `${state.clOrder} - ${state.orders.find(o => o.oId === state.clOrder).buyer}` : ''}`
    if (state.clFrom === 'l') autoName = `List ${state.clList ? state.rblists.find(l => l.rlId === parseInt(state.clList)).rlName : ''}`
    if (state.clFrom === 'b') autoName = `Backlog ${state.clBacklog ? state.backlogs.find(l => l.bId === parseInt(state.clBacklog)).bNo : ''}`
    if (state.clFrom === 'p') autoName = `Rem of "${state.clPList ? state.lists.find(l => l.plId === parseInt(state.clPList)).plName : ''}"`

    if (state.clFrom !== 'o' && state.clPickFrom) autoName = `${autoName} from ${state.warehouses.find(l => l.wId === parseInt(state.clPickFrom)).wName}`
    return autoName;
  }

  handleChange = event => {
    if (!event.target.validity.valid) return;

    const tid = event.target.id || event.target.name
    var val = event.target.value;
    if (event.target.type === 'checkbox') val = event.target.checked;

    this.setState(state => {
      state[tid] = val
      return {
        [tid]: val,
        clAutoName: this.calcAutoName(state)
      };
    });
  }

  handleItemChange = (e) => {
    const id = parseInt(e.target.id);
    var val = e.target.value;
    if (!val && e.target.attributes && e.target.attributes.value && e.target.attributes.value.value)
      val = e.target.attributes.value.value
    const type = e.target.type;
    const selectedItem = this.state.listItems[this.state.selectedList].find(i => i.rId === id)
    // console.log(id);
    // console.log(val);
    // console.log(type);
    if (type === 'select-one') {
      console.log(selectedItem);
      RestApi.setPickingListItemPicked(
        this.props.activeStore.storeId,
        this.state.selectedList,
        id,
        val)
      .then(res => {
        console.log(res.data);
        localStorage.setItem('btg.stockNeedsRefresh', JSON.stringify(true));
        this.refreshListItems(this.state.selectedList, true);
      })
    } else if (val === 'writeOff') {
      // console.log(selectedItem);
      this.setState({ showAddWriteOff: true, selectedItem: selectedItem, selWOQ: "1" });
    } else if (val === 'editLoc') {
      this.setState({ showEditStorage: true, selectedItem: selectedItem });
    }
  }

  handleSelectList = async (e) => {
    const selectedList = parseInt(e.target.id);
    if (!this.state.listItems[selectedList]) this.refreshListItems(selectedList);
    this.setState({ selectedList: selectedList });
  }

  handleCreateListShow = async (e) => {
    this.setState({ showCreateList: true });
  }

  handleCreateListHide = async (e) => {
    this.setState({ showCreateList: false });
  }

  handleCreateListSubmit = async (e) => {
    RestApi.createPickingList(
      this.props.activeStore.storeId,
      this.state.clName ? this.state.clName : this.state.clAutoName,
      this.state.clFrom === 'o' ? this.state.clOrder : null,
      this.state.clFrom === 'l' ? this.state.clList : null,
      this.state.clFrom === 'p' ? this.state.clPList : null,
      this.state.clFrom === 'b' ? this.state.clBacklog : null,
      this.state.clPickFrom
    )
    .then(res => {
      return refreshPickingLists(this.props.activeStore.storeId)
    })
    .then(res => this.setState({ lists: res, showCreateList: false }))
  }

  handleDeleteListShow = async (e) => {
    const listId = parseInt(e.target.id);
    const list = this.state.lists.find(l => l.plId === listId);
    const listName = list ? list.plName : null;
    this.setState({ showDeleteList: true, toDeleteListName: listName, toDeleteListId: listId });
  }

  handleDeleteListHide = async (e) => {
    this.setState({ showDeleteList: false });
  }

  handleDeleteListSubmit = async (e) => {
    const id = parseInt(e.target.id)
    const selId = id === this.state.selectedList ? 0 : this.state.selectedList;

    RestApi.deletePickingList(
      this.props.activeStore.storeId,
      id
    )
    .then(res => {
      return refreshPickingLists(this.props.activeStore.storeId)
    })
    .then(res => this.setState({ lists: res, showDeleteList: false, selectedList: selId, clPList: 0 }))
  }

  handleEditStorageHide = async (e) => {
    this.setState({ showEditStorage: false });
  }

  handleEditStorageSubmit = async (e) => {
    if (e.needRefresh) {
      this.refreshListItems(this.state.selectedList, true);
    }
    this.setState({ showEditStorage: false });
  }

  handleAddWriteOffHide = async (e) => {
    this.setState({ showAddWriteOff: false });
  }

  handleAddWriteOffSubmit = async (e) => {
    const id = parseInt(e.target.id)
    const q = parseInt(this.state.selWOQ)

    RestApi.createPickingListItem(
      this.props.activeStore.storeId,
      null,
      "Write Offs",
      id,
      q
    )
    .then(res => {
      return refreshPickingLists(this.props.activeStore.storeId)
    })
    .then(res => this.setState({ lists: res, showAddWriteOff: false }))
  }


  clIsValid() {
    if (this.state.clName && this.state.clName.length > 255)
      return false;

    if (!this.state.clFrom) return false;
    if (this.state.clFrom === 'o' && !this.state.clOrder) return false;
    if (this.state.clFrom === 'l' && (!this.state.clList || !this.state.clPickFrom)) return false;
    if (this.state.clFrom === 'p' && (!this.state.clPList || !this.state.clPickFrom)) return false;

    return true;
  }

  renderListItems() {
    var listItems = this.state.listItems[this.state.selectedList] || [];

    const sortKeys = {
      sWname: 'Location',
      sName: 'Name',
      sNo: 'Item Num',
    }

    return (
      <StockItemList id={this.state.selectedList} format="pickingList" data={listItems}
      sortKeys={sortKeys} onItemChange={this.handleItemChange} />
    )
  }

  renderLists() {
    var lists = this.state.lists;
    lists.sort(firstBy('plId', { ignoreCase: true }))

    var listRows = [];

    lists.forEach(list => {
      listRows.push(
      <tr key={list.plId}>
        <td><Button variant="link" size="sm" id={list.plId}
        onClick={this.handleSelectList}>{list.plName}</Button></td>
        <td><Button onClick={this.handleDeleteListShow} id={list.plId} variant="danger" size="sm">x</Button></td>
      </tr>
      )
    });

    return (
      <>
      <Table striped bordered hover size="sm">
        <thead>
          <tr><th>Picking Lists</th><td><Button onClick={this.handleCreateListShow} size="sm">+</Button></td></tr>
        </thead>
        <tbody>
          {listRows}
        </tbody>
      </Table>
      </>
    )
  }

  render() {
    return (
      <Container className='Home'>
        <Row>
          <Col></Col>
          <Col md="auto" className="text-right">
            <Alert className="mb-0" variant={this.state.statusVariant}>
              {this.state.status}
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col md="auto">
            {this.renderLists()}
          </Col>
          <Col>
            {this.renderListItems()}
          </Col>
        </Row>
        <CreateListModal {...this.state} show={this.state.showCreateList}
        onHide={this.handleCreateListHide} onSubmit={this.handleCreateListSubmit}
        onChange={this.handleChange} validated={this.clIsValid()} />
        <DeleteListModal show={this.state.showDeleteList} onHide={this.handleDeleteListHide}
        onSubmit={this.handleDeleteListSubmit} listName={this.state.toDeleteListName}
        listId={this.state.toDeleteListId} />
        <StorageLocationEditorModal {...this.props} show={this.state.showEditStorage}
        onHide={this.handleEditStorageHide} onSubmit={this.handleEditStorageSubmit}
        item={this.state.selectedItem} />
        <AddWriteOffModal {...this.state} show={this.state.showAddWriteOff}
        onHide={this.handleAddWriteOffHide} onSubmit={this.handleAddWriteOffSubmit}
        onChange={this.handleChange} item={this.state.selectedItem} />
      </Container>
    );
  }
}

export default withAuthenticator(Pick)

const CreateListModal = (props) => {
  var orderOptions = [];
  if (props.orders) {
    var orders = props.orders;
    orders.sort(firstBy('oDate', { direction: -1 }))

    orderOptions = orders.map(o => {
      return (<option key={o.oId} value={o.oId}>{o.oId} - {o.buyer}</option>)
    })
  }
  orderOptions.unshift(<option key={0} value={null}></option>)

  var listOptions = [];
  if (props.rblists) {
    var lists = props.rblists;
    lists.sort(firstBy('rlName', { ignoreCase: true }))

    listOptions = lists.map(l => {
      return (<option key={l.rlId} value={l.rlId}>{l.rlName}</option>)
    })
  }
  listOptions.unshift(<option key={0} value={null}></option>)

  var backlogOptions = [];
  if (props.backlogs) {
    var blists = props.backlogs;
    blists.sort(firstBy('bNo', { ignoreCase: true }))

    backlogOptions = blists.map(l => {
      return (<option key={l.bId} value={l.bId}>{l.bNo} - {l.bName}</option>)
    })
  }
  backlogOptions.unshift(<option key={0} value={null}></option>)

  var warehouseOptions = [];
  if (props.warehouses) {
    var flists = props.warehouses;
    flists.sort(firstBy('wName', { ignoreCase: true }))

    warehouseOptions = flists.map(l => {
      return (<option key={l.wId} value={l.wId}>{l.wName}</option>)
    })
  }
  warehouseOptions.unshift(<option key={0} value={null}></option>)

  var pickListOptions = [];
  if (props.plists) {
    var plists = props.plists;
    plists.sort(firstBy('plName', { ignoreCase: true }))

    pickListOptions = plists.map(l => {
      return (<option key={l.plId} value={l.plId}>{l.plName}</option>)
    })
  }
  pickListOptions.unshift(<option key={0} value={null}></option>)

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create Picking List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form.Group as={Row}>
        <Form.Label column >List Name</Form.Label>
        <Col><Form.Control onChange={props.onChange} value={props.clName || ''} id="clName" placeholder={props.clAutoName || 'New List Name'} type="text" /></Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col><Form.Check type="radio" name="clFrom" value="o" onChange={props.onChange} checked={props.clFrom === 'o'} className="pb-2 pt-2" label="From Order" />
             <Form.Check type="radio" name="clFrom" value="l" onChange={props.onChange} checked={props.clFrom === 'l'} className="pb-2 pt-2" label="From Rebrickable List" />
             <Form.Check type="radio" name="clFrom" value="b" onChange={props.onChange} checked={props.clFrom === 'b'} className="pb-2 pt-2" label="From Backlog" />
             <Form.Check type="radio" name="clFrom" value="p" onChange={props.onChange} checked={props.clFrom === 'p'} className="pb-2 pt-2" label="From Picking List Remainder" /></Col>
        <Col><Form.Control as="select" disabled={props.clFrom !== 'o'} onChange={props.onChange} value={props.clOrder} id="clOrder">{orderOptions}</Form.Control>
             <Form.Control as="select" disabled={props.clFrom !== 'l'} onChange={props.onChange} value={props.clList} id="clList">{listOptions}</Form.Control>
             <Form.Control as="select" disabled={props.clFrom !== 'b'} onChange={props.onChange} value={props.clBacklog} id="clBacklog">{backlogOptions}</Form.Control>
             <Form.Control as="select" disabled={props.clFrom !== 'p'} onChange={props.onChange} value={props.clPList} id="clPList">{pickListOptions}</Form.Control></Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col>Pick From Warehouse</Col>
        <Col><Form.Control as="select" onChange={props.onChange} value={props.clPickFrom} id="clPickFrom" disabled={props.clFrom === 'o'}>{warehouseOptions}</Form.Control></Col>
      </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button variant="primary" disabled={!props.validated} onClick={props.onSubmit}>
          Create List
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const DeleteListModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Picking List</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete list {props.listName}?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button variant="danger" id={props.listId} onClick={props.onSubmit}>
          Delete List
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const AddWriteOffModal = (props) => {
  if (!props.item) return null;

  const woOptions = [...Array(6).keys()].map(i => {
    return (<option key={i} value={i}>{i}</option>)
  });
  woOptions.shift();

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add to Write Off list <div className="font-weight-bold">{props.item.sNo} - {props.item.sName}</div></Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form.Group as={Row}>
      <Col>Quantity</Col>
      <Col md="auto"><Form.Control size="sm" as="select" onChange={props.onChange} value={props.selWOQ} id="selWOQ">{woOptions}</Form.Control></Col>
      </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button variant="primary" id={props.item.sId} onClick={props.onSubmit}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
