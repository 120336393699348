import React, { Component } from "react";
import { Link } from 'react-router-dom'

import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'

import { Auth } from "aws-amplify";
import RestApi from '../components/restapi'

export default class SignInUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 'signin',
      isLoading: false,
      confirmationCode: '',
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      acceptTerms: false,
      error: false,
      success: false,
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  validateSignupForm() {
    return (this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword &&
      this.state.name.length > 0 &&
      this.state.acceptTerms === true);
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  handleTermsChange = event => {
    this.setState({
      [event.target.id]: event.target.checked
    });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSignInSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true, error: false, errorMessage: '' });

    try {
      await Auth.signIn(this.state.email, this.state.password);
      await this.props.userHasAuthenticated(true);
    } catch (e) {
      console.log(e);
      if (e.code === 'UserNotFoundException') {
        this.setState({ page: 'signup' })
      } else if (e.code === 'UserNotConfirmedException') {
        this.setState({ page: 'confirm' })
      }
      this.setState({ isLoading: false, error: true, errorMessage: e.message });
    }
  }

  handleSignupSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true, error: false, errorMessage: '' });
    try {
      const newUser = await Auth.signUp({
        username: this.state.email,
        password: this.state.password,
        attributes: {
          name: this.state.name
        }
      });
      this.setState({
        newUser: newUser,
        page: 'confirm'
      });
    } catch (e) {
      this.setState({ isLoading: false, error: true, errorMessage: e.message });
    }
    this.setState({ isLoading: false });
  }

  handleConfirmationSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true, success: false, successMessage: '', error: false, errorMessage: '' });
    try {
      await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
      const user = await Auth.signIn(this.state.email, this.state.password);
      // const userInfo = await Auth.currentUserInfo();

      await RestApi.createUser(user.attributes.sub, user.attributes.email);
      await this.props.userHasAuthenticated(true);
      this.props.history.push("/");
    } catch (e) {
      console.log(JSON.stringify(e, null, 2));
      this.setState({ isLoading: false, success: false, error: true, errorMessage: e.message });
    }
  }

  resendCode = async event => {
    this.setState({ isLoading: true, success: false, successMessage: '', error: false, errorMessage: '' });
    Auth.resendSignUp(this.state.email).then(() => {
      this.setState({
        isLoading: false,
        success: true,
        emailSent: true,
        successMessage: 'A new confirmation code was sent to ' + this.state.email
      })
    }).catch(e => {
      this.setState({ isLoading: false, success: false, error: true, errorMessage: e.message });
    });
  }

  gotoSignIn = async event => {
    event.preventDefault();
    this.setState({ page: 'signin' })
  }

  gotoSignup = async event => {
    event.preventDefault();
    this.setState({ page: 'signup' })
  }

  renderSignInForm() {
    return (
      <Container>
      <h4>Sign In</h4>
      <Form className="SignIn" onSubmit={this.handleSignInSubmit}>
        <Form.Group controlId="email">
          <Form.Label>Email address</Form.Label>
          <Form.Control type='email' placeholder='Email'
            onChange={this.handleChange} value={this.state.email} autoFocus />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control type='password' placeholder='Password'
            onChange={this.handleChange} value={this.state.password} />
        </Form.Group>
        <Button type='submit' disabled={!this.validateForm()}>Sign In</Button>
        <Alert variant="danger" show={this.state.error}>
          <Alert.Heading>Sorry</Alert.Heading>
          <p>{this.state.errorMessage}</p>
        </Alert>
        <hr />
        <Container><Link to='/signin/reset'>Forgot your password?</Link> - No account yet? <Link to='' onClick={this.gotoSignup}>Create one!</Link></Container>
      </Form>
      </Container>
    );
  }
  // <Message error header='Sorry' content={this.state.errorMessage} />

  renderSignUpForm() {
    return (
      <Container>
      <h2>Sign Up</h2>
      <Form className="Signup" onSubmit={this.handleSignupSubmit}>
        <Form.Group controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control label='Name' type='text' placeholder='Name'
            onChange={this.handleChange} value={this.state.name} autoFocus />
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control type='email' placeholder='Email'
            onChange={this.handleChange} value={this.state.email} />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control type='password' placeholder='Password'
            onChange={this.handleChange} value={this.state.password} />
        </Form.Group>
        <Form.Group controlId="confirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control type='password' placeholder='Password'
            onChange={this.handleChange} value={this.state.confirmPassword} />
        </Form.Group>
        <Form.Group controlId="acceptTerms">
            <Form.Check onChange={this.handleTermsChange} checked={this.state.acceptTerms}
            label='I have read and agree to the Terms and Conditions' />
        </Form.Group>
        <Button type='submit' disabled={!this.validateSignupForm()}>Sign Up</Button>
        <Alert variant="danger" show={this.state.error}>
          <Alert.Heading>Sorry</Alert.Heading>
          <p>{this.state.errorMessage}</p>
        </Alert>
        <hr />
        <Container><Link to='/signin/reset'>Forgot your password?</Link> - Already have an account? <Link to='' onClick={this.gotoSignIn}>Sign in here!</Link></Container>
      </Form>
      </Container>
    );
  }
  // <Message error header='Sorry' content={this.state.errorMessage} />

  renderConfirmationForm() {
    return (
      <Container text>
      <h2>Verify you Email</h2>
      <Form className="Signup" onSubmit={this.handleConfirmationSubmit}>
        <Form.Group controlId="confirmationCode">
          <Form.Label>Confirmation Code</Form.Label>
          <Form.Control type='tel' onChange={this.handleChange}
            value={this.state.confirmationCode} autoFocus />
        </Form.Group>
        <Button type='submit' disabled={!this.validateConfirmationForm()}>Verify</Button>
        <Button disabled={this.state.emailSent} type="button" onClick={this.resendCode}>Resend Code</Button>
      </Form>
      <Alert variant="danger" show={this.state.error}>
        <Alert.Heading>Sorry</Alert.Heading>
        <p>{this.state.errorMessage}</p>
      </Alert>
      <Alert variant="success" show={this.state.success}>
        <Alert.Heading>On its way!</Alert.Heading>
        <p>{this.state.successMessage}</p>
      </Alert>
      <hr />
      </Container>
    );
  }
  // <Message error header='Sorry' content={this.state.errorMessage} />
  // <Message success header='On its way!' content={this.state.successMessage} />

  render() {
    if (this.state.page === 'signup') {
      return (
         this.renderSignUpForm()
      );
    } else if (this.state.page === 'confirm') {
      return (
         this.renderConfirmationForm()
      );
    } else {
      return (
         this.renderSignInForm()
      );
    }
  }
}
