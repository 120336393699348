import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
// import { useNavigate } from 'react-router-dom';
import './App.css';
import Routes from "./Routes";

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Amplify, { Auth } from "aws-amplify";
import aws_exports from './aws-exports';

import Header from './containers/Header';
import Footer from './containers/Footer';

import RestApi from './components/restapi'
import { cleanUpLoaders } from "./components/loaders"

Amplify.configure(aws_exports);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      user: null,
    };
  }

  async componentDidMount() {
    try {
      await Auth.currentSession();
      await this.userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        console.log(e);
      }
    }
    // this.tapIntoSessionStorage();
    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = async (authenticated) => {
    if (authenticated) {
      await this.setupSession();
    } else {
      await this.clearSession();
    }
    this.setState({ isAuthenticated: authenticated });
  }

  loadUser() {
    return RestApi.getUser()
    .then(res => {
      var preferences = JSON.parse(res.data[0].preferences);
      if (!preferences) { preferences = {}; };
      localStorage.setItem('btg.userPreferences', JSON.stringify(preferences));
      delete res.data[0].preferences;
      this.setState({ user: res.data[0] });
      return res.data[0];
    })
  }

  setupSession() {
    return this.loadUser()
    .then(res => {
      var preferences = JSON.parse(localStorage.getItem('btg.userPreferences'));
      if (preferences.activeStore) {
        this.setState({ activeStore: preferences.activeStore })
      }
      return res;
    })
    // .then(res => { if (res.status === 'New') { localStorage.removeItem('picatag.sessionImages'); }; return res; })
    // .then(res => { localStorage.setItem('picatag.currentSession', JSON.stringify(res)); return res; })
    .catch(e => { console.log(e); })
  }

  clearSession() {
    this.setState({ user: null, apiCreds: null, activeStore: null });
    // this.updateSelectedFiles([]);
    //
    // localStorage.removeItem('picatag.currentSession');
    // localStorage.removeItem('picatag.sessionImages');
    cleanUpLoaders();
    localStorage.removeItem('btg.saletemplates');
    localStorage.removeItem('btg.stock');
    localStorage.removeItem('btg.stockNeedsRefresh');
    localStorage.removeItem('btg.stores');
    localStorage.removeItem('btg.userPreferences');

    sessionStorage.removeItem('btg.apiCreds');

    this.props.history.push("/");
  }

  updateUserPreferences(preferences) {
    preferences.needSaving = true;
    localStorage.setItem('btg.userPreferences', JSON.stringify(preferences));
  }

  saveUserPreferences() {
    var preferences = JSON.parse(localStorage.getItem('btg.userPreferences'));
    if (preferences.needSaving) {
      preferences.needSaving = false;
      const user = this.state ? this.state.user : this.user;
      return RestApi.setUser(
        user.firstname,
        user.lastname,
        user.phonenumber,
        JSON.stringify(preferences),
      )
      .then(res => { localStorage.setItem('btg.userPreferences', JSON.stringify(preferences)); })
      .catch(e => { console.log(e); })
    } else {
      return Promise.resolve();
    }
  }

  handleLogout = async (e) => {
    this.state.user && await this.saveUserPreferences();
    await Auth.signOut();

    await this.userHasAuthenticated(false);
  }

  setApiCredentials = (creds) => {
    this.setState({ apiCreds: creds });
  }
  setActiveStore = (store) => {
    var preferences = JSON.parse(localStorage.getItem('btg.userPreferences'));
    preferences.activeStore = store;
    this.updateUserPreferences(preferences);
    this.setState({ activeStore: store });
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      isAdmin: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      handleLogout: this.handleLogout,
      user: this.state.user,
      refreshUser: this.refreshUser,
      activeStore: this.state.activeStore,
      setActiveStore: this.setActiveStore,
      apiCreds: this.state.apiCreds,
      setApiCredentials: this.setApiCredentials,
      updateUserPreferences: this.updateUserPreferences,
      saveUserPreferences: this.saveUserPreferences,
    };

    return (
      !this.state.isAuthenticating &&
      <Container className='root'>
        <Row>
          <Col><Header {...childProps} /></Col>
        </Row >
        <Row>
          <Col><Routes childProps={childProps} /></Col>
        </Row >
        <Row>
          <Col><Footer {...childProps} /></Col>
        </Row>
      </Container>
    )
  }
}

export default withRouter(App);
// export default App;
