import React, { Component } from "react";
import { withAuthenticator } from '@aws-amplify/ui-react';

import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import { firstBy } from "thenby";

import RestApi from '../components/restapi'
import { blTypeMap } from "../components/util"
import { cleanUpLoaders, getStorage } from "../components/loaders"
import { UsersApi } from '../components/rbApi'
var bl = require('../components/bl');

var CryptoJS = require("crypto-js");
var Promise = require("bluebird");

class SyncIn extends Component {
  creds = null;
  sscreds = null;

  constructor(props) {
    super(props);

    cleanUpLoaders();

    this.sscreds = sessionStorage.getItem('btg.apiCreds');
    if (this.sscreds) {
      // Decrypt
      var bytes  = CryptoJS.AES.decrypt(this.sscreds, props.user.attributes.sub);
      this.creds = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }

    this.state = {
      lists: [],
      status: 'Select a list',
      log: [],
    }
  }

  componentDidMount() {
    getStorage(this.props.activeStore.storeId)
    .then(res => {
      const warehouses = [];
      const map = new Map();
      for (const item of res) {
          if(!map.has(item.wId)){
              map.set(item.wId, true);    // set any value to Map
              warehouses.push({
                wId: item.wId,
                wName: item.wName
              });
          }
      }
      // warehouses.unshift({ wId: 0, wName: '' })
      this.setState({ warehouses: warehouses })
    })
    .then(res => {
      return this.refreshLists();
    })
    .then(res => {
      this.handlePullLists()
      this.handlePullInventories()
    })
  }

  refreshLists() {
    return RestApi.getRbList(this.props.activeStore.storeId)
    .then(res => {
      const lists = res.data.filter(l => l.rlType === 2 || l.rlType === 3);
      localStorage.setItem('btg.rblists', JSON.stringify(lists));
      this.setState({ lists: lists });
      return res;
    })
  }

  getFullSetListSets(rlId, page, items) {
    items = items || [];
    page = page || 1;

    var rbUser = new UsersApi(this.creds.rb);
    return rbUser.usersSetlistsSetsList(rlId, null, page)
    .then(res => {
      const pageItems = res.body.results.map(item => {
        return {
          rlId: rlId,
          rQ: item.quantity,
          rNo: item.set.set_num,
          rCid: 9999,
          rType: 3, // SET
          sNos: null
        }
      })
      const resItems = items.concat(pageItems)

      this.addToLog('Got ' + resItems.length + ' items from Rebrickable...', 'light' );
      if (res.body.next) {
        return this.getFullSetListSets(rlId, ++page, resItems)
      } else {
        return resItems;
      }
    })
    .catch(err => {
      console.log(err);
      return items;
    })
  }

  getFullPartsListParts(rlId, page, items) {
    items = items || [];
    page = page || 1;

    var rbUser = new UsersApi(this.creds.rb);
    return rbUser.usersPartlistsPartsList(rlId, null, page)
    .then(res => {
      const pageItems = res.body.results.map(item => {
        var sNos = null;
        if (item.part.external_ids) {
          sNos = JSON.stringify(item.part.external_ids.BrickLink);
        }
        // console.log(item.part.external_ids);
        return {
          rlId: rlId,
          rQ: item.quantity,
          rNo: item.part.part_num,
          rCid: item.color.id,
          rType: 2, // PART
          sNos: sNos || null,
        }
      })
      const resItems = items.concat(pageItems)

      this.addToLog('Got ' + resItems.length + ' items from Rebrickable...', 'light' );
      if (res.body.next) {
        return this.getFullPartsListParts(rlId, ++page, resItems)
      } else {
        return resItems;
      }
    })
    .catch(err => {
      console.log(err);
      return items;
    })
  }

  handlePullListItems = async (event) => {
    if (!this.creds) {
      this.addToLog('API Credentials Not Available', 'warning');
      this.setState({ status: 'API Credentials Not Available', statusVariant: 'warning' })
      return;
    }

    if (!RestApi.hasPrivilege(this.props.activeStore.privileges, 'StoreUpdateRb')) {
      this.addToLog('You are not authorised to perform Rebrickable updates', 'warning');
      this.setState({ status: 'You are not authorised to perform Rebrickable updates', statusVariant: 'warning' })
      return;
    }

    const rlId = event.target ? parseInt(event.target.id) : event;
    const list = this.state.lists.find(l => l.rlId === rlId);

    this.addToLog('Updating contents of ' + list.rlName, 'light' );

    var rbProm = null;
    if (list.rlType === 3) { // SET
      rbProm = this.getFullSetListSets(list.rlId)
    } else {
      rbProm = this.getFullPartsListParts(list.rlId)
    }

    return RestApi.getRbListItem(this.props.activeStore.storeId, null, list.rlId)
    .then(res => {
      const btgItems = res.data;
      this.addToLog('Got ' + btgItems.length + ' existing items...', 'light' );

      return rbProm.then(rbItems => {
        // items that have changed
        var changedItems = rbItems.filter(rbItem => {
          return btgItems.find(btgItem => (
            btgItem.rlId === rbItem.rlId &&
            btgItem.rCid === rbItem.rCid &&
            btgItem.rNo === rbItem.rNo &&
            btgItem.rQ === rbItem.rQ &&
            btgItem.rType === rbItem.rType &&
            btgItem.sNos === rbItem.sNos
          )) ? false : true;
        });
        this.addToLog(changedItems.length + ' need updating', 'light' );

        // items that were deleted on RB
        var deletedItems = btgItems.filter(btgItem => {
          return rbItems.find(rbItem => (
            btgItem.rlId === rbItem.rlId &&
            btgItem.rCid === rbItem.rCid &&
            btgItem.rNo === rbItem.rNo &&
            btgItem.rType === rbItem.rType
          )) ? false : true;
        });
        this.addToLog(deletedItems.length + ' need deleting', 'light' );

        var self = this;
        var uCnt = 0;
        var dCnt = 0;
        const storeId = this.props.activeStore.storeId
        return Promise.map(changedItems, function(item) {
          uCnt++;

          if (uCnt % 50 === 0 || uCnt === changedItems.length) {
            self.addToLog('Updated ' + uCnt + ' of ' + changedItems.length + ' items', 'light' );
          }
          return RestApi.setRbListItem(
            storeId,
            null,
            item.rlId,
            item.rNo,
            item.rCid,
            item.rType,
            item.rQ,
            item.sNos,
          )
        }, { concurrency: 5 } )
        .then(res => {
          return Promise.map(deletedItems, function(item) {
            dCnt++;

            if (dCnt % 50 === 0 || dCnt === deletedItems.length) {
              self.addToLog('Deleted ' + dCnt + ' of ' + deletedItems.length + ' items', 'light' );
            }
            return RestApi.deleteRbListItem(
              storeId,
              item.rId
            )
          }, { concurrency: 5 } )
        })
        .then(res => {
          this.addToLog('Rebrickable done!', 'light');
        })
      });
    })
  }

  handlePullLists = async () => {
    if (!this.creds) {
      this.addToLog('API Credentials Not Available', 'warning');
      this.setState({ status: 'API Credentials Not Available', statusVariant: 'warning' })
      return;
    }

    if (!RestApi.hasPrivilege(this.props.activeStore.privileges, 'StoreUpdateRb')) {
      this.addToLog('You are not authorised to perform Rebrickable updates', 'warning');
      this.setState({ status: 'You are not authorised to perform Rebrickable updates', statusVariant: 'warning' })
      return;
    }

    var rbUser = new UsersApi(this.creds.rb);

    this.addToLog('Fetching part lists from Rebrickable', 'light');
    rbUser.usersPartlistsList()
    .then(res => {
      var newLists = res.body.results.map(list => {
        return {
          rlId: list.id,
          rlName: list.name,
          rlCnt: list.num_parts,
          rlType: 2 // PART
        }
      })
      return newLists;
    })
    .then(partLists => {
      this.addToLog('Fetching set lists from Rebrickable', 'light');
      return rbUser.usersSetlistsList()
      .then(res => {
        var newLists = partLists.concat(res.body.results.map(list => {
          return {
            rlId: list.id,
            rlName: list.name,
            rlCnt: list.num_sets,
            rlType: 3 //SET
          }
        }));
        return newLists;
      });
    })
    .then(newLists => {
      // lists that have changed
      var changedLists = newLists.filter(newList => {
        return this.state.lists.find(l => (
          l.rlId === newList.rlId &&
          l.rlName === newList.rlName &&
          l.rlType === newList.rlType &&
          l.rlCnt === newList.rlCnt
        )) ? false : true;
      }).map(newList => {
        const existingList = this.state.lists.find(l => ( l.rlId === newList.rlId ));
        newList.isStock = existingList ? existingList.isstock : 0
        newList.wId = existingList ? existingList.wId : null
        return newList;
      });
      this.addToLog(changedLists.length + ' lists need updating', 'light');

      // lists that were deleted on RB
      var deletedLists = this.state.lists.filter(l => {
        return newLists.find(newList => (l.rlId === newList.rlId)) ? false : true;
      });
      this.addToLog(deletedLists.length + ' lists need deleting', 'light');

      const storeId = this.props.activeStore.storeId
      var self = this;
      return Promise.map(changedLists, function(list) {
        self.addToLog('Updating ' + list.rlName + '. You may want to update it\'s contents.', 'info');
        return RestApi.setRbList(
          storeId,
          list.rlId,
          list.rlType,
          list.rlName,
          list.rlCnt,
          list.wId > 0,
          list.wId
        )
      }, { concurrency: 5 } )
      .then(res => {
        return Promise.map(deletedLists, function(list) {
          self.addToLog('Deleting ' + list.rlName, 'light');
          return RestApi.deleteRbList(
            storeId,
            list.rlId,
            list.rlType,
          )
        }, { concurrency: 5 } )
      })
      .then(res => {
        return changedLists;
      })
    })
    .then(res => {
      return this.refreshLists()
      .then(() => {
        this.addToLog('Rebrickable done!', 'light');
        return res;
      });
    })
    .catch(err => {
      this.addToLog(err.toString(), 'danger');
      this.setState({ status: err.toString(), statusVariant: 'danger' })
      console.log(err);
    })
  }

  handlePullInventories = async () => {
    if (!this.creds) {
      this.addToLog('API Credentials Not Available', 'warning');
      this.setState({ status: 'API Credentials Not Available', statusVariant: 'warning' })
      return;
    }

    if (!RestApi.hasPrivilege(this.props.activeStore.privileges, 'StoreUpdateBl')) {
      this.addToLog('You are not authorised to perform Rebrickable updates', 'warning');
      this.setState({ status: 'You are not authorised to perform Bricklink updates', statusVariant: 'warning' })
      return;
    }

    var incColors = [ 1, 3, 5, 11, 85, 86 ];

    var blClient = new bl.Client(this.sscreds);
    var incInventories = bl.Inventory.all({ color_id: incColors.toString() })
    var exInventories = bl.Inventory.all({ color_id: incColors.map(function(x) { return x * -1; }).toString() })

    var promInventories = RestApi.getBlInventory(this.props.activeStore.storeId)

    this.addToLog('Fetching inventories from Bricklink', 'light');

    blClient.send(incInventories)
    .then(res => {
      var blInv = res.data
      return blClient.send(exInventories)
      .then(res => {
        blInv = blInv.concat(res.data);

      this.addToLog('Got ' + blInv.length + ' inventories from Bricklink...', 'light' );

      return promInventories.then(btgRes => {
        const btgInv = btgRes.data
          this.addToLog('Got ' + btgInv.length + ' existing inventories...', 'light' );

          // inventories that have changed
          var changedInv = blInv.filter(inv => {
            return btgInv.find(i => (
              i.iId === inv.inventory_id &&
              i.sNo === inv.item.no &&
              i.sType === blTypeMap[inv.item.type] &&
              i.sCid === inv.color_id &&
              i.iQ === inv.quantity
            )) ? false : true;
            // )) ? false : false;
          });
          this.addToLog(changedInv.length + ' inventories need updating', 'light');

          // lists that were deleted on RB
          var deletedInv = btgInv.filter(i => {
            return blInv.find(inv => (i.iId === inv.inventory_id)) ? false : true;
            // return blInv.find(inv => (i.iId === inv.inventory_id)) ? false : false;
          });
          this.addToLog(deletedInv.length + ' inventories need deleting', 'light');

          const self = this;
          var uCnt = 0;
          var dCnt = 0;
          const storeId = this.props.activeStore.storeId
          return Promise.map(changedInv, function(inv) {
            uCnt++;

            if (uCnt % 50 === 0 || uCnt === changedInv.length) {
              self.addToLog('Updated ' + uCnt + ' of ' + changedInv.length + ' inventories', 'light' );
            }
            return RestApi.setBlInventory(
              storeId,
              inv.inventory_id,
              inv.item.no,
              blTypeMap[inv.item.type],
              inv.color_id,
              inv.quantity,
              inv.new_or_used,
              inv.completeness,
              inv.unit_price,
              inv.bind_id,
              inv.description,
              inv.remarks,
              inv.bulk,
              inv.is_retain,
              inv.is_stock_room,
              inv.stock_room_id,
              inv.my_cost,
              inv.sale_rate,
              inv.tier_quantity1,
              inv.tier_quantity2,
              inv.tier_quantity3,
              inv.tier_price1,
              inv.tier_price2,
              inv.tier_price3,
              inv.my_weight,
              null
            )
          }, { concurrency: 5 } )
          .then(res => {
            return Promise.map(deletedInv, function(inv) {
              dCnt++;

              if (dCnt % 50 === 0 || dCnt === deletedInv.length) {
                self.addToLog('Deleted ' + dCnt + ' of ' + deletedInv.length + ' inventories', 'light' );
              }
              return RestApi.deleteBlInventory(
                storeId,
                inv.iId,
                inv.sId
              )
            }, { concurrency: 5 } )
          })
        })
      })
    })
    .then(res => {
      this.addToLog('Bricklink done!', 'light');
      return res;
    })
    .catch(err => {
      this.addToLog(err.toString(), 'danger');
      this.setState({ status: err.toString(), statusVariant: 'danger' })
      console.log(err);
    })
  }

  handleWarehouseChange = async (e) => {
    const id = parseInt(e.target.id);
    const value = parseInt(e.target.value);
    var list = this.state.lists.find(list => list.rlId === id)

    if (!this.creds) {
      this.addToLog('API Credentials Not Available', 'warning');
      this.setState({ status: 'API Credentials Not Available', statusVariant: 'warning' })
      return;
    }

    if (!RestApi.hasPrivilege(this.props.activeStore.privileges, 'StoreUpdateRb')) {
      this.addToLog('You are not authorised to perform Rebrickable updates', 'warning');
      this.setState({ status: 'You are not authorised to perform Rebrickable updates', statusVariant: 'warning' })
      return;
    }

    this.addToLog(`Setting default warehouse of list ${list.rlName} to ${value}`, 'light');
    return RestApi.setRbList(
      this.props.activeStore.storeId,
      list.rlId,
      list.rlType,
      list.rlName,
      list.rlCnt,
      value > 0,
      value
    )
    .then(res => {
      this.setState(state => {
        var list = state.lists.find(list => list.rlId === id);
        list.isstock = value > 0;
        list.wId = value;
        localStorage.setItem('btg.rblists', JSON.stringify(state.lists));
        return { lists: state.lists }
      })
      this.addToLog(`Done`, 'light');
    })
  }

  addToLog(msg, variant) {
    this.setState(state => {
        state.log.unshift({ id: state.log.length, msg: msg, var: variant, ts: Date.now() });

      return { log: state.log }
    })
  }

  renderLog() {
    var logRows = [];

    this.state.log.forEach(entry => {
      var date = new Date(entry.ts);
      logRows.push(
      <tr key={entry.id}>
        <td><Alert className="m-0 p-0" variant={entry.var}>
          {date.toLocaleString()}: {entry.msg}
        </Alert></td>
      </tr>
      )
    });

    return (
      <>
      <Table bordered size="sm">
        <thead>
          <tr>
            <th>Log</th>
          </tr>
        </thead>
        <tbody>
          {logRows}
        </tbody>
      </Table>
      </>
    )
  }


  renderLists() {
    var sortedLists = this.state.lists;
    sortedLists.sort(firstBy('rlName', { ignoreCase: true }))

    var setListRows = [];
    var partListRows = [];

    var setLists = this.state.lists.filter(list => list.rlType === 3) //'SET'
    var partLists = this.state.lists.filter(list => list.rlType === 2) //'PART'

    var wOptions = [];
    if (this.state.warehouses) {
      wOptions = this.state.warehouses.sort(firstBy('wName', { ignoreCase: true }))
      .map(i => { return (<option key={i.wId} value={i.wId}>{i.wName}</option>) })
    }
    wOptions.unshift(<option key={0} value={null}></option>)

    // <Form.Check id={list.rlId} checked={list.isstock || false}
    // onChange={this.handleListIsStockChange} />

    setLists.forEach(list => {
      setListRows.push(
      <Row key={list.rlId}>
        <Col className="px-1"><Button variant="link" size="sm" id={list.rlId}
        onClick={this.handlePullListItems}>{list.rlName} ({list.rlCnt})</Button></Col>
        <Col className="pl-0" md="auto"><Form.Control size="sm" as="select" onChange={this.handleWarehouseChange}
        value={list.wId || 0} id={list.rlId}>{wOptions}</Form.Control>
        </Col>
      </Row>
      )
    });

    partLists.forEach(list => {
      partListRows.push(
      <Row key={list.rlId}>
        <Col><Button variant="link" size="sm" id={list.rlId}
        onClick={this.handlePullListItems}>{list.rlName} ({list.rlCnt})</Button></Col>
        <Col className="pl-0" md="auto"><Form.Control size="sm" as="select" onChange={this.handleWarehouseChange}
        value={list.wId || 0} id={list.rlId}>{wOptions}</Form.Control>
        </Col>
      </Row>
      )
    });

    return (
      <>
        <Card className="mb-2">
        <Card.Header className="p-2">Set Lists <small>(click to sync)</small></Card.Header>
        <Card.Body className="p-1">
          {setListRows}
        </Card.Body>
        </Card>

        <Card className="mb-2">
        <Card.Header className="p-2">Part Lists <small>(click to sync)</small></Card.Header>
        <Card.Body className="p-1">
          {partListRows}
        </Card.Body>
        </Card>
      </>
    )
  }

  render() {
    return (
      <Container className='Home'>
        <Row>
          <Col>Rebrickable Lists</Col>
          <Col md="auto" className="text-right">
            <Alert className="mb-0" variant={this.state.statusVariant}>
              {this.state.status}
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col md="auto">
            {this.renderLists()}
          </Col>
          <Col>
            {this.renderLog()}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withAuthenticator(SyncIn)
