import React, { Component } from "react";
import { withAuthenticator } from '@aws-amplify/ui-react';

import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'

import RestApi from '../components/restapi'
// import * as rb from '../components/rbApi'
var bl = require('../components/bl');

// var CryptoJS = require("crypto-js");
var Promise = require("bluebird");

class Categories extends Component {
  constructor(props) {
    super(props);

    this.sscreds = sessionStorage.getItem('btg.apiCreds');

    this.state = {
      status: '',
      log: [],
    }

  }

  componentDidMount() {
    this.handlePullCategories()
  }

  handlePullCategories = async () => {
    if (!this.sscreds) {
      this.addToLog('API Credentials Not Available', 'warning');
      this.setState({ status: 'API Credentials Not Available', statusVariant: 'warning' })
      return;
    }

    // if (!RestApi.hasPrivilege(this.props.activeStore.privileges, 'StoreUpdateRb')) {
    //   this.addToLog('You are not authorised to perform Rebrickable updates', 'warning');
    //   this.setState({ status: 'You are not authorised to perform Rebrickable updates', statusVariant: 'warning' })
    //   return;
    // }
    //


    this.addToLog('Fetching category list from Bricklink', 'light');
    var blClient = new bl.Client(this.sscreds);
    var blReq = bl.Category.all()
    var blProm = blClient.send(blReq)

    this.addToLog('Fetching category list from BTG', 'light');
    var btgProm = RestApi.getCategory();

    blProm
    .then(blRes => {
      this.addToLog('Got ' + blRes.data.length + ' items from Bricklink...', 'light' );
      console.log(blRes.data);
      const mappedRes = blRes.data;

      return mappedRes;
    })
    .then(mappedRes => {
      return btgProm
      .then(res => {
        const btgRes = res.data;
        console.log(btgRes);
        this.addToLog('Got ' + btgRes.length + ' items from BTG...', 'light' );

        // lists that have changed
        var changed = mappedRes.filter(c => {
          return btgRes.find(b => (
            b.cId === c.category_id &&
            b.cName === c.category_name &&
            b.pId === c.parent_id
          )) ? false : true;
        });

        this.addToLog(changed.length + ' categories need updating', 'light');

        // lists that were deleted on RB
        var deleted = btgRes.filter(b => {
          return mappedRes.find(c => (b.cId === c.category_id)) ? false : true;
        });
        this.addToLog(deleted.length + ' categories need deleting', 'light');
        this.addToLog('Updating ' + changed.length + ' categories.', 'info');
        const self = this;
        return Promise.map(changed, function(c) {
          return RestApi.setCategory(
            c.category_id,
            c.category_name,
            c.parent_id
          )
          .catch(err => {
            self.addToLog(err.toString(), 'danger');
            self.setState({ status: err.toString(), statusVariant: 'danger' })
            console.log(err);
          })
        }, { concurrency: 5 } )
        .then(res => {
          if (deleted.length > 0)
            return Promise.map(deleted, function(c) {
              console.log(c);
              self.addToLog('Deleting (' + c.cId + ') ' + c.cName, 'info');
              return RestApi.deleteCategory(
                c.cId
              )
              .catch(err => {
                self.addToLog('Failed to delete (' + c.cId + ') ' + c.cName + ". Error: " + err.toString(), 'danger');
              })
            }, { concurrency: 5 } )
        })
      })
    })
    .then(res => {
      this.addToLog('All done!', 'light');
      return res;
    })
    .catch(err => {
      this.addToLog(err.toString(), 'danger');
      this.setState({ status: err.toString(), statusVariant: 'danger' })
      console.log(err);
    })
  }

  addToLog(msg, variant) {
    this.setState(state => {
        state.log.unshift({ id: state.log.length, msg: msg, var: variant, ts: Date.now() });

      return { log: state.log }
    })
  }

  renderLog() {
    var logRows = [];

    this.state.log.forEach(entry => {
      var date = new Date(entry.ts);
      logRows.push(
      <tr key={entry.id}>
        <td><Alert className="m-0 p-0" variant={entry.var}>
          {date.toLocaleString()}: {entry.msg}
        </Alert></td>
      </tr>
      )
    });

    return (
      <>
      <Table bordered size="sm">
        <thead>
          <tr>
            <th>Log</th>
          </tr>
        </thead>
        <tbody>
          {logRows}
        </tbody>
      </Table>
      </>
    )
  }

  render() {
    return (
      <Container className='Home'>
        <Row>
          <Col md="auto" className="text-right">
            <Alert className="mb-0" variant={this.state.statusVariant}>
              {this.state.status}
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col>
            {this.renderLog()}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withAuthenticator(Categories)
