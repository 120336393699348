import RestApi from '../components/restapi'

// clean up
export function cleanUpLoaders() {
  localStorage.removeItem('btg.backlog');
  localStorage.removeItem('btg.categories');
  localStorage.removeItem('btg.colors');
  localStorage.removeItem('btg.orders');
  localStorage.removeItem('btg.pickinglists');
  localStorage.removeItem('btg.rblists');
  localStorage.removeItem('btg.reports');
  localStorage.removeItem('btg.saletemplates');
  localStorage.removeItem('btg.stockLists');
  localStorage.removeItem('btg.storage');
}

// Backlog
export function getBacklog(storeId) {
  const data = JSON.parse(localStorage.getItem('btg.backlog'))
  if (data) return Promise.resolve(data)
  return refreshBacklog(storeId);
}

export function refreshBacklog(storeId) {
  return RestApi.getBacklog(storeId)
  .then(res => {
    const bList = res.data.map(i => {
      i.ppp = i.bV / i.nTot;
      return i;
    })
    localStorage.setItem('btg.backlog', JSON.stringify(bList));
    return bList;
  })
}

// Categories
export function getCategories() {
  const data = JSON.parse(localStorage.getItem('btg.categories'))
  if (data) return Promise.resolve(data)
  return refreshCategories();
}

export function refreshCategories() {
  return RestApi.getCategory()
  .then(res => {
    localStorage.setItem('btg.categories', JSON.stringify(res.data));
    return res.data;
  })
}

// Colors
export function getColors() {
  const data = JSON.parse(localStorage.getItem('btg.colors'))
  if (data) return Promise.resolve(data)
  return refreshColors();
}

export function refreshColors() {
  return RestApi.getColor()
  .then(res => {
    localStorage.setItem('btg.colors', JSON.stringify(res.data));
    return res.data;
  })
}

// Orders
export function getOrders(storeId) {
  const data = JSON.parse(localStorage.getItem('btg.orders'))
  if (data) return Promise.resolve(data)
  return refreshOrders(storeId);
}

export function refreshOrders(storeId) {
  return RestApi.getOrder(storeId)
  .then(res => {
    localStorage.setItem('btg.orders', JSON.stringify(res.data));
    return res.data;
  })
}

// PickingLists
export function getPickingLists(storeId) {
  const data = JSON.parse(localStorage.getItem('btg.pickinglists'))
  if (data) return Promise.resolve(data)
  return refreshPickingLists(storeId);
}

export function refreshPickingLists(storeId) {
  return RestApi.getPickingList(storeId)
  .then(res => {
    localStorage.setItem('btg.pickinglists', JSON.stringify(res.data));
    return res.data;
  })
}

// RbLists
export function getRbLists(storeId) {
  const data = JSON.parse(localStorage.getItem('btg.rblists'))
  if (data) return Promise.resolve(data)
  return refreshRbLists(storeId);
}

export function refreshRbLists(storeId) {
  return RestApi.getRbList(storeId)
  .then(res => {
    localStorage.setItem('btg.rblists', JSON.stringify(res.data));
    return res.data;
  })
}

// Report
export function getReports(storeId) {
  const data = JSON.parse(localStorage.getItem('btg.reports'))
  if (data) return Promise.resolve(data)
  return refreshReports(storeId);
}

export function refreshReports(storeId) {
  var promises = [];
  var reports = [];

  promises.push(RestApi.getReport(storeId, "outOfStock"));
  promises.push(RestApi.getReport(storeId, "lowStock"));
  promises.push(RestApi.getReport(storeId, "bestSellers"));
  promises.push(RestApi.getReport(storeId, "nonSellers"));
  promises.push(RestApi.getReport(storeId, "mostWanted"));
  promises.push(RestApi.getReport(storeId, "mostWantedOutOfStock"));
  promises.push(RestApi.getReport(storeId, "mostWantedLowStock"));
  promises.push(RestApi.getReport(storeId, "mostSold"));
  promises.push(RestApi.getReport(storeId, "mostSoldOutOfStock"));
  promises.push(RestApi.getReport(storeId, "mostSoldLowStock"));

  return Promise.all(promises)
  .then(res => {
      reports.push({
        data: res[0].data,
        name: 'Out of Stock',
        repId: 0
      });
      reports.push({
        data: res[1].data,
        name: 'Low Stock',
        repId: 1
      });
      reports.push({
        data: res[2].data,
        name: 'Best Sellers',
        repId: 2
      })
      reports.push({
        data: res[3].data,
        name: 'Non-Sellers',
        repId: 3
      })
      reports.push({
        data: res[4].data,
        name: 'Most Wanted',
        repId: 4
      })
      reports.push({
        data: res[5].data,
        name: 'Most Wanted Out',
        repId: 5
      })
      reports.push({
        data: res[6].data,
        name: 'Most Wanted Low',
        repId: 6
      })
      reports.push({
        data: res[7].data,
        name: 'Most Sold',
        repId: 7
      })
      reports.push({
        data: res[8].data,
        name: 'Most Sold Out',
        repId: 8
      })
      reports.push({
        data: res[9].data,
        name: 'Most Sold Low',
        repId: 9
      })
      return reports
  })
}

// SaleTemplates
export function getSaleTemplates(storeId) {
  const data = JSON.parse(localStorage.getItem('btg.saletemplates'))
  if (data) return Promise.resolve(data)
  return refreshSaleTemplates(storeId);
}

export function refreshSaleTemplates(storeId) {
  return RestApi.getSaleTemplate(storeId)
  .then(res => {
    localStorage.setItem('btg.saletemplates', JSON.stringify(res.data));
    return res.data;
  })
}

// StockLists
export function getStockLists(storeId) {
  const data = JSON.parse(localStorage.getItem('btg.stockLists'))
  if (data) return Promise.resolve(data)
  return refreshStockLists(storeId);
}

export function refreshStockLists(storeId) {
  return RestApi.getStockList(storeId)
  .then(res => {
    localStorage.setItem('btg.stockLists', JSON.stringify(res.data));
    return res.data;
  })
}

// Storage
export function getStorage(storeId) {
  const data = JSON.parse(localStorage.getItem('btg.storage'))
  if (data) return Promise.resolve(data)
  return refreshStorage(storeId);
}

export function refreshStorage(storeId) {
  return RestApi.getStorage(storeId)
  .then(res => {
    localStorage.setItem('btg.storage', JSON.stringify(res.data));
    return res.data;
  })
}
