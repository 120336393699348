import React, { Component } from "react";
import { withAuthenticator } from '@aws-amplify/ui-react';

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'

import RestApi from '../components/restapi'
import StockItem from './StockItem'
import StockItemList from './StockItemList'
import { blTypeMap, getKeyByVal } from "../components/util"
import { cleanUpLoaders, getCategories, getColors, getBacklog, refreshBacklog } from "../components/loaders"
import { getAssemblyParts } from "../components/assemblies"

import { firstBy } from "thenby";
var Promise = require("bluebird");

class BackLog extends Component {
  constructor(props) {
    super(props);

    cleanUpLoaders();

    this.sscreds = sessionStorage.getItem('btg.apiCreds');

    this.state = {
      bList: [],
      cbItemType: 3,
      cbItemCol: 0,
      bItems: {},
      whenNotPartedOut: true,
    }
  }

  componentDidMount() {
    getColors().then(res => { this.colors = res; })
    getCategories().then(res => { this.categories = res; })
    getBacklog(this.props.activeStore.storeId).then(res => { this.setState({ bList: res }) })
  }

  filterItems() {
    var res = this.state.bItems[this.state.selectedBacklog] || [];

    if (this.state.whenNotPartedOut)
      res = res.filter(i => !i.sFS);
    if (this.state.whenDontHave)
      res = res.filter(i => i.sQ > i.rQ);
    if (this.state.whenDontHaveInStock)
      res = res.filter(i => i.sQ > i.rQ + i.iQ);
    // if (this.state.whenApLimited)
    //   res = res.filter(i => i.sAPv && (i.sAPv === i.sMmin || i.sAPv === i.sMmax) );
    // if (this.state.whenPGEmpty)
    //   res = res.filter(i => i.sLUPG && !i.sPGRtq );
    // res = res.filter(i => this.state.showByList.includes(i.rlId));
    // res = res.filter(i => this.state.showByType.includes(i.sType));
    // res = res.filter(i => this.state.showByCond.includes(i.sNU) ||
    //                         (this.state.showByCond.includes('ns') && !i.sNU));
    // res = res.filter(i => this.state.showByComp.includes(i.sSCI) ||
    //                         (this.state.showByComp.includes('ns') && !i.sSCI));
    // res = res.filter(i => ((this.state.showForSale.includes('nfs') && i.sFS !== 1) ||
    //                         (this.state.showForSale.includes('fs') && i.sFS === 1)));
    return res;
  }

  refreshBacklogItems(backlogId, clearFirst) {
    if (!backlogId) return;

    return RestApi.getBacklogItem(this.props.activeStore.storeId, backlogId)
    .then(res => {
      const newItems = res.data.map(item => {
        const color = this.colors.find(l => l.colorid === item.sCid)
        if (color) {
          item.sCname = color.name;
          item.sCcode = color.code;
        }
        const cat = this.categories.find(l => l.cId === item.sCat)
        if (cat) {
          item.sCatN = cat.cName;
        }
        return new StockItem(item);
      });

      this.setState(state => {
        var bItems = state.bItems;
        if (clearFirst) bItems = {};
        bItems[backlogId] = newItems;
        return { bItems: bItems }
      });

      return newItems;
    })
  }

  itemPartInOut(backlog, item, partOut) {
    const prom = partOut ? getAssemblyParts(this.sscreds, item.sNo, item.sType, item.sCid) : Promise.resolve();
    var newItems = [];

    return prom.then(res => {
      if (!partOut || (partOut && res && res.length > 0))
        return RestApi.setBacklogItem(this.props.activeStore.storeId, item.sId, backlog, null, partOut || 0);
    })
    .then(res => {
      return this.refreshBacklogItems(backlog)
    })
    .then(res => {
      newItems = res;
      return refreshBacklog(this.props.activeStore.storeId)
    })
    .then(res => {
      localStorage.setItem('btg.stockNeedsRefresh', JSON.stringify(true));
      this.setState({ bList: res });
      return newItems;
    })
    .catch(err => { console.log(err); })
  }

  itemSetHave(item, haveQty) {
    RestApi.setBacklogItem(this.props.activeStore.storeId, item.sId, this.state.selectedBacklog, haveQty)
    .then(res => {
      return this.refreshBacklogItems(this.state.selectedBacklog)
    })
    .then(res => {
      return refreshBacklog(this.props.activeStore.storeId)
    })
    .then(res => {
      localStorage.setItem('btg.stockNeedsRefresh', JSON.stringify(true));
      this.setState({ bList: res });
    })
    .catch(err => { console.log(err); })
  }

  itemSetHaveAll(have) {
    RestApi.setBacklogItem(this.props.activeStore.storeId, null, this.state.selectedBacklog, null, null, have, !have)
    .then(res => {
      return this.refreshBacklogItems(this.state.selectedBacklog)
    })
    .then(res => {
      return refreshBacklog(this.props.activeStore.storeId)
    })
    .then(res => {
      localStorage.setItem('btg.stockNeedsRefresh', JSON.stringify(true));
      this.setState({ bList: res });
    })
    .catch(err => { console.log(err); })
  }

  handleChange = event => {
    if (event.target.validity && !event.target.validity.valid) return;

    const tid = event.target.id || event.target.name
    var val = event.target.value;
    if (event.target.type === 'checkbox') val = event.target.checked;

    this.setState({
      [tid]: val
    });
  }

  handleCreateBacklogShow = async (e) => {
    this.setState({ showCreateBacklog: true });
  }

  handleCreateBacklogHide = async (e) => {
    this.setState({ showCreateBacklog: false });
  }

  handleCreateBacklogSearch = async (e) => {
    console.log(this.state.cbItemType);
    if (this.state.cbItemType === '99') {
      RestApi.getRbList(this.props.activeStore.storeId)
      .then(res => {
        console.log(res);
        const data = res.data.map( r => {
          return {
            cNo: r.rlId,
            cType: '99',
            cName: r.rlName
          }
        })
        console.log(data);
        var newNo = '';
        if (data[0]) newNo = data[0].cNo;
        this.setState({ cbItems: data, cbItemNo: newNo })
      })
    } else {
      RestApi.getCatalog(this.state.cbSearch, this.state.cbItemType)
      .then(res => {
        var newNo = '';
        if (res.data[0]) newNo = res.data[0].cNo;
        this.setState({ cbItems: res.data, cbItemNo: newNo })
      })
    }
  }

  handleCreateBacklogSubmit = async (e) => {
    var item = {
      sNo: this.state.cbItemNo,
      sType: this.state.cbItemType,
      sCid: this.state.cbItemCol,
    };
    var newbid = null;
    RestApi.createBacklog(
      this.props.activeStore.storeId,
      this.state.cbItemNo,
      this.state.cbItemType,
      this.state.cbItemCol,
    )
    .then(res => {
      newbid = res.data[0].bId;
      item.sId = res.data[0].sId;
      return this.itemPartInOut(newbid, item, this.state.cbItemType !== '99')
    })
    // .then(res => {
    //   const minifigs = res.filter(i => i.sType === 1);
    //   const self = this;
    //   return Promise.map(minifigs, function(item) {
    //     return self.itemPartInOut(newbid, item, true);
    //   }, { concurrency: 5 } );
    // })
    .then(res => {
      this.setState({ showCreateBacklog: false })
      this.handleSelectBacklog(newbid);
    })
  }

  handleSelectBacklog = async (e) => {
    const selectedBacklog = e;
    // if (!this.state.bItems[selectedBacklog]) this.refreshBacklogItems(selectedBacklog);
    this.setState({ selectedBacklog: selectedBacklog });
  }

  handleItemChange = (e) => {
    if (!e.target.validity.valid) return;

    const tid = e.target.id || e.target.name
    var val = e.target.value;
    const item = this.state.bItems[this.state.selectedBacklog].find(i => i.k === parseInt(tid))

    if (e.target.type === 'checkbox') {
      val = e.target.checked;
      this.itemPartInOut(this.state.selectedBacklog, item, val);
    } else {
      this.itemSetHave(item, parseInt(val));
    }
  }

  handleHaveAll = (e) => {
    this.setState({ showConfirmHave: true, chHave: true })
  }

  handleHaveNone = (e) => {
    this.setState({ showConfirmHave: true, chHave: false })
  }

  handleConfirmHaveHide = (e) => {
    this.setState({ showConfirmHave: false })
  }

  handleConfirmHaveSubmit = (e) => {
    // console.log(this.state.chHave);
    this.itemSetHaveAll(this.state.chHave);
    this.setState({ showConfirmHave: false })
  }

  handleDeleteBacklogShow = async (e) => {
    const bId = parseInt(e.target.id);
    const b = this.state.bList.find(l => l.bId === bId);
    const bName = b ? `${b.bNo} - ${b.bName}` : null;
    this.setState({ showDeleteBacklog: true, toDeleteBacklogName: bName, toDeleteBacklogId: bId });
  }

  handleDeleteBacklogHide = async (e) => {
    this.setState({ showDeleteBacklog: false });
  }

  handleDeleteBacklogSubmit = async (e) => {
    const id = parseInt(e.target.id)
    const selId = id === this.state.selectedBacklog ? 0 : this.state.selectedBacklog;

    RestApi.deleteBacklog(
      this.props.activeStore.storeId,
      id
    )
    .then(res => {
      return refreshBacklog(this.props.activeStore.storeId)
    })
    .then(res => {
      localStorage.setItem('btg.stockNeedsRefresh', JSON.stringify(true));
      this.setState({ bList: res, showDeleteBacklog: false, selectedBacklog: selId })
    })
  }

  handleRatingChange = (e) => {
    const bId = parseInt(e.target.id)
    const rating = parseInt(e.target.value)

    RestApi.setBacklog(this.props.activeStore.storeId, bId, rating)
    .then(res => {
      localStorage.setItem('btg.stockNeedsRefresh', JSON.stringify(true));
      this.setState(state => {
        const currBL = state.bList.find(bl => bl.bId === parseInt(bId))
        currBL.bR = rating;
        localStorage.setItem('btg.backlog', JSON.stringify(state.bList));
        return { bList: state.bList }
      })
    })
  }

  handleGetXmlShow = async (e) => {
    this.setState({ showGetXml: true });
  }

  handleGetXmlHide = async (e) => {
    this.setState({ showGetXml: false });
  }

  cbIsValid() {
    const colorOk = this.state.cbItemType !== '2' || this.state.cbItemCol

    return this.state.cbItemNo && colorOk;
  }

  renderMainColumn() {
    const sortKeys = {
      sCatN: 'Category',
      sName: 'Name',
      sNo: 'Item Num',
    }

    const stockActions = [];
    stockActions.push({ label: 'Have All', cb: this.handleHaveAll, enabled: this.state.selectedBacklog })
    stockActions.push({ label: 'Have None', cb: this.handleHaveNone, enabled: this.state.selectedBacklog })
    stockActions.push({ label: 'Get XML', cb: this.handleGetXmlShow, enabled: this.state.selectedBacklog })

    if (this.state.selectedBacklog) {
      const currBL = this.state.bList.find(bl => bl.bId === parseInt(this.state.selectedBacklog))
      const rOptions = [...Array(6).keys()].map(i => { return (<option key={i} value={i}>{i}</option>) })

      if(!currBL) {
        this.setState({ selectedBacklog: null });
        return null;
      }

      return (
        <>
        <Container className="mb-2">
        <Row><Col><Button onClick={this.handleChange} id="selectedBacklog" variant="secondary" size="sm">Back to Summary</Button></Col></Row>
        <Row>
        <Col>{`${currBL.bNo} - ${currBL.bName}`}</Col>
        <Col>
          <Form.Row>
            <Form.Label>Rating:</Form.Label>
            <Col>
            <Form.Control style={{ maxWidth: '3.5rem' }} as="select" size="sm" onChange={this.handleRatingChange} value={currBL.bR} id={currBL.bId}>
            {rOptions}
            </Form.Control>
            </Col>
          </Form.Row>
        </Col>
        </Row>
        </Container>
        <StockItemList id={'stock'} data={this.filterItems()} sortKeys={sortKeys} onItemChange={this.handleItemChange}
        onChange={this.handleSelectionChange} actions={stockActions} format="backlog"/>
        </>
      )
    } else {
      return (<BacklogSummary {...this.state} onChange={this.handleChange} onRatingChange={this.handleRatingChange} />);
    }
  }

  render() {
    if (!this.state.bItems[this.state.selectedBacklog]) this.refreshBacklogItems(this.state.selectedBacklog);

    var bRows = [];
    if (this.state.bList) {
      bRows = this.state.bList.sort(firstBy('bNo')).map(b => {
        return (
          <Nav.Item style={{ maxWidth: '260px' }} key={b.bId}>
            <Button onClick={this.handleDeleteBacklogShow} id={b.bId} variant="danger" className="my-0 py-0 mr-1 float-right" size="sm">&times;</Button>
            <Nav.Link className="text-truncate px-1 py-0" eventKey={b.bId}>
            {`${b.bNo} - ${b.bName}`}
            </Nav.Link>
          </Nav.Item>
        )
      })
    }

    return (
      <Container>
      <Row>
        <Col className="pr-0" md="auto">
          <Card className="mb-2">
          <Card.Header className="p-2">Show Only Items</Card.Header>
          <Card.Body className="p-1">
          <Form.Switch onChange={this.handleChange} id="whenNotPartedOut" checked={this.state.whenNotPartedOut || false} label="Not parted out" />
          <Form.Switch onChange={this.handleChange} id="whenDontHave" checked={this.state.whenDontHave || false} label="I don't have" />
          <Form.Switch onChange={this.handleChange} id="whenDontHaveInStock" checked={this.state.whenDontHaveInStock || false} label="I don't have in stock" />
          </Card.Body>
          </Card>

          <Card className="mb-2">
          <Card.Header className="p-2">Backlog Items<div className="float-right ml-2"><Button onClick={this.handleCreateBacklogShow} size="sm" className="my-0 py-0">+</Button></div></Card.Header>
          <Card.Body className="p-1">
          <Nav variant="pills" className="flex-column mx-0" activeKey={this.state.selectedBacklog} onSelect={this.handleSelectBacklog}>
            {bRows}
          </Nav>
          </Card.Body>
          </Card>
        </Col>
        <Col>
          {this.renderMainColumn()}
        </Col>
      </Row>
      <CreateBacklogModal {...this.state} show={this.state.showCreateBacklog}
        onHide={this.handleCreateBacklogHide} onSubmit={this.handleCreateBacklogSubmit}
        onSearch={this.handleCreateBacklogSearch} onChange={this.handleChange}
        colors={this.colors} items={this.state.cbItems} validated={this.cbIsValid()} />
      <ConfirmHaveModal {...this.state} show={this.state.showConfirmHave}
        onHide={this.handleConfirmHaveHide} onSubmit={this.handleConfirmHaveSubmit} />
      <DeleteBacklogModal show={this.state.showDeleteBacklog} onHide={this.handleDeleteBacklogHide}
      onSubmit={this.handleDeleteBacklogSubmit} backlogName={this.state.toDeleteBacklogName}
      backlogId={this.state.toDeleteBacklogId} />
      <GetXmlModal {...this.state} show={this.state.showGetXml}
        onHide={this.handleGetXmlHide} data={this.filterItems()} />
      </Container>
    )
  }
}

export default withAuthenticator(BackLog)

const DeleteBacklogModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Backlog Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete {props.backlogName}?
      All part selections you have made will be lost and no stock will be reserved for it.</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button variant="danger" id={props.backlogId} onClick={props.onSubmit}>
          Delete Item
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const BacklogSummary = (props) => {
  var sumSort = 'bNo';
  var sortDir = 1;

  if (props.sumSort) {
    sumSort = props.sumSort;
    if (['bR', 'nTot', 'hTot', 'sTot', 'bTot', 'bV', 'ppp'].includes(sumSort)) {
      sortDir = -1;
    }
  }

  var rows = [];
  if (props.bList) {
    var res = props.bList;

    if (props.whenDontHave)
      res = res.filter(b => b.nTot > b.hTot);

    rows = res.sort(firstBy(sumSort, { ignoreCase: true, direction: sortDir })).map(b => {
      return (<BacklogSummaryRow key={b.bId} backlog={b} onChange={props.onChange} onRatingChange={props.onRatingChange} />)
    })
  }

  return (
    <Table size="sm" hover>
    <thead>
      <tr>
        <th><Button variant="link" onClick={props.onChange} id="sumSort" value="bNo" className="p-0">Number</Button></th>
        <th><Button variant="link" onClick={props.onChange} id="sumSort" value="bName" className="p-0">Name</Button></th>
        <th><Button variant="link" onClick={props.onChange} id="sumSort" value="bV" className="p-0">Value</Button></th>
        <th><Button variant="link" onClick={props.onChange} id="sumSort" value="ppp" className="p-0">PPP</Button></th>
        <th><Button variant="link" onClick={props.onChange} id="sumSort" value="bR" className="p-0">Rating</Button></th>
        <th><Button variant="link" onClick={props.onChange} id="sumSort" value="nTot" className="p-0">Items</Button></th>
        <th><Button variant="link" onClick={props.onChange} id="sumSort" value="hTot" className="p-0">Have</Button></th>
        <th><Button variant="link" onClick={props.onChange} id="sumSort" value="sTot" className="p-0">In Stock</Button></th>
        <th><Button variant="link" onClick={props.onChange} id="sumSort" value="bTot" className="p-0">In Backlogs</Button></th>
        <th><Button variant="link" onClick={props.onChange} id="sumSort" value="fTot" className="p-0">To Get</Button></th>
      </tr>
    </thead>
    <tbody>
      {rows}
    </tbody>
    </Table>
  )
}

const BacklogSummaryRow = (props) => {
  const rOptions = [...Array(6).keys()].map(i => { return (<option key={i} value={i}>{i}</option>) })

  return (
    <tr>
      <td>{props.backlog.bNo}</td>
      <td><Button style={{ maxWidth: '15rem' }} variant="link" onClick={props.onChange} id="selectedBacklog" value={props.backlog.bId} className="text-truncate p-0">{props.backlog.bName}</Button></td>
      <td className="text-right">{(props.backlog.bV && props.backlog.bV >= 0) ? `€${props.backlog.bV.toFixed(2)}` : null}</td>
      <td className="text-right">{(props.backlog.ppp && props.backlog.ppp >= 0) ? `€${props.backlog.ppp.toFixed(2)}` : null}</td>
      <td><Form.Control style={{ maxWidth: '3.5rem' }} as="select" size="sm" onChange={props.onRatingChange} value={props.backlog.bR} id={props.backlog.bId}>
            {rOptions}
          </Form.Control>
      </td>
      <td>{props.backlog.nTot}</td>
      <td>{props.backlog.hTot}</td>
      <td>{props.backlog.sTot}</td>
      <td>{props.backlog.bTot}</td>
      <td>{props.backlog.fTot}</td>
    </tr>
  )
}

const ConfirmHaveModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Change quantities?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to update the quantities of all items?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button variant="danger" onClick={props.onSubmit}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const CreateBacklogModal = (props) => {
  const typeOptions = Object.keys(blTypeMap).map(key => {
    return (<option key={blTypeMap[key]} value={blTypeMap[key]}>{key}</option>)
  })
  typeOptions.push(<option key={99} value={99}>{"Rebrickable List"}</option>)

  var colorOptions = [];
  if (props.colors) {
    colorOptions = props.colors.sort(firstBy('name', { ignoreCase: true })).map(c => {
      return (<option key={c.colorid} value={c.colorid}>{c.name}</option>)
    })
  }

  var itemOptions = [];
  if (props.items) {
    itemOptions = props.items.sort(firstBy('cNo', { ignoreCase: true })).map(i => {
      return (<option key={i.cNo} value={i.cNo}>{`${i.cNo} - ${i.cName}`}</option>)
    })
  }

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create Backlog Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form.Group as={Row}>
        <Form.Label column>Search</Form.Label>
        <Col><Form.Control onChange={props.onChange} value={props.cbSearch || ''} id="cbSearch" placeholder="Item Number" type="text" /></Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column>Type</Form.Label>
        <Col><Form.Control as="select" onChange={props.onChange} value={props.cbItemType || ''} id="cbItemType" placeholder="Item Type">{typeOptions}</Form.Control></Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column>Item</Form.Label>
        <Col><Form.Control as="select" onChange={props.onChange} value={props.cbItemNo || ''} id="cbItemNo" placeholder="Item Type">{itemOptions}</Form.Control></Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column>Color</Form.Label>
        <Col><Form.Control as="select" disabled={props.cbItemType !== '2'} onChange={props.onChange} value={props.cbItemCol || ''} id="cbItemCol" placeholder="Item Color" >{colorOptions}</Form.Control></Col>
      </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button variant="primary" disabled={!(props.cbSearch || props.cbItemType === '99')} onClick={props.onSearch}>
          Search
        </Button>
        <Button variant="primary" disabled={!props.validated} onClick={props.onSubmit}>
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function OBJtoXML(obj) {
  var xml = '';
  for (var prop in obj) {
    xml += obj[prop] instanceof Array ? '' : "<" + prop + ">";
    if (obj[prop] instanceof Array) {
      for (var array in obj[prop]) {
        xml += "<" + prop + ">";
        xml += OBJtoXML(obj[prop][array]);
        xml += "</" + prop + ">";
      }
    } else if (typeof obj[prop] == "object") {
      xml += OBJtoXML(obj[prop]);
    } else {
      xml += obj[prop];
    }
    xml += obj[prop] instanceof Array ? '' : "</" + prop + ">";
  }
  xml = xml.replace(/<\/?[0-9]{1,}>/g, '');
  return xml
}

const GetXmlModal = (props) => {
  // console.log(props.data);
  const items = props.data ? props.data.filter(i => i.sQ > i.rQ + i.iQ).map(i => {
    return {
      ITEM: {
        ITEMTYPE: getKeyByVal(blTypeMap, i.sType).substring(0, 1),
        ITEMID: i.sNo,
        COLOR: i.sCid,
        MINQTY: i.sQ - (i.rQ + i.iQ)
      }
    }
  }) : [];

  // console.log(items);

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>BrickLink Wishlist XML</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form.Group as={Row}>
        <Form.Control defaultValue={"<INVENTORY>" + OBJtoXML(items) + "</INVENTORY>"} as="textarea" rows="20" />
      </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
