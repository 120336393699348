import React, { Component } from "react";
// import { Button, Container, Grid, Image } from 'semantic-ui-react'
import { Link } from "react-router-dom";

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

export default class Home extends Component {

  render() {
    return (
      <Container className='Home'>
        <h1>Brick The Gap</h1>
        <Row>
        <Col>
          <img alt='' src='/brickthegap.jpg' />
        </Col>
        <Col>
          <h3>Exactly what you've been waiting for!</h3>
          <p>We can help you brick ANY gap.</p>
          <p><Button as={Link} to='/tag/select'>Start Bricking</Button></p>
        </Col>
        </Row>
      </Container>
    );
  }
}
