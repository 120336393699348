import React, { Component } from "react";

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'

import RestApi from '../components/restapi'
import { getStorage, refreshStorage } from "../components/loaders"

import { firstBy } from "thenby";

export class StorageLocationEditorModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      warehouses: {},
      selW: '',
      selWB: '',
      selWBB: '',
      moveAllColors: true,
      moveOnlyUnset: false,
    }
  }

  componentDidMount() {
    getStorage(this.props.activeStore.storeId).then(res => this.setState({ storage: this.buildStorageTree(res) }))
  }

  componentDidUpdate() {
    if (this.props.item && this.props.item.sNo !== this.currentNo) {
      if (this.props.item.sWid) {
        this.setState ({
          selW: this.props.item.sWid,
          newW: this.state.storage[this.props.item.sWid].name,
          selWB: this.props.item.sWBid || '',
          newWB: this.props.item.sWBid ? this.state.storage[this.props.item.sWid].boxes[this.props.item.sWBid].name : '',
          selWBB: this.props.item.sWBBid || '',
          newWBB: this.props.item.sWBBid ? this.state.storage[this.props.item.sWid].boxes[this.props.item.sWBid].bags[this.props.item.sWBBid].name : '',
        })
      }
      this.currentNo = this.props.item.sNo;
    }
  }

  buildStorageTree(storageList) {
    var warehouses = {};
    // warehouses
    storageList.forEach(s => warehouses[s.wId] = { name: s.wName, boxes: {} })
    // boxes
    storageList.forEach(s => { if (s.wbId) warehouses[s.wId].boxes[s.wbId] = { name: s.wbName, bags: {} } })
    // bags
    storageList.forEach(s => { if (s.wbId && s.wbbId) warehouses[s.wId].boxes[s.wbId].bags[s.wbbId] = { name: s.wbbName } })
    return warehouses;
  }

  handleChange = event => {
    if (!event.target.validity.valid) return;

    const tid = event.target.id || event.target.name
    var val = event.target.value;
    if (event.target.type === 'checkbox') val = event.target.checked;

    this.setState({
      [tid]: val
    });

    this.syncSelects(tid, val)
  }

  handleEditLocation = (e) => {
    var newLocProm = null;
    // need new storage location?
    if ((!this.state.selW && this.state.newW)
      || (!this.state.selWB && this.state.newWB)
      || (!this.state.selWBB && this.state.newWBB)
      || (this.state.selW && this.state.newW !== this.state.storage[this.state.selW].name)
      || (this.state.selWB && this.state.newWB !== this.state.storage[this.state.selW].boxes[this.state.selWB].name)
      || (this.state.selWBB && this.state.newWBB !== this.state.storage[this.state.selW].boxes[this.state.selWB].bags[this.state.selWBB].name)
    ) {

      newLocProm = RestApi.setStorage(
        this.props.activeStore.storeId,
        this.state.selW,
        this.state.newW,
        this.state.selWB,
        this.state.newWB,
        this.state.selWBB,
        this.state.newWBB
      )
      .then(csres => {
        return refreshStorage(this.props.activeStore.storeId)
        .then(res => this.setState({
          storage: this.buildStorageTree(res),
          selW: csres.data[0].wId,
          selWB: csres.data[0].wbId,
          selWBB: csres.data[0].wbbId,
        }))
      })

      // this.setState ({ newW: null, newWB: null, newWBB: null })
    } else { newLocProm = Promise.resolve() }

    newLocProm.then(res => {
      return RestApi.setStockLocation(
        this.props.activeStore.storeId,
        this.props.item.sId,
        this.state.selW,
        this.state.selWB,
        this.state.selWBB,
        this.state.moveAllColors,
        this.state.moveOnlyUnset
      )
      .then(res => {
        this.props.onSubmit({ needRefresh: true })
      })
    })
  }

  syncSelects(tid, val) {
    if (tid === 'selW') this.setState({ selWB: '', newWB: '', selWBB: '', newWBB: '', newW: val ? this.state.storage[val].name : '' });
    if (tid === 'selWB') this.setState({ selWBB: '', newWBB: '', newWB: val ? this.state.storage[this.state.selW].boxes[val].name : '' });
    if (tid === 'selWBB') this.setState({ newWBB: val ? this.state.storage[this.state.selW].boxes[this.state.selWB].bags[val].name : '' });
  }

  isValid() {

    return true;
  }

  render() {
    if (!this.props.item) return null;

    var wOptions = Object.keys(this.state.storage)
      .map(k => { return { key: k, name: this.state.storage[k].name} })
      .sort(firstBy('name', { ignoreCase: true })).map(i => { return (<option key={i.key} value={i.key}>{i.name}</option>) })
    wOptions.unshift(<option key={0} value={null}></option>)

    var wbOptions = [];
    if (this.state.selW) {
      wbOptions = Object.keys(this.state.storage[this.state.selW].boxes)
      .map(k => { return { key: k, name: this.state.storage[this.state.selW].boxes[k].name} })
      .sort(firstBy('name', { ignoreCase: true })).map(i => { return (<option key={i.key} value={i.key}>{i.name}</option>) })
    }
    wbOptions.unshift(<option key={0} value={null}></option>)

    var wbbOptions = [];
    if (this.state.selW && this.state.selWB) {
      wbbOptions = Object.keys(this.state.storage[this.state.selW].boxes[this.state.selWB].bags)
      .map(k => { return { key: k, name: this.state.storage[this.state.selW].boxes[this.state.selWB].bags[k].name} })
      .sort(firstBy('name', { ignoreCase: true })).map(i => { return (<option key={i.key} value={i.key}>{i.name}</option>) })
    }
    wbbOptions.unshift(<option key={0} value={null}></option>)

    return (
      <Modal show={this.props.show} onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Storage Location of <div className="font-weight-bold">{this.props.item.sNo} - {this.props.item.sName}</div></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Group as={Row}>
        <Col>Move all colors to the same location</Col>
        <Col md="auto"><Form.Switch onChange={this.handleChange} id="moveAllColors" checked={this.state.moveAllColors} label=" " /></Col>
        </Form.Group>
        <Form.Group as={Row}>
        <Col>Move only items without location</Col>
        <Col md="auto"><Form.Switch onChange={this.handleChange} id="moveOnlyUnset" checked={this.state.moveOnlyUnset} label=" " /></Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column >Warehouse</Form.Label>
          <Col><Form.Control size="sm" disabled={!this.state.selW} onChange={this.handleChange} value={this.state.newW || ''} id="newW" placeholder="New Warehouse" type="text" /></Col>
          <Col><Form.Control size="sm" disabled={true} as="select" onChange={this.handleChange} value={this.state.selW} id="selW">{wOptions}</Form.Control></Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column >Box</Form.Label>
          <Col><Form.Control size="sm" disabled={!this.state.selW} onChange={this.handleChange} value={this.state.newWB || ''} id="newWB" placeholder="New Box" type="text" /></Col>
          <Col><Form.Control size="sm" disabled={!this.state.selW} as="select" onChange={this.handleChange} value={this.state.selWB} id="selWB">{wbOptions}</Form.Control></Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column >Bag</Form.Label>
          <Col><Form.Control size="sm" disabled={!this.state.selWB} onChange={this.handleChange} value={this.state.newWBB || ''} id="newWBB" placeholder="New Bag" type="text" /></Col>
          <Col><Form.Control size="sm" disabled={!this.state.selWB} as="select" onChange={this.handleChange} value={this.state.selWBB} id="selWBB">{wbbOptions}</Form.Control></Col>
        </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.onHide}>
            Close
          </Button>
          <Button variant="primary" disabled={!this.isValid()} id={this.props.listId} onClick={this.handleEditLocation}>
            Set Location
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
