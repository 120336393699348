import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Nav from 'react-bootstrap/Nav'

class Footer extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return(
        <Nav className="justify-content-center">
          <Nav.Link as={Link} to='/faq'>FAQ</Nav.Link>
          <Nav.Link as={Link} to='/help'>Help</Nav.Link>
          <Nav.Link as={Link} to='/about'>About</Nav.Link>
          <Nav.Link as={Link} to='/privacy'>Privacy</Nav.Link>
          <Nav.Link as={Link} to='/terms'>Terms</Nav.Link>
        </Nav>
    )
  }
}

export default Footer;
