import React, { Component } from "react";
import { withAuthenticator } from '@aws-amplify/ui-react';

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'

import SetItem from './SetItem'
import SetItemList from './SetItemList'
import RestApi from '../components/restapi'
import { getCategories } from "../components/loaders"
import { getAssemblyParts } from "../components/assemblies"

var CryptoJS = require("crypto-js");
var Promise = require("bluebird");

class SetRating extends Component {
  constructor(props) {
    super(props);

    this.sscreds = sessionStorage.getItem('btg.apiCreds');
    if (this.sscreds) {
      // Decrypt
      var bytes  = CryptoJS.AES.decrypt(this.sscreds, props.user.attributes.sub);
      this.creds = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }

    this.state = {
      updatingStats: true,
      showList: true,
      selectedSet: 0,
      scores: [],
      prefs: this.getPrefs()
    }

  }

  getPrefs() {
    var prefs = JSON.parse(localStorage.getItem('btg.userPreferences'));

    if (!prefs.activeStore.storePreferences) {
      prefs.activeStore.storePreferences = {}
    }

    if (!prefs.activeStore.storePreferences.setRating) {
      prefs.activeStore.storePreferences.setRating = {};
    }

    if (!prefs.activeStore.storePreferences.setRating.factors) {
      prefs.activeStore.storePreferences.setRating.factors = {
        foiqtysold: 1,
        foitimessold: 10,
        fmsqtysold: 1/10000,
        fmstimessold: 1/1000,
        fmwlistcount: 1/10000,
        fbliqtymin: 10
      };

      console.log("saving prefs");

      this.props.updateUserPreferences(prefs)
      this.props.saveUserPreferences();
    }

    return prefs.activeStore.storePreferences.setRating;
  }

  savePrefs() {
    var prefs = JSON.parse(localStorage.getItem('btg.userPreferences'));
    prefs.activeStore.storePreferences.setRating = this.state.prefs;
    this.props.updateUserPreferences(prefs)
    this.props.saveUserPreferences();
  }

  componentDidMount() {
    var prefs = this.state.prefs;
    var statsProm = Promise.resolve();
    console.log((new Date() - new Date(prefs.lastStatsUpdate)));
    const intervalms = 60000000; // 60M - 1000 minutes - 16+h
    // const intervalms = 60000; // 60M - 1000 minutes - 16+h
    if (!prefs.lastStatsUpdate || (new Date() - new Date(prefs.lastStatsUpdate) > intervalms)) {
      prefs.lastStatsUpdate = new Date();
      this.setState ({ prefs: prefs });
      this.savePrefs();
      statsProm = RestApi.setItemStats(this.props.activeStore.storeId);
    }

    getCategories()
    .then(res => { this.categories = res; })
    .then(res => { return statsProm; })
    .then(res => {
      this.setState({ updatingStats: false });
      return this.getAssemblyScores();
    })
  }

  getAssemblyScores() {
    return RestApi.getAssemblyScores(
      this.props.activeStore.storeId,
      this.state.prefs.factors.foiqtysold,
      this.state.prefs.factors.foitimessold,
      this.state.prefs.factors.fmsqtysold,
      this.state.prefs.factors.fmstimessold,
      this.state.prefs.factors.fmwlistcount,
      this.state.prefs.factors.fbliqtymin
    ).then(res => {
      const sets = res.data.map(item => {
        const cat = this.categories.find(l => l.cId === item.categoryid)
        if (cat) {
          item.category = cat.cName;
        }
        return new SetItem(item);
      })

      this.setState({ scores: sets });
      console.log(this.state.scores);
    })
  }

  handleListSelectionChange = (id, ids, checked) => {
    this.setState(state => {
      var listItems = this.state.scores || [];

      var affectedItems = listItems.filter(i => ids.includes(i.k))
      affectedItems.forEach(i => { i.chk = checked; });
      return {
        scores: state.scores
      };
    })
  }

  handleBatchCreate = async (e) => {
    var data = this.state.scores;
    data = data.filter(item => item.chk === true)

    const creds = this.sscreds
    return Promise.map(data, function(item) {
      // console.log(item);
      return getAssemblyParts(creds, item.itemno, 3, 0);
    }, { concurrency: 5 })
    .then(res => {
      return this.getAssemblyScores();
    })
  }

  handleBatchIgnore = async (e) => {
    var data = this.state.scores;
    data = data.filter(item => item.chk === true)

    const storeId = this.props.activeStore.storeId;
    return Promise.map(data, function(item) {
      return RestApi.setIgnoredAssembly(storeId, item.itemno, 3, "Batch Ignore")
      .then(res => {
        return RestApi.deleteAssembly(item.itemno, 3);
      })
    }, { concurrency: 5 })
    .then(res => {
      return this.getAssemblyScores();
    })
  }

  handleFFormChanged = async (e) => {
    // console.log(e.target);
    const id = e.target.id;
    const val = e.target.value;
    // console.log(id);
    // console.log(val);

    var prefs = this.state.prefs;
    switch (id) {
      case "foiqtysold":
        prefs.factors.foiqtysold = parseFloat(val) || 0.0;
        break;
      case "foitimessold":
        prefs.factors.foitimessold = parseFloat(val) || 0.0;
        break;
      case "fmsqtysold":
        prefs.factors.fmsqtysold = parseFloat(val) || 0.0;
        break;
      case "fmstimessold":
        prefs.factors.fmstimessold = parseFloat(val) || 0.0;
        break;
      case "fmwlistcount":
        prefs.factors.fmwlistcount = parseFloat(val) || 0.0;
        break;
      case "fbliqtymin":
        prefs.factors.fbliqtymin = parseInt(val) || 0;
        break;
      case "fsubmit":
        this.savePrefs();
        this.getAssemblyScores();
        break;
      default:
        break;
    }

    this.setState({ prefs: prefs });
    // console.log(this.state.prefs);
  }

  renderSetList() {
    const haveCreds = this.sscreds;

    const setActions = [];
    setActions.push({ label: 'Refresh', cb: this.handleBatchCreate, enabled: haveCreds })
    setActions.push({ label: 'Ignore', cb: this.handleBatchIgnore, enabled: haveCreds })

    const sortKeys = {
      marginscore: 'Margin Score',
      markup: 'Markup',
      pscorepp: 'Price Score / Part',
      pscore: 'Price Score',
      pricepp: 'Price / Part',
      price: 'Price',
      itemno: 'Set Number',
      name: 'Name',
      category: 'Category',
      yearreleased: 'Year',
      weight: 'Weight',
      numparts: '# Parts'
    }

    return (
      <SetItemList id={1} format="setScore" data={this.state.scores}
      sortKeys={sortKeys} sortDir={false} onChange={this.handleListSelectionChange}
      onItemChange={this.handleItemChange} actions={setActions} />
    )
  }

  renderSetsForm() {

    return (
      <Row>
        <Col>
          <Form.Group md="auto" as={Col} controlId="foiqtysold">
            <Form.Label size="sm">Qty Sold</Form.Label>{' '}
            <Form.Control size={'sm'} placeholder={1}
            onChange={this.handleFFormChanged} value={this.state.prefs.factors.foiqtysold} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group md="auto" as={Col} controlId="foitimessold">
            <Form.Label size="sm">Times Sold</Form.Label>{' '}
            <Form.Control size={'sm'} placeholder={1}
            onChange={this.handleFFormChanged} value={this.state.prefs.factors.foitimessold} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group md="auto" as={Col} controlId="fmsqtysold">
            <Form.Label size="sm">Most Sold(q)</Form.Label>{' '}
            <Form.Control size={'sm'} placeholder={1}
            onChange={this.handleFFormChanged} value={this.state.prefs.factors.fmsqtysold} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group md="auto" as={Col} controlId="fmstimessold">
            <Form.Label size="sm">Most Sold(t)</Form.Label>{' '}
            <Form.Control size={'sm'} placeholder={1}
            onChange={this.handleFFormChanged} value={this.state.prefs.factors.fmstimessold} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group md="auto" as={Col} controlId="fmwlistcount">
            <Form.Label size="sm">Most Wanted</Form.Label>{' '}
            <Form.Control size={'sm'} placeholder={1}
            onChange={this.handleFFormChanged} value={this.state.prefs.factors.fmwlistcount} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group size={'sm'} md="auto" as={Col} controlId="fbliqtymin">
            <Form.Label size={'sm'}>Min For Sale</Form.Label>{' '}
            <Form.Control size={'sm'} placeholder={1}
            onChange={this.handleFFormChanged} value={this.state.prefs.factors.fbliqtymin} />
          </Form.Group>
        </Col>
        <Col >
        <Form.Group md="auto" as={Col} controlId="fsubmit">
          <Form.Label size="sm" >Apply</Form.Label>{' '}
          <Form.Control as={Button} className="btn-sm" onClick={this.handleFFormChanged}>Submit</Form.Control>
        </Form.Group>
        </Col>
      </Row>
    )
  }

  render() {
    var status = ""
    if (this.state.updatingStats)
      status = "Updating item statistics. Please wait.";

    // var leftCol = ""
    var formRow = ""
    var mainCol = ""
    if (this.state.showList) {
      mainCol = this.renderSetList();
      formRow = this.renderSetsForm();
    }

    return (
      <Container className='Home'>
        <Row>
          <Col className="text-right">{ status }</Col>
        </Row>
        { formRow }
        <Row>
          <Col>{ mainCol }</Col>
        </Row>
      </Container>
    );
  }
}

export default withAuthenticator(SetRating)
