import { API, Auth } from "aws-amplify";

class RestApi {

  // Create
  static createBacklog(storeId, itemNo, itemType, colorId) {
    const args = [ storeId, itemNo, itemType, colorId ];
    return this.post('Backlog', args);
  }

  static createPickingList(storeId, listName, orderId, rbListId, plId, backlogId, fromListId) {
    const args = [ storeId, listName, orderId, rbListId, plId, backlogId, fromListId ];
    return this.post('PickingList', args);
  }

  static createPickingListItem(storeId, listId, listName, stockId, quantity) {
    const args = [ storeId, listId, listName, stockId, quantity ];
    return this.post('PickingListItem', args);
  }

  static createStockFromBlI(storeId, inventoryId) {
    const args = [ storeId, inventoryId ];
    return this.post('StockFromBlI', args);
  }

  static createStockFromCLI(storeId, listItemId) {
    const args = [ storeId, listItemId ];
    return this.post('StockFromCLI', args);
  }

  static createStockFromRbLI(storeId, listItemId) {
    const args = [ storeId, listItemId ];
    return this.post('StockFromRbLI', args);
  }

  static createStockListItem(storeId, listId, itemNo, itemType, colorId, quantity) {
    const args = [ storeId, listId, itemNo, itemType, colorId, quantity ];
    return this.post('StockListItem', args);
  }

  static createStore(name) {
    const args = [ name ];
    return this.post('Store', args);
  }

  static createUser(sub, email) {
    const args = [ sub, email ];
    return this.post('User', args);
  }

  // Get
  static getAssembly(itemNo, itemType, itemColor) {
    const args = [ itemNo, itemType, itemColor ];
    return this.get('Assembly', args);
  }

  static getAssemblyScores(storeId, foiqtysold, foitimessold, fmsqtysold, fmstimessold, fmwlistcount, fbliqtymin) {
    const args = [ storeId, foiqtysold, foitimessold, fmsqtysold, fmstimessold, fmwlistcount, fbliqtymin ];
    return this.get('AssemblyScores', args);
  }

  static getBacklog(storeId, backlogId = null, itemNo = null, itemType = null) {
    const args = [ storeId, backlogId, itemNo, itemType ];
    return this.get('Backlog', args);
  }

  static getBacklogItem(storeId, backlogId) {
    const args = [ storeId, backlogId ];
    return this.get('BacklogItem', args);
  }

  static getBlInventory(storeId, inventoryId = null, itemNo = null, itemType = null, colorId = null) {
    const args = [ storeId, inventoryId, itemNo, itemType, colorId ];
    return this.get('BlInventory', args);
  }

  static getCatalog(itemNo, itemType = null, itemName = null) {
    const args = [ itemNo, itemType, itemName ];
    return this.get('Catalog', args);
  }

  static getCategory(categoryId = null) {
    const args = [ categoryId ];
    return this.get('Category', args);
  }

  static getColor(colorId = null) {
    const args = [ colorId ];
    return this.get('Color', args);
  }

  static getIgnoredAssembly(storeId) {
    const args = [ storeId ];
    return this.get('IgnoredAssembly', args);
  }

  static getInconsistencies(storeId, incType) {
    const args = [ storeId, incType ];
    return this.get('Inconsistencies', args);
  }

  static getMissingAssemblies(storeId, year) {
    const args = [ storeId, year ];
    return this.get('MissingAssemblies', args);
  }

  static getOrder(storeId, orderId = null) {
    const args = [ storeId, orderId ];
    return this.get('Order', args);
  }

  static getPickingList(storeId, listId = null) {
    const args = [ storeId, listId ];
    return this.get('PickingList', args);
  }

  static getPickingListItem(storeId, listId = null, itemid = null) {
    const args = [ storeId, listId, itemid ];
    return this.get('PickingListItem', args);
  }

  static getRbList(storeId, listId = null, listType = null) {
    const args = [ storeId, listId, listType ];
    return this.get('RbList', args);
  }

  static getRbListItem(storeId, listItemId, listId, itemNo = null, colorId = null, listType = null) {
    const args = [ storeId, listItemId, listId, itemNo, colorId, listType ];
    return this.get('RbListItem', args);
  }

  static getReport(storeId, report) {
    const args = [ storeId, report ];
    return this.get('Report', args);
  }

  static getSaleTemplate(storeId, templateId = null) {
    const args = [ storeId, templateId ];
    return this.get('SaleTemplate', args);
  }

  static getStock(storeId, stockId = null) {
    const args = [ storeId, stockId ];
    return this.get('Stock', args);
  }

  static getStockList(storeId, stockListId = null) {
    const args = [ storeId, stockListId ];
    return this.get('StockList', args);
  }

  static getStockListItem(storeId, stockListId = null, stockListItemId = null) {
    const args = [ storeId, stockListId, stockListItemId ];
    return this.get('StockListItem', args);
  }

  static getStorage(storeId, warehouseId = null, boxId = null, bagId = null) {
    const args = [ storeId, warehouseId, boxId, bagId ];
    return this.get('Storage', args);
  }

  static getStore(storeId = null) {
    const args = [ storeId ];
    return this.get('Store', args);
  }

  static getUser() {
    const args = [ ];
    return this.get('User', args);
  }

  // Set
  static setAssembly(supno, suptype, supcolorid, subno, subtype, subcolorid, matchNo, quantity, extraQ, isAlt, isCounter) {
    const args = [ supno, suptype, supcolorid, subno, subtype, subcolorid, matchNo, quantity, extraQ, isAlt, isCounter ];
    return this.put('Assembly', args);
  }

  static setBacklog(storeId, backlogId, rating) {
    const args = [ storeId, backlogId, rating ];
    return this.put('Backlog', args);
  }

  static setBacklogItem(storeId, backlogItemId, backlogId, haveQty, usePartOut, haveAll, haveNone) {
    const args = [ storeId, backlogItemId, backlogId, haveQty, usePartOut, haveAll, haveNone ];
    return this.put('BacklogItem', args);
  }

  static setBlInventory(storeId, inventoryId, itemNo, itemType, colorId, quantity,
    new_or_used,
    completeness,
    unit_price,
    bind_id,
    description,
    remarks,
    bulk,
    is_retain,
    is_stock_room,
    stock_room_id,
    my_cost,
    sale_rate,
    tier_quantity1,
    tier_quantity2,
    tier_quantity3,
    tier_price1,
    tier_price2,
    tier_price3,
    my_weight,
    stockId
  ) {
    const args = [ storeId, inventoryId, itemNo, itemType, colorId, quantity,
      new_or_used,
      completeness,
      unit_price,
      bind_id,
      description,
      remarks,
      bulk,
      is_retain,
      is_stock_room,
      stock_room_id,
      my_cost,
      sale_rate,
      tier_quantity1,
      tier_quantity2,
      tier_quantity3,
      tier_price1,
      tier_price2,
      tier_price3,
      my_weight,
      stockId
    ];
    return this.put('BlInventory', args);
  }

  static setCategory(colorId, name, parentId) {
    const args = [ colorId, name, parentId ];
    return this.put('Category', args);
  }

  static setColor(colorId, name, code, type, colorIdRb, colorIdLego) {
    const args = [ colorId, name, code, type, colorIdRb, colorIdLego ];
    return this.put('Color', args);
  }

  static setIgnoredAssembly(storeId, itemNo, itemType, reason) {
    const args = [ storeId, itemNo, itemType, reason ];
    return this.put('IgnoredAssembly', args);
  }

  static setItemStats(storeId) {
    const args = [ storeId ];
    return this.put('ItemStats', args);
  }

  static setOrder(storeId, orderId, source, dateOrdered, dateStatusChanged, sellerName, storeName, buyerName, totalCount, uniqueCount, status, paymentMethod, paymentStatus, datePaid, currencyCode, subtotal, grandTotal, costCurrencyCode, orderDetails) {
    const args = [ storeId, orderId, source, dateOrdered, dateStatusChanged, sellerName, storeName, buyerName, totalCount, uniqueCount, status, paymentMethod, paymentStatus, datePaid, currencyCode, subtotal, grandTotal, costCurrencyCode, orderDetails ];
    return this.put('Order', args);
  }

  static setOrderItem(storeId, orderId, source, batch, inventoryId, itemNo, itemType, colorId, quantity, newOrUsed, completeness, unitPrice, unitPriceFinal, discountPrice, discountPriceFinal, currencyCode, remarks, description, weight) {
    const args = [ storeId, orderId, source, batch, inventoryId, itemNo, itemType, colorId, quantity, newOrUsed, completeness, unitPrice, unitPriceFinal, discountPrice, discountPriceFinal, currencyCode, remarks, description, weight ];
    return this.put('OrderItem', args);
  }

  static setPickingListItemPicked(storeId, listId, itemId, pickedQty) {
    const args = [ storeId, listId, itemId, pickedQty ];
    return this.put('PickingListItemPicked', args);
  }

  static setRbList(storeId, listId, listType, name, numitems, isStock, warehouseId) {
    const args = [ storeId, listId, listType, name, numitems, isStock, warehouseId ];
    return this.put('RbList', args);
  }

  static setRbListItem(storeId, listItemId, listId, itemNo, colorId, itemType, quantity, blItemNums) {
    const args = [ storeId, listItemId, listId, itemNo, colorId, itemType, quantity, blItemNums ];
    return this.put('RbListItem', args);
  }

  static setRbListItemStockId(storeId, listItemId, stockId) {
    const args = [ storeId, listItemId, stockId ];
    return this.put('RbListItemStockId', args);
  }

  static setStockBatch(storeId, stockIdList, newOrUsed, completeness, saleTemplateId, autoPrice, description, setDescription, pMin, pMax, setMinMax ) {
    const args = [ storeId, stockIdList, newOrUsed, completeness, saleTemplateId, autoPrice, description, setDescription, pMin, pMax, setMinMax ];
    return this.put('StockBatch', args);
  }

  static setStockFromBlI(storeId, stockId) {
    const args = [ storeId, stockId ];
    return this.put('StockFromBlI', args);
  }

  static setStockFromRbLI(storeId, stockId) {
    const args = [ storeId, stockId ];
    return this.put('StockFromRbLI', args);
  }

  static setStockList(storeId, listId, listName, warehouseId) {
    const args = [ storeId, listId, listName, warehouseId ];
    return this.put('StockList', args);
  }

  static setStockLocation(storeId, stockId, warehouseId, boxId, bagId, moveAllColors, moveOnlyUnset ) {
    const args = [ storeId, stockId, warehouseId, boxId, bagId, moveAllColors, moveOnlyUnset ];
    return this.put('StockLocation', args);
  }

  static setStockPG(storeId, stockId, sPGCmin, sPGCmax, sPGCavg, sPGCqavg, sPGCuq, sPGCtq,
                                      sPGRmin, sPGRmax, sPGRavg, sPGRqavg, sPGRuq, sPGRtq,
                                      sPGCSmin, sPGCSmax, sPGCSavg, sPGCSqavg, sPGCSuq, sPGCStq,
                                      sPGRSmin, sPGRSmax, sPGRSavg, sPGRSqavg, sPGRSuq, sPGRStq) {
    const args = [ storeId, stockId, sPGCmin, sPGCmax, sPGCavg, sPGCqavg, sPGCuq, sPGCtq,
                                      sPGRmin, sPGRmax, sPGRavg, sPGRqavg, sPGRuq, sPGRtq,
                                      sPGCSmin, sPGCSmax, sPGCSavg, sPGCSqavg, sPGCSuq, sPGCStq,
                                      sPGRSmin, sPGRSmax, sPGRSavg, sPGRSqavg, sPGRSuq, sPGRStq ];
    return this.put('StockPG', args);
  }

  static setStorage(storeId, warehouseId, warehouseName, boxId, boxName, bagId, bagName) {
    const args = [ storeId, warehouseId, warehouseName, boxId, boxName, bagId, bagName ];
    return this.put('Storage', args);
  }

  static setStockListItem(storeId, stockListId, itemId, quantity) {
    const args = [ storeId, stockListId, itemId, quantity ];
    return this.put('StockListItem', args);
  }

  static setStockQty(storeId, stockId, quantity) {
    const args = [ storeId, stockId, quantity ];
    return this.put('StockQty', args);
  }

  static setStore(storeId, name, preferences) {
    const args = [ storeId, name, preferences ];
    return this.put('Store', args);
  }

  static setUser(firstname, lastname, phonenumber, preferences) {
    const args = [ firstname, lastname, phonenumber, preferences ];
    return this.put('User', args);
  }

  // Delete
  static deleteAssembly(supno, suptype, supcolorid, subno, subtype, subcolorid, matchNo) {
    const args = [ supno, suptype, supcolorid, subno, subtype, subcolorid, matchNo ];
    return this.delete('Assembly', args);
  }

  static deleteBacklog(storeId, backlogId) {
    const args = [ storeId, backlogId ];
    return this.delete('Backlog', args);
  }

  // Not needed yet
  // static deleteBacklogItem(storeId, backlogItemId, backlogId, parentId) {
  //   const args = [ storeId, backlogItemId, backlogId, parentId ];
  //   return this.delete('BacklogItem', args);
  // }

  static deleteBlInventory(storeId, inventoryId, stockId) {
    const args = [ storeId, inventoryId, stockId ];
    return this.delete('BlInventory', args);
  }

  static deleteCategory(categoryId) {
    const args = [ categoryId ];
    return this.delete('Category', args);
  }

  static deleteColor(colorId) {
    const args = [ colorId ];
    return this.delete('Color', args);
  }

  static deleteIgnoredAssembly(storeId, itemNo, itemType) {
    const args = [ storeId, itemNo, itemType ];
    return this.delete('IgnoredAssembly', args);
  }

  static deletePickingList(storeId, listId) {
    const args = [ storeId, listId ];
    return this.delete('PickingList', args);
  }

  static deleteRbList(storeId, listId, listType) {
    const args = [ storeId, listId, listType ];
    return this.delete('RbList', args);
  }

  static deleteRbListItem(storeId, listItemId) {
    const args = [ storeId, listItemId ];
    return this.delete('RbListItem', args);
  }

  static deleteStock(storeId, stockId) {
    const args = [ storeId, stockId ];
    return this.delete('Stock', args);
  }

  static deleteStockList(storeId, stockListId) {
    const args = [ storeId, stockListId ];
    return this.delete('StockList', args);
  }

  static deleteStockListItem(storeId, stockListId, itemId) {
    const args = [ storeId, stockListId, itemId ];
    return this.delete('StockListItem', args);
  }

  static deleteStore(storeId) {
    const args = [ storeId ];
    return this.delete('Store', args);
  }

  // Util
  static hasPrivilege(privileges, privilege) {
    const privMap = {
      StoreEditDetails: '1',
      StoreDelete: '2',
      StoreShare: '3',
      StoreRead: '4',
      StoreUpdateRb: '5',
      StoreUpdateBl: '6',
      StoreUpdateStock: '7'
    }

    return privileges.split(',').includes(privMap[privilege]);
  }

  static get(uri, args) {
    const body = { args };

    return Auth.currentSession()
    .then(res => {
      const headers = { ApiAuth: res.idToken.jwtToken }
      return API.get('RestApi', uri, { queryStringParameters: body, headers: headers } )
    })
    .then(res => {
      return this.formatResult(res);
    });
  }

  static post(uri, args) {
    const body = { args };

    return Auth.currentSession()
    .then(res => {
      const headers = { ApiAuth: res.idToken.jwtToken }
      return API.post('RestApi', uri, { body: body, headers: headers } )
    })
    .then(res => {
      return this.formatResult(res);
    });
  }

  static put(uri, args) {
    const body = { args };

    return Auth.currentSession()
    .then(res => {
      const headers = { ApiAuth: res.idToken.jwtToken }
      return API.put('RestApi', uri, { body: body, headers: headers } )
    })
    .then(res => {
      return this.formatResult(res);
    });
  }

  static delete(uri, args) {
    const body = { args };

    return Auth.currentSession()
    .then(res => {
      const headers = { ApiAuth: res.idToken.jwtToken }
      return API.del('RestApi', uri, { body: body, headers: headers } )
    })
    .then(res => {
      return this.formatResult(res);
    });
  }

  static formatResult(res) {
    if (res.body.length) {
      return {
        meta: res.body.splice(-1,1)[0],
        data: res.body.length > 1 ? res.body : res.body[0],
      }
    }
    else {
      return {
        meta: res.body,
        data: null
      }
    }
  }
}

export default RestApi
