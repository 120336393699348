import React, { Component } from "react";
import { withAuthenticator } from '@aws-amplify/ui-react';

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
// import Table from 'react-bootstrap/Table'

// import RestApi from '../components/restapi'
// import StockItem from './StockItem'
// import StockItemList from './StockItemList'
import { blTypeMap } from "../components/util"
import { cleanUpLoaders, getStorage } from "../components/loaders"

import { firstBy } from "thenby";
// var bl = require('../components/bl');
// var Promise = require("bluebird");

class Storage extends Component {
  constructor(props) {
    super(props);

    cleanUpLoaders();

    this.sscreds = sessionStorage.getItem('btg.apiCreds');

    this.state = {
      selectedWarehouse: 0,
      // bList: [],
      // cbItemType: 3,
      // cbItemCol: 0,
      // bItems: {},
    }
  }

  componentDidMount() {
    getStorage(this.props.activeStore.storeId).then(res => { this.setState({ storage: res }) });
    // getColors().then(res => { this.colors = res; })
    // getCategories().then(res => { this.categories = res; })
    // getBacklog(this.props.activeStore.storeId).then(res => { this.setState({ bList: res }) })
  }
  //
  // filterItems() {
  //   var res = this.state.bItems[this.state.selectedBacklog] || [];
  //   res = res.filter(i => !i.sFS);
  //
  //   if (this.state.whenDontHave)
  //     res = res.filter(i => i.sQ > i.rQ);
  //   if (this.state.whenDontHaveInStock)
  //     res = res.filter(i => i.sQ > i.rQ + i.iQ);
  //   // if (this.state.whenApLimited)
  //   //   res = res.filter(i => i.sAPv && (i.sAPv === i.sMmin || i.sAPv === i.sMmax) );
  //   // if (this.state.whenPGEmpty)
  //   //   res = res.filter(i => i.sLUPG && !i.sPGRtq );
  //   // res = res.filter(i => this.state.showByList.includes(i.rlId));
  //   // res = res.filter(i => this.state.showByType.includes(i.sType));
  //   // res = res.filter(i => this.state.showByCond.includes(i.sNU) ||
  //   //                         (this.state.showByCond.includes('ns') && !i.sNU));
  //   // res = res.filter(i => this.state.showByComp.includes(i.sSCI) ||
  //   //                         (this.state.showByComp.includes('ns') && !i.sSCI));
  //   // res = res.filter(i => ((this.state.showForSale.includes('nfs') && i.sFS !== 1) ||
  //   //                         (this.state.showForSale.includes('fs') && i.sFS === 1)));
  //   return res;
  // }
  //
  // refreshBacklogItems(backlogId, clearFirst) {
  //   if (!backlogId) return;
  //
  //   return RestApi.getBacklogItem(this.props.activeStore.storeId, backlogId)
  //   .then(res => {
  //     const newItems = res.data.map(item => {
  //       const color = this.colors.find(l => l.colorid === item.sCid)
  //       if (color) {
  //         item.sCname = color.name;
  //         item.sCcode = color.code;
  //       }
  //       const cat = this.categories.find(l => l.cId === item.sCat)
  //       if (cat) {
  //         item.sCatN = cat.cName;
  //       }
  //       return new StockItem(item);
  //     });
  //
  //     this.setState(state => {
  //       var bItems = state.bItems;
  //       if (clearFirst) bItems = {};
  //       bItems[backlogId] = newItems;
  //       return { bItems: bItems }
  //     });
  //
  //     return newItems;
  //   })
  // }
  //
  // getAssemblyParts(itemNo, itemType, itemColor) {
  //   var parts = [];
  //
  //   return RestApi.getAssembly(itemNo, itemType, itemColor || 0)
  //   .then(res => {
  //     // console.log(res);
  //     parts = res.data;
  //
  //     const blClient = new bl.Client(this.sscreds);
  //     const subSet = bl.Subset.get(getKeyByVal(blTypeMap, parseInt(itemType)), itemNo, { color_id: itemColor || null })
  //
  //     return blClient.send(subSet)
  //     .then(res => {
  //       const entries = res.data.map(i => i.entries.map(ii => {
  //         ii.mNo = i.match_no;
  //         return ii;
  //       })).flat();
  //
  //       // console.log(parts);
  //       // entries to update
  //       var changedItems = entries.filter(entry => {
  //         return parts.find(p => (
  //           entry.item.no === p.sNo &&
  //           blTypeMap[entry.item.type] === p.sType &&
  //           entry.color_id === p.sCid &&
  //           entry.quantity === p.sQ &&
  //           entry.extra_quantity === p.sE &&
  //           entry.is_alternate === (p.sA > 0) &&
  //           entry.is_counterpart === (p.sC > 0) &&
  //           entry.mNo === p.mNo
  //         )) ? false : true;
  //       })
  //
  //       // entries to delete
  //       var deletedItems = parts.filter(p => {
  //         return entries.find(entry => (
  //           entry.item.no === p.sNo &&
  //           blTypeMap[entry.item.type] === p.sType &&
  //           entry.color_id === p.sCid &&
  //           entry.mNo === p.mNo
  //         )) ? false : true;
  //       });
  //       // console.log(changedItems);
  //       // console.log(deletedItems);
  //
  //       return Promise.map(changedItems, function(item) {
  //         return RestApi.setAssembly(
  //           itemNo,
  //           itemType,
  //           itemColor || 0,
  //           item.item.no,
  //           blTypeMap[item.item.type],
  //           item.color_id,
  //           item.mNo,
  //           item.quantity,
  //           item.extra_quantity,
  //           item.is_alternate,
  //           item.is_counterpart,
  //         )
  //       }, { concurrency: 5 } )
  //       .then(res => {
  //         return Promise.map(deletedItems, function(item) {
  //           return RestApi.deleteAssembly(
  //             itemNo,
  //             itemType,
  //             itemColor,
  //             item.sNo,
  //             item.sType,
  //             item.sCid,
  //             item.mNo
  //           )
  //         }, { concurrency: 5 } )
  //       })
  //       .then(res => {
  //         return entries.map(entry => {
  //           return {
  //             sNo: entry.item.no,
  //             sType: blTypeMap[entry.item.type],
  //             sCid: entry.color_id,
  //             sQ: entry.quantity,
  //             sE: entry.extra_quantity,
  //             sA: entry.is_alternate,
  //             sC: entry.is_counterpart,
  //             mNo: entry.mNo,
  //           }
  //         })
  //       })
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     })
  //   })
  // }
  //
  // itemPartInOut(backlog, item, partOut) {
  //   const prom = partOut ? this.getAssemblyParts(item.sNo, item.sType, item.sCid) : Promise.resolve();
  //   var newItems = [];
  //
  //   return prom.then(res => {
  //     return RestApi.setBacklogItem(this.props.activeStore.storeId, item.sId, backlog, null, partOut || 0);
  //   })
  //   .then(res => {
  //     return this.refreshBacklogItems(backlog)
  //   })
  //   .then(res => {
  //     newItems = res;
  //     return refreshBacklog(this.props.activeStore.storeId)
  //   })
  //   .then(res => {
  //     localStorage.setItem('btg.stockNeedsRefresh', JSON.stringify(true));
  //     this.setState({ bList: res });
  //     return newItems;
  //   })
  //   .catch(err => { console.log(err); })
  // }
  //
  // itemSetHave(item, haveQty) {
  //   RestApi.setBacklogItem(this.props.activeStore.storeId, item.sId, this.state.selectedBacklog, haveQty)
  //   .then(res => {
  //     return this.refreshBacklogItems(this.state.selectedBacklog)
  //   })
  //   .then(res => {
  //     return refreshBacklog(this.props.activeStore.storeId)
  //   })
  //   .then(res => {
  //     localStorage.setItem('btg.stockNeedsRefresh', JSON.stringify(true));
  //     this.setState({ bList: res });
  //   })
  //   .catch(err => { console.log(err); })
  // }
  //
  // itemSetHaveAll(have) {
  //   RestApi.setBacklogItem(this.props.activeStore.storeId, null, this.state.selectedBacklog, null, null, have, !have)
  //   .then(res => {
  //     return this.refreshBacklogItems(this.state.selectedBacklog)
  //   })
  //   .then(res => {
  //     return refreshBacklog(this.props.activeStore.storeId)
  //   })
  //   .then(res => {
  //     localStorage.setItem('btg.stockNeedsRefresh', JSON.stringify(true));
  //     this.setState({ bList: res });
  //   })
  //   .catch(err => { console.log(err); })
  // }
  //
  handleChange = event => {
    if (event.target.validity && !event.target.validity.valid) return;

    const tid = event.target.id || event.target.name
    var val = event.target.value;
    if (event.target.type === 'checkbox') val = event.target.checked;

    this.setState({
      [tid]: val
    });
  }

  // handleCreateBacklogShow = async (e) => {
  //   this.setState({ showCreateBacklog: true });
  // }
  //
  // handleCreateBacklogHide = async (e) => {
  //   this.setState({ showCreateBacklog: false });
  // }
  //
  // handleCreateBacklogSearch = async (e) => {
  //   RestApi.getCatalog(this.state.cbSearch, this.state.cbItemType)
  //   .then(res => {
  //     var newNo = '';
  //     if (res.data[0]) newNo = res.data[0].cNo;
  //     this.setState({ cbItems: res.data, cbItemNo: newNo })
  //   })
  // }
  //
  // handleCreateBacklogSubmit = async (e) => {
  //   var item = {
  //     sNo: this.state.cbItemNo,
  //     sType: this.state.cbItemType,
  //     sCid: this.state.cbItemCol,
  //   };
  //   var newbid = null;
  //   RestApi.createBacklog(
  //     this.props.activeStore.storeId,
  //     this.state.cbItemNo,
  //     this.state.cbItemType,
  //     this.state.cbItemCol,
  //   )
  //   .then(res => {
  //     newbid = res.data[0].bId;
  //     item.sId = res.data[0].sId;
  //     return this.itemPartInOut(newbid, item, true)
  //   })
  //   .then(res => {
  //     const minifigs = res.filter(i => i.sType === 1);
  //     const self = this;
  //     return Promise.map(minifigs, function(item) {
  //       return self.itemPartInOut(newbid, item, true);
  //     }, { concurrency: 5 } );
  //   })
  //   .then(res => {
  //     this.setState({ showCreateBacklog: false })
  //     this.handleSelectBacklog(newbid);
  //   })
  // }
  //
  handleSelectWarehouse = async (e) => {
    const selectedWarehouse = parseInt(e);
    this.setState({ selectedWarehouse: selectedWarehouse, selectedBox: 0, selectedBag: 0 });
  }

  handleSelectBox = async (e) => {
    const selectedBox = parseInt(e);
    this.setState({ selectedBox: selectedBox, selectedBag: 0 });
  }

  handleSelectBag = async (e) => {
    const selectedBag = parseInt(e);
    this.setState({ selectedBag: selectedBag });
  }

  // handleItemChange = (e) => {
  //   if (!e.target.validity.valid) return;
  //
  //   const tid = e.target.id || e.target.name
  //   var val = e.target.value;
  //   const item = this.state.bItems[this.state.selectedBacklog].find(i => i.k === parseInt(tid))
  //
  //   if (e.target.type === 'checkbox') {
  //     val = e.target.checked;
  //     this.itemPartInOut(this.state.selectedBacklog, item, val);
  //   } else {
  //     this.itemSetHave(item, parseInt(val));
  //   }
  // }
  //
  // handleHaveAll = (e) => {
  //   this.setState({ showConfirmHave: true, chHave: true })
  // }
  //
  // handleHaveNone = (e) => {
  //   this.setState({ showConfirmHave: true, chHave: false })
  // }
  //
  // handleConfirmHaveHide = (e) => {
  //   this.setState({ showConfirmHave: false })
  // }
  //
  // handleConfirmHaveSubmit = (e) => {
  //   // console.log(this.state.chHave);
  //   this.itemSetHaveAll(this.state.chHave);
  //   this.setState({ showConfirmHave: false })
  // }
  //
  // handleDeleteBacklogShow = async (e) => {
  //   const bId = parseInt(e.target.id);
  //   const b = this.state.bList.find(l => l.bId === bId);
  //   const bName = b ? `${b.bNo} - ${b.bName}` : null;
  //   this.setState({ showDeleteBacklog: true, toDeleteBacklogName: bName, toDeleteBacklogId: bId });
  // }
  //
  // handleDeleteBacklogHide = async (e) => {
  //   this.setState({ showDeleteBacklog: false });
  // }
  //
  // handleDeleteBacklogSubmit = async (e) => {
  //   const id = parseInt(e.target.id)
  //   const selId = id === this.state.selectedBacklog ? 0 : this.state.selectedBacklog;
  //
  //   RestApi.deleteBacklog(
  //     this.props.activeStore.storeId,
  //     id
  //   )
  //   .then(res => {
  //     return refreshBacklog(this.props.activeStore.storeId)
  //   })
  //   .then(res => {
  //     localStorage.setItem('btg.stockNeedsRefresh', JSON.stringify(true));
  //     this.setState({ bList: res, showDeleteBacklog: false, selectedBacklog: selId })
  //   })
  // }
  //
  // handleRatingChange = (e) => {
  //   const bId = parseInt(e.target.id)
  //   const rating = parseInt(e.target.value)
  //
  //   RestApi.setBacklog(this.props.activeStore.storeId, bId, rating)
  //   .then(res => {
  //     localStorage.setItem('btg.stockNeedsRefresh', JSON.stringify(true));
  //     this.setState(state => {
  //       const currBL = state.bList.find(bl => bl.bId === parseInt(bId))
  //       currBL.bR = rating;
  //       localStorage.setItem('btg.backlog', JSON.stringify(state.bList));
  //       return { bList: state.bList }
  //     })
  //   })
  // }

  cbIsValid() {
    const colorOk = this.state.cbItemType !== '2' || this.state.cbItemCol

    return this.state.cbItemNo && colorOk;
  }

  getWRows() {
    var wRows = [];
    if (this.state.storage) {
      const warehouses = [];
      const map = new Map();
      for (const item of this.state.storage) {
          if(!map.has(item.wId)){
              map.set(item.wId, true);    // set any value to Map
              warehouses.push(item);
          }
      }
      warehouses.unshift({ wId: 0 })

      wRows = warehouses.sort(firstBy('wName', { ignoreCase: true })).map(s => {
        return (
          <Nav.Item style={{ maxWidth: '260px' }} key={s.wId}>
            <Button onClick={this.handleDeleteWarehouseShow} id={s.wId} variant="danger" className="my-0 py-0 mr-1 float-right" size="sm">x</Button>
            <Nav.Link className="text-truncate px-1 py-0" eventKey={s.wId}>{s.wName || '(None)'}</Nav.Link>
          </Nav.Item>
        )
      })
    }
    return wRows;
  }

  getWbRows() {
    var wbRows = [];
    if (this.state.storage) {
      const boxes = [];
      if (this.state.selectedWarehouse) {
        const bmap = new Map();
        for (const item of this.state.storage.filter(s => s.wId === this.state.selectedWarehouse && s.wbId)) {
            if(!bmap.has(item.wbId)){
                bmap.set(item.wbId, true);    // set any value to Map
                boxes.push(item);
            }
        }
        boxes.unshift({ wbId: 0 })

        wbRows = boxes.sort(firstBy('wbName', { ignoreCase: true })).map(s => {
          return (
            <Nav.Item style={{ maxWidth: '260px' }} key={s.wbId}>
              <Button onClick={this.handleDeleteBoxShow} id={s.wbId} variant="danger" className="my-0 py-0 mr-1 float-right" size="sm">x</Button>
              <Nav.Link className="text-truncate px-1 py-0" eventKey={s.wbId}>{s.wbName || '(None)'}</Nav.Link>
            </Nav.Item>
          )
        })
      }
    }
    return wbRows;
  }

  getWbbRows() {
    var wbbRows = [];
    if (this.state.storage) {
      const bags = [];
      if (this.state.selectedBox) {
        const bbmap = new Map();
        for (const item of this.state.storage.filter(s => s.wbId === this.state.selectedBox && s.wbbId)) {
            if(!bbmap.has(item.wbbId)){
                bbmap.set(item.wbbId, true);    // set any value to Map
                bags.push(item);
            }
        }
        bags.unshift({ wbbId: 0 })

        wbbRows = bags.sort(firstBy('wbbName', { ignoreCase: true })).map(s => {
          return (
            <Nav.Item style={{ maxWidth: '260px' }} key={s.wbbId}>
              <Button onClick={this.handleDeleteBagShow} id={s.wbbId} variant="danger" className="my-0 py-0 mr-1 float-right" size="sm">x</Button>
              <Nav.Link className="text-truncate px-1 py-0" eventKey={s.wbbId}>{s.wbbName || '(None)'}</Nav.Link>
            </Nav.Item>
          )
        })
      }
    }
    return wbbRows;
  }

  renderMainColumn() {
    // const sortKeys = {
    //   sCatN: 'Category',
    //   sName: 'Name',
    //   sNo: 'Item Num',
    // }
    //
    // const stockActions = [];
    // stockActions.push({ label: 'Have All', cb: this.handleHaveAll, enabled: this.state.selectedBacklog })
    // stockActions.push({ label: 'Have None', cb: this.handleHaveNone, enabled: this.state.selectedBacklog })
    //
    // if (this.state.selectedBacklog) {
    //   const currBL = this.state.bList.find(bl => bl.bId === parseInt(this.state.selectedBacklog))
    //   const rOptions = [...Array(6).keys()].map(i => { return (<option key={i} value={i}>{i}</option>) })
    //
    //   return (
    //     <>
    //     <Container className="mb-2">
    //     <Row><Col><Button onClick={this.handleChange} id="selectedBacklog" variant="secondary" size="sm">Back to Summary</Button></Col></Row>
    //     <Row>
    //     <Col>{`${currBL.bNo || null} - ${currBL.bName || null}`}</Col>
    //     <Col>
    //       <Form.Row>
    //         <Form.Label>Rating:</Form.Label>
    //         <Col>
    //         <Form.Control style={{ maxWidth: '3.5rem' }} as="select" size="sm" onChange={this.handleRatingChange} value={currBL.bR} id={currBL.bId}>
    //         {rOptions}
    //         </Form.Control>
    //         </Col>
    //       </Form.Row>
    //     </Col>
    //     </Row>
    //     </Container>
    //     <StockItemList id={'stock'} data={this.filterItems()} sortKeys={sortKeys} onItemChange={this.handleItemChange}
    //     onChange={this.handleSelectionChange} actions={stockActions} format="backlog"/>
    //     </>
    //   )
    // } else {
    //   return (<BacklogSummary {...this.state} onChange={this.handleChange} onRatingChange={this.handleRatingChange} />);
    // }
  }

  render() {
    var wRows = this.getWRows();
    var wbRows = this.getWbRows();
    var wbbRows = this.getWbbRows();

    // <Card className="mb-2">
    // <Card.Header className="p-2">Show Only Items</Card.Header>
    // <Card.Body className="p-1">
    // <Form.Switch onChange={this.handleChange} id="whenNoStorage" checked={this.state.whenNoStorage || false} label="Without storage location" />
    // </Card.Body>
    // </Card>

    // <Form.Switch onChange={this.handleChange} id="whenDontHaveInStock" checked={this.state.whenDontHaveInStock || false} label="I don't have in stock" />

    return (
      <Container>
      <Row>
        <Col className="pr-0" md="auto">
          <Card className="mb-2">
          <Card.Header className="p-2">Warehouses<div className="float-right ml-2"><Button onClick={this.handleCreateBacklogShow} size="sm" className="my-0 py-0">+</Button></div></Card.Header>
          <Card.Body className="p-1">
          <Nav variant="pills" className="flex-column mx-0" activeKey={this.state.selectedWarehouse} onSelect={this.handleSelectWarehouse}>
            {wRows}
          </Nav>
          </Card.Body>
          </Card>
          <Card className="mb-2">
          <Card.Header className="p-2">Boxes<div className="float-right ml-2"><Button onClick={this.handleCreateBoxShow} size="sm" className="my-0 py-0">+</Button></div></Card.Header>
          <Card.Body className="p-1">
          <Nav variant="pills" className="flex-column mx-0" activeKey={this.state.selectedBox} onSelect={this.handleSelectBox}>
            {wbRows}
          </Nav>
          </Card.Body>
          </Card>
          <Card className="mb-2">
          <Card.Header className="p-2">Bags<div className="float-right ml-2"><Button onClick={this.handleCreateBagShow} size="sm" className="my-0 py-0">+</Button></div></Card.Header>
          <Card.Body className="p-1">
          <Nav variant="pills" className="flex-column mx-0" activeKey={this.state.selectedBag} onSelect={this.handleSelectBag}>
            {wbbRows}
          </Nav>
          </Card.Body>
          </Card>
        </Col>
        <Col>
          {this.renderMainColumn()}
        </Col>
      </Row>
      <CreateBacklogModal {...this.state} show={this.state.showCreateBacklog}
        onHide={this.handleCreateBacklogHide} onSubmit={this.handleCreateBacklogSubmit}
        onSearch={this.handleCreateBacklogSearch} onChange={this.handleChange}
        colors={this.colors} items={this.state.cbItems} validated={this.cbIsValid()} />
      <ConfirmHaveModal {...this.state} show={this.state.showConfirmHave}
        onHide={this.handleConfirmHaveHide} onSubmit={this.handleConfirmHaveSubmit} />
      <DeleteBacklogModal show={this.state.showDeleteBacklog} onHide={this.handleDeleteBacklogHide}
      onSubmit={this.handleDeleteBacklogSubmit} backlogName={this.state.toDeleteBacklogName}
      backlogId={this.state.toDeleteBacklogId} />
      </Container>
    )
  }
}

export default withAuthenticator(Storage)

const DeleteBacklogModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Backlog Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete {props.backlogName}?
      All part selections you have made will be lost and no stock will be reserved for it.</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button variant="danger" id={props.backlogId} onClick={props.onSubmit}>
          Delete Item
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

// const BacklogSummary = (props) => {
//   var sumSort = 'bNo';
//   var sortDir = 1;
//
//   if (props.sumSort) {
//     sumSort = props.sumSort;
//     if (['bR', 'nTot', 'hTot', 'sTot', 'bTot', 'bV', 'ppp'].includes(sumSort)) {
//       sortDir = -1;
//     }
//   }
//
//   var rows = [];
//   if (props.bList) {
//     var res = props.bList;
//
//     if (props.whenDontHave)
//       res = res.filter(b => b.nTot > b.hTot);
//
//     rows = res.sort(firstBy(sumSort, { ignoreCase: true, direction: sortDir })).map(b => {
//       return (<BacklogSummaryRow key={b.bId} backlog={b} onChange={props.onChange} onRatingChange={props.onRatingChange} />)
//     })
//   }
//
//   return (
//     <Table size="sm" hover>
//     <thead>
//       <tr>
//         <th><Button variant="link" onClick={props.onChange} id="sumSort" value="bNo" className="p-0">Number</Button></th>
//         <th><Button variant="link" onClick={props.onChange} id="sumSort" value="bName" className="p-0">Name</Button></th>
//         <th><Button variant="link" onClick={props.onChange} id="sumSort" value="bV" className="p-0">Value</Button></th>
//         <th><Button variant="link" onClick={props.onChange} id="sumSort" value="ppp" className="p-0">PPP</Button></th>
//         <th><Button variant="link" onClick={props.onChange} id="sumSort" value="bR" className="p-0">Rating</Button></th>
//         <th><Button variant="link" onClick={props.onChange} id="sumSort" value="nTot" className="p-0">Items</Button></th>
//         <th><Button variant="link" onClick={props.onChange} id="sumSort" value="hTot" className="p-0">Have</Button></th>
//         <th><Button variant="link" onClick={props.onChange} id="sumSort" value="sTot" className="p-0">In Stock</Button></th>
//         <th><Button variant="link" onClick={props.onChange} id="sumSort" value="bTot" className="p-0">In Backlogs</Button></th>
//         <th><Button variant="link" onClick={props.onChange} id="sumSort" value="fTot" className="p-0">To Get</Button></th>
//       </tr>
//     </thead>
//     <tbody>
//       {rows}
//     </tbody>
//     </Table>
//   )
// }
//
// const BacklogSummaryRow = (props) => {
//   const rOptions = [...Array(6).keys()].map(i => { return (<option key={i} value={i}>{i}</option>) })
//
//   return (
//     <tr>
//       <td>{props.backlog.bNo}</td>
//       <td><Button style={{ maxWidth: '15rem' }} variant="link" onClick={props.onChange} id="selectedBacklog" value={props.backlog.bId} className="text-truncate p-0">{props.backlog.bName}</Button></td>
//       <td className="text-right">{(props.backlog.bV && props.backlog.bV >= 0) ? `€${props.backlog.bV.toFixed(2)}` : null}</td>
//       <td className="text-right">{(props.backlog.ppp && props.backlog.ppp >= 0) ? `€${props.backlog.ppp.toFixed(2)}` : null}</td>
//       <td><Form.Control style={{ maxWidth: '3.5rem' }} as="select" size="sm" onChange={props.onRatingChange} value={props.backlog.bR} id={props.backlog.bId}>
//             {rOptions}
//           </Form.Control>
//       </td>
//       <td>{props.backlog.nTot}</td>
//       <td>{props.backlog.hTot}</td>
//       <td>{props.backlog.sTot}</td>
//       <td>{props.backlog.bTot}</td>
//       <td>{props.backlog.fTot}</td>
//     </tr>
//   )
// }

const ConfirmHaveModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Change quantities?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to update the quantities of all items?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button variant="danger" onClick={props.onSubmit}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const CreateBacklogModal = (props) => {
  const typeOptions = Object.keys(blTypeMap).map(key => {
    return (<option key={blTypeMap[key]} value={blTypeMap[key]}>{key}</option>)
  })

  var colorOptions = [];
  if (props.colors) {
    colorOptions = props.colors.sort(firstBy('name', { ignoreCase: true })).map(c => {
      return (<option key={c.colorid} value={c.colorid}>{c.name}</option>)
    })
  }

  var itemOptions = [];
  if (props.items) {
    itemOptions = props.items.sort(firstBy('cNo', { ignoreCase: true })).map(i => {
      return (<option key={i.cNo} value={i.cNo}>{`${i.cNo} - ${i.cName}`}</option>)
    })
  }

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create Backlog Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form.Group as={Row}>
        <Form.Label column>Search</Form.Label>
        <Col><Form.Control onChange={props.onChange} value={props.cbSearch || ''} id="cbSearch" placeholder="Item Number" type="text" /></Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column>Type</Form.Label>
        <Col><Form.Control as="select" onChange={props.onChange} value={props.cbItemType || ''} id="cbItemType" placeholder="Item Type">{typeOptions}</Form.Control></Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column>Item</Form.Label>
        <Col><Form.Control as="select" onChange={props.onChange} value={props.cbItemNo || ''} id="cbItemNo" placeholder="Item Type">{itemOptions}</Form.Control></Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column>Color</Form.Label>
        <Col><Form.Control as="select" disabled={props.cbItemType !== '2'} onChange={props.onChange} value={props.cbItemCol || ''} id="cbItemCol" placeholder="Item Color" >{colorOptions}</Form.Control></Col>
      </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button variant="primary" disabled={!props.cbSearch} onClick={props.onSearch}>
          Search
        </Button>
        <Button variant="primary" disabled={!props.validated} onClick={props.onSubmit}>
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
