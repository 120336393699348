import RestApi from '../components/restapi'

import { blTypeMap, getKeyByVal } from "../components/util"
var bl = require('../components/bl');
var Promise = require("bluebird");

export function getAssemblyParts(creds, itemNo, itemType, itemColor) {
  var parts = [];

  return RestApi.getAssembly(itemNo, itemType, itemColor || 0)
  .then(res => {
    // console.log(res);
    parts = res.data;

    const blClient = new bl.Client(creds);
    const subSet = bl.Subset.get(getKeyByVal(blTypeMap, parseInt(itemType)), itemNo, { color_id: itemColor || null })

    return blClient.send(subSet)
    .then(res => {
      const entries = res.data.map(i => i.entries.map(ii => {
        ii.mNo = i.match_no;
        return ii;
      })).flat();

      // console.log(parts);
      // entries to update
      var changedItems = entries.filter(entry => {
        return parts.find(p => (
          entry.item.no === p.sNo &&
          blTypeMap[entry.item.type] === p.sType &&
          entry.color_id === p.sCid &&
          entry.quantity === p.sQ &&
          entry.extra_quantity === p.sE &&
          entry.is_alternate === (p.sA > 0) &&
          entry.is_counterpart === (p.sC > 0) &&
          entry.mNo === p.mNo
        )) ? false : true;
      })

      // entries to delete
      var deletedItems = parts.filter(p => {
        return entries.find(entry => (
          entry.item.no === p.sNo &&
          blTypeMap[entry.item.type] === p.sType &&
          entry.color_id === p.sCid &&
          entry.mNo === p.mNo
        )) ? false : true;
      });
      // console.log(changedItems);
      // console.log(deletedItems);

      return Promise.map(changedItems, function(item) {
        return RestApi.setAssembly(
          itemNo,
          itemType,
          itemColor || 0,
          item.item.no,
          blTypeMap[item.item.type],
          item.color_id,
          item.mNo,
          item.quantity,
          item.extra_quantity,
          item.is_alternate,
          item.is_counterpart,
        )
      }, { concurrency: 5 } )
      .then(res => {
        return Promise.map(deletedItems, function(item) {
          return RestApi.deleteAssembly(
            itemNo,
            itemType,
            itemColor,
            item.sNo,
            item.sType,
            item.sCid,
            item.mNo
          )
        }, { concurrency: 5 } )
      })
      .then(res => {
        return entries.map(entry => {
          return {
            sNo: entry.item.no,
            sType: blTypeMap[entry.item.type],
            sCid: entry.color_id,
            sQ: entry.quantity,
            sE: entry.extra_quantity,
            sA: entry.is_alternate,
            sC: entry.is_counterpart,
            mNo: entry.mNo,
          }
        })
      })
      .catch(err => {
        console.log(itemNo);
        console.log(itemType);
        return new Promise((res, rej) => {
          console.log('waiting to resolve');
          setTimeout(() => res(null), 2000);
        })
        .then(res => {
          return RestApi.deleteAssembly(
            itemNo,
            itemType,
          )
        })
        .then(res => {
          return [];
        })
      })
    })
    .catch(err => {
      console.log(err);
    })
  })
}
