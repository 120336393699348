// WARNING: DO NOT EDIT. This file is automatically generated by CDK. It will be overwritten.

const awsmobile = {
  "aws_user_pools_id": "eu-north-1_WEpwSS85D",
  "aws_user_pools_web_client_id": "19i26ojdt3r19s8e8797f8shka",
  // "aws_appsync_region": "eu-west-1",
  // "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  // "aws_appsync_graphqlEndpoint": "https://cyb62f7sabaw7jcpy6hltshtuy.appsync-api.eu-west-1.amazonaws.com/graphql",
  // "aws_user_files_s3_bucket_region": "eu-west-1",
  // "aws_user_files_s3_bucket": "picatag-sbx-picatagsbxsiteuploadsbucketb21c8b64-1nidol3u0vaxy",
  "aws_project_region": "eu-north-1",
  "aws_cloud_logic_custom": [
    {
      "name": "RestApi",
      "region": "eu-north-1",
      "endpoint": "https://447cs7dhp3.execute-api.eu-north-1.amazonaws.com/prod/"
    }
  ],
  // "stripe_currency": "EUR",
  // "stripe_key": "pk_test_ZiswWQ3Ig6b6Gy74nt5zPcJs00HrNk8smZ",
  "aws_cognito_region": "eu-north-1",
  "aws_cognito_identity_pool_id": "eu-north-1:81f06198-6f79-4f06-9869-fd9aa5c11100"
}

export default awsmobile;
