import {Request,RequestParams} from '../request';
// import {CatalogItem} from '../catalogItem/catalogItem';

/**
 * Represents a Category
 */
export class Inventory {
  /**
   * Create a new instance of a category
   * @param {object} [data] API response data.
   * @param {number} [data.inventory_id=0] The ID of the inventory
   * @param {CatalogItem} [data.category_name] An object representation of the item
   * @param {number} [data.color_id] The ID of the color of the item
   * @param {string} [data.color_name] Color name of the item
   * @param {number} [data.quantity] The number of items included in this inventory
   * @param {string} [data.new_or_used] Indicates whether the item is new or used 	N: New, U: Used
   * @param {string} [data.completeness] Indicates whether the set is complete or incomplete. (This value is valid only for SET type) 	C: Complete, B: Incomplete, S: Sealed
   * @param {number} [data.unit_price] Fixed Point Number 	The original price of this item per sale unit
   * @param {number} [data.bind_id] The ID of the parent lot that this lot is bound to
   * @param {string} [data.description] A short description for this inventory
   * @param {string} [data.remarks] User remarks on this inventory
   * @param {number} [data.bulk] Buyers can buy this item only in multiples of the bulk amount
   * @param {boolean} [data.is_retain] Indicates whether the item retains in inventory after it is sold out
   * @param {boolean} [data.is_stock_room] Indicates whether the item appears only in owner’s inventory
   * @param {string} [data.stock_room_id] Indicates the stockroom that the item to be placed when the user uses multiple stockroom 	A, B, C
   * @param {number} [data.date_created] The time this lot is created
   * @param {number} [data.my_cost] My Cost value to tracking the cost of item
   * @param {number} [data.sale_rate] Sale value to adjust item price 	Must be less than 100. 20 for 20% sale
   * @param {number} [data.tier_quantity1] A parameter for Tiered pricing 	0 for no tier sale option
   * @param {number} [data.tier_quantity2] A parameter for Tiered pricing 	0 for no tier sale option, Must be greater than tier_quantity1
   * @param {number} [data.tier_quantity3] A parameter for Tiered pricing 	0 for no tier sale option, Must be greater than tier_quantity2
   * @param {number} [data.tier_price1] A parameter for Tiered pricing 	0 for no tier sale option. Must be less than unit_price
   * @param {number} [data.tier_price2] A parameter for Tiered pricing 	0 for no tier sale option, Must be less than tier_price1
   * @param {number} [data.tier_price3] A parameter for Tiered pricing 	0 for no tier sale option, Must be less than tier_price2
   * @param {number} [data.my_weight] Custom weight of the item 	Upcoming
   */
  constructor(data) {
    data = data || {};
    /** @type {number} */
    this.inventory_id = data.inventory_id || 0;
    /** @type {CatalogItem} */
    this.item = data.item || null;//? new CatalogItem(data.item) : new CatalogItem();
    /** @type {number} */
    this.color_id = data.color_id || 0;
    /** @type {string} */
    this.color_name = data.color_name || '';
    /** @type {number} */
    this.quantity = data.quantity || 0;
    /** @type {string} */
    this.new_or_used = data.new_or_used || '';
    /** @type {string} */
    this.completeness = data.completeness || '';
    /** @type {number} */
    this.unit_price = data.unit_price || 0;
    /** @type {number} */
    this.bind_id = data.bind_id || 0;
    /** @type {string} */
    this.description = data.description || '';
    /** @type {string} */
    this.remarks = data.remarks || '';
    /** @type {number} */
    this.bulk = data.bulk || 1;
    /** @type {boolean} */
    this.is_retain = data.is_retain || true;
    /** @type {boolean} */
    this.is_stock_room = data.is_stock_room || false;
    /** @type {string} */
    this.stock_room_id = data.stock_room_id || '';
    /** @type {number} */
    this.date_created = data.date_created || 0;
    /** @type {number} */
    this.my_cost = data.my_cost || 0;
    /** @type {number} */
    this.sale_rate = data.sale_rate || 0;
    /** @type {number} */
    this.tier_quantity1 = data.tier_quantity1 || 0;
    /** @type {number} */
    this.tier_quantity2 = data.tier_quantity2 || 0;
    /** @type {number} */
    this.tier_quantity3 = data.tier_quantity3 || 0;
    /** @type {number} */
    this.tier_price1 = data.tier_price1 || 0;
    /** @type {number} */
    this.tier_price2 = data.tier_price2 || 0;
    /** @type {number} */
    this.tier_price3 = data.tier_price3 || 0;
    /** @type {number} */
    this.my_weight = data.my_weight || 0;
  }

  /**
   * Method to get a specific category
   *
   * Usage:
   *
   * ```
   * var req = Category.get(123);
   * client.send(req).then(category => console.log(category));
   * ```
   *
   * @param {number} inventoryId Catalog item type
   * @return {Request} A request that resolves to a {@link Category} instance.
   */
  static get(inventoryId) {
    let method = Request.GET;
    let uri = `/inventories/${inventoryId}`;

    return new Request(method, uri, null, data => new Inventory(data));
  }

  /**
   * Get a list of all colors
   * Usage:
   *
   * ```
   * var req = Category.all();
   * client.send(req).then(categories => console.log(categories));
   * ```
   *
   * @return {Request} A request that resolves to an array of {@link Category}.
   */
  static all(options) {
    options = options ? new InventoryOptions(options) : new InventoryOptions();
    let method = Request.GET;
    let uri = `/inventories`;

    return new Request(method, uri, options, data => data.map(d => new Inventory(d)));
  }

  static create(options) {
    options = options ? new Inventory(options) : new Inventory();
    let method = Request.POST;
    let uri = `/inventories`;

    return new Request(method, uri, options, data => data.map(d => new Inventory(d)));
  }

  static update(inventoryId, options) {
    options = options ? new InventoryUpdateOptions(options) : new InventoryUpdateOptions();
    let method = Request.PUT;
    let uri = `/inventories/${inventoryId}`;

    return new Request(method, uri, options, data => data.map(d => new Inventory(d)));
  }

  static delete(inventoryId) {
    let method = Request.DELETE;
    let uri = `/inventories/${inventoryId}`;

    return new Request(method, uri, null, data => new Inventory(data));
  }
}

export class InventoryUpdateOptions extends RequestParams {
  /**
   * Create an instance of Price Guide options.
   * @param {object} data Optional parameter data.
   * @param {string|null} [data.item_type=null] The color Identification numbe of the item
   * @param {string|null} [data.status='stock'] Indicates that which statistics to be provided. Options include "sold" and "stock"
   * @param {number|null} [data.category_id='N'] Indicates the condition of items that are included in the statistics. Acceptable values are: "N": new item (default) and "U": used item
   * @param {number|null} [data.color_id=null]
   */
  constructor(data){
    super(data);

    data = data || {};
    /** @type {number} */
    this.quantity = `${data.quantity < 0 ? '' : '+'}${data.quantity || 0}`;
    /** @type {number} */
    this.unit_price = data.unit_price || 0;
    /** @type {string} */
    this.description = data.description || '';
    /** @type {string} */
    this.remarks = data.remarks || '';
    /** @type {number} */
    this.bulk = data.bulk || 1;
    /** @type {boolean} */
    this.is_retain = data.is_retain || true;
    /** @type {boolean} */
    this.is_stock_room = data.is_stock_room || false;
    /** @type {string} */
    this.stock_room_id = data.stock_room_id || '';
    /** @type {number} */
    this.my_cost = data.my_cost || 0;
    /** @type {number} */
    this.sale_rate = data.sale_rate || 0;
    /** @type {number} */
    this.tier_quantity1 = data.tier_quantity1 || 0;
    /** @type {number} */
    this.tier_quantity2 = data.tier_quantity2 || 0;
    /** @type {number} */
    this.tier_quantity3 = data.tier_quantity3 || 0;
    /** @type {number} */
    this.tier_price1 = data.tier_price1 || 0;
    /** @type {number} */
    this.tier_price2 = data.tier_price2 || 0;
    /** @type {number} */
    this.tier_price3 = data.tier_price3 || 0;
    /** @type {number} */
  }
}

/**
 * Options that can be used when make a request for a subset.
 */
export class InventoryOptions extends RequestParams {
  /**
   * Create an instance of Price Guide options.
   * @param {object} data Optional parameter data.
   * @param {string|null} [data.item_type=null] The color Identification numbe of the item
   * @param {string|null} [data.status='stock'] Indicates that which statistics to be provided. Options include "sold" and "stock"
   * @param {number|null} [data.category_id='N'] Indicates the condition of items that are included in the statistics. Acceptable values are: "N": new item (default) and "U": used item
   * @param {number|null} [data.color_id=null]
   */
  constructor(data){
    super(data);

    data = data || {};
    /** @type {string|null} [data.color_id=null] The color Identification numbe of the item */
    this.item_type = data.item_type || null;
    /** @type {string|null} [guide_type='stock'] Indicates that which statistics to be provided. Options include "sold" and "stock" */
    this.status = data.status || null;
    /** @type {number|null} [new_or_used='N'] Indicates the condition of items that are included in the statistics. Acceptable values are: "N": new item (default) and "U": used item */
    this.category_id = data.category_id || null;
    /** @type {number|null} [country_code=null] */
    this.color_id = data.color_id || null;
  }
}
