import React, { Component } from "react";
import { withAuthenticator } from '@aws-amplify/ui-react';

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'

import RestApi from '../components/restapi'
import StockItem from './StockItem'
import StockItemList from './StockItemList'
import { cleanUpLoaders, getColors, getReports } from "../components/loaders"
import { blTypeMap, getKeyByVal } from "../components/util"

import { firstBy } from "thenby";

class Restock extends Component {
  constructor(props) {
    super(props);

    cleanUpLoaders();

    this.state = {
      lItems: {},
    }
  }


  componentDidMount() {
    getColors().then(res => { this.colors = res; });
    getReports(this.props.activeStore.storeId).then(res => {
      var reports = this.compileShoppingList(res);
      this.setState({ reports: reports })
    });
  }

  compileShoppingList(reports) {
    var shoppingList = {
      data: [],
      name: 'Shopping List',
      repId: 99
    }

    for (const r of [1, 6, 9]) {
      var report = reports.find(rep => rep.repId === r);
      if (report) {
        for (const line of report.data) {
          var sline = shoppingList.data.find(sl => sl.part === line.part && sl.color === line.color)

          // can't edit the existing line or the other reports get mangled up after updates
          if (!sline) {
            shoppingList.data.push(line)
          } else {
            if (sline.need < line.need) {
              shoppingList.data = shoppingList.data.filter(sl => !(sl.part === line.part && sl.color === line.color))
              shoppingList.data.push(line)
            }
          }
        }
      }
    }

    reports.push(shoppingList);
    return reports;
  }

  filterItems() {
    if (!this.state.selectedReport) return null;
    var report = this.state.reports.find(rep => rep.repId === parseInt(this.state.selectedReport))
    if (!report) return null
    // console.log(report.data);
    var res = report.data.map(i => {
      return new StockItem({
        sId: i.part + '-' + i.color + '-' + i.cond,
        sNo: i.part,
        sName: i.name,
        sCid: i.color,
        sQfs: i.fs,
        sQ: i.minqty,
        iQ: i.fs,
        rQ: i.qtySold,
        bQ: i.need,
        sCname: i.cname,
        sCcode: i.ccode,
        sNU: i.cond,
        sP: i.price,
        sTid: i.itemtype,
      })
    });

    return res;
  }

  refreshListItems(listId, clearFirst) {
    if (!listId) return;

    return RestApi.getStockListItem(this.props.activeStore.storeId, listId)
    .then(res => {
      const newItems = res.data.map(item => {
        const color = this.colors.find(l => l.colorid === item.sCid)
        if (color) {
          item.sCname = color.name;
          item.sCcode = color.code;
        }
        const cat = this.categories.find(l => l.cId === item.sCat)
        if (cat) {
          item.sCatN = cat.cName;
        }
        return new StockItem(item);
      });

      this.setState(state => {
        var lItems = state.lItems;
        if (clearFirst) lItems = {};
        lItems[listId] = newItems;
        return { lItems: lItems }
      });

      return newItems;
    })
  }

  handleChange = event => {
    if (event.target.validity && !event.target.validity.valid) return;

    const tid = event.target.id || event.target.name
    var val = event.target.value;
    if (event.target.type === 'checkbox') val = event.target.checked;

    this.setState({
      [tid]: val
    });
  }

  handleSelectReport = async (e) => {
    const selectedReport = e;
    this.setState({ selectedReport: selectedReport });
  }

  handleGetXmlShow = async (e) => {
    this.setState({ showGetXml: true });
  }

  handleGetXmlHide = async (e) => {
    this.setState({ showGetXml: false });
  }

  renderMainColumn() {
    const sortKeys = {
      bQ: 'Quantity Needed',
      sQ: 'Quantity',
      sName: 'Name',
      sNo: 'Item Num',
    }
    const stockActions = [];
    stockActions.push({ label: 'Get XML', cb: this.handleGetXmlShow, enabled: true })

    if (this.state.selectedReport) {
      const currRep = this.state.reports.find(rep => rep.repId === parseInt(this.state.selectedReport))

      return (
        <>
        <Container className="mb-2">
        <Row><Col>{currRep.name} <div className="float-right ml-2"></div></Col></Row>
        </Container>
        <StockItemList id={'stock'} data={this.filterItems()} sortKeys={sortKeys} sortDir={false}
        actions={stockActions} format="shoppingList" />
        </>
      )
    } else { return (<Container className="mb-2"><Row><Col>Select a report</Col></Row></Container>) }
  }

  render() {
    if (!this.state.lItems[this.state.selectedList]) this.refreshListItems(this.state.selectedList);
    var wOptions = [];
    if (this.state.warehouses) {
      wOptions = this.state.warehouses.sort(firstBy('wName', { ignoreCase: true }))
      .map(i => { return (<option key={i.wId} value={i.wId}>{i.wName}</option>) })
    }
    wOptions.unshift(<option key={0} value={null}></option>)

    var rRows = [];
    if (this.state.reports) {
      rRows = this.state.reports.sort(firstBy('repId')).map(r => {
        return (
          <Row key={r.repId}>
            <Col>
              <Nav.Item style={{ maxWidth: '260px' }}>
              <Nav.Link className="text-truncate px-1 py-0" eventKey={r.repId}>{r.name} ({r.data.length})</Nav.Link>
              </Nav.Item>
            </Col>
          </Row>
        )
      })
    }

    return (
      <Container className='Home'>
        <Row>
          <Col md="auto">
            <Card className="mb-2">
              <Card.Header className="p-2">Stock Reports</Card.Header>
              <Card.Body className="p-1">
              <Nav variant="pills" className="flex-column mx-0" activeKey={this.state.selectedReport} onSelect={this.handleSelectReport}>
                {rRows}
              </Nav>
              </Card.Body>
            </Card>
          </Col>
          <Col>
          {this.renderMainColumn()}
          </Col>
        </Row>
        <GetXmlModal {...this.state} show={this.state.showGetXml}
          onHide={this.handleGetXmlHide} data={this.filterItems()} />
      </Container>
    );
  }
}

export default withAuthenticator(Restock)

function OBJtoXML(obj) {
  var xml = '';
  for (var prop in obj) {
    xml += obj[prop] instanceof Array ? '' : "<" + prop + ">";
    if (obj[prop] instanceof Array) {
      for (var array in obj[prop]) {
        xml += "<" + prop + ">";
        xml += OBJtoXML(obj[prop][array]);
        xml += "</" + prop + ">";
      }
    } else if (typeof obj[prop] == "object") {
      xml += OBJtoXML(obj[prop]);
    } else {
      xml += obj[prop];
    }
    xml += obj[prop] instanceof Array ? '' : "</" + prop + ">";
  }
  xml = xml.replace(/<\/?[0-9]{1,}>/g, '');
  return xml
}

const GetXmlModal = (props) => {
  const items = props.data ? props.data.map(i => {
    return {
      ITEM: {
        ITEMTYPE: getKeyByVal(blTypeMap, i.sTid).substring(0, 1),
        ITEMID: i.sNo,
        COLOR: i.sCid,
        MAXPRICE: i.sP / 4,
        MINQTY: i.bQ
      }
    }
  }) : [];

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>BrickLink Wishlist XML</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form.Group as={Row}>
        <Form.Control defaultValue={"<INVENTORY>" + OBJtoXML(items) + "</INVENTORY>"} as="textarea" rows="20" />
      </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
