import React, { Component } from "react";
import { withAuthenticator } from '@aws-amplify/ui-react';

import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'

import RestApi from '../components/restapi'
import { LegoApi } from '../components/rbApi'
var bl = require('../components/bl');

var CryptoJS = require("crypto-js");
var Promise = require("bluebird");

class Colors extends Component {
  constructor(props) {
    super(props);

    this.sscreds = sessionStorage.getItem('btg.apiCreds');
    if (this.sscreds) {
      // Decrypt
      var bytes  = CryptoJS.AES.decrypt(this.sscreds, props.user.attributes.sub);
      this.creds = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }

    this.state = {
      status: '',
      log: [],
    }

  }

  componentDidMount() {
    this.handlePullColors()
  }

  getAllRbColors(page, items) {
    items = items || [];
    page = page || 1;

    var rbLego = new LegoApi(this.creds.rb);
    return rbLego.legoColorsList(page)
    .then(res => {
      const pageItems = res.body.results /*.map(item => {
        return {
          rlId: rlId,
          rQ: item.quantity,
          rNo: item.set.set_num,
          rCid: 9999,
          rType: 3, // SET
          sNos: null
        }
      })*/
      const resItems = items.concat(pageItems)

      this.addToLog('Got ' + resItems.length + ' items from Rebrickable...', 'light' );
      if (res.body.next) {
        return this.getAllRbColors(++page, resItems)
      } else {
        return resItems;
      }
    })
    .catch(err => {
      console.log(err);
      return items;
    })
  }

  handlePullColors = async () => {
    if (!this.creds) {
      this.addToLog('API Credentials Not Available', 'warning');
      this.setState({ status: 'API Credentials Not Available', statusVariant: 'warning' })
      return;
    }

    // if (!RestApi.hasPrivilege(this.props.activeStore.privileges, 'StoreUpdateRb')) {
    //   this.addToLog('You are not authorised to perform Rebrickable updates', 'warning');
    //   this.setState({ status: 'You are not authorised to perform Rebrickable updates', statusVariant: 'warning' })
    //   return;
    // }
    //


    this.addToLog('Fetching color list from Rebrickable', 'light');
    var rbProm = this.getAllRbColors()

    this.addToLog('Fetching color list from Bricklink', 'light');
    var blClient = new bl.Client(this.sscreds);
    var blReq = bl.Color.all()
    var blProm = blClient.send(blReq)

    this.addToLog('Fetching color list from BTG', 'light');
    var btgProm = RestApi.getColor();

    rbProm
    .then(rbRes => {
      console.log(rbRes);

      return blProm
      .then(blRes => {
        this.addToLog('Got ' + blRes.data.length + ' items from Bricklink...', 'light' );

        const mappedRes = blRes.data.map(bl => {
          var rbid = rbRes.find(rb => rb.name === bl.color_name)
          if (!rbid) rbid = rbRes.find(rb => (rb.external_ids
                                          && rb.external_ids.BrickLink
                                          && rb.external_ids.BrickLink.ext_ids.includes(bl.color_id)))
          var legoid = null;
          if (rbid && rbid.external_ids && rbid.external_ids.LEGO) legoid = Math.min(...rbid.external_ids.LEGO.ext_ids);

          return {
            colorid: bl.color_id,
            name: bl.color_name,
            code: bl.color_code,
            type: bl.color_type,
            coloridrb: rbid ? rbid.id : null,
            coloridlego: legoid
          }
        });

        mappedRes.push({
          colorid: 0,
          name: null,
          code: null,
          type: null,
          coloridrb: 9999,
          coloridlego: null
        })

        const usedRbIds = mappedRes.map(i => i.coloridrb);
        const nfRb = rbRes.filter(r => !usedRbIds.includes(r.id));

        this.addToLog(nfRb.length + ' Rebrickable colors could not be matched to Bricklink colors', 'info' );

        return mappedRes;
      })
    })
    .then(mappedRes => {
      const nfBl = mappedRes.filter(r => !r.coloridrb);
      this.addToLog(nfBl.length + ' Bricklink colors could not be matched to Rebrickable colors', 'info' );

      return btgProm
      .then(res => {
        const btgRes = res.data;

        this.addToLog('Got ' + btgRes.length + ' items from BTG...', 'light' );

        // lists that have changed
        var changedColors = mappedRes.filter(c => {
          return btgRes.find(b => (
            b.colorid === c.colorid &&
            b.name === c.name &&
            b.code === c.code &&
            b.type === c.type &&
            b.coloridrb === c.coloridrb &&
            b.coloridlego === c.coloridlego
          )) ? false : true;
        });

        this.addToLog(changedColors.length + ' colors need updating', 'light');

        // lists that were deleted on RB
        var deletedColors = btgRes.filter(b => {
          return mappedRes.find(c => (b.colorid === c.colorid)) ? false : true;
        });
        this.addToLog(deletedColors.length + ' colors need deleting', 'light');
        this.addToLog('Updating ' + changedColors.length + ' colors.', 'info');
        const self = this;
        return Promise.map(changedColors, function(c) {
          return RestApi.setColor(
            c.colorid,
            c.name,
            c.code,
            c.type,
            c.coloridrb,
            c.coloridlego
          )
          .catch(err => {
            self.addToLog(err.toString(), 'danger');
            self.setState({ status: err.toString(), statusVariant: 'danger' })
            console.log(err);
          })
        }, { concurrency: 5 } )
        .then(res => {
          if (deletedColors.length > 0)
            return Promise.map(deletedColors, function(c) {
              self.addToLog('Deleting ' + c.name, 'info');
              return RestApi.deleteColor(
                c.colorid
              )
              .catch(err => {
                self.addToLog('Failed to delete ' + c.name + ". Error: " + err.toString(), 'danger');
              })
            }, { concurrency: 5 } )
        })
      })
    })
    .then(res => {
      this.addToLog('All done!', 'light');
      return res;
    })
    .catch(err => {
      this.addToLog(err.toString(), 'danger');
      this.setState({ status: err.toString(), statusVariant: 'danger' })
      console.log(err);
    })
  }

  addToLog(msg, variant) {
    this.setState(state => {
        state.log.unshift({ id: state.log.length, msg: msg, var: variant, ts: Date.now() });

      return { log: state.log }
    })
  }

  renderLog() {
    var logRows = [];

    this.state.log.forEach(entry => {
      var date = new Date(entry.ts);
      logRows.push(
      <tr key={entry.id}>
        <td><Alert className="m-0 p-0" variant={entry.var}>
          {date.toLocaleString()}: {entry.msg}
        </Alert></td>
      </tr>
      )
    });

    return (
      <>
      <Table bordered size="sm">
        <thead>
          <tr>
            <th>Log</th>
          </tr>
        </thead>
        <tbody>
          {logRows}
        </tbody>
      </Table>
      </>
    )
  }

  render() {
    return (
      <Container className='Home'>
        <Row>
          <Col md="auto" className="text-right">
            <Alert className="mb-0" variant={this.state.statusVariant}>
              {this.state.status}
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col>
            {this.renderLog()}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withAuthenticator(Colors)
