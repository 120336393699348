import {Request, RequestParams} from '../request';

/**
 * Represents a Catalog Item.
 */
export class Feedback {
  /**
   * Create a new instance of a Catalog Item
   * @param {object} [data] Data typically returned from an API response.
   */
  constructor(data){
    data = data || {};

    /** @type {number} */
    this.feedback_id = data.feedback_id || 0;
    /** @type {number} */
    this.order_id = data.order_id || 0;
    /** @type {string} */
    this.from = data.from || '';
    /** @type {string} */
    this.to = data.to || '';
    /** @type {timestamp?} */
    this.date_rated = data.date_rated || '';
    /** @type {number} */
    this.rating = data.rating || 0;
    /** @type {string} */
    this.rating_of_bs = data.rating_of_bs || '';
    /** @type {string} */
    this.comment = data.comment || '';
    /** @type {string} */
    this.reply = data.reply || '';
  }

  /**
   * Method to get a known catalog item
   *
   * Usage:
   *
   * ```
   * var req = CatalogItem.get(ItemType.Part, '3001');
   * client.send(req).then(item => console.log(item));
   * ```
   *
   * @param {number} feedbackId The ID of the feedback to get
   * @return {Request} A request that is ready to execute with a client.
   */
  static get(feedbackId) {
    let method = Request.GET;
    let uri = `/feedback/${feedbackId}`;

    return new Request(method, uri, null, (data) => {
      let item = new Feedback(data)
      return item;
    });
  }

  /**
   * This method gets a list of feedback you received or posted.
   *
   * Usage:
   *
   * ```
   * var req = Feedback.all('in');
   * client.send(req).then(feedback => console.log(feedback));
   * ```
   *
   * @param {string} direction The ID of the feedback to get
   * @return {Request} A request that resolves to an array of {@link Color}.
   */
  static all(options) {
    options = options ? new FeedbackOptions(options) : new FeedbackOptions();
    let method = Request.GET;
    let uri = `/feedback`;

    return new Request(method, uri, options, data => data.map(d => new Feedback(d)));
  }
}

/**
 * Options that can be used when make a request for a subset.
 */
export class FeedbackOptions extends RequestParams {
  /**
   * Make an instance of SubsetOptions.
   * @param {direction} "out": Gets posted feedback. "in": Gets received feedback. (default) 
   */
  constructor(direction){
    super(direction);
    this.direction = direction || null;
  }
}
