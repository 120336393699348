import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { withAuthenticator } from '@aws-amplify/ui-react';

import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
// import Nav from 'react-bootstrap/Nav'
// import Row from 'react-bootstrap/Row'

import { UsersApi } from '../components/rbApi'
var bl = require('../components/bl');

var CryptoJS = require("crypto-js");

class ApiCredentials extends Component {
  constructor(props) {
    super(props);

    var sscreds = sessionStorage.getItem('btg.apiCreds');
    if (sscreds) {
      try {
        // Decrypt
        var bytes  = CryptoJS.AES.decrypt(sscreds, props.user.attributes.sub);
        var creds = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      catch(err) {
        console.log(err);
      }
    }
    else {
      sscreds = ''
    }

    this.state = {
      isLoading: false,
      ckey: creds ? creds.bl.consumerKey : '',
      csecret: creds ? creds.bl.consumerSecret : '',
      tkey: creds ? creds.bl.tokenKey : '',
      tsecret: creds ? creds.bl.tokenSecret : '',
      akey: creds ? creds.rb.apiKey : '',
      utoken: creds ? creds.rb.userToken : '',
      error: false,
      success: false,
      rerror: false,
      rsuccess: false,
      ciphertext: sscreds,
    };
  }

  validateForm() {
    return  this.state.ckey.length > 0 &&
            this.state.csecret.length > 0 &&
            this.state.tkey.length > 0 &&
            this.state.tsecret.length > 0 &&
            this.state.akey.length > 0 &&
            this.state.utoken.length > 0 ;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true, error: false, rerror: false, success: false, rsuccess: false });

    const creds = {
      bl: {
        consumerKey: this.state.ckey,
        consumerSecret: this.state.csecret,
        tokenKey: this.state.tkey,
        tokenSecret: this.state.tsecret,
      },
      rb: {
        apiKey: this.state.akey,
        userToken: this.state.utoken,
      }
    }

    console.log(creds);

    // Encrypt
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(creds), this.props.user.attributes.sub);
    sessionStorage.setItem('btg.apiCreds', ciphertext.toString());


    this.testCredentials()
    .then(res => {
      this.setState({ isLoading: false });
    })
    .catch(err => {
      sessionStorage.removeItem('btg.apiCreds');
      this.setState({ isLoading: false, ciphertext: '' });
    })
  }

  handleCtextSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true, error: false, rerror: false, success: false, rsuccess: false });

    sessionStorage.setItem('btg.apiCreds', this.state.ciphertext);

    this.testCredentials()
    .then(res => {
      this.setState({ isLoading: false });
    })
    .catch(err => {
      sessionStorage.removeItem('btg.apiCreds');
      this.setState({ isLoading: false, ciphertext: '' });
    })


  }

  testCredentials() {
    var promises = [];

    try {
      // Decrypt
      var bytes  = CryptoJS.AES.decrypt(sessionStorage.getItem('btg.apiCreds'), this.props.user.attributes.sub);
      var creds = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    catch (err) {
      console.log(err);
      this.setState({ error: true, success: false, errorMessage: 'Invalid encrypted credentials' });
      return Promise.reject();
    }

    this.setState({
      ckey: creds.bl.consumerKey,
      csecret: creds.bl.consumerSecret,
      tkey: creds.bl.tokenKey,
      tsecret: creds.bl.tokenSecret,
      akey: creds.rb.apiKey,
      utoken: creds.rb.userToken,
      ciphertext: sessionStorage.getItem('btg.apiCreds'),
    })

    // var lego = new rb.LegoApi();
    var rbUser = new UsersApi(creds.rb);
    // rbUser.setDefaultAuthentication(new rb.ApiKeyAuth('query', 'key'))
    // rbUser.authentications.default.apiKey = creds.rb.apiKey

    var blClient = new bl.Client(sessionStorage.getItem('btg.apiCreds'));
    var colors = bl.Feedback.all()

    promises.push(blClient.send(colors)
    .then(res => {
      // console.log(res);
      const as = res.data ? ' as ' + res.data[0].to : ''
      this.setState({ error: false, success: true, successMessage: 'We can connect to Bricklink' + as});
      return res;
    })
    .catch(err => {
      console.log(err);
      this.setState({ error: true, success: false, errorMessage: err.description });
      throw err;
    }));

    promises.push(rbUser.usersProfileRead()
    .then(res => {
      // console.log(res);
      // console.log(res.body.username);
      this.setState({ rerror: false, rsuccess: true, rsuccessMessage: 'We can connect to Rebrickable as ' + res.body.username });
      return res;
    })
    .catch(err => {
      console.log(err);
      this.setState({ rerror: true, rsuccess: false, rerrorMessage: err.body.detail });
      throw err;
    }));

    return Promise.all(promises);
  }

  render() {
    return (
      <Container>
      <h4>API Credentials</h4>
      <p>To integrate with Rebrickable and Bricklink, you need to provide your API
      credentials. As defined in their terms and conditions, we are not allowed
      to store those credentials so you need to enter them every time you sign in
      to Brick The Gap.</p>
      <p>You can use your previously generated encrypted credentials:</p>
      <Form onSubmit={this.handleCtextSubmit}>
          <Form.Group controlId="ciphertext">
            <Form.Label>Encrypted Credentials</Form.Label>
            <Form.Control placeholder='Encrypted Credentials'
              onChange={this.handleChange} value={this.state.ciphertext} />
          </Form.Group>
          <Button type='submit' disabled={!this.state.ciphertext}>Add Credentials</Button>
      </Form>
      <br />
      <p>OR you can enter your API credentials here. </p>
      <Form onSubmit={this.handleSubmit}>
        <Form.Row>
          <Col><Button variant="link" href='https://www.bricklink.com/v2/api/register_consumer.page' target='blank'>Bricklink</Button></Col>
          <Col><Button variant="link" href='https://rebrickable.com/api/' target='blank'>Rebrickable</Button></Col>
        </Form.Row>
        <Form.Row>
          <Col>
          <Form.Group controlId="ckey">
            <Form.Label>Consumer Key</Form.Label>
            <Form.Control placeholder='Consumer Key'
              onChange={this.handleChange} value={this.state.ckey} />
          </Form.Group>
          <Form.Group controlId="csecret">
            <Form.Label>Consumer Secret</Form.Label>
            <Form.Control type='password' placeholder='Consumer Secret'
              onChange={this.handleChange} value={this.state.csecret} />
          </Form.Group>
          <Form.Group controlId="tkey">
            <Form.Label>Token Value</Form.Label>
            <Form.Control placeholder='Token Value'
              onChange={this.handleChange} value={this.state.tkey} />
          </Form.Group>
          <Form.Group controlId="tsecret">
            <Form.Label>Token Secret</Form.Label>
            <Form.Control type='password' placeholder='Token Secret'
              onChange={this.handleChange} value={this.state.tsecret} />
          </Form.Group>
          <Alert variant="danger" show={this.state.error}>
            <Alert.Heading>Sorry</Alert.Heading>
            <p>{this.state.errorMessage}</p>
          </Alert>
          <Alert variant="success" show={this.state.success}>
            <Alert.Heading>Nice!</Alert.Heading>
            <p>{this.state.successMessage}</p>
          </Alert>
          </Col>
          <Col>
          <Form.Group controlId="akey">
            <Form.Label>API Key</Form.Label>
            <Form.Control placeholder='API Key'
              onChange={this.handleChange} value={this.state.akey} />
          </Form.Group>
          <Form.Group controlId="utoken">
            <Form.Label>User Token</Form.Label>
            <Form.Control type='password' placeholder='User Token'
              onChange={this.handleChange} value={this.state.utoken} />
          </Form.Group>
          <Alert variant="danger" show={this.state.rerror}>
            <Alert.Heading>Sorry</Alert.Heading>
            <p>{this.state.rerrorMessage}</p>
          </Alert>
          <Alert variant="success" show={this.state.rsuccess}>
            <Alert.Heading>Nice!</Alert.Heading>
            <p>{this.state.rsuccessMessage}</p>
          </Alert>
          </Col>
        </Form.Row>
        <Form.Row>
          <Button type='submit' disabled={!this.validateForm()}>Add Credentials</Button>
        </Form.Row>
      </Form>
      </Container>
    );
  }
}

export default withAuthenticator(ApiCredentials)
