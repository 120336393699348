import React, { Component } from "react";
import { withAuthenticator } from '@aws-amplify/ui-react';

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'

import RestApi from '../components/restapi'
import StockItem from './StockItem'
import StockItemList from './StockItemList'
import { blTypeMap } from "../components/util"
import { cleanUpLoaders, getColors, getCategories, getStorage, getStockLists, refreshStockLists } from "../components/loaders"

import { firstBy } from "thenby";

// var bl = require('../components/bl');
var Promise = require("bluebird");

class CustomLists extends Component {
  constructor(props) {
    super(props);

    cleanUpLoaders();

    this.state = {
      lItems: {},
      cliItemType: '1',
      cliItemQty: 1
    }
  }

  componentDidMount() {
    getColors().then(res => { this.colors = res; });
    getCategories().then(res => { this.categories = res; });
    getStorage(this.props.activeStore.storeId)
    .then(res => {
      const warehouses = [];
      const map = new Map();
      for (const item of res) {
          if(!map.has(item.wId)){
              map.set(item.wId, true);    // set any value to Map
              warehouses.push({
                wId: item.wId,
                wName: item.wName
              });
          }
      }

      this.setState({ warehouses: warehouses })
    })
    .then(res => {
      return getStockLists(this.props.activeStore.storeId);
    })
    .then(res => {
      this.setState({ stockLists: res })
    })
  }

  filterItems() {
    var res = this.state.lItems[this.state.selectedList] || [];

    if (this.state.whenNotPartedOut)
      res = res.filter(i => !i.sFS);
    if (this.state.whenDontHave)
      res = res.filter(i => i.sQ > i.rQ);
    if (this.state.whenDontHaveInStock)
      res = res.filter(i => i.sQ > i.rQ + i.iQ);
    // if (this.state.whenApLimited)
    //   res = res.filter(i => i.sAPv && (i.sAPv === i.sMmin || i.sAPv === i.sMmax) );
    // if (this.state.whenPGEmpty)
    //   res = res.filter(i => i.sLUPG && !i.sPGRtq );
    // res = res.filter(i => this.state.showByList.includes(i.rlId));
    // res = res.filter(i => this.state.showByType.includes(i.sType));
    // res = res.filter(i => this.state.showByCond.includes(i.sNU) ||
    //                         (this.state.showByCond.includes('ns') && !i.sNU));
    // res = res.filter(i => this.state.showByComp.includes(i.sSCI) ||
    //                         (this.state.showByComp.includes('ns') && !i.sSCI));
    // res = res.filter(i => ((this.state.showForSale.includes('nfs') && i.sFS !== 1) ||
    //                         (this.state.showForSale.includes('fs') && i.sFS === 1)));
    return res;
  }

  refreshListItems(listId, clearFirst) {
    if (!listId) return;

    return RestApi.getStockListItem(this.props.activeStore.storeId, listId)
    .then(res => {
      const newItems = res.data.map(item => {
        const color = this.colors.find(l => l.colorid === item.sCid)
        if (color) {
          item.sCname = color.name;
          item.sCcode = color.code;
        }
        const cat = this.categories.find(l => l.cId === item.sCat)
        if (cat) {
          item.sCatN = cat.cName;
        }
        return new StockItem(item);
      });

      this.setState(state => {
        var lItems = state.lItems;
        if (clearFirst) lItems = {};
        lItems[listId] = newItems;
        return { lItems: lItems }
      });

      return newItems;
    })
  }

  clIsValid() {
    return this.state.clName;
  }

  cliIsValid() {
    return true; //this.state.clName;
  }

  eliIsValid() {
    return true; //this.state.clName;
  }

  handleChange = event => {
    if (event.target.validity && !event.target.validity.valid) return;

    const tid = event.target.id || event.target.name
    var val = event.target.value;
    if (event.target.type === 'checkbox') val = event.target.checked;

    this.setState({
      [tid]: val
    });
  }

  handleSelectionChange = (id, ids, checked) => {
    this.setState(state => {
      var affectedItems = state.lItems[state.selectedList].filter(i => ids.includes(i.k))
      affectedItems.forEach(i => { i.chk = checked; });
      return { lItems: state.lItems };
    })
  }

  handleSelectList = async (e) => {
    const selectedList = e;
    this.setState({ selectedList: selectedList });
  }

  handleCreateListShow = async (e) => {
    this.setState({ showCreateList: true });
  }

  handleCreateListHide = async (e) => {
    this.setState({ showCreateList: false });
  }

  handleCreateListSubmit = async (e) => {
    RestApi.setStockList(this.props.activeStore.storeId, null, this.state.clName)
    .then(res => {
      return refreshStockLists(this.props.activeStore.storeId)
    })
    .then(res => {
      this.setState({ showCreateList: false, stockLists: res })
    })
  }

  handleDeleteListShow = async (e) => {
    const lId = parseInt(e.target.id);
    const l = this.state.stockLists.find(l => l.slId === lId);
    const lName = l ? l.slName : null;
    this.setState({ showDeleteList: true, toDeleteListName: lName, toDeleteListId: lId });
  }

  handleDeleteListHide = async (e) => {
    this.setState({ showDeleteList: false });
  }

  handleDeleteListSubmit = async (e) => {
    const id = parseInt(e.target.id)
    const selId = id === this.state.selectedList ? 0 : this.state.selectedList;

    RestApi.deleteStockList(
      this.props.activeStore.storeId,
      id
    )
    .then(res => {
      return refreshStockLists(this.props.activeStore.storeId)
    })
    .then(res => {
      // localStorage.setItem('btg.stockNeedsRefresh', JSON.stringify(true));
      this.setState({ stockLists: res, showDeleteList: false, selectedList: selId })
    })
  }

  handleCreateListItemShow = async (e) => {
    this.setState({ showCreateListItem: true });
  }

  handleCreateListItemHide = async (e) => {
    this.setState({ showCreateListItem: false });
  }

  handleCreateListItemSearch = async (e) => {
    RestApi.getCatalog(this.state.cliSearch, this.state.cliItemType)
    .then(res => {
      var newNo = '';
      if (res.data[0]) newNo = res.data[0].cNo;
      this.setState({ cliItems: res.data, cliItemNo: newNo })
    })
  }

  handleCreateListItemSubmit = async (e) => {
    var item = {
      sNo: this.state.cliItemNo,
      sType: this.state.cliItemType,
      sCid: this.state.cliItemType === '2' || this.state.cliItemType === '5' ? this.state.cliItemCol : 0,
      sQ: this.state.cliItemQty
    };

    RestApi.createStockListItem(
      this.props.activeStore.storeId,
      this.state.selectedList,
      item.sNo,
      item.sType,
      item.sCid,
      item.sQ
    )
    .then(res => {
      item.rId = res.data[0].sliId;
      this.refreshListItems(this.state.selectedList);
      this.setState({ showCreateListItem: false })
    })
  }

  handleDeleteItemsShow = async (e) => {
    this.setState({ showDeleteItems: true });
  }

  handleDeleteItemsHide = async (e) => {
    this.setState({ showDeleteItems: false });
  }

  handleDeleteItemsSubmit = async (e) => {
    var selectedItems = this.state.lItems[this.state.selectedList].filter(i => i.chk === true)

    const storeId = this.props.activeStore.storeId;
    return Promise.map(selectedItems, function(item) {
      return RestApi.deleteStockListItem(
        storeId,
        item.rlId,
        item.rId
      )
    }, { concurrency: 5 } )
    .then(res => {
      this.refreshListItems(this.state.selectedList);
      this.setState({ showDeleteItems: false })
    })
  }

  handleItemChange = (e) => {
    const id = parseInt(e.target.id);
    // const val = e.target.value;
    // const type = e.target.type;
    const selectedItem = this.state.lItems[this.state.selectedList].find(i => i.rId === id)
    // console.log(id);
    // console.log(val);
    // console.log(type);
    // console.log(selectedItem);
    this.setState({ showEditListItem: true, selectedItem: selectedItem, cliItemQty: selectedItem.rQ });
  }

  handleEditListItemHide = async (e) => {
    this.setState({ showEditListItem: false });
  }

  handleEditListItemSubmit = async (e) => {
    // const id = parseInt(e.target.id)
    // console.log(id);
    // console.log(this.state.cliItemQty);
    // console.log(this.state.selectedItem);
    RestApi.setStockListItem(
      this.props.activeStore.storeId,
      this.state.selectedList,
      this.state.selectedItem.rId,
      this.state.cliItemQty
    )
    .then(res => {
      this.setState(state => {
        var lItems = state.lItems;
        var sItem = lItems[state.selectedList].find(i => i.rId === state.selectedItem.rId)
        sItem.rQ = parseInt(state.cliItemQty)
        return { lItems: lItems, showEditListItem: false }
      });
    })
  }

  handleWarehouseChange = async (e) => {
    const id = parseInt(e.target.id);
    const value = parseInt(e.target.value);
    var list = this.state.stockLists.find(list => list.slId === id)

    // console.log(id);
    // console.log(value);
    // console.log(list);

    return RestApi.setStockList(
      this.props.activeStore.storeId,
      list.slId,
      list.slName,
      value
    )
    .then(res => {
      this.setState(state => {
        var list = state.stockLists.find(list => list.slId === id);
        list.wId = value;
        return { stockLists: state.stockLists }
      })
    })
  }

  renderMainColumn() {
    const sortKeys = {
      sName: 'Name',
      sNo: 'Item Num',
      sQ: 'Quantity',
    }
    const stockActions = [];
    stockActions.push({ label: 'Delete', cb: this.handleDeleteItemsShow, enabled: this.state.selectedList })

    if (this.state.selectedList) {
      const currSL = this.state.stockLists.find(sl => sl.slId === parseInt(this.state.selectedList))

      return (
        <>
        <Container className="mb-2">
        <Row><Col>{currSL.slName} <div className="float-right ml-2"><Button onClick={this.handleCreateListItemShow} size="sm" >Add Item</Button></div></Col></Row>
        </Container>
        <StockItemList id={'stock'} data={this.filterItems()} sortKeys={sortKeys} onItemChange={this.handleItemChange}
        onChange={this.handleSelectionChange} actions={stockActions} format="stockList"/>
        </>
      )
    } else { return (<Container className="mb-2"><Row><Col>Select a list</Col></Row></Container>) }
  }

  render() {
    if (!this.state.lItems[this.state.selectedList]) this.refreshListItems(this.state.selectedList);
    var wOptions = [];
    if (this.state.warehouses) {
      wOptions = this.state.warehouses.sort(firstBy('wName', { ignoreCase: true }))
      .map(i => { return (<option key={i.wId} value={i.wId}>{i.wName}</option>) })
    }
    wOptions.unshift(<option key={0} value={null}></option>)

    var lRows = [];
    if (this.state.stockLists) {
      lRows = this.state.stockLists.sort(firstBy('slName')).map(l => {
        return (
          <Row key={l.slId}>
            <Col>
              <Nav.Item style={{ maxWidth: '260px' }}>
              <Nav.Link className="text-truncate px-1 py-0" eventKey={l.slId}>{l.slName}</Nav.Link>
              </Nav.Item>
            </Col>
            <Col className="px-1" md="auto"><Form.Control size="sm" as="select" onChange={this.handleWarehouseChange} value={l.wId || 0} id={l.slId}>{wOptions}</Form.Control></Col>
            <Col className="pl-0" md="auto"><Button onClick={this.handleDeleteListShow} id={l.slId} variant="danger" className="my-0 py-0 mr-1 float-right" size="sm">&times;</Button></Col>
          </Row>
        )
      })
    }

    return (
      <Container className='Home'>
        <Row>
          <Col md="auto">
            <Card className="mb-2">
              <Card.Header className="p-2">Custom Lists<div className="float-right ml-2"><Button onClick={this.handleCreateListShow} size="sm" className="my-0 py-0">+</Button></div></Card.Header>
              <Card.Body className="p-1">
              <Nav variant="pills" className="flex-column mx-0" activeKey={this.state.selectedList} onSelect={this.handleSelectList}>
                {lRows}
              </Nav>
              </Card.Body>
            </Card>
          </Col>
          <Col>
          {this.renderMainColumn()}
          </Col>
        </Row>
        <CreateListModal {...this.state} show={this.state.showCreateList}
          onHide={this.handleCreateListHide} onSubmit={this.handleCreateListSubmit}
          onChange={this.handleChange} validated={this.clIsValid()} />
        <DeleteListModal show={this.state.showDeleteList} onHide={this.handleDeleteListHide}
          onSubmit={this.handleDeleteListSubmit} listName={this.state.toDeleteListName}
          listId={this.state.toDeleteListId} />
        <CreateListItemModal {...this.state} show={this.state.showCreateListItem}
          onHide={this.handleCreateListItemHide} onSubmit={this.handleCreateListItemSubmit}
          onSearch={this.handleCreateListItemSearch} onChange={this.handleChange}
          colors={this.colors} items={this.state.cliItems} validated={this.cliIsValid()} />
        <EditListItemModal {...this.state} show={this.state.showEditListItem}
          onHide={this.handleEditListItemHide} onSubmit={this.handleEditListItemSubmit}
          onChange={this.handleChange} validated={this.eliIsValid()} />
        <DeleteItemsModal show={this.state.showDeleteItems} onHide={this.handleDeleteItemsHide}
          onSubmit={this.handleDeleteItemsSubmit} />
      </Container>
    );
  }
}

export default withAuthenticator(CustomLists)

const DeleteItemsModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Selected Items</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete all selected items from this list?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button variant="danger" onClick={props.onSubmit}>
          Delete Items
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const EditListItemModal = (props) => {
  if (!props.selectedItem) return null;

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit {props.selectedItem.sNo} - {props.selectedItem.sName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form.Group as={Row}>
        <Form.Label column>Quantity</Form.Label>
        <Col><Form.Control type="number" min="0" onChange={props.onChange} value={props.cliItemQty || 0} id="cliItemQty" /></Col>
      </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button variant="primary" disabled={!props.validated} onClick={props.onSubmit}>
          Edit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const CreateListItemModal = (props) => {
  const typeOptions = Object.keys(blTypeMap).map(key => {
    return (<option key={blTypeMap[key]} value={blTypeMap[key]}>{key}</option>)
  })

  var colorOptions = [];
  if (props.colors) {
    colorOptions = props.colors.sort(firstBy('name', { ignoreCase: true })).map(c => {
      return (<option key={c.colorid} value={c.colorid}>{c.name}</option>)
    })
  }

  var itemOptions = [];
  if (props.items) {
    itemOptions = props.items.sort(firstBy('cNo', { ignoreCase: true })).map(i => {
      return (<option key={i.cNo} value={i.cNo}>{`${i.cNo} - ${i.cName}`}</option>)
    })
  }

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create List Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form.Group as={Row}>
        <Form.Label column>Search</Form.Label>
        <Col><Form.Control onChange={props.onChange} value={props.cliSearch || ''} id="cliSearch" placeholder="Item Number" type="text" /></Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column>Type</Form.Label>
        <Col><Form.Control as="select" onChange={props.onChange} value={props.cliItemType || ''} id="cliItemType" placeholder="Item Type">{typeOptions}</Form.Control></Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column>Item</Form.Label>
        <Col><Form.Control as="select" onChange={props.onChange} value={props.cliItemNo || ''} id="cliItemNo" placeholder="Item Type">{itemOptions}</Form.Control></Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column>Color</Form.Label>
        <Col><Form.Control as="select" disabled={props.cliItemType !== '2'} onChange={props.onChange} value={props.cliItemCol || ''} id="cliItemCol" placeholder="Item Color" >{colorOptions}</Form.Control></Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column>Quantity</Form.Label>
        <Col><Form.Control type="number" min="0" onChange={props.onChange} value={props.cliItemQty || 0} id="cliItemQty" /></Col>
      </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button variant="primary" disabled={!props.cliSearch} onClick={props.onSearch}>
          Search
        </Button>
        <Button variant="primary" disabled={!props.validated} onClick={props.onSubmit}>
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const DeleteListModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Custom List</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete list {props.listName}?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button variant="danger" id={props.listId} onClick={props.onSubmit}>
          Delete List
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const CreateListModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create Stock List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form.Group as={Row}>
        <Form.Label column>Name</Form.Label>
        <Col><Form.Control onChange={props.onChange} value={props.clName || ''} id="clName" placeholder="List Name" type="text" /></Col>
      </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button variant="primary" disabled={!props.validated} onClick={props.onSubmit}>
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
