
import rateLimit from 'axios-rate-limit';
// const localVarRequest = require("request");
const axios = require('axios').default;
const http = rateLimit(axios.create(), { maxRequests: 1, perMilliseconds: 2000, maxRPS: 1 })

let defaultBasePath = 'https://rebrickable.com';
// let nextApiCallTime = 0;

class RequestSender {

  static get(headers, queryParameters, formParams, path, useQueryString, auth) {
    return this.send('GET', headers, queryParameters, formParams, path, useQueryString, auth);
  }

  static put(headers, queryParameters, formParams, path, useQueryString, auth) {
    return this.send('PUT', headers, queryParameters, formParams, path, useQueryString, auth);
  }

  static delete(headers, queryParameters, formParams, path, useQueryString, auth) {
    return this.send('DELETE', headers, queryParameters, formParams, path, useQueryString, auth);
  }

  static send(method, headers, queryParameters, formParams, path, useQueryString, auth) {
    let requestOptions = {
        method: method,
        qs: queryParameters,
        headers: headers || {},
        uri: path,
        useQuerystring: useQueryString,
        json: true,
    };
    auth.applyToRequest(requestOptions);
    if (Object.keys(formParams).length) {
      requestOptions.form = formParams;
    }

    let axiosOptions = {
      method: requestOptions.method,
      url: requestOptions.uri,
      params: requestOptions.qs,
      headers: requestOptions.headers,
      data: requestOptions.form,
    };

    // console.log(requestOptions);
    // console.log(axiosOptions);

    return http(axiosOptions)
    .then(res => {
      // console.log(res);
      return { response: res, body: res.data }
    })
    // .catch(err => {
    //   console.log(err);
    // })
  }
}

let primitives = [
    "string",
    "boolean",
    "double",
    "integer",
    "long",
    "float",
    "number",
    "any"
];
class ObjectSerializer {
    static findCorrectType(data, expectedType) {
        if (data === undefined) {
            return expectedType;
        }
        else if (primitives.indexOf(expectedType.toLowerCase()) !== -1) {
            return expectedType;
        }
        else if (expectedType === "Date") {
            return expectedType;
        }
        else {
            if (enumsMap[expectedType]) {
                return expectedType;
            }
            if (!typeMap[expectedType]) {
                return expectedType; // w/e we don't know the type
            }
            // Check the discriminator
            let discriminatorProperty = typeMap[expectedType].discriminator;
            if (discriminatorProperty == null) {
                return expectedType; // the type does not have a discriminator. use it.
            }
            else {
                if (data[discriminatorProperty]) {
                    return data[discriminatorProperty]; // use the type given in the discriminator
                }
                else {
                    return expectedType; // discriminator was not present (or an empty string)
                }
            }
        }
    }
    static serialize(data, type) {
        if (data === undefined) {
            return data;
        }
        else if (primitives.indexOf(type.toLowerCase()) !== -1) {
            return data;
        }
        else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
            let subType = type.replace("Array<", ""); // Array<Type> => Type>
            subType = subType.substring(0, subType.length - 1); // Type> => Type
            let transformedData = [];
            for (let index in data) {
                let date = data[index];
                transformedData.push(ObjectSerializer.serialize(date, subType));
            }
            return transformedData;
        }
        else if (type === "Date") {
            return data.toString();
        }
        else {
            if (enumsMap[type]) {
                return data;
            }
            if (!typeMap[type]) { // in case we dont know the type
                return data;
            }
            // get the map for the correct type.
            let attributeTypes = typeMap[type].getAttributeTypeMap();
            let instance = {};
            for (let index in attributeTypes) {
                let attributeType = attributeTypes[index];
                instance[attributeType.baseName] = ObjectSerializer.serialize(data[attributeType.name], attributeType.type);
            }
            return instance;
        }
    }
    static deserialize(data, type) {
        // polymorphism may change the actual type.
        type = ObjectSerializer.findCorrectType(data, type);
        if (data === undefined) {
            return data;
        }
        else if (primitives.indexOf(type.toLowerCase()) !== -1) {
            return data;
        }
        else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
            let subType = type.replace("Array<", ""); // Array<Type> => Type>
            subType = subType.substring(0, subType.length - 1); // Type> => Type
            let transformedData = [];
            for (let index in data) {
                let date = data[index];
                transformedData.push(ObjectSerializer.deserialize(date, subType));
            }
            return transformedData;
        }
        else if (type === "Date") {
            return new Date(data);
        }
        else {
            if (enumsMap[type]) { // is Enum
                return data;
            }
            if (!typeMap[type]) { // dont know the type
                return data;
            }
            let instance = new typeMap[type]();
            let attributeTypes = typeMap[type].getAttributeTypeMap();
            for (let index in attributeTypes) {
                let attributeType = attributeTypes[index];
                instance[attributeType.name] = ObjectSerializer.deserialize(data[attributeType.baseName], attributeType.type);
            }
            return instance;
        }
    }
}
let enumsMap = {};
let typeMap = {};

class ApiKeyAuth {
    constructor(location, paramName, apiKey) {
        this.location = location;
        this.paramName = paramName;
        this.apiKey = apiKey;
    }
    applyToRequest(requestOptions) {
        if (this.location === "query") {
            requestOptions.qs[this.paramName] = this.apiKey;
        }
        else if (this.location === "header" && requestOptions && requestOptions.headers) {
            requestOptions.headers['Authorization'] = this.paramName + ' ' + this.apiKey;
        }
    }
}

class VoidAuth {
    constructor() {
        this.username = '';
        this.password = '';
    }
    applyToRequest(_) {
        // Do nothing
    }
}

var LegoApiApiKeys;

export class LegoApi {
    constructor(credentials) {
        this._basePath = defaultBasePath;
        this.defaultHeaders = {};
        this._useQuerystring = false;
        this.authentications = {
            'default': new VoidAuth(),
        };
        if (credentials) {
            if (credentials.apiKey) {
              this.authentications = {
                  'default': new ApiKeyAuth('header', 'key', credentials.apiKey),
              };
            }
            if (credentials.userToken) {
              this._userToken = credentials.userToken;
            }
        }
    }
    set useQuerystring(value) {
        this._useQuerystring = value;
    }
    set basePath(basePath) {
        this._basePath = basePath;
    }
    get basePath() {
        return this._basePath;
    }
    setDefaultAuthentication(auth) {
        this.authentications.default = auth;
    }
    setApiKey(key, value) {
        this.authentications[LegoApiApiKeys[key]].apiKey = value;
    }
    /**
     * Get a list of all Colors.
     * @summary Get a list of all Colors.
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param ordering Which field to use when ordering the results.
     * @param {*} [options] Override http request options.
     */
    legoColorsList(page, pageSize, ordering, options = {}) {
        const localVarPath = this.basePath + '/api/v3/lego/colors/';
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        if (page !== undefined) {
            localVarQueryParameters['page'] = ObjectSerializer.serialize(page, "number");
        }
        if (pageSize !== undefined) {
            localVarQueryParameters['page_size'] = ObjectSerializer.serialize(pageSize, "number");
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Get details about a specific Color.
     * @summary Get details about a specific Color.
     * @param id A unique value identifying this color.
     * @param ordering Which field to use when ordering the results.
     * @param {*} [options] Override http request options.
     */
    legoColorsRead(id, ordering, options = {}) {
        const localVarPath = this.basePath + '/api/v3/lego/colors/{id}/'
            .replace('{id}', encodeURIComponent(String(id)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling legoColorsRead.');
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Get details about a specific Element ID.
     * @summary Get details about a specific Element ID.
     * @param elementId
     * @param {*} [options] Override http request options.
     */
    legoElementsRead(elementId, options = {}) {
        const localVarPath = this.basePath + '/api/v3/lego/elements/{element_id}/'
            .replace('{element_id}', encodeURIComponent(String(elementId)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'elementId' is not null or undefined
        if (elementId === null || elementId === undefined) {
            throw new Error('Required parameter elementId was null or undefined when calling legoElementsRead.');
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Get a list of all Inventory Parts in this MOC.
     * @summary Get a list of all Inventory Parts in this MOC.
     * @param setNum
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param {*} [options] Override http request options.
     */
    legoMocsPartsList(setNum, page, pageSize, options = {}) {
        const localVarPath = this.basePath + '/api/v3/lego/mocs/{set_num}/parts/'
            .replace('{set_num}', encodeURIComponent(String(setNum)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'setNum' is not null or undefined
        if (setNum === null || setNum === undefined) {
            throw new Error('Required parameter setNum was null or undefined when calling legoMocsPartsList.');
        }
        if (page !== undefined) {
            localVarQueryParameters['page'] = ObjectSerializer.serialize(page, "number");
        }
        if (pageSize !== undefined) {
            localVarQueryParameters['page_size'] = ObjectSerializer.serialize(pageSize, "number");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Get details for a specific MOC.
     * @summary Get details for a specific MOC.
     * @param setNum
     * @param {*} [options] Override http request options.
     */
    legoMocsRead(setNum, options = {}) {
        const localVarPath = this.basePath + '/api/v3/lego/mocs/{set_num}/'
            .replace('{set_num}', encodeURIComponent(String(setNum)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'setNum' is not null or undefined
        if (setNum === null || setNum === undefined) {
            throw new Error('Required parameter setNum was null or undefined when calling legoMocsRead.');
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Get a list of all Part Categories.
     * @summary Get a list of all Part Categories.
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param ordering Which field to use when ordering the results.
     * @param {*} [options] Override http request options.
     */
    legoPartCategoriesList(page, pageSize, ordering, options = {}) {
        const localVarPath = this.basePath + '/api/v3/lego/part_categories/';
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        if (page !== undefined) {
            localVarQueryParameters['page'] = ObjectSerializer.serialize(page, "number");
        }
        if (pageSize !== undefined) {
            localVarQueryParameters['page_size'] = ObjectSerializer.serialize(pageSize, "number");
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Get details about a specific Part Category.
     * @summary Get details about a specific Part Category.
     * @param id A unique integer value identifying this part category.
     * @param ordering Which field to use when ordering the results.
     * @param {*} [options] Override http request options.
     */
    legoPartCategoriesRead(id, ordering, options = {}) {
        const localVarPath = this.basePath + '/api/v3/lego/part_categories/{id}/'
            .replace('{id}', encodeURIComponent(String(id)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling legoPartCategoriesRead.');
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Get a list of all Colors a Part has appeared in.
     * @summary Get a list of all Colors a Part has appeared in.
     * @param partNum
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param ordering Which field to use when ordering the results.
     * @param {*} [options] Override http request options.
     */
    legoPartsColorsList(partNum, page, pageSize, ordering, options = {}) {
        const localVarPath = this.basePath + '/api/v3/lego/parts/{part_num}/colors/'
            .replace('{part_num}', encodeURIComponent(String(partNum)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'partNum' is not null or undefined
        if (partNum === null || partNum === undefined) {
            throw new Error('Required parameter partNum was null or undefined when calling legoPartsColorsList.');
        }
        if (page !== undefined) {
            localVarQueryParameters['page'] = ObjectSerializer.serialize(page, "number");
        }
        if (pageSize !== undefined) {
            localVarQueryParameters['page_size'] = ObjectSerializer.serialize(pageSize, "number");
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Get details about a specific Part/Color combination.
     * @summary Get details about a specific Part/Color combination.
     * @param partNum
     * @param colorId
     * @param {*} [options] Override http request options.
     */
    legoPartsColorsRead(partNum, colorId, options = {}) {
        const localVarPath = this.basePath + '/api/v3/lego/parts/{part_num}/colors/{color_id}/'
            .replace('{part_num}', encodeURIComponent(String(partNum)))
            .replace('{color_id}', encodeURIComponent(String(colorId)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'partNum' is not null or undefined
        if (partNum === null || partNum === undefined) {
            throw new Error('Required parameter partNum was null or undefined when calling legoPartsColorsRead.');
        }
        // verify required parameter 'colorId' is not null or undefined
        if (colorId === null || colorId === undefined) {
            throw new Error('Required parameter colorId was null or undefined when calling legoPartsColorsRead.');
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Get a list of all Sets the Part/Color combination has appeard in.
     * @summary Get a list of all Sets the Part/Color combination has appeard in.
     * @param partNum
     * @param colorId
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param ordering Which field to use when ordering the results.
     * @param {*} [options] Override http request options.
     */
    legoPartsColorsSetsList(partNum, colorId, page, pageSize, ordering, options = {}) {
        const localVarPath = this.basePath + '/api/v3/lego/parts/{part_num}/colors/{color_id}/sets/'
            .replace('{part_num}', encodeURIComponent(String(partNum)))
            .replace('{color_id}', encodeURIComponent(String(colorId)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'partNum' is not null or undefined
        if (partNum === null || partNum === undefined) {
            throw new Error('Required parameter partNum was null or undefined when calling legoPartsColorsSetsList.');
        }
        // verify required parameter 'colorId' is not null or undefined
        if (colorId === null || colorId === undefined) {
            throw new Error('Required parameter colorId was null or undefined when calling legoPartsColorsSetsList.');
        }
        if (page !== undefined) {
            localVarQueryParameters['page'] = ObjectSerializer.serialize(page, "number");
        }
        if (pageSize !== undefined) {
            localVarQueryParameters['page_size'] = ObjectSerializer.serialize(pageSize, "number");
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Get a list of Parts.  Optional parameter inc_part_details=1 can be used to return additional fields, the same as for a single part lookup.  The optional part_nums filter parameter should be a comma separated list of part_num without spaces.  Optionally filter by one or more of the below query parameters.
     * @summary Get a list of Parts.
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param partNum
     * @param partNums
     * @param partCatId
     * @param colorId
     * @param bricklinkId
     * @param brickowlId
     * @param legoId
     * @param ldrawId
     * @param ordering Which field to use when ordering the results.
     * @param search A search term.
     * @param {*} [options] Override http request options.
     */
    legoPartsList(page, pageSize, partNum, partNums, partCatId, colorId, bricklinkId, brickowlId, legoId, ldrawId, ordering, search, options = {}) {
        const localVarPath = this.basePath + '/api/v3/lego/parts/';
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        if (page !== undefined) {
            localVarQueryParameters['page'] = ObjectSerializer.serialize(page, "number");
        }
        if (pageSize !== undefined) {
            localVarQueryParameters['page_size'] = ObjectSerializer.serialize(pageSize, "number");
        }
        if (partNum !== undefined) {
            localVarQueryParameters['part_num'] = ObjectSerializer.serialize(partNum, "string");
        }
        if (partNums !== undefined) {
            localVarQueryParameters['part_nums'] = ObjectSerializer.serialize(partNums, "string");
        }
        if (partCatId !== undefined) {
            localVarQueryParameters['part_cat_id'] = ObjectSerializer.serialize(partCatId, "string");
        }
        if (colorId !== undefined) {
            localVarQueryParameters['color_id'] = ObjectSerializer.serialize(colorId, "string");
        }
        if (bricklinkId !== undefined) {
            localVarQueryParameters['bricklink_id'] = ObjectSerializer.serialize(bricklinkId, "string");
        }
        if (brickowlId !== undefined) {
            localVarQueryParameters['brickowl_id'] = ObjectSerializer.serialize(brickowlId, "string");
        }
        if (legoId !== undefined) {
            localVarQueryParameters['lego_id'] = ObjectSerializer.serialize(legoId, "string");
        }
        if (ldrawId !== undefined) {
            localVarQueryParameters['ldraw_id'] = ObjectSerializer.serialize(ldrawId, "string");
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }
        if (search !== undefined) {
            localVarQueryParameters['search'] = ObjectSerializer.serialize(search, "string");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Get details about a specific Part.
     * @summary Get details about a specific Part.
     * @param partNum
     * @param {*} [options] Override http request options.
     */
    legoPartsRead(partNum, options = {}) {
        const localVarPath = this.basePath + '/api/v3/lego/parts/{part_num}/'
            .replace('{part_num}', encodeURIComponent(String(partNum)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'partNum' is not null or undefined
        if (partNum === null || partNum === undefined) {
            throw new Error('Required parameter partNum was null or undefined when calling legoPartsRead.');
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Get a list of MOCs which are Alternate Builds of a specific Set - i.e. all parts in the MOC can be found in the Set.
     * @summary Get a list of MOCs which are Alternate Builds of a specific Set - i.e. all parts in the MOC can
     * @param setNum
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param ordering Which field to use when ordering the results.
     * @param {*} [options] Override http request options.
     */
    legoSetsAlternatesList(setNum, page, pageSize, ordering, options = {}) {
        const localVarPath = this.basePath + '/api/v3/lego/sets/{set_num}/alternates/'
            .replace('{set_num}', encodeURIComponent(String(setNum)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'setNum' is not null or undefined
        if (setNum === null || setNum === undefined) {
            throw new Error('Required parameter setNum was null or undefined when calling legoSetsAlternatesList.');
        }
        if (page !== undefined) {
            localVarQueryParameters['page'] = ObjectSerializer.serialize(page, "number");
        }
        if (pageSize !== undefined) {
            localVarQueryParameters['page_size'] = ObjectSerializer.serialize(pageSize, "number");
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Get a list of Sets, optionally filtered by any of the below parameters.
     * @summary Get a list of Sets, optionally filtered by any of the below parameters.
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param themeId
     * @param minYear
     * @param maxYear
     * @param minParts
     * @param maxParts
     * @param ordering Which field to use when ordering the results.
     * @param search A search term.
     * @param {*} [options] Override http request options.
     */
    legoSetsList(page, pageSize, themeId, minYear, maxYear, minParts, maxParts, ordering, search, options = {}) {
        const localVarPath = this.basePath + '/api/v3/lego/sets/';
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        if (page !== undefined) {
            localVarQueryParameters['page'] = ObjectSerializer.serialize(page, "number");
        }
        if (pageSize !== undefined) {
            localVarQueryParameters['page_size'] = ObjectSerializer.serialize(pageSize, "number");
        }
        if (themeId !== undefined) {
            localVarQueryParameters['theme_id'] = ObjectSerializer.serialize(themeId, "string");
        }
        if (minYear !== undefined) {
            localVarQueryParameters['min_year'] = ObjectSerializer.serialize(minYear, "number");
        }
        if (maxYear !== undefined) {
            localVarQueryParameters['max_year'] = ObjectSerializer.serialize(maxYear, "number");
        }
        if (minParts !== undefined) {
            localVarQueryParameters['min_parts'] = ObjectSerializer.serialize(minParts, "number");
        }
        if (maxParts !== undefined) {
            localVarQueryParameters['max_parts'] = ObjectSerializer.serialize(maxParts, "number");
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }
        if (search !== undefined) {
            localVarQueryParameters['search'] = ObjectSerializer.serialize(search, "string");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Get a list of all Inventory Parts in this Set.  Optional parameter inc_part_details=1 can be used to return additional part fields, the same as for a single part lookup.
     * @summary Get a list of all Inventory Parts in this Set.
     * @param setNum
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param {*} [options] Override http request options.
     */
    legoSetsPartsList(setNum, page, pageSize, options = {}) {
        const localVarPath = this.basePath + '/api/v3/lego/sets/{set_num}/parts/'
            .replace('{set_num}', encodeURIComponent(String(setNum)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'setNum' is not null or undefined
        if (setNum === null || setNum === undefined) {
            throw new Error('Required parameter setNum was null or undefined when calling legoSetsPartsList.');
        }
        if (page !== undefined) {
            localVarQueryParameters['page'] = ObjectSerializer.serialize(page, "number");
        }
        if (pageSize !== undefined) {
            localVarQueryParameters['page_size'] = ObjectSerializer.serialize(pageSize, "number");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Get details for a specific Set.
     * @summary Get details for a specific Set.
     * @param setNum
     * @param {*} [options] Override http request options.
     */
    legoSetsRead(setNum, options = {}) {
        const localVarPath = this.basePath + '/api/v3/lego/sets/{set_num}/'
            .replace('{set_num}', encodeURIComponent(String(setNum)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'setNum' is not null or undefined
        if (setNum === null || setNum === undefined) {
            throw new Error('Required parameter setNum was null or undefined when calling legoSetsRead.');
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Get a list of all Inventory Sets in this Set.
     * @summary Get a list of all Inventory Sets in this Set.
     * @param setNum
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param {*} [options] Override http request options.
     */
    legoSetsSetsList(setNum, page, pageSize, options = {}) {
        const localVarPath = this.basePath + '/api/v3/lego/sets/{set_num}/sets/'
            .replace('{set_num}', encodeURIComponent(String(setNum)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'setNum' is not null or undefined
        if (setNum === null || setNum === undefined) {
            throw new Error('Required parameter setNum was null or undefined when calling legoSetsSetsList.');
        }
        if (page !== undefined) {
            localVarQueryParameters['page'] = ObjectSerializer.serialize(page, "number");
        }
        if (pageSize !== undefined) {
            localVarQueryParameters['page_size'] = ObjectSerializer.serialize(pageSize, "number");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Return all Themes
     * @summary Return all Themes
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param ordering Which field to use when ordering the results.
     * @param {*} [options] Override http request options.
     */
    legoThemesList(page, pageSize, ordering, options = {}) {
        const localVarPath = this.basePath + '/api/v3/lego/themes/';
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        if (page !== undefined) {
            localVarQueryParameters['page'] = ObjectSerializer.serialize(page, "number");
        }
        if (pageSize !== undefined) {
            localVarQueryParameters['page_size'] = ObjectSerializer.serialize(pageSize, "number");
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Return details for a specific Theme
     * @summary Return details for a specific Theme
     * @param id A unique integer value identifying this theme.
     * @param ordering Which field to use when ordering the results.
     * @param {*} [options] Override http request options.
     */
    legoThemesRead(id, ordering, options = {}) {
        const localVarPath = this.basePath + '/api/v3/lego/themes/{id}/'
            .replace('{id}', encodeURIComponent(String(id)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling legoThemesRead.');
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
}

var UsersApiApiKeys;

export class UsersApi {
    constructor(credentials) {
        this._basePath = defaultBasePath;
        this.defaultHeaders = {};
        this._useQuerystring = false;
        this.authentications = {
            'default': new VoidAuth(),
        };
        if (credentials) {
            if (credentials.apiKey) {
              this.authentications = {
                  'default': new ApiKeyAuth('header', 'key', credentials.apiKey),
              };
            }
            if (credentials.userToken) {
              this._userToken = credentials.userToken;
            }
        }
    }
    set useQuerystring(value) {
        this._useQuerystring = value;
    }
    set basePath(basePath) {
        this._basePath = basePath;
    }
    get basePath() {
        return this._basePath;
    }
    setDefaultAuthentication(auth) {
        this.authentications.default = auth;
    }
    setApiKey(key, value) {
        this.authentications[UsersApiApiKeys[key]].apiKey = value;
    }
    /**
     * Get a list of all the Parts in all the user's Part Lists as well as the Parts inside Sets in the user's Set Lists.  ###WARNING this call is very resource intensive, do not overuse it!  Optionally, filter by one or more of the below query parameters.
     * @summary Get a list of all the Parts in all the user's Part Lists as well as the Parts inside Sets in the user's Set Lists.
     * @param userToken
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param partNum
     * @param partCatId
     * @param colorId
     * @param {*} [options] Override http request options.
     */
    usersAllpartsList(userToken, page, pageSize, partNum, partCatId, colorId, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/allparts/'
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        if (page !== undefined) {
            localVarQueryParameters['page'] = ObjectSerializer.serialize(page, "number");
        }
        if (pageSize !== undefined) {
            localVarQueryParameters['page_size'] = ObjectSerializer.serialize(pageSize, "number");
        }
        if (partNum !== undefined) {
            localVarQueryParameters['part_num'] = ObjectSerializer.serialize(partNum, "string");
        }
        if (partCatId !== undefined) {
            localVarQueryParameters['part_cat_id'] = ObjectSerializer.serialize(partCatId, "number");
        }
        if (colorId !== undefined) {
            localVarQueryParameters['color_id'] = ObjectSerializer.serialize(colorId, "number");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Get a list of all the available Badges
     * @summary Get a list of all the available Badges
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param ordering Which field to use when ordering the results.
     * @param {*} [options] Override http request options.
     */
    usersBadgesList(page, pageSize, ordering, options = {}) {
        const localVarPath = this.basePath + '/api/v3/users/badges/';
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        if (page !== undefined) {
            localVarQueryParameters['page'] = ObjectSerializer.serialize(page, "number");
        }
        if (pageSize !== undefined) {
            localVarQueryParameters['page_size'] = ObjectSerializer.serialize(pageSize, "number");
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Get details about a specific Badge
     * @summary Get details about a specific Badge
     * @param id A unique integer value identifying this badge.
     * @param ordering Which field to use when ordering the results.
     * @param {*} [options] Override http request options.
     */
    usersBadgesRead(id, ordering, options = {}) {
        const localVarPath = this.basePath + '/api/v3/users/badges/{id}/'
            .replace('{id}', encodeURIComponent(String(id)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling usersBadgesRead.');
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Find out how many parts the user needs to build the specified Set.  The user's default Build Settings will be used to calculate a Build Match % using their LEGO Collection of Sets and Parts.
     * @summary Find out how many parts the user needs to build the specified Set.
     * @param setNum
     * @param userToken
     * @param {*} [options] Override http request options.
     */
    usersBuildRead(setNum, userToken, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/build/{set_num}/'
            .replace('{set_num}', encodeURIComponent(String(setNum)))
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'setNum' is not null or undefined
        if (setNum === null || setNum === undefined) {
            throw new Error('Required parameter setNum was null or undefined when calling usersBuildRead.');
        }
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersBuildRead.');
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Add one or more Lost Parts to the user.  ### Single Part When adding a single Part, returns the successfully created Part (status 201) or details for why the Part could not be added. ### Multiple Parts To add multiple Parts, POST a JSON list of them (using a Content-Type header of 'application/json'). The inv_part_id field can be retrieved from the Set's inventory. e.g: `[{\"inv_part_id\": 806698, \"lost_quantity\": 3},   {\"inv_part_id\": 256007, \"lost_quantity\": 2}]` Returns a list of successfully added Parts. If the Part already exists or is unrecognised, it will be skipped.
     * @summary Add one or more Lost Parts to the user.
     * @param userToken
     * @param invPartId
     * @param lostQuantity
     * @param {*} [options] Override http request options.
     */
/*    usersLostPartsCreate(userToken, invPartId, lostQuantity, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/lost_parts/'
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarHeaderParams = Object.assign({}, this.defaultHeaders);
        let localVarFormParams = {};
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersLostPartsCreate.');
        }
        // verify required parameter 'invPartId' is not null or undefined
        if (invPartId === null || invPartId === undefined) {
            throw new Error('Required parameter invPartId was null or undefined when calling usersLostPartsCreate.');
        }
        Object.assign(localVarHeaderParams, options.headers);
        let localVarUseFormData = false;
        if (lostQuantity !== undefined) {
            localVarFormParams['lost_quantity'] = ObjectSerializer.serialize(lostQuantity, "number");
        }
        if (invPartId !== undefined) {
            localVarFormParams['inv_part_id'] = ObjectSerializer.serialize(invPartId, "number");
        }
        let localVarRequestOptions = {
            method: 'POST',
            qs: localVarQueryParameters,
            headers: localVarHeaderParams,
            uri: localVarPath,
            useQuerystring: this._useQuerystring,
            json: true,
        };
        this.authentications.default.applyToRequest(localVarRequestOptions);
        if (Object.keys(localVarFormParams).length) {
            if (localVarUseFormData) {
                localVarRequestOptions.formData = localVarFormParams;
            }
            else {
                localVarRequestOptions.form = localVarFormParams;
            }
        }
        return new Promise((resolve, reject) => {
            localVarRequest(localVarRequestOptions, (error, response, body) => {
                if (error) {
                    reject(error);
                }
                else {
                    if (response.statusCode && response.statusCode >= 200 && response.statusCode <= 299) {
                        resolve({ response: response, body: body });
                    }
                    else {
                        reject({ response: response, body: body });
                    }
                }
            });
        });
    }*/
    /**
     * Remove the Lost Part from the user.
     * @summary Remove the Lost Part from the user.
     * @param id
     * @param userToken
     * @param ordering Which field to use when ordering the results.
     * @param {*} [options] Override http request options.
     */
/*    usersLostPartsDelete(id, userToken, ordering, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/lost_parts/{id}/'
            .replace('{id}', encodeURIComponent(String(id)))
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarHeaderParams = Object.assign({}, this.defaultHeaders);
        let localVarFormParams = {};
        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling usersLostPartsDelete.');
        }
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersLostPartsDelete.');
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }
        Object.assign(localVarHeaderParams, options.headers);
        let localVarUseFormData = false;
        let localVarRequestOptions = {
            method: 'DELETE',
            qs: localVarQueryParameters,
            headers: localVarHeaderParams,
            uri: localVarPath,
            useQuerystring: this._useQuerystring,
            json: true,
        };
        this.authentications.default.applyToRequest(localVarRequestOptions);
        if (Object.keys(localVarFormParams).length) {
            if (localVarUseFormData) {
                localVarRequestOptions.formData = localVarFormParams;
            }
            else {
                localVarRequestOptions.form = localVarFormParams;
            }
        }
        return new Promise((resolve, reject) => {
            localVarRequest(localVarRequestOptions, (error, response, body) => {
                if (error) {
                    reject(error);
                }
                else {
                    if (response.statusCode && response.statusCode >= 200 && response.statusCode <= 299) {
                        resolve({ response: response, body: body });
                    }
                    else {
                        reject({ response: response, body: body });
                    }
                }
            });
        });
    }*/
    /**
     * Get a list of all the Lost Parts from the user's LEGO collection.  Optionally, filter by one or more of the below query parameters.
     * @summary Get a list of all the Lost Parts from the user's LEGO collection.
     * @param userToken
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param ordering Which field to use when ordering the results.
     * @param {*} [options] Override http request options.
     */
    usersLostPartsList(userToken, page, pageSize, ordering, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/lost_parts/'
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersLostPartsList.');
        }
        if (page !== undefined) {
            localVarQueryParameters['page'] = ObjectSerializer.serialize(page, "number");
        }
        if (pageSize !== undefined) {
            localVarQueryParameters['page_size'] = ObjectSerializer.serialize(pageSize, "number");
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Add a new Part List.
     * @summary Add a new Part List.
     * @param userToken
     * @param name
     * @param isBuildable
     * @param numParts
     * @param {*} [options] Override http request options.
     */
/*    usersPartlistsCreate(userToken, name, isBuildable, numParts, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/partlists/'
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarHeaderParams = Object.assign({}, this.defaultHeaders);
        let localVarFormParams = {};
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersPartlistsCreate.');
        }
        // verify required parameter 'name' is not null or undefined
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling usersPartlistsCreate.');
        }
        Object.assign(localVarHeaderParams, options.headers);
        let localVarUseFormData = false;
        if (isBuildable !== undefined) {
            localVarFormParams['is_buildable'] = ObjectSerializer.serialize(isBuildable, "boolean");
        }
        if (name !== undefined) {
            localVarFormParams['name'] = ObjectSerializer.serialize(name, "string");
        }
        if (numParts !== undefined) {
            localVarFormParams['num_parts'] = ObjectSerializer.serialize(numParts, "number");
        }
        let localVarRequestOptions = {
            method: 'POST',
            qs: localVarQueryParameters,
            headers: localVarHeaderParams,
            uri: localVarPath,
            useQuerystring: this._useQuerystring,
            json: true,
        };
        this.authentications.default.applyToRequest(localVarRequestOptions);
        if (Object.keys(localVarFormParams).length) {
            if (localVarUseFormData) {
                localVarRequestOptions.formData = localVarFormParams;
            }
            else {
                localVarRequestOptions.form = localVarFormParams;
            }
        }
        return new Promise((resolve, reject) => {
            localVarRequest(localVarRequestOptions, (error, response, body) => {
                if (error) {
                    reject(error);
                }
                else {
                    if (response.statusCode && response.statusCode >= 200 && response.statusCode <= 299) {
                        resolve({ response: response, body: body });
                    }
                    else {
                        reject({ response: response, body: body });
                    }
                }
            });
        });
    }*/
    /**
     * Delete a Part List and all it's Parts.
     * @summary Delete a Part List and all it's Parts.
     * @param listId
     * @param userToken
     * @param {*} [options] Override http request options.
     */
/*    usersPartlistsDelete(listId, userToken, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/partlists/{list_id}/'
            .replace('{list_id}', encodeURIComponent(String(listId)))
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarHeaderParams = Object.assign({}, this.defaultHeaders);
        let localVarFormParams = {};
        // verify required parameter 'listId' is not null or undefined
        if (listId === null || listId === undefined) {
            throw new Error('Required parameter listId was null or undefined when calling usersPartlistsDelete.');
        }
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersPartlistsDelete.');
        }
        Object.assign(localVarHeaderParams, options.headers);
        let localVarUseFormData = false;
        let localVarRequestOptions = {
            method: 'DELETE',
            qs: localVarQueryParameters,
            headers: localVarHeaderParams,
            uri: localVarPath,
            useQuerystring: this._useQuerystring,
            json: true,
        };
        this.authentications.default.applyToRequest(localVarRequestOptions);
        if (Object.keys(localVarFormParams).length) {
            if (localVarUseFormData) {
                localVarRequestOptions.formData = localVarFormParams;
            }
            else {
                localVarRequestOptions.form = localVarFormParams;
            }
        }
        return new Promise((resolve, reject) => {
            localVarRequest(localVarRequestOptions, (error, response, body) => {
                if (error) {
                    reject(error);
                }
                else {
                    if (response.statusCode && response.statusCode >= 200 && response.statusCode <= 299) {
                        resolve({ response: response, body: body });
                    }
                    else {
                        reject({ response: response, body: body });
                    }
                }
            });
        });
    }*/
    /**
     * Get a list of all the user's Part Lists.
     * @summary Get a list of all the user's Part Lists.
     * @param userToken
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param {*} [options] Override http request options.
     */
    usersPartlistsList(userToken, page, pageSize, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/partlists/'
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersPartlistsList.');
        }
        if (page !== undefined) {
            localVarQueryParameters['page'] = ObjectSerializer.serialize(page, "number");
        }
        if (pageSize !== undefined) {
            localVarQueryParameters['page_size'] = ObjectSerializer.serialize(pageSize, "number");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Update an existing Part List's details.
     * @summary Update an existing Part List's details.
     * @param listId
     * @param userToken
     * @param isBuildable
     * @param name
     * @param numParts
     * @param {*} [options] Override http request options.
     */
/*    usersPartlistsPartialUpdate(listId, userToken, isBuildable, name, numParts, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/partlists/{list_id}/'
            .replace('{list_id}', encodeURIComponent(String(listId)))
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarHeaderParams = Object.assign({}, this.defaultHeaders);
        let localVarFormParams = {};
        // verify required parameter 'listId' is not null or undefined
        if (listId === null || listId === undefined) {
            throw new Error('Required parameter listId was null or undefined when calling usersPartlistsPartialUpdate.');
        }
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersPartlistsPartialUpdate.');
        }
        Object.assign(localVarHeaderParams, options.headers);
        let localVarUseFormData = false;
        if (isBuildable !== undefined) {
            localVarFormParams['is_buildable'] = ObjectSerializer.serialize(isBuildable, "boolean");
        }
        if (name !== undefined) {
            localVarFormParams['name'] = ObjectSerializer.serialize(name, "string");
        }
        if (numParts !== undefined) {
            localVarFormParams['num_parts'] = ObjectSerializer.serialize(numParts, "number");
        }
        let localVarRequestOptions = {
            method: 'PATCH',
            qs: localVarQueryParameters,
            headers: localVarHeaderParams,
            uri: localVarPath,
            useQuerystring: this._useQuerystring,
            json: true,
        };
        this.authentications.default.applyToRequest(localVarRequestOptions);
        if (Object.keys(localVarFormParams).length) {
            if (localVarUseFormData) {
                localVarRequestOptions.formData = localVarFormParams;
            }
            else {
                localVarRequestOptions.form = localVarFormParams;
            }
        }
        return new Promise((resolve, reject) => {
            localVarRequest(localVarRequestOptions, (error, response, body) => {
                if (error) {
                    reject(error);
                }
                else {
                    if (response.statusCode && response.statusCode >= 200 && response.statusCode <= 299) {
                        resolve({ response: response, body: body });
                    }
                    else {
                        reject({ response: response, body: body });
                    }
                }
            });
        });
    }*/
    /**
     * Add one or more Parts to the Part List.  ### Single Part When adding a single Part, returns the successfully created Part (status 201) or details for why the Part could not be added. ### Multiple Parts To add multiple Parts, POST a JSON list of them (using a Content-Type header of 'application/json'). e.g: `[{\"part_num\":\"3001\", \"color_id\": 1, \"quantity\": 10},  {\"part_num\":\"3001\", \"color_id\": 2, \"quantity\": 20},  {\"part_num\":\"3002\", \"color_id\": 14, \"quantity\": 30}]` Returns a list of successfully added Parts. If the Part already exists or is unrecognised, it will be skipped.
     * @summary Add one or more Parts to the Part List.
     * @param listId
     * @param userToken
     * @param partNum
     * @param quantity
     * @param colorId
     * @param {*} [options] Override http request options.
     */
/*    usersPartlistsPartsCreate(listId, userToken, partNum, quantity, colorId, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/partlists/{list_id}/parts/'
            .replace('{list_id}', encodeURIComponent(String(listId)))
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarHeaderParams = Object.assign({}, this.defaultHeaders);
        let localVarFormParams = {};
        // verify required parameter 'listId' is not null or undefined
        if (listId === null || listId === undefined) {
            throw new Error('Required parameter listId was null or undefined when calling usersPartlistsPartsCreate.');
        }
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersPartlistsPartsCreate.');
        }
        // verify required parameter 'partNum' is not null or undefined
        if (partNum === null || partNum === undefined) {
            throw new Error('Required parameter partNum was null or undefined when calling usersPartlistsPartsCreate.');
        }
        // verify required parameter 'quantity' is not null or undefined
        if (quantity === null || quantity === undefined) {
            throw new Error('Required parameter quantity was null or undefined when calling usersPartlistsPartsCreate.');
        }
        // verify required parameter 'colorId' is not null or undefined
        if (colorId === null || colorId === undefined) {
            throw new Error('Required parameter colorId was null or undefined when calling usersPartlistsPartsCreate.');
        }
        Object.assign(localVarHeaderParams, options.headers);
        let localVarUseFormData = false;
        if (partNum !== undefined) {
            localVarFormParams['part_num'] = ObjectSerializer.serialize(partNum, "string");
        }
        if (quantity !== undefined) {
            localVarFormParams['quantity'] = ObjectSerializer.serialize(quantity, "number");
        }
        if (colorId !== undefined) {
            localVarFormParams['color_id'] = ObjectSerializer.serialize(colorId, "number");
        }
        let localVarRequestOptions = {
            method: 'POST',
            qs: localVarQueryParameters,
            headers: localVarHeaderParams,
            uri: localVarPath,
            useQuerystring: this._useQuerystring,
            json: true,
        };
        this.authentications.default.applyToRequest(localVarRequestOptions);
        if (Object.keys(localVarFormParams).length) {
            if (localVarUseFormData) {
                localVarRequestOptions.formData = localVarFormParams;
            }
            else {
                localVarRequestOptions.form = localVarFormParams;
            }
        }
        return new Promise((resolve, reject) => {
            localVarRequest(localVarRequestOptions, (error, response, body) => {
                if (error) {
                    reject(error);
                }
                else {
                    if (response.statusCode && response.statusCode >= 200 && response.statusCode <= 299) {
                        resolve({ response: response, body: body });
                    }
                    else {
                        reject({ response: response, body: body });
                    }
                }
            });
        });
    }*/
    /**
     * Delete a Part from the Part List.
     * @summary Delete a Part from the Part List.
     * @param listId
     * @param partNum
     * @param colorId
     * @param userToken
     * @param ordering Which field to use when ordering the results.
     * @param {*} [options] Override http request options.
     */
    usersPartlistsPartsDelete(listId, partNum, colorId, userToken, ordering, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/partlists/{list_id}/parts/{part_num}/{color_id}/'
            .replace('{list_id}', encodeURIComponent(String(listId)))
            .replace('{part_num}', encodeURIComponent(String(partNum)))
            .replace('{color_id}', encodeURIComponent(String(colorId)))
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        // let localVarHeaderParams = Object.assign({}, this.defaultHeaders);
        let localVarFormParams = {};
        // verify required parameter 'listId' is not null or undefined
        if (listId === null || listId === undefined) {
            throw new Error('Required parameter listId was null or undefined when calling usersPartlistsPartsDelete.');
        }
        // verify required parameter 'partNum' is not null or undefined
        if (partNum === null || partNum === undefined) {
            throw new Error('Required parameter partNum was null or undefined when calling usersPartlistsPartsDelete.');
        }
        // verify required parameter 'colorId' is not null or undefined
        if (colorId === null || colorId === undefined) {
            throw new Error('Required parameter colorId was null or undefined when calling usersPartlistsPartsDelete.');
        }
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersPartlistsPartsDelete.');
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }

        return RequestSender.delete(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);

/*        Object.assign(localVarHeaderParams, options.headers);
        let localVarUseFormData = false;
        let localVarRequestOptions = {
            method: 'DELETE',
            qs: localVarQueryParameters,
            headers: localVarHeaderParams,
            uri: localVarPath,
            useQuerystring: this._useQuerystring,
            json: true,
        };
        this.authentications.default.applyToRequest(localVarRequestOptions);
        if (Object.keys(localVarFormParams).length) {
            if (localVarUseFormData) {
                localVarRequestOptions.formData = localVarFormParams;
            }
            else {
                localVarRequestOptions.form = localVarFormParams;
            }
        }
        return new Promise((resolve, reject) => {
            localVarRequest(localVarRequestOptions, (error, response, body) => {
                if (error) {
                    reject(error);
                }
                else {
                    if (response.statusCode && response.statusCode >= 200 && response.statusCode <= 299) {
                        resolve({ response: response, body: body });
                    }
                    else {
                        reject({ response: response, body: body });
                    }
                }
            });
        });*/
    }
    /**
     * Get a list of all the Parts in a specific Part List.  Optional parameter inc_part_details=1 can be used to return additional part fields, the same as for a single part lookup.
     * @summary Get a list of all the Parts in a specific Part List.
     * @param listId
     * @param userToken
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param ordering Which field to use when ordering the results.
     * @param {*} [options] Override http request options.
     */
    usersPartlistsPartsList(listId, userToken, page, pageSize, ordering, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/partlists/{list_id}/parts/'
            .replace('{list_id}', encodeURIComponent(String(listId)))
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'listId' is not null or undefined
        if (listId === null || listId === undefined) {
            throw new Error('Required parameter listId was null or undefined when calling usersPartlistsPartsList.');
        }
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersPartlistsPartsList.');
        }
        if (page !== undefined) {
            localVarQueryParameters['page'] = ObjectSerializer.serialize(page, "number");
        }
        if (pageSize !== undefined) {
            localVarQueryParameters['page_size'] = ObjectSerializer.serialize(pageSize, "number");
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Get details about a specific Part in the Part List.
     * @summary Get details about a specific Part in the Part List.
     * @param listId
     * @param partNum
     * @param colorId
     * @param userToken
     * @param ordering Which field to use when ordering the results.
     * @param {*} [options] Override http request options.
     */
    usersPartlistsPartsRead(listId, partNum, colorId, userToken, ordering, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/partlists/{list_id}/parts/{part_num}/{color_id}/'
            .replace('{list_id}', encodeURIComponent(String(listId)))
            .replace('{part_num}', encodeURIComponent(String(partNum)))
            .replace('{color_id}', encodeURIComponent(String(colorId)))
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'listId' is not null or undefined
        if (listId === null || listId === undefined) {
            throw new Error('Required parameter listId was null or undefined when calling usersPartlistsPartsRead.');
        }
        // verify required parameter 'partNum' is not null or undefined
        if (partNum === null || partNum === undefined) {
            throw new Error('Required parameter partNum was null or undefined when calling usersPartlistsPartsRead.');
        }
        // verify required parameter 'colorId' is not null or undefined
        if (colorId === null || colorId === undefined) {
            throw new Error('Required parameter colorId was null or undefined when calling usersPartlistsPartsRead.');
        }
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersPartlistsPartsRead.');
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Replace an existing Part's details in the Part List.
     * @summary Replace an existing Part's details in the Part List.
     * @param listId
     * @param partNum
     * @param colorId
     * @param userToken
     * @param quantity
     * @param ordering Which field to use when ordering the results.
     * @param {*} [options] Override http request options.
     */
    usersPartlistsPartsUpdate(listId, partNum, colorId, quantity, userToken, ordering, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/partlists/{list_id}/parts/{part_num}/{color_id}/'
            .replace('{list_id}', encodeURIComponent(String(listId)))
            .replace('{part_num}', encodeURIComponent(String(partNum)))
            .replace('{color_id}', encodeURIComponent(String(colorId)))
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarHeaderParams = Object.assign({}, this.defaultHeaders);
        let localVarFormParams = {};
        // verify required parameter 'listId' is not null or undefined
        if (listId === null || listId === undefined) {
            throw new Error('Required parameter listId was null or undefined when calling usersPartlistsPartsUpdate.');
        }
        // verify required parameter 'partNum' is not null or undefined
        if (partNum === null || partNum === undefined) {
            throw new Error('Required parameter partNum was null or undefined when calling usersPartlistsPartsUpdate.');
        }
        // verify required parameter 'colorId' is not null or undefined
        if (colorId === null || colorId === undefined) {
            throw new Error('Required parameter colorId was null or undefined when calling usersPartlistsPartsUpdate.');
        }
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersPartlistsPartsUpdate.');
        }
        // verify required parameter 'quantity' is not null or undefined
        if (quantity === null || quantity === undefined) {
            throw new Error('Required parameter quantity was null or undefined when calling usersPartlistsPartsUpdate.');
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }
        Object.assign(localVarHeaderParams, options.headers);

        if (quantity !== undefined) {
            localVarFormParams['quantity'] = ObjectSerializer.serialize(quantity, "number");
        }

        return RequestSender.put(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Get details about a specific Part List.
     * @summary Get details about a specific Part List.
     * @param listId
     * @param userToken
     * @param {*} [options] Override http request options.
     */
    usersPartlistsRead(listId, userToken, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/partlists/{list_id}/'
            .replace('{list_id}', encodeURIComponent(String(listId)))
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'listId' is not null or undefined
        if (listId === null || listId === undefined) {
            throw new Error('Required parameter listId was null or undefined when calling usersPartlistsRead.');
        }
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersPartlistsRead.');
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Replace an existing Part List's details.
     * @summary Replace an existing Part List's details.
     * @param listId
     * @param userToken
     * @param name
     * @param isBuildable
     * @param numParts
     * @param {*} [options] Override http request options.
     */
/*    usersPartlistsUpdate(listId, userToken, name, isBuildable, numParts, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/partlists/{list_id}/'
            .replace('{list_id}', encodeURIComponent(String(listId)))
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarHeaderParams = Object.assign({}, this.defaultHeaders);
        let localVarFormParams = {};
        // verify required parameter 'listId' is not null or undefined
        if (listId === null || listId === undefined) {
            throw new Error('Required parameter listId was null or undefined when calling usersPartlistsUpdate.');
        }
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersPartlistsUpdate.');
        }
        // verify required parameter 'name' is not null or undefined
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling usersPartlistsUpdate.');
        }
        Object.assign(localVarHeaderParams, options.headers);
        let localVarUseFormData = false;
        if (isBuildable !== undefined) {
            localVarFormParams['is_buildable'] = ObjectSerializer.serialize(isBuildable, "boolean");
        }
        if (name !== undefined) {
            localVarFormParams['name'] = ObjectSerializer.serialize(name, "string");
        }
        if (numParts !== undefined) {
            localVarFormParams['num_parts'] = ObjectSerializer.serialize(numParts, "number");
        }
        let localVarRequestOptions = {
            method: 'PUT',
            qs: localVarQueryParameters,
            headers: localVarHeaderParams,
            uri: localVarPath,
            useQuerystring: this._useQuerystring,
            json: true,
        };
        this.authentications.default.applyToRequest(localVarRequestOptions);
        if (Object.keys(localVarFormParams).length) {
            if (localVarUseFormData) {
                localVarRequestOptions.formData = localVarFormParams;
            }
            else {
                localVarRequestOptions.form = localVarFormParams;
            }
        }
        return new Promise((resolve, reject) => {
            localVarRequest(localVarRequestOptions, (error, response, body) => {
                if (error) {
                    reject(error);
                }
                else {
                    if (response.statusCode && response.statusCode >= 200 && response.statusCode <= 299) {
                        resolve({ response: response, body: body });
                    }
                    else {
                        reject({ response: response, body: body });
                    }
                }
            });
        });
    }*/
    /**
     * Get a list of all the Parts in all the user's Part Lists.  Optional parameter inc_part_details=1 can be used to return additional part fields, the same as for a single part lookup.  ###Set List logic Parts appearing in multiple Part Lists will be listed multiple times.  Optionally, filter by one or more of the below query parameters.
     * @summary Get a list of all the Parts in all the user's Part Lists.
     * @param userToken
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param partNum
     * @param partCatId
     * @param colorId
     * @param ordering Which field to use when ordering the results.
     * @param search A search term.
     * @param {*} [options] Override http request options.
     */
    usersPartsList(userToken, page, pageSize, partNum, partCatId, colorId, ordering, search, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/parts/'
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersPartsList.');
        }
        if (page !== undefined) {
            localVarQueryParameters['page'] = ObjectSerializer.serialize(page, "number");
        }
        if (pageSize !== undefined) {
            localVarQueryParameters['page_size'] = ObjectSerializer.serialize(pageSize, "number");
        }
        if (partNum !== undefined) {
            localVarQueryParameters['part_num'] = ObjectSerializer.serialize(partNum, "string");
        }
        if (partCatId !== undefined) {
            localVarQueryParameters['part_cat_id'] = ObjectSerializer.serialize(partCatId, "number");
        }
        if (colorId !== undefined) {
            localVarQueryParameters['color_id'] = ObjectSerializer.serialize(colorId, "number");
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }
        if (search !== undefined) {
            localVarQueryParameters['search'] = ObjectSerializer.serialize(search, "string");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Get details about a specific user.
     * @summary Get details about a specific user.
     * @param userToken
     * @param {*} [options] Override http request options.
     */
    usersProfileRead(userToken, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/profile/'
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersProfileRead.');
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Add a new Set List.
     * @summary Add a new Set List.
     * @param userToken
     * @param name
     * @param isBuildable
     * @param numSets
     * @param {*} [options] Override http request options.
     */
    /*usersSetlistsCreate(userToken, name, isBuildable, numSets, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/setlists/'
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarHeaderParams = Object.assign({}, this.defaultHeaders);
        let localVarFormParams = {};
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersSetlistsCreate.');
        }
        // verify required parameter 'name' is not null or undefined
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling usersSetlistsCreate.');
        }
        Object.assign(localVarHeaderParams, options.headers);
        let localVarUseFormData = false;
        if (isBuildable !== undefined) {
            localVarFormParams['is_buildable'] = ObjectSerializer.serialize(isBuildable, "boolean");
        }
        if (name !== undefined) {
            localVarFormParams['name'] = ObjectSerializer.serialize(name, "string");
        }
        if (numSets !== undefined) {
            localVarFormParams['num_sets'] = ObjectSerializer.serialize(numSets, "number");
        }
        let localVarRequestOptions = {
            method: 'POST',
            qs: localVarQueryParameters,
            headers: localVarHeaderParams,
            uri: localVarPath,
            useQuerystring: this._useQuerystring,
            json: true,
        };
        this.authentications.default.applyToRequest(localVarRequestOptions);
        if (Object.keys(localVarFormParams).length) {
            if (localVarUseFormData) {
                localVarRequestOptions.formData = localVarFormParams;
            }
            else {
                localVarRequestOptions.form = localVarFormParams;
            }
        }
        return new Promise((resolve, reject) => {
            localVarRequest(localVarRequestOptions, (error, response, body) => {
                if (error) {
                    reject(error);
                }
                else {
                    if (response.statusCode && response.statusCode >= 200 && response.statusCode <= 299) {
                        resolve({ response: response, body: body });
                    }
                    else {
                        reject({ response: response, body: body });
                    }
                }
            });
        });
    }*/
    /**
     * Delete a Set List and all it's Sets.
     * @summary Delete a Set List and all it's Sets.
     * @param listId
     * @param userToken
     * @param {*} [options] Override http request options.
     */
/*    usersSetlistsDelete(listId, userToken, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/setlists/{list_id}/'
            .replace('{list_id}', encodeURIComponent(String(listId)))
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarHeaderParams = Object.assign({}, this.defaultHeaders);
        let localVarFormParams = {};
        // verify required parameter 'listId' is not null or undefined
        if (listId === null || listId === undefined) {
            throw new Error('Required parameter listId was null or undefined when calling usersSetlistsDelete.');
        }
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersSetlistsDelete.');
        }
        Object.assign(localVarHeaderParams, options.headers);
        let localVarUseFormData = false;
        let localVarRequestOptions = {
            method: 'DELETE',
            qs: localVarQueryParameters,
            headers: localVarHeaderParams,
            uri: localVarPath,
            useQuerystring: this._useQuerystring,
            json: true,
        };
        this.authentications.default.applyToRequest(localVarRequestOptions);
        if (Object.keys(localVarFormParams).length) {
            if (localVarUseFormData) {
                localVarRequestOptions.formData = localVarFormParams;
            }
            else {
                localVarRequestOptions.form = localVarFormParams;
            }
        }
        return new Promise((resolve, reject) => {
            localVarRequest(localVarRequestOptions, (error, response, body) => {
                if (error) {
                    reject(error);
                }
                else {
                    if (response.statusCode && response.statusCode >= 200 && response.statusCode <= 299) {
                        resolve({ response: response, body: body });
                    }
                    else {
                        reject({ response: response, body: body });
                    }
                }
            });
        });
    }*/
    /**
     * Get a list of all the user's Set Lists.
     * @summary Get a list of all the user's Set Lists.
     * @param userToken
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param {*} [options] Override http request options.
     */
    usersSetlistsList(userToken, page, pageSize, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/setlists/'
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersSetlistsList.');
        }
        if (page !== undefined) {
            localVarQueryParameters['page'] = ObjectSerializer.serialize(page, "number");
        }
        if (pageSize !== undefined) {
            localVarQueryParameters['page_size'] = ObjectSerializer.serialize(pageSize, "number");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Update an existing Set List's details.
     * @summary Update an existing Set List's details.
     * @param listId
     * @param userToken
     * @param isBuildable
     * @param name
     * @param numSets
     * @param {*} [options] Override http request options.
     */
/*    usersSetlistsPartialUpdate(listId, userToken, isBuildable, name, numSets, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/setlists/{list_id}/'
            .replace('{list_id}', encodeURIComponent(String(listId)))
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarHeaderParams = Object.assign({}, this.defaultHeaders);
        let localVarFormParams = {};
        // verify required parameter 'listId' is not null or undefined
        if (listId === null || listId === undefined) {
            throw new Error('Required parameter listId was null or undefined when calling usersSetlistsPartialUpdate.');
        }
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersSetlistsPartialUpdate.');
        }
        Object.assign(localVarHeaderParams, options.headers);
        let localVarUseFormData = false;
        if (isBuildable !== undefined) {
            localVarFormParams['is_buildable'] = ObjectSerializer.serialize(isBuildable, "boolean");
        }
        if (name !== undefined) {
            localVarFormParams['name'] = ObjectSerializer.serialize(name, "string");
        }
        if (numSets !== undefined) {
            localVarFormParams['num_sets'] = ObjectSerializer.serialize(numSets, "number");
        }
        let localVarRequestOptions = {
            method: 'PATCH',
            qs: localVarQueryParameters,
            headers: localVarHeaderParams,
            uri: localVarPath,
            useQuerystring: this._useQuerystring,
            json: true,
        };
        this.authentications.default.applyToRequest(localVarRequestOptions);
        if (Object.keys(localVarFormParams).length) {
            if (localVarUseFormData) {
                localVarRequestOptions.formData = localVarFormParams;
            }
            else {
                localVarRequestOptions.form = localVarFormParams;
            }
        }
        return new Promise((resolve, reject) => {
            localVarRequest(localVarRequestOptions, (error, response, body) => {
                if (error) {
                    reject(error);
                }
                else {
                    if (response.statusCode && response.statusCode >= 200 && response.statusCode <= 299) {
                        resolve({ response: response, body: body });
                    }
                    else {
                        reject({ response: response, body: body });
                    }
                }
            });
        });
    }*/
    /**
     * Get details about a specific Set List.
     * @summary Get details about a specific Set List.
     * @param listId
     * @param userToken
     * @param {*} [options] Override http request options.
     */
    usersSetlistsRead(listId, userToken, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/setlists/{list_id}/'
            .replace('{list_id}', encodeURIComponent(String(listId)))
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'listId' is not null or undefined
        if (listId === null || listId === undefined) {
            throw new Error('Required parameter listId was null or undefined when calling usersSetlistsRead.');
        }
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersSetlistsRead.');
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Add one or more Sets to the Set List. Existing Sets are unaffected.  ### Single Set When adding a single Set, returns the successfully created Set (status 201) or details for why the Set could not be added. ### Multiple Sets To add multiple Sets, POST a JSON list of them (using a Content-Type header of 'application/json'). e.g: `[{\"set_num\":\"8043-1\", \"quantity\": 1},  {\"set_num\":\"8110-1\", \"quantity\": 2, \"include_spares\": \"False\"}]` Returns a list of successfully added Sets. If the Set already exists or is unrecognised, it will be skipped.
     * @summary Add one or more Sets to the Set List. Existing Sets are unaffected.
     * @param listId
     * @param userToken
     * @param setNum
     * @param quantity
     * @param includeSpares
     * @param {*} [options] Override http request options.
     */
/*    usersSetlistsSetsCreate(listId, userToken, setNum, quantity, includeSpares, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/setlists/{list_id}/sets/'
            .replace('{list_id}', encodeURIComponent(String(listId)))
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarHeaderParams = Object.assign({}, this.defaultHeaders);
        let localVarFormParams = {};
        // verify required parameter 'listId' is not null or undefined
        if (listId === null || listId === undefined) {
            throw new Error('Required parameter listId was null or undefined when calling usersSetlistsSetsCreate.');
        }
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersSetlistsSetsCreate.');
        }
        // verify required parameter 'setNum' is not null or undefined
        if (setNum === null || setNum === undefined) {
            throw new Error('Required parameter setNum was null or undefined when calling usersSetlistsSetsCreate.');
        }
        Object.assign(localVarHeaderParams, options.headers);
        let localVarUseFormData = false;
        if (setNum !== undefined) {
            localVarFormParams['set_num'] = ObjectSerializer.serialize(setNum, "string");
        }
        if (quantity !== undefined) {
            localVarFormParams['quantity'] = ObjectSerializer.serialize(quantity, "number");
        }
        if (includeSpares !== undefined) {
            localVarFormParams['include_spares'] = ObjectSerializer.serialize(includeSpares, "boolean");
        }
        let localVarRequestOptions = {
            method: 'POST',
            qs: localVarQueryParameters,
            headers: localVarHeaderParams,
            uri: localVarPath,
            useQuerystring: this._useQuerystring,
            json: true,
        };
        this.authentications.default.applyToRequest(localVarRequestOptions);
        if (Object.keys(localVarFormParams).length) {
            if (localVarUseFormData) {
                localVarRequestOptions.formData = localVarFormParams;
            }
            else {
                localVarRequestOptions.form = localVarFormParams;
            }
        }
        return new Promise((resolve, reject) => {
            localVarRequest(localVarRequestOptions, (error, response, body) => {
                if (error) {
                    reject(error);
                }
                else {
                    if (response.statusCode && response.statusCode >= 200 && response.statusCode <= 299) {
                        resolve({ response: response, body: body });
                    }
                    else {
                        reject({ response: response, body: body });
                    }
                }
            });
        });
    }*/
    /**
     * Delete a Set from the Set List.
     * @summary Delete a Set from the Set List.
     * @param listId
     * @param setNum
     * @param userToken
     * @param ordering Which field to use when ordering the results.
     * @param {*} [options] Override http request options.
     */
    usersSetlistsSetsDelete(listId, setNum, userToken, ordering, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/setlists/{list_id}/sets/{set_num}/'
            .replace('{list_id}', encodeURIComponent(String(listId)))
            .replace('{set_num}', encodeURIComponent(String(setNum)))
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        // let localVarHeaderParams = Object.assign({}, this.defaultHeaders);
        let localVarFormParams = {};
        // verify required parameter 'listId' is not null or undefined
        if (listId === null || listId === undefined) {
            throw new Error('Required parameter listId was null or undefined when calling usersSetlistsSetsDelete.');
        }
        // verify required parameter 'setNum' is not null or undefined
        if (setNum === null || setNum === undefined) {
            throw new Error('Required parameter setNum was null or undefined when calling usersSetlistsSetsDelete.');
        }
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersSetlistsSetsDelete.');
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }

        return RequestSender.delete(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);

/*        Object.assign(localVarHeaderParams, options.headers);
        let localVarUseFormData = false;
        let localVarRequestOptions = {
            method: 'DELETE',
            qs: localVarQueryParameters,
            headers: localVarHeaderParams,
            uri: localVarPath,
            useQuerystring: this._useQuerystring,
            json: true,
        };
        this.authentications.default.applyToRequest(localVarRequestOptions);
        if (Object.keys(localVarFormParams).length) {
            if (localVarUseFormData) {
                localVarRequestOptions.formData = localVarFormParams;
            }
            else {
                localVarRequestOptions.form = localVarFormParams;
            }
        }
        return new Promise((resolve, reject) => {
            localVarRequest(localVarRequestOptions, (error, response, body) => {
                if (error) {
                    reject(error);
                }
                else {
                    if (response.statusCode && response.statusCode >= 200 && response.statusCode <= 299) {
                        resolve({ response: response, body: body });
                    }
                    else {
                        reject({ response: response, body: body });
                    }
                }
            });
        });*/
    }
    /**
     * Get a list of all the Sets in a specific Set List.
     * @summary Get a list of all the Sets in a specific Set List.
     * @param listId
     * @param userToken
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param ordering Which field to use when ordering the results.
     * @param {*} [options] Override http request options.
     */
    usersSetlistsSetsList(listId, userToken, page, pageSize, ordering, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/setlists/{list_id}/sets/'
            .replace('{list_id}', encodeURIComponent(String(listId)))
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'listId' is not null or undefined
        if (listId === null || listId === undefined) {
            throw new Error('Required parameter listId was null or undefined when calling usersSetlistsSetsList.');
        }
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersSetlistsSetsList.');
        }
        if (page !== undefined) {
            localVarQueryParameters['page'] = ObjectSerializer.serialize(page, "number");
        }
        if (pageSize !== undefined) {
            localVarQueryParameters['page_size'] = ObjectSerializer.serialize(pageSize, "number");
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Update an existing Set's details in the Set List.
     * @summary Update an existing Set's details in the Set List.
     * @param listId
     * @param setNum
     * @param userToken
     * @param quantity
     * @param includeSpares
     * @param ordering Which field to use when ordering the results.
     * @param {*} [options] Override http request options.
     */
/*    usersSetlistsSetsPartialUpdate(listId, setNum, userToken, quantity, includeSpares, ordering, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/setlists/{list_id}/sets/{set_num}/'
            .replace('{list_id}', encodeURIComponent(String(listId)))
            .replace('{set_num}', encodeURIComponent(String(setNum)))
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarHeaderParams = Object.assign({}, this.defaultHeaders);
        let localVarFormParams = {};
        // verify required parameter 'listId' is not null or undefined
        if (listId === null || listId === undefined) {
            throw new Error('Required parameter listId was null or undefined when calling usersSetlistsSetsPartialUpdate.');
        }
        // verify required parameter 'setNum' is not null or undefined
        if (setNum === null || setNum === undefined) {
            throw new Error('Required parameter setNum was null or undefined when calling usersSetlistsSetsPartialUpdate.');
        }
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersSetlistsSetsPartialUpdate.');
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }
        Object.assign(localVarHeaderParams, options.headers);
        let localVarUseFormData = false;
        if (quantity !== undefined) {
            localVarFormParams['quantity'] = ObjectSerializer.serialize(quantity, "number");
        }
        if (includeSpares !== undefined) {
            localVarFormParams['include_spares'] = ObjectSerializer.serialize(includeSpares, "boolean");
        }
        let localVarRequestOptions = {
            method: 'PATCH',
            qs: localVarQueryParameters,
            headers: localVarHeaderParams,
            uri: localVarPath,
            useQuerystring: this._useQuerystring,
            json: true,
        };
        this.authentications.default.applyToRequest(localVarRequestOptions);
        if (Object.keys(localVarFormParams).length) {
            if (localVarUseFormData) {
                localVarRequestOptions.formData = localVarFormParams;
            }
            else {
                localVarRequestOptions.form = localVarFormParams;
            }
        }
        return new Promise((resolve, reject) => {
            localVarRequest(localVarRequestOptions, (error, response, body) => {
                if (error) {
                    reject(error);
                }
                else {
                    if (response.statusCode && response.statusCode >= 200 && response.statusCode <= 299) {
                        resolve({ response: response, body: body });
                    }
                    else {
                        reject({ response: response, body: body });
                    }
                }
            });
        });
    }*/
    /**
     * Get details about a specific Set in the Set List.
     * @summary Get details about a specific Set in the Set List.
     * @param listId
     * @param setNum
     * @param userToken
     * @param ordering Which field to use when ordering the results.
     * @param {*} [options] Override http request options.
     */
    usersSetlistsSetsRead(listId, setNum, userToken, ordering, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/setlists/{list_id}/sets/{set_num}/'
            .replace('{list_id}', encodeURIComponent(String(listId)))
            .replace('{set_num}', encodeURIComponent(String(setNum)))
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'listId' is not null or undefined
        if (listId === null || listId === undefined) {
            throw new Error('Required parameter listId was null or undefined when calling usersSetlistsSetsRead.');
        }
        // verify required parameter 'setNum' is not null or undefined
        if (setNum === null || setNum === undefined) {
            throw new Error('Required parameter setNum was null or undefined when calling usersSetlistsSetsRead.');
        }
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersSetlistsSetsRead.');
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Replace an existing Set's details in the Set List.
     * @summary Replace an existing Set's details in the Set List.
     * @param listId
     * @param setNum
     * @param userToken
     * @param quantity
     * @param includeSpares
     * @param ordering Which field to use when ordering the results.
     * @param {*} [options] Override http request options.
     */
    usersSetlistsSetsUpdate(listId, setNum, quantity, includeSpares, userToken, ordering, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/setlists/{list_id}/sets/{set_num}/'
            .replace('{list_id}', encodeURIComponent(String(listId)))
            .replace('{set_num}', encodeURIComponent(String(setNum)))
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarHeaderParams = Object.assign({}, this.defaultHeaders);
        let localVarFormParams = {};
        // verify required parameter 'listId' is not null or undefined
        if (listId === null || listId === undefined) {
            throw new Error('Required parameter listId was null or undefined when calling usersSetlistsSetsUpdate.');
        }
        // verify required parameter 'setNum' is not null or undefined
        if (setNum === null || setNum === undefined) {
            throw new Error('Required parameter setNum was null or undefined when calling usersSetlistsSetsUpdate.');
        }
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersSetlistsSetsUpdate.');
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }
        Object.assign(localVarHeaderParams, options.headers);
        // let localVarUseFormData = false;
        if (quantity !== undefined) {
            localVarFormParams['quantity'] = ObjectSerializer.serialize(quantity, "number");
        }
        if (includeSpares !== undefined) {
            localVarFormParams['include_spares'] = ObjectSerializer.serialize(includeSpares, "boolean");
        }

        return RequestSender.put(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);

/*        let localVarRequestOptions = {
            method: 'PUT',
            qs: localVarQueryParameters,
            headers: localVarHeaderParams,
            uri: localVarPath,
            useQuerystring: this._useQuerystring,
            json: true,
        };
        this.authentications.default.applyToRequest(localVarRequestOptions);
        if (Object.keys(localVarFormParams).length) {
            if (localVarUseFormData) {
                localVarRequestOptions.formData = localVarFormParams;
            }
            else {
                localVarRequestOptions.form = localVarFormParams;
            }
        }
        return new Promise((resolve, reject) => {
            localVarRequest(localVarRequestOptions, (error, response, body) => {
                if (error) {
                    reject(error);
                }
                else {
                    if (response.statusCode && response.statusCode >= 200 && response.statusCode <= 299) {
                        resolve({ response: response, body: body });
                    }
                    else {
                        reject({ response: response, body: body });
                    }
                }
            });
        });*/
    }
    /**
     * Replace an existing Set List's details.
     * @summary Replace an existing Set List's details.
     * @param listId
     * @param userToken
     * @param name
     * @param isBuildable
     * @param numSets
     * @param {*} [options] Override http request options.
     */
/*    usersSetlistsUpdate(listId, userToken, name, isBuildable, numSets, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/setlists/{list_id}/'
            .replace('{list_id}', encodeURIComponent(String(listId)))
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarHeaderParams = Object.assign({}, this.defaultHeaders);
        let localVarFormParams = {};
        // verify required parameter 'listId' is not null or undefined
        if (listId === null || listId === undefined) {
            throw new Error('Required parameter listId was null or undefined when calling usersSetlistsUpdate.');
        }
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersSetlistsUpdate.');
        }
        // verify required parameter 'name' is not null or undefined
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling usersSetlistsUpdate.');
        }
        Object.assign(localVarHeaderParams, options.headers);
        let localVarUseFormData = false;
        if (isBuildable !== undefined) {
            localVarFormParams['is_buildable'] = ObjectSerializer.serialize(isBuildable, "boolean");
        }
        if (name !== undefined) {
            localVarFormParams['name'] = ObjectSerializer.serialize(name, "string");
        }
        if (numSets !== undefined) {
            localVarFormParams['num_sets'] = ObjectSerializer.serialize(numSets, "number");
        }
        let localVarRequestOptions = {
            method: 'PUT',
            qs: localVarQueryParameters,
            headers: localVarHeaderParams,
            uri: localVarPath,
            useQuerystring: this._useQuerystring,
            json: true,
        };
        this.authentications.default.applyToRequest(localVarRequestOptions);
        if (Object.keys(localVarFormParams).length) {
            if (localVarUseFormData) {
                localVarRequestOptions.formData = localVarFormParams;
            }
            else {
                localVarRequestOptions.form = localVarFormParams;
            }
        }
        return new Promise((resolve, reject) => {
            localVarRequest(localVarRequestOptions, (error, response, body) => {
                if (error) {
                    reject(error);
                }
                else {
                    if (response.statusCode && response.statusCode >= 200 && response.statusCode <= 299) {
                        resolve({ response: response, body: body });
                    }
                    else {
                        reject({ response: response, body: body });
                    }
                }
            });
        });
    }*/
    /**
     * Add one or more Sets to the user's LEGO collection. Existing Sets are unaffected.  ### Set List logic The Set List used when adding sets is chosen in the following order: 1. If no Set Lists exist, one will be created and used 2. User's configured default Set List for Imports 3. The first Set List alphabetically  ### Single Set When adding a single Set, returns the successfully created Set (status 201) or details for why the Set could not be added. ### Multiple Sets To add multiple Sets, POST a JSON list of them (using a Content-Type header of 'application/json'). e.g: `[{\"set_num\":\"8043-1\", \"quantity\": 1},  {\"set_num\":\"8110-1\", \"quantity\": 2, \"include_spares\": \"False\"}]` Returns a list of successfully added Sets. If the Set already exists or is unrecognised, it will be skipped.
     * @summary Add one or more Sets to the user's LEGO collection. Existing Sets are unaffected.
     * @param userToken
     * @param setNum
     * @param quantity
     * @param includeSpares
     * @param {*} [options] Override http request options.
     */
/*    usersSetsCreate(userToken, setNum, quantity, includeSpares, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/sets/'
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarHeaderParams = Object.assign({}, this.defaultHeaders);
        let localVarFormParams = {};
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersSetsCreate.');
        }
        // verify required parameter 'setNum' is not null or undefined
        if (setNum === null || setNum === undefined) {
            throw new Error('Required parameter setNum was null or undefined when calling usersSetsCreate.');
        }
        Object.assign(localVarHeaderParams, options.headers);
        let localVarUseFormData = false;
        if (setNum !== undefined) {
            localVarFormParams['set_num'] = ObjectSerializer.serialize(setNum, "string");
        }
        if (quantity !== undefined) {
            localVarFormParams['quantity'] = ObjectSerializer.serialize(quantity, "number");
        }
        if (includeSpares !== undefined) {
            localVarFormParams['include_spares'] = ObjectSerializer.serialize(includeSpares, "boolean");
        }
        let localVarRequestOptions = {
            method: 'POST',
            qs: localVarQueryParameters,
            headers: localVarHeaderParams,
            uri: localVarPath,
            useQuerystring: this._useQuerystring,
            json: true,
        };
        this.authentications.default.applyToRequest(localVarRequestOptions);
        if (Object.keys(localVarFormParams).length) {
            if (localVarUseFormData) {
                localVarRequestOptions.formData = localVarFormParams;
            }
            else {
                localVarRequestOptions.form = localVarFormParams;
            }
        }
        return new Promise((resolve, reject) => {
            localVarRequest(localVarRequestOptions, (error, response, body) => {
                if (error) {
                    reject(error);
                }
                else {
                    if (response.statusCode && response.statusCode >= 200 && response.statusCode <= 299) {
                        resolve({ response: response, body: body });
                    }
                    else {
                        reject({ response: response, body: body });
                    }
                }
            });
        });
    }*/
    /**
     * Delete the Set from all the user's Set Lists.
     * @summary Delete the Set from all the user's Set Lists.
     * @param setNum
     * @param userToken
     * @param setNum2
     * @param themeId
     * @param minYear
     * @param maxYear
     * @param minParts
     * @param maxParts
     * @param ordering Which field to use when ordering the results.
     * @param search A search term.
     * @param {*} [options] Override http request options.
     */
/*    usersSetsDelete(setNum, userToken, setNum2, themeId, minYear, maxYear, minParts, maxParts, ordering, search, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/sets/{set_num}/'
            .replace('{set_num}', encodeURIComponent(String(setNum)))
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarHeaderParams = Object.assign({}, this.defaultHeaders);
        let localVarFormParams = {};
        // verify required parameter 'setNum' is not null or undefined
        if (setNum === null || setNum === undefined) {
            throw new Error('Required parameter setNum was null or undefined when calling usersSetsDelete.');
        }
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersSetsDelete.');
        }
        if (setNum2 !== undefined) {
            localVarQueryParameters['set_num'] = ObjectSerializer.serialize(setNum2, "string");
        }
        if (themeId !== undefined) {
            localVarQueryParameters['theme_id'] = ObjectSerializer.serialize(themeId, "number");
        }
        if (minYear !== undefined) {
            localVarQueryParameters['min_year'] = ObjectSerializer.serialize(minYear, "number");
        }
        if (maxYear !== undefined) {
            localVarQueryParameters['max_year'] = ObjectSerializer.serialize(maxYear, "number");
        }
        if (minParts !== undefined) {
            localVarQueryParameters['min_parts'] = ObjectSerializer.serialize(minParts, "number");
        }
        if (maxParts !== undefined) {
            localVarQueryParameters['max_parts'] = ObjectSerializer.serialize(maxParts, "number");
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }
        if (search !== undefined) {
            localVarQueryParameters['search'] = ObjectSerializer.serialize(search, "string");
        }
        Object.assign(localVarHeaderParams, options.headers);
        let localVarUseFormData = false;
        let localVarRequestOptions = {
            method: 'DELETE',
            qs: localVarQueryParameters,
            headers: localVarHeaderParams,
            uri: localVarPath,
            useQuerystring: this._useQuerystring,
            json: true,
        };
        this.authentications.default.applyToRequest(localVarRequestOptions);
        if (Object.keys(localVarFormParams).length) {
            if (localVarUseFormData) {
                localVarRequestOptions.formData = localVarFormParams;
            }
            else {
                localVarRequestOptions.form = localVarFormParams;
            }
        }
        return new Promise((resolve, reject) => {
            localVarRequest(localVarRequestOptions, (error, response, body) => {
                if (error) {
                    reject(error);
                }
                else {
                    if (response.statusCode && response.statusCode >= 200 && response.statusCode <= 299) {
                        resolve({ response: response, body: body });
                    }
                    else {
                        reject({ response: response, body: body });
                    }
                }
            });
        });
    }*/
    /**
     * Get a list of all the Sets in the user's LEGO collection.  ### Set List logic Sets appearing in multiple Set Lists will be listed multiple times.  Optionally, filter by one or more of the below query parameters.
     * @summary Get a list of all the Sets in the user's LEGO collection.
     * @param userToken
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param setNum
     * @param themeId
     * @param minYear
     * @param maxYear
     * @param minParts
     * @param maxParts
     * @param ordering Which field to use when ordering the results.
     * @param search A search term.
     * @param {*} [options] Override http request options.
     */
    usersSetsList(userToken, page, pageSize, setNum, themeId, minYear, maxYear, minParts, maxParts, ordering, search, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/sets/'
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersSetsList.');
        }
        if (page !== undefined) {
            localVarQueryParameters['page'] = ObjectSerializer.serialize(page, "number");
        }
        if (pageSize !== undefined) {
            localVarQueryParameters['page_size'] = ObjectSerializer.serialize(pageSize, "number");
        }
        if (setNum !== undefined) {
            localVarQueryParameters['set_num'] = ObjectSerializer.serialize(setNum, "string");
        }
        if (themeId !== undefined) {
            localVarQueryParameters['theme_id'] = ObjectSerializer.serialize(themeId, "number");
        }
        if (minYear !== undefined) {
            localVarQueryParameters['min_year'] = ObjectSerializer.serialize(minYear, "number");
        }
        if (maxYear !== undefined) {
            localVarQueryParameters['max_year'] = ObjectSerializer.serialize(maxYear, "number");
        }
        if (minParts !== undefined) {
            localVarQueryParameters['min_parts'] = ObjectSerializer.serialize(minParts, "number");
        }
        if (maxParts !== undefined) {
            localVarQueryParameters['max_parts'] = ObjectSerializer.serialize(maxParts, "number");
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }
        if (search !== undefined) {
            localVarQueryParameters['search'] = ObjectSerializer.serialize(search, "string");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Get details about a specific Set in the user's LEGO collection.  ### Set List logic Because this merges sets found across all Set Lists the fields list_id and include_spares may not be accurate unless the Set actually only exists in a single Set List.
     * @summary Get details about a specific Set in the user's LEGO collection.
     * @param setNum
     * @param userToken
     * @param setNum2
     * @param themeId
     * @param minYear
     * @param maxYear
     * @param minParts
     * @param maxParts
     * @param ordering Which field to use when ordering the results.
     * @param search A search term.
     * @param {*} [options] Override http request options.
     */
    usersSetsRead(setNum, userToken, setNum2, themeId, minYear, maxYear, minParts, maxParts, ordering, search, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/sets/{set_num}/'
            .replace('{set_num}', encodeURIComponent(String(setNum)))
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarFormParams = {};
        // verify required parameter 'setNum' is not null or undefined
        if (setNum === null || setNum === undefined) {
            throw new Error('Required parameter setNum was null or undefined when calling usersSetsRead.');
        }
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersSetsRead.');
        }
        if (setNum2 !== undefined) {
            localVarQueryParameters['set_num'] = ObjectSerializer.serialize(setNum2, "string");
        }
        if (themeId !== undefined) {
            localVarQueryParameters['theme_id'] = ObjectSerializer.serialize(themeId, "number");
        }
        if (minYear !== undefined) {
            localVarQueryParameters['min_year'] = ObjectSerializer.serialize(minYear, "number");
        }
        if (maxYear !== undefined) {
            localVarQueryParameters['max_year'] = ObjectSerializer.serialize(maxYear, "number");
        }
        if (minParts !== undefined) {
            localVarQueryParameters['min_parts'] = ObjectSerializer.serialize(minParts, "number");
        }
        if (maxParts !== undefined) {
            localVarQueryParameters['max_parts'] = ObjectSerializer.serialize(maxParts, "number");
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }
        if (search !== undefined) {
            localVarQueryParameters['search'] = ObjectSerializer.serialize(search, "string");
        }

        return RequestSender.get(options.headers, localVarQueryParameters, localVarFormParams, localVarPath, this._useQuerystring, this.authentications.default);
    }
    /**
     * Synchronise a user's Sets to the POSTed list.  ### Set List logic This is used to completely replace the user's Sets with those in the supplied list. It will remove any Sets in Rebrickable that are not found in the supplied list. It will attempt to keep any current Rebrickable Sets in their existing Set Lists, and will add any new Sets found into the Default Set List for Imports in the user's settings. ### Single Set When adding a single Set, returns the successfully created Set (status 201) or details for why the Set could not be added. ### Multiple Sets To add multiple Sets, POST a JSON list of them (using a Content-Type header of 'application/json'). e.g: `[{\"set_num\":\"8043-1\", \"quantity\": 1},  {\"set_num\":\"8110-1\", \"quantity\": 2, \"include_spares\": \"False\"}]` Returns a list of successfully added Sets. If the Set is unrecognised, it will be skipped.
     * @summary Synchronise a user's Sets to the POSTed list.
     * @param userToken
     * @param setNum
     * @param quantity
     * @param includeSpares
     * @param {*} [options] Override http request options.
     */
/*    usersSetsSyncCreate(userToken, setNum, quantity, includeSpares, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/sets/sync/'
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarHeaderParams = Object.assign({}, this.defaultHeaders);
        let localVarFormParams = {};
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersSetsSyncCreate.');
        }
        // verify required parameter 'setNum' is not null or undefined
        if (setNum === null || setNum === undefined) {
            throw new Error('Required parameter setNum was null or undefined when calling usersSetsSyncCreate.');
        }
        Object.assign(localVarHeaderParams, options.headers);
        let localVarUseFormData = false;
        if (quantity !== undefined) {
            localVarFormParams['quantity'] = ObjectSerializer.serialize(quantity, "number");
        }
        if (includeSpares !== undefined) {
            localVarFormParams['include_spares'] = ObjectSerializer.serialize(includeSpares, "boolean");
        }
        if (setNum !== undefined) {
            localVarFormParams['set_num'] = ObjectSerializer.serialize(setNum, "string");
        }
        let localVarRequestOptions = {
            method: 'POST',
            qs: localVarQueryParameters,
            headers: localVarHeaderParams,
            uri: localVarPath,
            useQuerystring: this._useQuerystring,
            json: true,
        };
        this.authentications.default.applyToRequest(localVarRequestOptions);
        if (Object.keys(localVarFormParams).length) {
            if (localVarUseFormData) {
                localVarRequestOptions.formData = localVarFormParams;
            }
            else {
                localVarRequestOptions.form = localVarFormParams;
            }
        }
        return new Promise((resolve, reject) => {
            localVarRequest(localVarRequestOptions, (error, response, body) => {
                if (error) {
                    reject(error);
                }
                else {
                    if (response.statusCode && response.statusCode >= 200 && response.statusCode <= 299) {
                        resolve({ response: response, body: body });
                    }
                    else {
                        reject({ response: response, body: body });
                    }
                }
            });
        });
    }*/
    /**
     * Update an existing Set's quantity in all Set Lists. This PUT call is different to others in that it will create the Set if it doesn't already exist, and it will delete the Set if you pass a quantity of 0.  ### Set List logic * Default Set List = user's configured default import list or the first alphabetically if none exist. * Increasing quantity = add to Set in default Set List if it exists, else add it there * Decreasing quantity = remove from Set in default Set List first, then from remaining lists until done
     * @summary Update an existing Set's quantity in all Set Lists. This PUT call is different to others in that it will create
     * @param setNum
     * @param userToken
     * @param quantity
     * @param setNum2
     * @param themeId
     * @param minYear
     * @param maxYear
     * @param minParts
     * @param maxParts
     * @param ordering Which field to use when ordering the results.
     * @param search A search term.
     * @param {*} [options] Override http request options.
     */
/*    usersSetsUpdate(setNum, userToken, quantity, setNum2, themeId, minYear, maxYear, minParts, maxParts, ordering, search, options = {}) {
      // verify required parameter 'userToken' is not null or undefined
      if (userToken === null || userToken === undefined) {
          userToken = this._userToken;
      }
        const localVarPath = this.basePath + '/api/v3/users/{user_token}/sets/{set_num}/'
            .replace('{set_num}', encodeURIComponent(String(setNum)))
            .replace('{user_token}', encodeURIComponent(String(userToken)));
        let localVarQueryParameters = {};
        let localVarHeaderParams = Object.assign({}, this.defaultHeaders);
        let localVarFormParams = {};
        // verify required parameter 'setNum' is not null or undefined
        if (setNum === null || setNum === undefined) {
            throw new Error('Required parameter setNum was null or undefined when calling usersSetsUpdate.');
        }
        // verify required parameter 'userToken' is not null or undefined
        if (userToken === null || userToken === undefined) {
            throw new Error('Required parameter userToken was null or undefined when calling usersSetsUpdate.');
        }
        if (setNum2 !== undefined) {
            localVarQueryParameters['set_num'] = ObjectSerializer.serialize(setNum2, "string");
        }
        if (themeId !== undefined) {
            localVarQueryParameters['theme_id'] = ObjectSerializer.serialize(themeId, "number");
        }
        if (minYear !== undefined) {
            localVarQueryParameters['min_year'] = ObjectSerializer.serialize(minYear, "number");
        }
        if (maxYear !== undefined) {
            localVarQueryParameters['max_year'] = ObjectSerializer.serialize(maxYear, "number");
        }
        if (minParts !== undefined) {
            localVarQueryParameters['min_parts'] = ObjectSerializer.serialize(minParts, "number");
        }
        if (maxParts !== undefined) {
            localVarQueryParameters['max_parts'] = ObjectSerializer.serialize(maxParts, "number");
        }
        if (ordering !== undefined) {
            localVarQueryParameters['ordering'] = ObjectSerializer.serialize(ordering, "string");
        }
        if (search !== undefined) {
            localVarQueryParameters['search'] = ObjectSerializer.serialize(search, "string");
        }
        Object.assign(localVarHeaderParams, options.headers);
        let localVarUseFormData = false;
        if (quantity !== undefined) {
            localVarFormParams['quantity'] = ObjectSerializer.serialize(quantity, "number");
        }
        let localVarRequestOptions = {
            method: 'PUT',
            qs: localVarQueryParameters,
            headers: localVarHeaderParams,
            uri: localVarPath,
            useQuerystring: this._useQuerystring,
            json: true,
        };
        this.authentications.default.applyToRequest(localVarRequestOptions);
        if (Object.keys(localVarFormParams).length) {
            if (localVarUseFormData) {
                localVarRequestOptions.formData = localVarFormParams;
            }
            else {
                localVarRequestOptions.form = localVarFormParams;
            }
        }
        return new Promise((resolve, reject) => {
            localVarRequest(localVarRequestOptions, (error, response, body) => {
                if (error) {
                    reject(error);
                }
                else {
                    if (response.statusCode && response.statusCode >= 200 && response.statusCode <= 299) {
                        resolve({ response: response, body: body });
                    }
                    else {
                        reject({ response: response, body: body });
                    }
                }
            });
        });
    }*/
    /**
     * Generate a User Token to be used for authorising user account actions in subsequent calls. Username can be either  the actual username or the user's email address.
     * @summary Generate a User Token to be used for authorising user account actions in subsequent calls. Username can be either
     * @param username Username (or email) of user
     * @param password Password of user
     * @param {*} [options] Override http request options.
     */
/*    usersTokenCreate(username, password, options = {}) {
        const localVarPath = this.basePath + '/api/v3/users/_token/';
        let localVarQueryParameters = {};
        let localVarHeaderParams = Object.assign({}, this.defaultHeaders);
        let localVarFormParams = {};
        // verify required parameter 'username' is not null or undefined
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling usersTokenCreate.');
        }
        // verify required parameter 'password' is not null or undefined
        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling usersTokenCreate.');
        }
        Object.assign(localVarHeaderParams, options.headers);
        let localVarUseFormData = false;
        if (username !== undefined) {
            localVarFormParams['username'] = ObjectSerializer.serialize(username, "string");
        }
        if (password !== undefined) {
            localVarFormParams['password'] = ObjectSerializer.serialize(password, "string");
        }
        let localVarRequestOptions = {
            method: 'POST',
            qs: localVarQueryParameters,
            headers: localVarHeaderParams,
            uri: localVarPath,
            useQuerystring: this._useQuerystring,
            json: true,
        };
        this.authentications.default.applyToRequest(localVarRequestOptions);
        if (Object.keys(localVarFormParams).length) {
            if (localVarUseFormData) {
                localVarRequestOptions.formData = localVarFormParams;
            }
            else {
                localVarRequestOptions.form = localVarFormParams;
            }
        }
        return new Promise((resolve, reject) => {
            localVarRequest(localVarRequestOptions, (error, response, body) => {
                if (error) {
                    reject(error);
                }
                else {
                    if (response.statusCode && response.statusCode >= 200 && response.statusCode <= 299) {
                        resolve({ response: response, body: body });
                    }
                    else {
                        reject({ response: response, body: body });
                    }
                }
            });
        });
    }*/
}
