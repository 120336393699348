import {Request} from '../request';

/**
 * Represents a color object
 */
export class OrderItem {
  /**
   * Create a new instance of a color
   * @param {object} [data] API response data.
   * @param {number} [data.color_id=0] The color id. i.e. - 10
   * @param {string} [data.color_name] The color name. i.e. - Dark Gray
   * @param {string} [data.color_code] The HTML HEX associated to the color. i.e. - 6b5a5a
   * @param {string} [data.color_type] A group that the color belongs to. i.e. - Solid, Transparent, etc...
   */
  constructor(data) {
    data = data || {};

    this.inventory_id  = data.inventory_id  || null;
    this.item  = data.item  || {};
    this.item.no  = data.item.no  || null;
    this.item.name  = data.item.name  || null;
    this.item.type  = data.item.type  || null;
    this.item.category_id  = data.item.category_id  || null;
    this.color_id  = data.color_id  || null;
    this.color_name  = data.color_name  || null;
    this.quantity  = data.quantity  || null;
    this.new_or_used  = data.new_or_used  || null;
    this.completeness  = data.completeness  || null;
    this.unit_price  = data.unit_price  || null;
    this.unit_price_final  = data.unit_price_final  || null;
    this.disp_unit_price  = data.disp_unit_price  || null;
    this.disp_unit_price_final  = data.disp_unit_price_final  || null;
    this.currency_code  = data.currency_code  || null;
    this.disp_currency_code  = data.disp_currency_code  || null;
    this.remarks  = data.remarks  || null;
    this.description  = data.description  || null;
    this.weight  = data.weight  || null;
  }

  /**
   * Get a list of all colors
   *
   * Usage:
   *
   * ```
   * var req = Color.all();
   * client.send(req).then(colors => console.log(colors));
   * ```
   *
   * @return {Request} A request that resolves to an array of {@link Color}.
   */
  static all(orderId) {
    let method = Request.GET;
    let uri = `/orders/${orderId}/items`;

    return new Request(method, uri, null, data => data.map(d => new OrderItem(d)));
  }
}
