import React, { Component } from "react";
import { withAuthenticator } from '@aws-amplify/ui-react';

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'

import SetItem from './SetItem'
import SetItemList from './SetItemList'

import RestApi from '../components/restapi'
import { getCategories } from "../components/loaders"
import { getAssemblyParts } from "../components/assemblies"

// import { firstBy } from "thenby";

var CryptoJS = require("crypto-js");
var Promise = require("bluebird");
// var bl = require('../components/bl');

// const recalcPriceGuide = (res) => {
//   res.price_detail = res.price_detail.map(d => {
//     d.unit_price = parseFloat(d.unit_price);
//     return d;
//   })
//   console.log(res);
//
//   res.price_detail = res.price_detail.sort( function(a, b) {
//     return a.unit_price - b.unit_price;
//   });
//
//   const pdRaw = res.price_detail.concat();
//
//   const values = pdRaw.map(pd => {
//     return pd.unit_price;
//   });
//
//   console.log(values);
//
//   /* Then find a generous IQR. This is generous because if (values.length / 4)
//    * is not an int, then really you should average the two elements on either
//    * side to find q1.
//    */
//   const idxQ1 = Math.floor(res.total_quantity / 4);
//   const idxQ3 = Math.floor(res.total_quantity * (3 / 4));
//   console.log(idxQ1);
//   console.log(idxQ3);
//
//   var q1q = 0;
//   var q3q = 0;
//   var runIdx = 0;
//
//   pdRaw.forEach(p => {
//     runIdx += p.quantity;
//     if (q1q === 0 && runIdx >= idxQ1) {
//       q1q = p.unit_price;
//     }
//     if (q3q === 0 && runIdx >= idxQ3) {
//       q3q = p.unit_price;
//     }
//   })
//
//   console.log(q1q);
//   console.log(q3q);
//   const iqrq = q3q - q1q;
//   // Then find min and max values
//   const maxValueq = q3q + iqrq*1.5;
//   const minValueq = q1q - iqrq*1.5;
//   console.log(minValueq);
//   console.log(maxValueq);
//
//   const filteredq = pdRaw.filter(v => v.unit_price > minValueq && v.unit_price < maxValueq);
//   const countq = filteredq.length ? filteredq.reduce((a, b) => a + b.quantity, 0) : 0;
//   const averageq = countq ? filteredq.reduce((a, b) => a + (b.unit_price * b.quantity), 0) / countq : 0;
//   const average = filteredq.length ? filteredq.reduce((a, b) => a + b.unit_price, 0) / filteredq.length : 0;
//
//   const prices = pdRaw.map(d => d.unit_price);
//
//   res.unit_quantity = filteredq.length;
//   res.total_quantity = countq;
//   res.avg_price = average;
//   res.qty_avg_price = averageq;
//   res.min_price = res.unit_quantity ? Math.min(...prices) : 0;
//   res.max_price = res.unit_quantity ? Math.max(...prices) : 0;
//   res.price_detail = filteredq;
//
//   console.log(res);
//   return res;
// };

class Sets extends Component {
  constructor(props) {
    super(props);

    this.sscreds = sessionStorage.getItem('btg.apiCreds');
    if (this.sscreds) {
      // Decrypt
      var bytes  = CryptoJS.AES.decrypt(this.sscreds, props.user.attributes.sub);
      this.creds = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }

    this.state = {
      selectedList: 1,
      setYear: new Date().getFullYear() - 3,
      missingSets: [],
      ignoredSets: [],
    }

  }

  componentDidMount() {
    // this.test();
    getCategories()
    .then(res => { this.categories = res; })
    .then(res => {
      return this.refreshAssemblies()
    })
  }

  // test() {
  //   console.log("hello");
  //
  //   var blClient = new bl.Client(this.sscreds);
  //
  //   const paramsR = {
  //     color_id: null,
  //     new_or_used: "U",
  //     // region: 'eu',
  //     country_code: 'NL',
  //     currency_code: 'EUR',
  //     guide_type: 'stock',
  //     vat: 'Y',
  //   }
  //
  //   var pgR = bl.PriceGuide.get("SET", "42043-1", paramsR)
  //
  //   return blClient.send(pgR)
  //   .then(rRes => {
  //     recalcPriceGuide(rRes.data);
  //   })
  // }



  refreshAssemblies() {
    const mprom = this.loadMissingSets(this.state.setYear);
    return this.loadIgnoredSets()
    .then(res => {
      return mprom;
    })
  }

  loadMissingSets(year) {
    return RestApi.getMissingAssemblies(this.props.activeStore.storeId, year)
    .then(res => {
      const sets = res.data.map(item => {
        const cat = this.categories.find(l => l.cId === item.categoryid)
        if (cat) {
          item.category = cat.cName;
        }
        return new SetItem(item);
      })
      this.setState({ missingSets: sets });
      // console.log(this.state.missingSets);
    })
  }

  loadIgnoredSets() {
    return RestApi.getIgnoredAssembly(this.props.activeStore.storeId)
    .then(res => {
      const sets = res.data.map(item => {
        const cat = this.categories.find(l => l.cId === item.categoryid)
        if (cat) {
          item.category = cat.cName;
        }
        return new SetItem(item);
      })

      this.setState({ ignoredSets: sets });
      // console.log(this.state.ignoredSets);
    })
  }

  handleSelectList = async (e) => {
    const selectedList = parseInt(e.target.id);
    const ids = this.state.selectedList === 1 ? this.state.missingSets.filter(item => item.chk).map(i => i.k) : this.state.ignoredSets.filter(item => item.chk).map(i => i.k)
    this.handleSelectionChange(null, ids, false)
    // if (!this.state.listItems[selectedList]) this.refreshListItems(selectedList);
    this.setState({ selectedList: selectedList });
  }

  handleSelectionChange = (id, ids, checked) => {
    this.setState(state => {
      var listItems = this.state.selectedList === 1 ? this.state.missingSets || [] : this.state.ignoredSets || [];

      var affectedItems = listItems.filter(i => ids.includes(i.k))
      affectedItems.forEach(i => { i.chk = checked; });
      return {
        missingSets: state.missingSets,
        ignoredSets: state.ignoredSets
      };
    })
  }

  handleBatchCreate = async (e) => {
    var data = this.state.missingSets;
    data = data.filter(item => item.chk === true)

    const creds = this.sscreds
    return Promise.map(data, function(item) {
      // console.log(item);
      return getAssemblyParts(creds, item.itemno, 3, 0);
    }, { concurrency: 5 })
    .then(res => {
      return this.loadMissingSets(this.state.setYear);
    })
  }

  handleBatchIgnore = async (e) => {
    var data = this.state.missingSets;
    data = data.filter(item => item.chk === true)

    const storeId = this.props.activeStore.storeId;
    return Promise.map(data, function(item) {
      return RestApi.setIgnoredAssembly(storeId, item.itemno, 3, "Batch Ignore")
      .then(res => {
        return RestApi.deleteAssembly(item.itemno, 3);
      })
    }, { concurrency: 5 })
    .then(res => {
      return this.refreshAssemblies();
    })
  }

  handleBatchRestore = async (e) => {
    var data = this.state.ignoredSets;
    data = data.filter(item => item.chk === true)
    // console.log(data);

    const creds = this.sscreds
    const storeId = this.props.activeStore.storeId;
    return Promise.map(data, function(item) {
      // console.log(item);
      return RestApi.deleteIgnoredAssembly(storeId, item.itemno, 3)
      .then(res => {
        return getAssemblyParts(creds, item.itemno, 3, 0);
      })
    }, { concurrency: 5 })
    .then(res => {
      return this.refreshAssemblies();
    })
  }

  renderListItems() {
    const haveCreds = this.sscreds;

    var listItems = [];
    const setActions = [];

    if (this.state.selectedList === 1) {
      listItems = this.state.missingSets || [];

      setActions.push({ label: 'Create', cb: this.handleBatchCreate, enabled: haveCreds })
      setActions.push({ label: 'Ignore', cb: this.handleBatchIgnore, enabled: haveCreds })
    } else {
      listItems = this.state.ignoredSets || [];
      setActions.push({ label: 'Restore', cb: this.handleBatchRestore, enabled: haveCreds })
    }

    const sortKeys = {
      itemno: 'Set Number',
      name: 'Name',
      category: 'Category',
      yearreleased: 'Year',
      weight: 'Weight',
    }

    return (
      <SetItemList id={this.state.selectedList} format="missingSets" data={listItems}
      sortKeys={sortKeys} onChange={this.handleSelectionChange}
      onItemChange={this.handleItemChange} actions={setActions} />
    )
  }

  renderLists() {
    return (
      <>
      <Table striped bordered hover size="sm">
        <thead>
          <tr><th>Sets</th></tr>
        </thead>
        <tbody>
          <tr key={1}>
            <td><Button variant="link" size="sm" id={1}
            onClick={this.handleSelectList}>Missing ({this.state.missingSets.length})</Button></td>
          </tr>
          <tr key={2}>
            <td><Button variant="link" size="sm" id={2}
            onClick={this.handleSelectList}>Ignored ({this.state.ignoredSets.length})</Button></td>
          </tr>

        </tbody>
      </Table>
      </>
    )
  }

  render() {
    return (
      <Container className='Home'>
        <Row>
          <Col></Col>
          <Col md="auto" className="text-right">{this.state.selectedList === 1 ? "Missing" : "Ignored" }</Col>
        </Row>
        <Row>
          <Col md="auto">
            {this.renderLists()}
          </Col>
          <Col>
            {this.renderListItems()}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withAuthenticator(Sets)
