import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { withAuthenticator } from '@aws-amplify/ui-react';

import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
// import Nav from 'react-bootstrap/Nav'
// import Row from 'react-bootstrap/Row'

// import { UsersApi } from '../components/rbApi'
// var bl = require('../components/bl');

var CryptoJS = require("crypto-js");

class EncryptCredentials extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // isLoading: false,
      ckey: '',
      csecret: '',
      tkey: '',
      tsecret: '',
      uname: '',
      // error: false,
      // success: false,
      // rerror: false,
      // rsuccess: false,
      // ciphertext: sscreds,
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    const creds = {
      bl: {
        consumerKey: this.state.ckey,
        consumerSecret: this.state.csecret,
        tokenKey: this.state.tkey,
        tokenSecret: this.state.tsecret,
      },
    }

    console.log(creds);

    // Encrypt
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(creds), this.state.uname).toString();
    console.log(ciphertext);
    this.setState({ciphertext: ciphertext})
  }

  validateForm() {
    return  this.state.ckey.length > 0 &&
            this.state.csecret.length > 0 &&
            this.state.tkey.length > 0 &&
            this.state.tsecret.length > 0 &&
            this.state.uname.length > 0 ;
  }

  render() {
    return (
      <Container>
      <h4>Encrypt Credentials</h4>
      <p>This form can be used to generate an encrypted cipher text for use with
      the BLProxy. It performs no validation.</p>
      <Form onSubmit={this.handleCtextSubmit}>
          <Form.Group controlId="ciphertext">
            <Form.Label>Encrypted Credentials</Form.Label>
              <br /><textarea value={this.state.ciphertext} />
          </Form.Group>
      </Form>
      <br />
      <p>Enter your API credentials here. </p>
      <Form onSubmit={this.handleSubmit}>
        <Form.Row>
          <Button variant="link" href='https://www.bricklink.com/v2/api/register_consumer.page' target='blank'>Bricklink</Button>
        </Form.Row>
        <Form.Row>
        <Form.Group controlId="uname">
          <Form.Label>Username</Form.Label>
          <Form.Control placeholder='Username'
            onChange={this.handleChange} value={this.state.uname} />
        </Form.Group>
        <Form.Group controlId="ckey">
          <Form.Label>Consumer Key</Form.Label>
          <Form.Control placeholder='Consumer Key'
            onChange={this.handleChange} value={this.state.ckey} />
        </Form.Group>
          <Form.Group controlId="csecret">
            <Form.Label>Consumer Secret</Form.Label>
            <Form.Control type='password' placeholder='Consumer Secret'
              onChange={this.handleChange} value={this.state.csecret} />
          </Form.Group>
          <Form.Group controlId="tkey">
            <Form.Label>Token Value</Form.Label>
            <Form.Control placeholder='Token Value'
              onChange={this.handleChange} value={this.state.tkey} />
          </Form.Group>
          <Form.Group controlId="tsecret">
            <Form.Label>Token Secret</Form.Label>
            <Form.Control type='password' placeholder='Token Secret'
              onChange={this.handleChange} value={this.state.tsecret} />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Button disabled={!this.validateForm()} onClick={this.handleSubmit}>Encrypt Credentials</Button>
        </Form.Row>
      </Form>
      </Container>
    );
  }
}

export default withAuthenticator(EncryptCredentials)
