import React, { Component } from "react";
import { withAuthenticator } from '@aws-amplify/ui-react';

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import ToggleButton from 'react-bootstrap/ToggleButton'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'

import RestApi from '../components/restapi'
import StockItem, { StockItemSaleCheckList } from './StockItem'
import StockItemList from './StockItemList'
import { blTypeMap, getKeyByVal } from "../components/util"
import { pgNeedsUpdate, applyPriceGuide, autoPriceMap, reCalcAutoPrice } from "../components/autoPrice"
import { cleanUpLoaders, getCategories, getColors, getRbLists, getStockLists, getSaleTemplates } from "../components/loaders"

import { firstBy } from "thenby";

var bl = require('../components/bl');
var Promise = require("bluebird");

class Stock extends Component {
  constructor(props) {
    super(props);

    // localStorage.removeItem('btg.stock');
    cleanUpLoaders();

    this.sscreds = sessionStorage.getItem('btg.apiCreds');

    // const stock = JSON.parse(localStorage.getItem('btg.stock'));

    this.state = {
      // stock: stock || [],
      stock: [],
      showForSale: ['fs', 'nfs'],
      showByList: [], //this.lists ? this.lists.filter(l => l.isstock).map(l => l.rlId) : [],
      showByType: Object.keys(blTypeMap).map(key => blTypeMap[key]),
      showByCond: ['N', 'U', 'ns'],
      showByComp: ['S', 'C', 'B', 'ns'],
      showBatchEdit: false,
    }
  }

  componentDidMount() {
    getRbLists(this.props.activeStore.storeId)
    .then(res => {
      this.lists = res;
      return getStockLists(this.props.activeStore.storeId)
    })
    .then(res => {
      this.lists = this.lists.concat(res.map(l => {
        return {
          rlId: l.slId,
          rlName: l.slName,
          wId: l.wId,
          isstock: l.wId > 0 ? 1 : 0
        }
      }));
      // console.log(this.lists);
      const showLists = []; //this.lists.filter(l => l.isstock).map(l => l.rlId)
      this.setState({ showByList: showLists })
    })
    .then(res => { return getColors(); })
    .then(res => { this.colors = res; })
    .then(res => { return getCategories(); })
    .then(res => { this.categories = res; })
    .then(res => { return this.refreshStock(); })

    getSaleTemplates(this.props.activeStore.storeId)
    .then(res => {
      this.sTemplates = res;
    })
  }

  getAllStock(storeId, stockId) {
    return RestApi.getStock(this.props.activeStore.storeId, stockId)
    .then(res => {
      var lastItem = res.data[res.data.length - 1]
      if (res.data.length === 6000) {
        // console.log(lastItem);
        return this.getAllStock(storeId, lastItem.sId)
        .then(r => {
          res.data = res.data.concat(r.data);
          // console.log(r.data.length);
          // console.log(res.data.length);
          return res;
        })
      } else {
        return res;
      }
    })
  }

  refreshStock() {
    // const stockNeedsRefresh = JSON.parse(localStorage.getItem('btg.stockNeedsRefresh'))
    // if (!stockNeedsRefresh && this.state.stock.length) return Promise.resolve();

    // return RestApi.getStock(this.props.activeStore.storeId)
    return this.getAllStock(this.props.activeStore.storeId)
    .then(res => {
      console.log(res.data.length);
      const stock = res.data.map(item => {
        const list = this.lists.find(l => l.rlId === item.rlId)
        if (list) item.rlName = list.rlName;
        const color = this.colors.find(l => l.colorid === item.sCid)
        if (color) {
          item.sCname = color.name;
          item.sCcode = color.code;
        }
        const cat = this.categories.find(l => l.cId === item.sCat)
        if (cat) {
          item.sCatN = cat.cName;
        }
        return new StockItem(item);
      })

      // localStorage.setItem('btg.stock', JSON.stringify(stock));
      // localStorage.setItem('btg.stockNeedsRefresh', JSON.stringify(false));
      this.setState({ stock: stock })

      // const s = localStorage.getItem('btg.stock')
      // console.log(s.length);

      return res;
    })
  }

  filterStock() {
    var res = this.state.stock;
    if (this.state.whenNAR)
      res = res.filter(i => (i.sQfs > 0));
    if (this.state.whenAR)
      res = res.filter(i => (i.sQfs === 0));
    if (this.state.whenSUBL)
      res = res.filter(i => i.sUBL || (i.sAPv && (i.sAPv !== i.sP)));
    if (this.state.whenNoQtyDiff)
      res = res.filter(i => (i.sQfs === i.iQ));
    if (this.state.whenAPvNotSp)
      res = res.filter(i => i.sAPv && (i.sAPv !== i.sP));
    if (this.state.whenApLimited)
      res = res.filter(i => i.sAPv && (i.sAPv === i.sMmin || i.sAPv === i.sMmax) );
    if (this.state.whenPGEmpty)
      res = res.filter(i => i.sLUPG && !i.sPGRtq );
    res = res.filter(i => (this.state.showByList.length === 0 && !i.rlId) || this.state.showByList.includes(i.rlId));
    res = res.filter(i => this.state.showByType.includes(i.sType));
    res = res.filter(i => this.state.showByCond.includes(i.sNU) ||
                            (this.state.showByCond.includes('ns') && !i.sNU));
    res = res.filter(i => this.state.showByComp.includes(i.sSCI) ||
                            (this.state.showByComp.includes('ns') && !i.sSCI));
    res = res.filter(i => ((this.state.showForSale.includes('nfs') && i.sFS !== 1) ||
                            (this.state.showForSale.includes('fs') && i.sFS === 1)));
    return res;
  }

  unselectAll() {
    this.handleSelectionChange(null, this.state.stock.filter(item => item.chk).map(i => i.sId), false);
  }

  handleChange = event => {
    if (!event.target.validity.valid) return;

    const tid = event.target.id || event.target.name
    var val = event.target.value;
    if (event.target.type === 'checkbox') val = event.target.checked;

    this.setState({
      [tid]: val
    });
  }

  handleSelectionChange = (id, ids, checked) => {
    this.setState(state => {
      var affectedItems = state.stock.filter(i => ids.includes(i.k))
      affectedItems.forEach(i => { i.chk = checked; });
      return { stock: state.stock };
    })
  }

  handleUpdatePG = async (e) => {
    var data = this.state.stock;
    data = data.filter(item => item.chk === true && item.sNU && pgNeedsUpdate(item))

    var blClient = new bl.Client(this.sscreds);

    const storeId = this.props.activeStore.storeId
    // const self = this;
    return Promise.map(data, function(item) {
      const paramsR = {
        color_id: item.sCid,
        new_or_used: item.sNU,
        region: 'eu',
        currency_code: 'EUR',
        guide_type: 'sold',
        vat: 'Y',
      }

      const paramsC = {
        color_id: item.sCid,
        new_or_used: item.sNU,
        country_code: 'NL',
        currency_code: 'EUR',
        guide_type: 'sold',
        vat: 'Y',
      }

      const paramsRS = {
        color_id: item.sCid,
        new_or_used: item.sNU,
        region: 'eu',
        currency_code: 'EUR',
        guide_type: 'stock',
        vat: 'Y',
      }

      const paramsCS = {
        color_id: item.sCid,
        new_or_used: item.sNU,
        country_code: 'NL',
        currency_code: 'EUR',
        guide_type: 'stock',
        vat: 'Y',
      }

      var pgR = bl.PriceGuide.get(getKeyByVal(blTypeMap, item.sType), item.sNo, paramsR)
      var pgC = bl.PriceGuide.get(getKeyByVal(blTypeMap, item.sType), item.sNo, paramsC)
      var pgRS = bl.PriceGuide.get(getKeyByVal(blTypeMap, item.sType), item.sNo, paramsRS)
      var pgCS = bl.PriceGuide.get(getKeyByVal(blTypeMap, item.sType), item.sNo, paramsCS)

      return blClient.send(pgR)
      .then(rRes => {
        return blClient.send(pgC)
        .then(cRes => {
          return blClient.send(pgRS)
          .then(rsRes => {
            return blClient.send(pgCS)
            .then(csRes => {
              applyPriceGuide(item, cRes, rRes, csRes, rsRes);
              return item;
            })
          })
        })
      })
      .then(res => {
        return RestApi.setStockPG(
          storeId,
          item.sId,
          item.sPGCmin,
          item.sPGCmax,
          item.sPGCavg,
          item.sPGCqavg,
          item.sPGCuq,
          item.sPGCtq,
          item.sPGRmin,
          item.sPGRmax,
          item.sPGRavg,
          item.sPGRqavg,
          item.sPGRuq,
          item.sPGRtq,
          item.sPGCSmin,
          item.sPGCSmax,
          item.sPGCSavg,
          item.sPGCSqavg,
          item.sPGCSuq,
          item.sPGCStq,
          item.sPGRSmin,
          item.sPGRSmax,
          item.sPGRSavg,
          item.sPGRSqavg,
          item.sPGRSuq,
          item.sPGRStq,
        )
      })
      .catch(err => {
        console.log(err);
      })
    }, { concurrency: 5 } )
    .then(res => {
      // localStorage.setItem('btg.stock', JSON.stringify(self.state.stock));
      this.unselectAll();
    })
  }

  handleListChange = async (e) => {
    if (!e.target || e.target.type !== 'checkbox') return;

    const id = e.target.id
    const checked = e.target.checked;

    const lname = id.split('-')[0];
    var val = parseInt(id.split('-')[1]);
    if (!val) val = id.split('-')[1];

    var list = this.state[lname] || [];
    if (checked) {
      list.includes(val) || list.push(val);
    } else {
      const index = list.indexOf(val);
      if (index !== -1) list.splice(index, 1);
    }

    this.unselectAll();
    this.setState({ [lname]: list })
  }

  handleBatchEditShow = async (e) => {
    this.setState({ showBatchEdit: true });
  }

  handleBatchEditHide = async (e) => {
    this.setState({ showBatchEdit: false });
  }

  handleBatchEditSubmit = async (e) => {
    var data = this.state.stock;
    data = data.filter(item => item.chk === true)
    const ids = data.map(item => item.sId);

    return RestApi.setStockBatch(
      this.props.activeStore.storeId,
      JSON.stringify(ids),
      this.state.beCondition,
      this.state.beSetCondition,
      this.state.beTemplate,
      this.state.beAutoPrice,
      this.state.beDescription,
      this.state.beSetDescription || 0,
      parseFloat(this.state.bePmin),
      parseFloat(this.state.bePmax),
      this.state.beSetMinMax || 0
    )
    .then(res => {
      if (res.meta.affectedRows !== ids.length) {
        console.log('This should not normally happen!!!');
        // localStorage.setItem('btg.stockNeedsRefresh', JSON.stringify(true));
        return this.refreshStock()
      } else {
        data = data.map(item => {
          item.sNU = this.state.beCondition;
          item.sSCI = item.sType === 3 ? this.state.beSetCondition : undefined;
          item.sTid = parseInt(this.state.beTemplate);
          item.sAP = parseInt(this.state.beAutoPrice);
          if (this.state.beSetDescription) item.sDesc = this.state.beDescription;
          if (this.state.beSetMinMax) {
            item.sMmin = parseFloat(this.state.bePmin);
            item.sMmax = parseFloat(this.state.bePmax);
          }
          item.sAPv = reCalcAutoPrice(item);
          item.sUBL = 1;
          return item;
        });
        // localStorage.setItem('btg.stock', JSON.stringify(this.state.stock));
      }
    })
    .then(res => {
      this.setState({ showBatchEdit: false });
    })
    .catch(err => {
      console.log(err);
    })
  }

  handleCreateInv = async (e) => {
    var data = this.state.stock;
    data = data.filter(item => item.chk === true
                              && !item.sFS
                              && !item.iId
                              && !StockItemSaleCheckList(item)
                              && item.sQfs > 0)

    var blClient = new bl.Client(this.sscreds);

    const storeId = this.props.activeStore.storeId
    const self = this;
    return Promise.map(data, function(item) {
      const template = self.sTemplates.find(t => t.stId === item.sTid)

      const invData = {
        item: {
          no: item.sNo,
          type: getKeyByVal(blTypeMap, item.sType)
        },
        color_id: item.sCid,
        quantity: item.sQfs,
        unit_price: item.sAPv,
        new_or_used: item.sNU,
        completeness: item.sSCI,
        description: item.sDesc,
        remarks: item.sRem,
        bulk: template.bulk,
        is_retain: template.is_retain,
        is_stock_room: template.is_stock_room,
        stock_room_id: template.stock_room_id,
        // my_cost
        sale_rate: template.sale_rate,
        tier_quantity1: item.sQfs >= template.tier_quantity1 ? template.tier_quantity1 : 0,
        tier_quantity2: item.sQfs >= template.tier_quantity1 ? template.tier_quantity2 : 0,
        tier_quantity3: item.sQfs >= template.tier_quantity1 ? template.tier_quantity3 : 0,
        tier_price1: item.sQfs >= template.tier_quantity1 && template.tier_quantity1 ? ((100 - template.tier_price1_pct) * item.sAPv) / 100 : 0,
        tier_price2: item.sQfs >= template.tier_quantity1 && template.tier_quantity2 ? ((100 - template.tier_price2_pct) * item.sAPv) / 100 : 0,
        tier_price3: item.sQfs >= template.tier_quantity1 && template.tier_quantity3 ? ((100 - template.tier_price3_pct) * item.sAPv) / 100 : 0,
      }

      const blReq = bl.Inventory.create(invData)

      return blClient.send(blReq)
      .then(res => {
        const inv = res.data;

        // console.log(res);
        return RestApi.setBlInventory(
          storeId,
          inv.inventory_id,
          inv.item.no,
          blTypeMap[inv.item.type],
          inv.color_id,
          inv.quantity,
          inv.new_or_used,
          inv.completeness,
          inv.unit_price,
          inv.bind_id,
          inv.description,
          inv.remarks,
          inv.bulk,
          inv.is_retain,
          inv.is_stock_room,
          inv.stock_room_id,
          inv.my_cost,
          inv.sale_rate,
          inv.tier_quantity1,
          inv.tier_quantity2,
          inv.tier_quantity3,
          inv.tier_price1,
          inv.tier_price2,
          inv.tier_price3,
          inv.my_weight,
          item.sId
        )
        .then(btgres => {
          item.sP = parseFloat(inv.unit_price);
          item.sFS = 1;
          item.iId = inv.inventory_id;
        })
      })
      .catch(err => {
        console.log(err);
      })
    }, { concurrency: 5 } )
    .then(res => {
      // localStorage.setItem('btg.stock', JSON.stringify(self.state.stock));
      this.unselectAll();
    })
  }

  handleUpdateInv = async (e) => {
    var data = this.state.stock;
    data = data.filter(item => item.chk === true
                              && item.sFS
                              && item.iId
                              && !StockItemSaleCheckList(item)
                              && ((item.sAPv && (item.sAPv !== item.sP)) || item.sUBL)
                      )

    var blClient = new bl.Client(this.sscreds);

    const storeId = this.props.activeStore.storeId
    const self = this;
    return Promise.map(data, function(item) {
      const template = self.sTemplates.find(t => t.stId === item.sTid)

      const invData = {
        quantity: item.sQfs - item.iQ,
        unit_price: item.sAPv,
        description: item.sDesc,
        remarks: item.sRem,
        bulk: template.bulk,
        is_retain: template.is_retain,
        is_stock_room: item.sQfs === 0 ? true : template.is_stock_room,
        stock_room_id: item.sQfs === 0 ? 'A' : template.stock_room_id,
        // my_cost
        sale_rate: template.sale_rate,
        tier_quantity1: item.sQfs >= template.tier_quantity1 ? template.tier_quantity1 : 0,
        tier_quantity2: item.sQfs >= template.tier_quantity1 ? template.tier_quantity2 : 0,
        tier_quantity3: item.sQfs >= template.tier_quantity1 ? template.tier_quantity3 : 0,
        tier_price1: item.sQfs >= template.tier_quantity1 && template.tier_quantity1 ? ((100 - template.tier_price1_pct) * item.sAPv) / 100 : 0,
        tier_price2: item.sQfs >= template.tier_quantity1 && template.tier_quantity2 ? ((100 - template.tier_price2_pct) * item.sAPv) / 100 : 0,
        tier_price3: item.sQfs >= template.tier_quantity1 && template.tier_quantity3 ? ((100 - template.tier_price3_pct) * item.sAPv) / 100 : 0,
      }

      const blReq = bl.Inventory.update(item.iId, invData)

      return blClient.send(blReq)
      .then(res => {
        const inv = res.data;

        return RestApi.setBlInventory(
          storeId,
          inv.inventory_id,
          inv.item.no,
          blTypeMap[inv.item.type],
          inv.color_id,
          inv.quantity,
          inv.new_or_used,
          inv.completeness,
          inv.unit_price,
          inv.bind_id,
          inv.description,
          inv.remarks,
          inv.bulk,
          inv.is_retain,
          inv.is_stock_room,
          inv.stock_room_id,
          inv.my_cost,
          inv.sale_rate,
          inv.tier_quantity1,
          inv.tier_quantity2,
          inv.tier_quantity3,
          inv.tier_price1,
          inv.tier_price2,
          inv.tier_price3,
          inv.my_weight,
          item.sId
        )
        .then(btgres => {
          item.sP = parseFloat(inv.unit_price);
          item.sFS = 1;
          item.iId = inv.inventory_id;
          item.sUBL = 0;
        })
      })
      .catch(err => {
        console.log(err);
      })
    }, { concurrency: 5 } )
    .then(res => {
      // localStorage.setItem('btg.stock', JSON.stringify(self.state.stock));
      this.unselectAll();
    })
  }

  handleDeleteInv = async (e) => {
    var data = this.state.stock;
    data = data.filter(item => item.chk === true && item.sFS && item.iId)

    var blClient = new bl.Client(this.sscreds);

    const storeId = this.props.activeStore.storeId
    // const self = this;
    return Promise.map(data, function(item) {

      const blReq = bl.Inventory.delete(item.iId)
      return blClient.send(blReq)
      .then(res => {
        return RestApi.deleteBlInventory(storeId, item.iId, item.sId);
      })
      .then(btgres => {
        item.sFS = 0
        item.iId = null
      })
      .catch(err => {
        console.log(err);
      })

    }, { concurrency: 5 } )
    .then(res => {
      // localStorage.setItem('btg.stock', JSON.stringify(self.state.stock));
      this.unselectAll();
    })
  }

  beIsValid() {
    if (this.state.beCondition === 'U' && this.state.beSetCondition === 'S')
      return false;
    if (this.state.beSetDescription && this.state.beDescription)
      return this.state.beDescription.length < 255;
    if (this.state.beSetMinMax && this.state.bePmin && this.state.bePmax)
      return parseFloat(this.state.bePmin) <= parseFloat(this.state.bePmax);
    return (
      this.state.beCondition
      && this.state.beSetCondition
      && this.state.beTemplate
      && this.state.beAutoPrice >= 0
    )
  }

  render() {
    const haveCreds = this.sscreds;
    const havePrivileges = RestApi.hasPrivilege(this.props.activeStore.privileges, 'StoreUpdateStock')
    const haveBlPrivileges = RestApi.hasPrivilege(this.props.activeStore.privileges, 'StoreUpdateBl')

    const stockActions = [];
    stockActions.push({ label: 'Edit', cb: this.handleBatchEditShow, enabled: haveCreds && havePrivileges })
    stockActions.push({ label: 'Update PG', cb: this.handleUpdatePG, enabled: haveCreds && havePrivileges })
    stockActions.push({ label: 'Create Inv', cb: this.handleCreateInv, enabled: haveCreds && haveBlPrivileges })
    stockActions.push({ label: 'Update Inv', cb: this.handleUpdateInv, enabled: haveCreds && haveBlPrivileges })
    stockActions.push({ label: 'Delete Inv', cb: this.handleDeleteInv, enabled: haveCreds && haveBlPrivileges })

    const listCBs = this.lists ? this.lists.filter(l => l.isstock).sort(firstBy('rlName', { ignoreCase: true })).map(l => {
      return (<Form.Switch key={l.rlId} onChange={this.handleListChange} id={`showByList-${l.rlId}`} checked={this.state.showByList.includes(l.rlId)} label={l.rlName} />)
    }) : []

    const sortKeys = {
      sName: 'Name',
      sNo: 'Item Num',
      sP: 'Price',
      sAPv: 'Auto Price',
      sLUPG: 'PG Age',
    }

    return (
      <Container>
      <Row>
        <Col md="auto">
          <Card className="mb-2">
          <Card.Header className="p-2">Show Items</Card.Header>
          <Card.Body className="p-1">
          <Form.Switch onChange={this.handleListChange} id="showForSale-fs" checked={this.state.showForSale.includes('fs')} label="For Sale" />
          <Form.Switch onChange={this.handleListChange} id="showForSale-nfs" checked={this.state.showForSale.includes('nfs')} label="Not For Sale" />
          </Card.Body>
          </Card>

          <Card className="mb-2">
          <Card.Header className="p-2">Show Items In</Card.Header>
          <Card.Body className="p-1">
            {listCBs}
          </Card.Body>
          </Card>

          <Card className="mb-2">
          <Card.Header className="p-2">Show Only When</Card.Header>
          <Card.Body className="p-1">
          <Form.Switch onChange={this.handleChange} id="whenSUBL" checked={this.state.whenSUBL || false} label="BrickLink Needs Update" />
          <Form.Switch onChange={this.handleChange} id="whenNAR" checked={this.state.whenNAR || false} label="Not All Reserved" />
          <Form.Switch onChange={this.handleChange} id="whenAR" checked={this.state.whenAR || false} label="All Reserved" />
          <Form.Switch onChange={this.handleChange} id="whenNoQtyDiff" checked={this.state.whenNoQtyDiff || false} label="No Quantity Change" />
          <Form.Switch onChange={this.handleChange} id="whenAPvNotSp" checked={this.state.whenAPvNotSp || false} label="Auto Price Not Equal To Price" />
          <Form.Switch onChange={this.handleChange} id="whenApLimited" checked={this.state.whenApLimited || false} label="Auto Price Limited" />
          <Form.Switch onChange={this.handleChange} id="whenPGEmpty" checked={this.state.whenPGEmpty || false} label="PG Found Nothing" />
          </Card.Body>
          </Card>

          <Card className="mb-2">
          <Card.Header className="p-2">Show Types</Card.Header>
          <Card.Body className="p-1">
          <Form.Switch onChange={this.handleListChange} id="showByType-1" checked={this.state.showByType.includes(1)} label="Minifigs" />
          <Form.Switch onChange={this.handleListChange} id="showByType-2" checked={this.state.showByType.includes(2)} label="Parts" />
          <Form.Switch onChange={this.handleListChange} id="showByType-3" checked={this.state.showByType.includes(3)} label="Sets" />
          <Form.Switch onChange={this.handleListChange} id="showByType-5" checked={this.state.showByType.includes(5)} label="Gear" />
          </Card.Body>
          </Card>

          <Card className="mb-2">
          <Card.Header className="p-2">Show Condition</Card.Header>
          <Card.Body className="p-1">
          <Form.Switch onChange={this.handleListChange} id="showByCond-N" checked={this.state.showByCond.includes('N')} label="New" />
          <Form.Switch onChange={this.handleListChange} id="showByCond-U" checked={this.state.showByCond.includes('U')} label="Used" />
          <Form.Switch onChange={this.handleListChange} id="showByCond-ns" checked={this.state.showByCond.includes('ns')} label="Not Set" />
          </Card.Body>
          </Card>

          <Card className="mb-2">
          <Card.Header className="p-2">Show Completeness</Card.Header>
          <Card.Body className="p-1">
          <Form.Switch onChange={this.handleListChange} id="showByComp-S" checked={this.state.showByComp.includes('S')} label="Sealed" />
          <Form.Switch onChange={this.handleListChange} id="showByComp-C" checked={this.state.showByComp.includes('C')} label="Complete" />
          <Form.Switch onChange={this.handleListChange} id="showByComp-B" checked={this.state.showByComp.includes('B')} label="Incomplete" />
          <Form.Switch onChange={this.handleListChange} id="showByComp-ns" checked={this.state.showByComp.includes('ns')} label="Not Set" />
          </Card.Body>
          </Card>
        </Col>
        <Col>
          <StockItemList id={'stock'} data={this.filterStock()} sortKeys={sortKeys}
          onChange={this.handleSelectionChange} actions={stockActions} />
        </Col>
      </Row>
      <BatchEditStockModal {...this.state} show={this.state.showBatchEdit}
        onHide={this.handleBatchEditHide} onSubmit={this.handleBatchEditSubmit}
        onChange={this.handleChange} sTemplates={this.sTemplates} validated={this.beIsValid()} />
      </Container>
    )
  }
}

export default withAuthenticator(Stock)

const BatchEditStockModal = (props) => {
  var stRow = null
  if (props.sTemplates) {
    const stOptions = props.sTemplates.map(t => {
      return (<option key={t.stId} value={t.stId}>{t.name}</option>)
    })
    stOptions.unshift(<option key={0} value={0}></option>)

    stRow = (
      <Form.Group as={Row}>
        <Form.Label column>Sale Template</Form.Label>
        <Col>
          <Form.Control id="beTemplate" as="select" onChange={props.onChange}
          value={props.beTemplate}>{stOptions}</Form.Control>
        </Col>
      </Form.Group>
    )
  }

  const apOptions = Object.keys(autoPriceMap).map(key => {
    return (<option key={key} value={key}>{autoPriceMap[key]}</option>)
  })
  apOptions.unshift(<option key={-1} value={-1}></option>)

  const apRow = (
    <Form.Group as={Row}>
      <Form.Label column>Auto Price</Form.Label>
      <Col>
        <Form.Control id="beAutoPrice" as="select" onChange={props.onChange}
        value={props.beAutoPrice}>{apOptions}</Form.Control>
      </Col>
    </Form.Group>
  )


  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Update Stock</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form.Group as={Row}>
        <Form.Label column>Condition</Form.Label>
        <ToggleButtonGroup as={Col} name='beCondition' value={props.beCondition} type='radio'>
          <ToggleButton onChange={props.onChange} variant='outline-secondary' value='N'>New</ToggleButton>
          <ToggleButton onChange={props.onChange} variant='outline-secondary' value='U'>Used</ToggleButton>
        </ToggleButtonGroup>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column>Set Condition</Form.Label>
        <ToggleButtonGroup as={Col} name='beSetCondition' value={props.beSetCondition} type='radio'>
          <ToggleButton onChange={props.onChange} disabled={props.beCondition === 'U'} variant='outline-secondary' value='S'>Sealed</ToggleButton>
          <ToggleButton onChange={props.onChange} variant='outline-secondary' value='C'>Complete</ToggleButton>
          <ToggleButton onChange={props.onChange} variant='outline-secondary' value='B'>Incomplete</ToggleButton>
        </ToggleButtonGroup>
      </Form.Group>
      {stRow}
      {apRow}
      <Form.Group as={Row}>
        <Form.Label column>Price Limits <Form.Switch onChange={props.onChange} checked={props.beSetMinMax || false} id="beSetMinMax" label="Set for all" /></Form.Label>
        <Col><Form.Control disabled={!props.beSetMinMax} onChange={props.onChange} value={props.bePmin || ''} id="bePmin" placeholder="Min Price" type="number" min="0" step="0.01" />
        <Form.Control disabled={!props.beSetMinMax} onChange={props.onChange} value={props.bePmax || ''} id="bePmax" placeholder="Max Price" type="number" min="0" step="0.01" /></Col>
      </Form.Group>
      <Form.Group as={Row} controlId="beDescription">
        <Form.Label column>Description <Form.Switch onChange={props.onChange} checked={props.beSetDescription || false} id="beSetDescription" label="Set for all" /></Form.Label>
        <Col><Form.Control disabled={!props.beSetDescription} onChange={props.onChange} value={props.beDescription} as="textarea" rows="3" /></Col>
      </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button variant="primary" disabled={!props.validated} onClick={props.onSubmit}>
          Update Selected
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
