import React from "react";

import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
// import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'

// import { calcAutoPrice } from "../components/autoPrice"
// import { blTypeMap, getKeyByVal } from "../components/util"

// prefixes:
// s: stock
// i: BL Inventory
// r: RB List Item
// rl: RB List
// c: color

class SetItem {
  constructor(data) {
    data = data || {};

    this.itemno = data.itemno || undefined;
    this.name = data.name || undefined;
    this.category = data.category || undefined;
    this.categoryid = data.categoryid || undefined;
    this.weight = data.weight || undefined;
    this.yearreleased = data.year_released || undefined;
    this.reason = data.reason || undefined;
    this.lastupdate = data.lastupdate || undefined;
    this.numparts = data.numparts || 0;
    this.score = data.score || 0;
    this.scorepp = data.score/data.numparts || 0;
    this.pscore = data.pscore || 0;
    this.pscorepp = data.pscore/data.numparts || 0;
    this.price = data.price || 0;
    this.pricepp = data.price/data.numparts || 0;
    this.pricebw = data.pricebw || data.price;
    this.pricediff = data.price - this.pricebw;
    this.markup = this.pricediff / this.pricebw;
    this.marginscore = this.pscorepp * this.markup

    this.k = data.itemno || undefined
    this.chk = false // util field for selecting instance in lists
  }
}

const SetItemCheckBox = (props) => {
  if (!props.onChange) return null;
  return (<Form.Check id={props.item.k} checked={props.item.chk}
          onChange={props.onChange} className={'float-right'} />);
}

const SetItemCardSet = (props) => {
  return (
    <Card className="m-1" style={{ 'minWidth': '14rem'  }}>
      <Card.Body>
        <Card.Title><SetItemCheckBox {...props} />({props.item.itemno})  {props.item.name || "Item not recognised"}</Card.Title>
          <div className="font-italic">{props.item.yearreleased} - {props.item.category} - {props.item.weight}{props.item.weight > 0 ? "g" : ""}</div>
      </Card.Body>
    </Card>
  )
}

const SetItemCardScore = (props) => {
  return (
    <Row>
      <Col sm={1} className="border-bottom">{props.item.itemno}</Col>
      <Col sm={3} className="border-bottom">{props.item.name}</Col>
      {/*<Col sm={2} className="border-bottom">{props.item.category}</Col>*/}
      <Col sm={1} className="border-bottom">{props.item.yearreleased}</Col>
      <Col sm={1} className="text-right border-bottom">{props.item.numparts}</Col>
      {/*<Col sm={1} className="text-right border-bottom">{props.item.weight}{props.item.weight > 0 ? "g" : ""}</Col>
      <Col sm={1} className="text-right border-bottom">{Math.round(props.item.score)}</Col>
      <Col sm={1} className="text-right border-bottom">{Math.round(props.item.scorepp*100)/100}</Col>*/}
      <Col sm={1} className="text-right border-bottom">{Math.round(props.item.price*100)/100}</Col>
      <Col sm={1} className="text-right border-bottom">{Math.round(props.item.pricebw*100)/100}</Col>
      {/* <Col sm={1} className="text-right border-bottom">{Math.round(props.item.pricepp*100)/100}</Col> */}
      {/* <Col sm={1} className="text-right border-bottom">{Math.round(props.item.pscore*100)/100}</Col> */}
      <Col sm={1} className="text-right border-bottom">{Math.round(props.item.pscorepp*100)/100}</Col>
      <Col sm={1} className="text-right border-bottom">{Math.round(props.item.markup*100)/100}</Col>
      <Col sm={1} className="text-right border-bottom">{Math.round(props.item.marginscore*100)/100}</Col>
      <Col sm={1} className="text-right border-bottom"><SetItemCheckBox {...props} /></Col>
    </Row>
  )
}

const SetItemCard = (props) => {
  switch (props.format) {
    // case 'backlog':
    //   return (<SetItemCardBacklog {...props} />)
    // case 'pickingList':
    //   return (<SetItemCardPickingList {...props} />)
    // case 'setScore':
    //   return (<SetItemCardScore {...props} />)
    default:
      return (<SetItemCardSet {...props} />)
  }
}

export const SetItemCardCol = (props) => {
  switch (props.format) {
    // case 'setScore':
    //   return (<SetItemCard {...props} />)
    default:
      return (<Col><SetItemCard {...props} /></Col>)
  }
}

export const SetItemCardRow = (props) => {
  switch (props.format) {
    case 'setScore':
      return (<SetItemCardScore {...props} />)
    default:
      return (<Row><SetItemCardCol {...props} /></Row>)
  }
}

export default SetItem;
