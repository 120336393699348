import React from "react";

import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'

import { calcAutoPrice } from "../components/autoPrice"
import { blTypeMap, getKeyByVal } from "../components/util"

// prefixes:
// s: stock
// i: BL Inventory
// r: RB List Item
// rl: RB List
// c: color

class StockItem {
  constructor(data) {
    data = data || {};

    this.sId = data.sId || undefined; // stock table
    this.sNo = data.sNo || undefined;
    this.sType = data.sType || undefined;
    this.sCat = data.sCat || undefined;
    this.sCid = data.sCid || undefined;
    this.sQ = (data.sQ || data.sQ === 0) ? data.sQ : undefined;
    this.sQfs = (data.sQfs || data.sQfs === 0) ? data.sQfs : undefined;
    this.sNU = data.sNU || undefined; //condition
    this.sSCI = data.sSCI || undefined; //completeness
    this.sP = data.sP || undefined; //price
    this.sDesc = data.sDesc || undefined;
    this.sRem = data.sRem || undefined;
    this.sMW = data.sMW || undefined;
    this.sFS = data.sFS || undefined;
    this.sTid = data.sTid || undefined;
    this.sMmin = data.sMmin || undefined;
    this.sMmax = data.sMmax || undefined;
    this.sPGCmin = data.sPGCmin || 0;
    this.sPGCmax = data.sPGCmax || 0;
    this.sPGCavg = data.sPGCavg || 0;
    this.sPGCqavg = data.sPGCqavg || 0;
    this.sPGCuq = data.sPGCuq || 0;
    this.sPGCtq = data.sPGCtq || 0;
    this.sPGRmin = data.sPGRmin || 0;
    this.sPGRmax = data.sPGRmax || 0;
    this.sPGRavg = data.sPGRavg || 0;
    this.sPGRqavg = data.sPGRqavg || 0;
    this.sPGRuq = data.sPGRuq || 0;
    this.sPGRtq = data.sPGRtq || 0;
    this.sPGCSmin = data.sPGCSmin || 0;
    this.sPGCSmax = data.sPGCSmax || 0;
    this.sPGCSavg = data.sPGCSavg || 0;
    this.sPGCSqavg = data.sPGCSqavg || 0;
    this.sPGCSuq = data.sPGCSuq || 0;
    this.sPGCStq = data.sPGCStq || 0;
    this.sPGRSmin = data.sPGRSmin || 0;
    this.sPGRSmax = data.sPGRSmax || 0;
    this.sPGRSavg = data.sPGRSavg || 0;
    this.sPGRSqavg = data.sPGRSqavg || 0;
    this.sPGRSuq = data.sPGRSuq || 0;
    this.sPGRStq = data.sPGRStq || 0;
    this.sLUPG = data.sLUPG || undefined;
    this.sAP = data.sAP || undefined;
    this.sAPv = calcAutoPrice(data) || undefined;
    this.sWid = data.sWid || undefined;
    this.sWBid = data.sWBid || undefined;
    this.sWBBid = data.sWBBid || undefined;
    this.sWname = data.sWname || undefined;

    this.sName = data.sName || undefined;
    this.sCname = data.sCname || undefined;
    this.sCcode = data.sCcode || undefined;
    this.sCatN = data.sCatN || undefined;

    this.iId = data.iId || undefined; // bl inv table
    this.iQ = (data.iQ || data.iQ === 0) ? data.iQ : undefined;

    this.rId = data.rId || undefined; // rb list item table
    this.rlId = data.rlId || undefined;
    this.rlName = data.rlName || undefined;
    this.rNo = data.rNo || undefined;
    this.rQ = (data.rQ || data.rQ === 0) ? data.rQ : undefined;
    this.rCid = (data.rCid || data.rCid === 0) ? data.rCid : undefined;

    this.bQ = (data.bQ || data.bQ === 0) ? data.bQ : undefined;
    this.sUBL = data.sUBL || undefined;

    this.sAPF = data.sAPF || 1.0;

    // this.k = `${data.sId}-${data.iId}-${data.rId}`
    this.k = data.sId || data.iId || data.rId || undefined
    this.chk = false // util field for selecting instance in lists
  }
}

export const StockItemSaleCheckList = (item) => {
  var missingFields = [];
  if (!item.sNU || (item.sType === 3 && !item.sSCI)) missingFields.push(<div key='sNU'>Condition</div>);
  if (!item.sP && (!item.sAP && !item.sLUPG)) missingFields.push(<div key='sP'>Price</div>);
  if (!item.sTid) missingFields.push(<div key='sTid'>Sale Template</div>);

  return missingFields.length > 0 ? missingFields : null;
}

function getQty(item) {
  var res = ''
  if (item.sQ || item.rQ || item.iQ || item.sQ === 0) res = 'Qty:'
  if (item.sQ || item.sQ === 0) res = `${res} ${item.sQ}`
  if ((item.iQ || item.iQ === 0) && item.sQ !== item.iQ) res = `${res} (B: ${item.iQ})`
  if ((item.rQ || item.rQ === 0) && item.sQ !== item.rQ) res = `${res} (R: ${item.rQ})`
  if (item.sQfs !== item.sQ) res = `${res}, Res: ${item.sQ - item.sQfs}`
  return res
}

function getItemNo(item) {
  var res = item.sNo || ''
  if (item.rNo && item.sNo !== item.rNo)
    res = `${res} (R: ${item.rNo})`;
  return res
}

function toPrice(num) {
  try {
    if (num >= 0) return `€${num.toFixed(3)}`
    else return null;
  }
  catch(err) {
    console.log(num);
    console.log(err);
  }
}

const StockItemSaleCheckListCard = (props) => {
  var missingFields = StockItemSaleCheckList(props.item);
  if (missingFields) {
    return (
      <Card className={'float-right '}>
        <Card.Header className={'p-1 bg-warning font-weight-bold'} >Missing</Card.Header>
        <Card.Body className={'p-1'}>
        {missingFields}
        </Card.Body>
      </Card>
    );
  }
  else
    return null;
}

const StockItemPriceGuideCard = (props) => {
  const item = props.item;
  if (item.sLUPG) {
    return (
      <Card className={'float-right small'}>
        <Card.Body className={'p-1'}>
          <Container className='text-right'>
            <Row className="font-weight-bold">
            <Col></Col>
            <Col className='text-center' sm={4}>Country</Col>
            <Col className='text-center' sm={5}>Region</Col>
            </Row>
            <Row>
            <Col></Col>
            <Col>Sold</Col>
            <Col>Stock</Col>
            <Col>Sold</Col>
            <Col>Stock</Col>
            </Row>
            <Row>
            <Col className="font-weight-bold">Min</Col>
            <Col>{toPrice(item.sPGCmin)}</Col>
            <Col>{toPrice(item.sPGCSmin)}</Col>
            <Col>{toPrice(item.sPGRmin)}</Col>
            <Col>{toPrice(item.sPGRSmin)}</Col>
            </Row>
            <Row>
            <Col className="font-weight-bold">Max</Col>
            <Col>{toPrice(item.sPGCmax)}</Col>
            <Col>{toPrice(item.sPGCSmax)}</Col>
            <Col>{toPrice(item.sPGRmax)}</Col>
            <Col>{toPrice(item.sPGRSmax)}</Col>
            </Row>
            <Row>
            <Col className="font-weight-bold">Avg</Col>
            <Col>{toPrice(item.sPGCavg)}</Col>
            <Col>{toPrice(item.sPGCSavg)}</Col>
            <Col>{toPrice(item.sPGRavg)}</Col>
            <Col>{toPrice(item.sPGRSavg)}</Col>
            </Row>
            <Row>
            <Col className="font-weight-bold">Q Avg</Col>
            <Col>{toPrice(item.sPGCqavg)}</Col>
            <Col>{toPrice(item.sPGCSqavg)}</Col>
            <Col>{toPrice(item.sPGRqavg)}</Col>
            <Col>{toPrice(item.sPGRSqavg)}</Col>
            </Row>
            <Row>
            <Col className="font-weight-bold">Count</Col>
            <Col>{item.sPGCuq}</Col>
            <Col>{item.sPGCSuq}</Col>
            <Col>{item.sPGRuq}</Col>
            <Col>{item.sPGRSuq}</Col>
            </Row>
            <Row>
            <Col className="font-weight-bold">Total</Col>
            <Col>{item.sPGCtq}</Col>
            <Col>{item.sPGCStq}</Col>
            <Col>{item.sPGRtq}</Col>
            <Col>{item.sPGRStq}</Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    );
  }
  else
    return null;
}

const StockItemPriceCard = (props) => {
  const item = props.item;

  const limits = `(${toPrice(item.sMmin ? item.sMmin : 0)} -> ${item.sMmax ? toPrice(item.sMmax) : '\u221e'})`

  return (
    <div>
    <div>Price: {toPrice(item.sP)}</div>
    <div>Auto Price: {toPrice(calcAutoPrice(item))} <small>{limits}</small></div>
    </div>
  )
}

const StockItemCheckBox = (props) => {
  if (!props.onChange) return null;
  return (<Form.Check id={props.item.k} checked={props.item.chk}
          onChange={props.onChange} className={'float-right'} />);
}

const StockItemColorDiv = (props) => {
  if (!props.item.sCname) return null;
  return (<div><div className={`float-left color ${props.item.sCname.startsWith('Trans') ? 'color-trans' : ''}`}
          style={{ 'backgroundColor': `#${props.item.sCcode}` }} title={props.item.sCname}></div> {props.item.sCname}</div>);
}

const StockItemLocationDiv = (props) => {
  if (!props.item.sId) return 'Item not found in this warehouse';

  var loc = 'Location: Not Set'
  if (props.item.sWname) loc = `Location: ${props.item.sWname}`;
  return (
    <div>{loc} <input type="image" onClick={props.onItemChange} value="editLoc" id={props.item.rId} src="/icon-edit.svg" width="14px" title="Edit Location" alt="Edit Location" /></div>);
}

const StockItemBacklogOptionsDiv = (props) => {
  if (!props.item.sId) return null;

  const haveOptions = [...Array(props.item.sQ + 1).keys()].map(i => {
    return (<option key={i} value={i}>{i}</option>)
  })

  var partOutCheck = <Form.Check id={props.item.k} checked={props.item.sFS || false} label="Part Out"
      onChange={props.onItemChange} className={'float-right'} />
  return (
    <div>Have:
    <Form.Control id={props.item.k} size={'sm'} as="select" style={{ maxWidth: '3rem' }}
      className={'d-inline-flex'} onChange={props.onItemChange} disabled={props.item.sFS}
      value={props.item.rQ}>{haveOptions}</Form.Control>
      {` Need: ${props.item.sQ}, Missing: `}<strong>{props.item.sQ - props.item.rQ}</strong>
      {` In Stock: ${props.item.iQ}, In Backlogs: ${props.item.bQ}, To Find: `}<strong>{Math.max(0, props.item.sQ - props.item.iQ - props.item.rQ)}</strong>
    {partOutCheck}
    </div>);
}

const StockItemPickOptionsCard = (props) => {
  const maxPick = props.item.sQ ? Math.min(props.item.iQ + props.item.sQ, props.item.rQ) : Math.min(props.item.iQ, props.item.rQ)
  // console.log(maxPick);

  const pickOptions = [...Array(maxPick + 1).keys()].map(i => {
    return (<option key={i} value={i}>{i}</option>)
  });

  return (
    <Card className={'float-right small'}>
      <Card.Body className={'p-1'}>
        <Container className='text-right'>
        <Row><Col>Needed</Col><Col>{props.item.rQ}</Col></Row>
        <Row><Col className="text-nowrap">In Stock</Col><Col>{props.item.sQ}</Col></Row>
        <Row><Col>Picked</Col><Col><Form.Control id={props.item.rId} size={'sm'} as="select"
          style={{ maxWidth: '3rem' }} onChange={props.onItemChange} disabled={!props.item.sId}
          value={props.item.iQ}>{pickOptions}</Form.Control></Col></Row>
        </Container>
      </Card.Body>
    </Card>
  )
}

const StockItemCardStock = (props) => {
  var fromList = ''
  if (props.item.rlName) fromList = `In ${props.item.rlName}`

  return (
    <Card className="m-1" style={{ 'minWidth': '14rem'  }}>
      <Card.Body>
        <Card.Title><StockItemCheckBox {...props} /> {props.item.sName || "Item not recognised"}</Card.Title>
          <StockItemSaleCheckListCard {...props} />
          <StockItemPriceGuideCard {...props} />

          <div><StockItemColorDiv {...props} /> {getItemNo(props.item)} - {getQty(props.item)}</div>
          <div>{fromList}</div>
          <div className="font-italic">{props.item.sDesc}</div>
          <StockItemPriceCard {...props} />
      </Card.Body>
    </Card>
  )
}

const StockItemCardPickingList = (props) => {
  return (
    <Card className="m-1" style={{ 'minWidth': '14rem'  }}>
      <Card.Body>
        <Card.Title><StockItemCheckBox {...props} /><StockItemPickOptionsCard {...props} />
        {props.item.sName || "Item not found in this stock list"} {props.item.sId && <input type="image" onClick={props.onItemChange} value="writeOff" id={props.item.rId} src="/icon-recycle.png" width="14px" title="Add Write-Off" alt="Add Write-Off" />}</Card.Title>
          <div><StockItemColorDiv {...props} /> {getItemNo(props.item)}</div>
          <div className="font-italic">{props.item.sDesc}</div>
          <StockItemLocationDiv {...props} />
      </Card.Body>
    </Card>
  )
}

const StockItemCardBacklog = (props) => {
  return (
    <Card className="m-1" style={{ 'minWidth': '14rem'  }}>
      <Card.Body>
        <Card.Title>
          <StockItemColorDiv {...props} />
          {props.item.sNo} {props.item.sName} <small>({getKeyByVal(blTypeMap, props.item.sType)})</small>
        </Card.Title>
        <small>{props.item.sCatN}</small>
        <StockItemBacklogOptionsDiv {...props} />
      </Card.Body>
    </Card>
  )
}

const StockItemCardStockList = (props) => {
  return (
    <Card className="m-1" style={{ 'minWidth': '14rem'  }}>
      <Card.Body>
        <Card.Title>
        <StockItemCheckBox {...props} />
        <StockItemColorDiv {...props} />
        {props.item.sNo} - {props.item.sName}
        </Card.Title>
          <small>{getKeyByVal(blTypeMap, props.item.sType)} in {props.item.sCatN}</small>
          <div>Qty: {props.item.rQ} <input type="image" onClick={props.onItemChange} value="editLoc" id={props.item.rId} src="/icon-edit.svg" width="14px" title="Edit Quantity" alt="Edit Quantity" /></div>
          <div className="font-italic">{props.item.sDesc}</div>
      </Card.Body>
    </Card>
  )
}

const StockItemCard = (props) => {
  switch (props.format) {
    case 'backlog':
      return (<StockItemCardBacklog {...props} />)
    case 'pickingList':
      return (<StockItemCardPickingList {...props} />)
    case 'stockList':
      return (<StockItemCardStockList {...props} />)
    default:
      return (<StockItemCardStock {...props} />)
  }
}

export const StockItemCardCol = (props) => {
  return (<Col><StockItemCard {...props} /></Col>)
}

export const StockItemCardRow = (props) => {
  return (<Row><StockItemCardCol {...props} /></Row>)
}

export default StockItem;
