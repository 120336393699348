const getKeyByVal = (obj, value) => {
  for (var key in obj) {
    if(obj[key] === value){
      return key;
    }
  }
  return null;
};

const blTypeMap = {
  MINIFIG: 1,
  PART: 2,
  SET: 3,
  BOOK: 4,
  GEAR: 5,
  CATALOG: 6,
  INSTRUCTION: 7,
  UNSORTED_LOT: 8,
  ORIGINAL_BOX: 9
};

exports.blTypeMap = blTypeMap;
exports.getKeyByVal = getKeyByVal;
