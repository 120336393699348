import React, { Component } from "react";

import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Pagination from 'react-bootstrap/Pagination'
import Row from 'react-bootstrap/Row'

import { SetItemCardRow } from './SetItem'

import { firstBy } from "thenby";

class SetItemList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      page: 0,
      pageSize: 20,
      selectAll: false,
      sortDir: props.sortDir === undefined ? true : props.sortDir,
      sortKey: props.sortKeys ? Object.keys(props.sortKeys)[0] : 'sName',
    }

    this.pageData = [];
    this.filteredData = [];
  }

  prepData() {
    var data = this.props.data;
    // console.log(data);
    this.filteredData = data.filter(item => {
      const lcaseFliter = this.state.filter.toLowerCase();

      return (
        (item.name ? item.name.toLowerCase().includes(lcaseFliter) : false)
        || (item.itemno ? item.itemno.toLowerCase().includes(lcaseFliter) : false)
        || (item.category ? item.category.toLowerCase().includes(lcaseFliter) : false)
      )
    })

    this.filteredData.sort(
      firstBy(this.state.sortKey, { ignoreCase: true, direction: this.state.sortDir ? 1 : -1 })
      .thenBy('name', {ignoreCase:true})
    )

    var pageStart = this.state.page * this.state.pageSize;
    if (pageStart > this.filteredData.length)
      pageStart = Math.floor(this.filteredData.length/this.state.pageSize) * this.state.pageSize
    const pageEnd = pageStart + this.state.pageSize;
    this.pageData = this.filteredData.slice(pageStart, pageEnd);
  }

  unselectAll() {
    if (!this.props.onChange) return;

    const ids = this.props.data.filter(item => item.chk).map(item => item.k)
    this.props.onChange( this.props.id, ids, false);

    this.setState({ selectAll: false })
  }

  handleSelectAllOnChange = (e) => {
    const checked = e.target.checked;

    const ids = this.pageData.map(item => item.k)

    this.props.onChange( this.props.id, ids, checked);
    this.setState({ selectAll: checked })
  }

  handleSelectItemOnChange = (e) => {
    // const id = parseInt(e.target.id);
    const id = e.target.id;
    const checked = e.target.checked;

    this.props.onChange( this.props.id, [id], checked);
  }

  handlePageChanged = (e) => {
    const id = e.target.id ? e.target.id : e.target.parentNode.id
    document.activeElement.blur()

    this.unselectAll();
    this.setState({ page: id - 1 })
  }

  handlePageSizeChanged = (e) => {
    this.unselectAll();
    this.setState({ pageSize: parseInt(e.target.value) })
  }

  handleFilterChanged = (e) => {
    this.unselectAll();
    this.setState({ filter: e.target.value })
  }

  handleSortChanged = (e) => {
    const value = e.target.value
    const key = value.split('-')[0];
    const dir = value.split('-')[1] === 'true' ? true : false;

    this.unselectAll();
    this.setState({ sortDir: dir, sortKey: key });
  }

  renderActionButtons(haveActions) {
    if (!haveActions) return;

    const actionButtons = this.props.actions.map(action => {
      return(<Button size="sm" key={action.label} onClick={action.cb} disabled={!action.enabled}>{action.label}</Button>)
    });

    return (
      <Col className={'text-right'}>
        { this.props.onChange &&
        <Form.Check inline label='Select All' checked={this.state.selectAll} onChange={this.handleSelectAllOnChange} />}
        <ButtonGroup>{actionButtons}</ButtonGroup>
      </Col>
    )
  }

  renderPagination() {
    const maxPage = Math.ceil(this.filteredData.length / this.state.pageSize) || 1
    const curPage = Math.min(this.state.page + 1, maxPage);

    return (
      <Col md="auto">
        <Pagination size="sm">
          <Pagination.First id={1} disabled={curPage === 1} onClick={this.handlePageChanged} />
          <Pagination.Prev id={curPage - 1} disabled={curPage === 1} onClick={this.handlePageChanged} />
          <Pagination.Next id={curPage + 1} disabled={curPage === maxPage} onClick={this.handlePageChanged} />
          <Pagination.Last id={maxPage} disabled={curPage === maxPage} onClick={this.handlePageChanged} />
        </Pagination>
      </Col>
    )
  }

  renderPageSize() {
    return (
      <Form.Group md="auto" as={Col} controlId="pageSize">
        <Form.Label>Show</Form.Label>{' '}
        <Form.Control className={'d-inline-flex'} size={'sm'} style={{ maxWidth: '3.5rem' }}
        onChange={this.handlePageSizeChanged} as="select" value={this.state.pageSize}>
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={30}>30</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
        </Form.Control>
      </Form.Group>
    )
  }

  renderFilter() {
    return (
      <Col md="auto">
        <Form.Control size={'sm'} placeholder={'Filter'}
        onChange={this.handleFilterChanged} value={this.state.filter} />
      </Col>
    )
  }

  renderSort() {
    const arrows = {
      true: '\u21E7',
      false: '\u21E9'
    }
    const keys = this.props.sortKeys || {
      sName: 'Name',
      sNo: 'Item Num'
    }

    const options = [];
    options.push(<option key={0} value={`${this.state.sortKey}-${this.state.sortDir}`}>{keys[this.state.sortKey]} {arrows[this.state.sortDir]}</option>)

    for (var key in keys) {
      const dir = (key !== this.state.sortKey) || !this.state.sortDir
      options.push(<option key={options.length} value={`${key}-${dir}`}>{keys[key]} {key !== this.state.sortKey ? "" : arrows[dir]}</option>)
    }

    return (
      <Col md="auto">
        <Form.Control size={'sm'} as="select"
        onChange={this.handleSortChanged} value={`${this.state.sortKey}-${this.state.sortDir}`}>
        {options}
        </Form.Control>
      </Col>
    )
  }

  render() {
    this.prepData();
    const haveActions = this.props.actions && this.props.actions.length > 0;
    const selectChangeHandler = haveActions ? this.handleSelectItemOnChange : null;

    const cardList = this.pageData.map(item => {
      return (<SetItemCardRow key={item.k} item={item} onChange={selectChangeHandler}
        onItemChange={this.props.onItemChange} format={this.props.format} />);
    })

    return(
      <Container>
      <Row>
        {this.renderSort()}
        {this.renderFilter()}
        {this.renderPageSize()}
        {this.renderPagination()}
        {this.renderActionButtons(haveActions)}
      </Row>
      <Row>
        <Col sm={1} className="font-weight-bold border-bottom">No</Col>
        <Col sm={3} className="font-weight-bold border-bottom">Name</Col>
        {/*<Col sm={2} className="border-bottom">{props.item.category}</Col>*/}
        <Col sm={1} className="font-weight-bold border-bottom">Year</Col>
        <Col sm={1} className="font-weight-bold text-right border-bottom"># Parts</Col>
        {/*<Col sm={1} className="font-weight-bold text-right border-bottom">Weight</Col>*/}
        {/*<Col sm={1} className="font-weight-bold text-right border-bottom">Score</Col>
        <Col sm={1} className="font-weight-bold text-right border-bottom">Score/P</Col>*/}
        <Col sm={1} className="font-weight-bold text-right border-bottom">Price</Col>
        <Col sm={1} className="font-weight-bold text-right border-bottom">BPrice</Col>
        {/* <Col sm={1} className="font-weight-bold text-right border-bottom">Price/P</Col> */}
        {/* <Col sm={1} className="font-weight-bold text-right border-bottom">PScore</Col> */}
        <Col sm={1} className="font-weight-bold text-right border-bottom">PScorePP</Col>
        <Col sm={1} className="font-weight-bold text-right border-bottom">Markup</Col>
        <Col sm={1} className="font-weight-bold text-right border-bottom">MScore</Col>
        <Col sm={1} className="font-weight-bold text-right border-bottom"> </Col>
      </Row>
        {cardList}
      </Container>
    );
  }
}

export default SetItemList
