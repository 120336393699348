import React from "react";
import { Route, Switch } from "react-router-dom";

// Components
import AppliedRoute from "./components/AppliedRoute";
import AdminRoute from "./components/AdminRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

// Containers
// import About from "./containers/About";
import ApiCredentials from "./containers/ApiCredentials";
import BackLog from "./containers/BackLog";
import Colors from "./containers/Colors";
import Categories from "./containers/Categories";
import EncryptCredentials from "./containers/EncryptCredentials";
// import Faq from "./containers/Faq";
// import Help from "./containers/Help";
import Home from "./containers/Home";
// import MyAccount from "./containers/MyAccount";
// import MyPreferences from "./containers/MyPreferences";
import CustomLists from "./containers/CustomLists";
import NotFound from "./containers/NotFound";
import Orders from "./containers/Orders";
import Overview from "./containers/Overview";
import Pick from "./containers/Pick";
import Reports from "./containers/Reports";
// import Privacy from "./containers/Privacy";
import ResetPassword from "./containers/ResetPassword";
import Restock from "./containers/Restock";
import SignInUp from "./containers/SignInUp";
import Sets from "./containers/Sets";
import SetRating from "./containers/SetRating";
import Stock from "./containers/Stock";
import Storage from "./containers/Storage";
import Stores from "./containers/Stores";
import SyncIn from "./containers/SyncIn";
// import Tag from "./containers/Tag";
// import Terms from "./containers/Terms";

const Routes = ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
{/*
    <AppliedRoute path="/about" exact component={About} props={childProps} />
    <AppliedRoute path="/help" exact component={Help} props={childProps} />
    <AppliedRoute path="/faq" exact component={Faq} props={childProps} />
    <AppliedRoute path="/privacy" exact component={Privacy} props={childProps} />
    <AppliedRoute path="/terms" exact component={Terms} props={childProps} />
*/}
    <AdminRoute path="/admin/colors" exact component={Colors} props={childProps} />
    <AdminRoute path="/admin/categories" exact component={Categories} props={childProps} />

    <UnauthenticatedRoute path="/signin" exact component={SignInUp} props={childProps} />
    <UnauthenticatedRoute path="/signin/reset" exact component={ResetPassword} props={childProps} />

    <AuthenticatedRoute path="/apicred" exact component={ApiCredentials} props={childProps} />
    <AuthenticatedRoute path="/backlog" exact component={BackLog} props={childProps} />
    <AuthenticatedRoute path="/custom" exact component={CustomLists} props={childProps} />
    <AuthenticatedRoute path="/enccred" exact component={EncryptCredentials} props={childProps} />
    <AuthenticatedRoute path="/orders" exact component={Orders} props={childProps} />
    <AuthenticatedRoute path="/overview" exact component={Overview} props={childProps} />
    <AuthenticatedRoute path="/pick" exact component={Pick} props={childProps} />
    <AuthenticatedRoute path="/reports" exact component={Reports} props={childProps} />
    <AuthenticatedRoute path="/restock" exact component={Restock} props={childProps} />
    <AuthenticatedRoute path="/sets" exact component={Sets} props={childProps} />
    <AuthenticatedRoute path="/setrating" exact component={SetRating} props={childProps} />
    <AuthenticatedRoute path="/stock" exact component={Stock} props={childProps} />
    <AuthenticatedRoute path="/storage" exact component={Storage} props={childProps} />
    <AuthenticatedRoute path="/stores" exact component={Stores} props={childProps} />
    <AuthenticatedRoute path="/syncin" exact component={SyncIn} props={childProps} />
{/*
    <AuthenticatedRoute path="/myaccount" exact component={MyAccount} props={childProps} />
    <AuthenticatedRoute path="/mypreferences" exact component={MyPreferences} props={childProps} />
    <AuthenticatedRoute path="/tag/select" exact component={Select} props={childProps} />
    <AuthenticatedRoute path="/tag/credits" exact component={Credits} props={childProps} />
    <AuthenticatedRoute path="/tag/tag" exact component={Tag} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;

export default Routes
