// import { firstBy } from "thenby";

export const autoPriceMap = {
  0: 'Manual',
  1: 'PG Average',
  2: 'PG Qty Average',
  3: 'PG Middle',
  4: 'PG Min',
  5: 'PG Max',
  6: 'PG Avg Aggregate',
  7: 'PG Min Aggregate',
  8: 'PG Max Aggregate',
  9: 'PG Int Aggregate',
}

export const pgNeedsUpdate = (item) => {
  if (!item.sLUPG) return true;

  const diff = (new Date()) - Date.parse(item.sLUPG);
  return diff > 1000 * 60 * 60 * 24 * 7; // allow every 7 days
}

const recalcPriceGuide = (item, res) => {
  res.price_detail = res.price_detail.map(d => {
    d.unit_price = parseFloat(d.unit_price);
    return d;
  })

  if (res.price_detail.length < 10) {
    res.avg_price = parseFloat(res.avg_price);
    res.qty_avg_price = parseFloat(res.qty_avg_price);
    res.min_price = parseFloat(res.min_price);
    res.max_price = parseFloat(res.max_price);
    return res;
  }
  // console.log(res);

  res.price_detail = res.price_detail.sort( function(a, b) {
    return a.unit_price - b.unit_price;
  });

  const pdRaw = res.price_detail.concat();

  // const values = pdRaw.map(pd => {
  //   return pd.unit_price;
  // });

  /* Then find a generous IQR. This is generous because if (values.length / 4)
   * is not an int, then really you should average the two elements on either
   * side to find q1.
   */
  const idxQ1 = Math.floor(res.total_quantity / 4);
  const idxQ3 = Math.floor(res.total_quantity * (3 / 4));

  var q1q = 0;
  var q3q = 0;
  var runIdx = 0;

  pdRaw.forEach(p => {
    runIdx += p.quantity;
    if (q1q === 0 && runIdx >= idxQ1) {
      q1q = p.unit_price;
    }
    if (q3q === 0 && runIdx >= idxQ3) {
      q3q = p.unit_price;
    }
  })

  const iqrq = q3q - q1q;
  // Then find min and max values
  const maxValueq = q3q + iqrq*1.5;
  const minValueq = q1q - iqrq*1.5;

  const filteredq = pdRaw.filter(v => v.unit_price > minValueq && v.unit_price < maxValueq);
  const countq = filteredq.length ? filteredq.reduce((a, b) => a + b.quantity, 0) : 0;
  const averageq = countq ? filteredq.reduce((a, b) => a + (b.unit_price * b.quantity), 0) / countq : 0;
  const average = filteredq.length ? filteredq.reduce((a, b) => a + b.unit_price, 0) / filteredq.length : 0;

  const prices = pdRaw.map(d => d.unit_price);

  res.unit_quantity = filteredq.length;
  res.total_quantity = countq;
  res.avg_price = average;
  res.qty_avg_price = averageq;
  res.min_price = res.unit_quantity ? Math.min(...prices) : 0;
  res.max_price = res.unit_quantity ? Math.max(...prices) : 0;
  res.price_detail = filteredq;

  return res;
};

export const applyPriceGuide = (item, cRes, rRes, csRes, rsRes) => {
  recalcPriceGuide(item, cRes.data);
  recalcPriceGuide(item, rRes.data);
  recalcPriceGuide(item, csRes.data);
  recalcPriceGuide(item, rsRes.data);

  item.sPGRmin = rRes.data.min_price;
  item.sPGRmax = rRes.data.max_price;
  item.sPGRavg = rRes.data.avg_price;
  item.sPGRqavg = rRes.data.qty_avg_price;
  item.sPGRuq = rRes.data.unit_quantity;
  item.sPGRtq = rRes.data.total_quantity;

  item.sPGCmin = cRes.data.min_price;
  item.sPGCmax = cRes.data.max_price;
  item.sPGCavg = cRes.data.avg_price;
  item.sPGCqavg = cRes.data.qty_avg_price;
  item.sPGCuq = cRes.data.unit_quantity;
  item.sPGCtq = cRes.data.total_quantity;

  item.sPGRSmin = rsRes.data.min_price;
  item.sPGRSmax = rsRes.data.max_price;
  item.sPGRSavg = rsRes.data.avg_price;
  item.sPGRSqavg = rsRes.data.qty_avg_price;
  item.sPGRSuq = rsRes.data.unit_quantity;
  item.sPGRStq = rsRes.data.total_quantity;

  item.sPGCSmin = csRes.data.min_price;
  item.sPGCSmax = csRes.data.max_price;
  item.sPGCSavg = csRes.data.avg_price;
  item.sPGCSqavg = csRes.data.qty_avg_price;
  item.sPGCSuq = csRes.data.unit_quantity;
  item.sPGCStq = csRes.data.total_quantity;

  item.sLUPG = (new Date()).toISOString();
  item.sAPv = reCalcAutoPrice(item);

  return item;
}

const pickCorR = (item, apPrefs, cVal, rVal) => {
  if ((apPrefs.minUQ && apPrefs.minUQ <= item.sPGCuq)
    || (apPrefs.minTQ && apPrefs.minTQ <= item.sPGCtq))
    return cVal;
  if ((apPrefs.minUQ && apPrefs.minUQ <= item.sPGRuq)
    || (apPrefs.minTQ && apPrefs.minTQ <= item.sPGRtq))
    return rVal;
  return cVal ? cVal : rVal;
}

export const calcAutoPrice = (item, apPrefs) => {
  if (item.sAPv) return item.sAPv;

  return reCalcAutoPrice(item, apPrefs);
}

// const getPGAvgAggregate = (item) => {
//   var res = 0;
//   var vals = [];
//   vals = [item.sPGCavg, item.sPGRavg, item.sPGCqavg, item.sPGRqavg];
//   vals = vals.map(v => v ? v : 0);
//   const arrSum = vals.reduce((a,b) => a + b, 0);
//   const arrCnt = vals.filter(v => v > 0);
//   if (arrCnt.length > 0)
//     res = arrSum / arrCnt.length
//   return res;
// }

export const reCalcAutoPrice = (item, apPrefs) => {
  var res = 0;
  var vals = [];
  switch (item.sAP) {
    case 1: // PG Average
      item.sAPv = pickCorR(item, apPrefs, item.sPGCavg, item.sPGRavg)
      break;
    case 2: // PG Qty Average
      item.sAPv = pickCorR(item, apPrefs, item.sPGCqavg, item.sPGRqavg)
      break;
    case 3: // PG Middle
      const cMid = item.sPGCmin + (item.sPGCmax - item.sPGCmin) / 2;
      const rMid = item.sPGRmin + (item.sPGRmax - item.sPGRmin) / 2;
      item.sAPv = pickCorR(item, apPrefs, cMid, rMid)
      break;
    case 4: // PG Min
      item.sAPv = pickCorR(item, apPrefs, item.sPGCmin, item.sPGRmin)
      break;
    case 5: // PG Max
      // item.sAPv = pickCorR(item, apPrefs, item.sPGCmax, item.sPGRmax)
      vals = [item.sPGCavg, item.sPGRavg, item.sPGCqavg, item.sPGRqavg, item.sPGCSavg, item.sPGRSavg, item.sPGCSqavg, item.sPGRSqavg, item.sMmin];
      vals = vals.map(v => v ? v : 0);
      res = Math.max(...vals);
      item.sAPv = res;
      if (item.sMmin) item.sAPv = Math.max(item.sMmin, res)
      if (item.sMmax) item.sAPv = Math.min(item.sMmax, res)
      break;
    case 6: // PG Avg Aggregate
      vals = [item.sPGCavg, item.sPGRavg, item.sPGCqavg, item.sPGRqavg];
      vals = vals.map(v => v ? v : 0);
      const arrSum = vals.reduce((a,b) => a + b, 0);
      const arrCnt = vals.filter(v => v > 0);
      if (arrCnt.length > 0)
        res = arrSum / arrCnt.length
      item.sAPv = res;
      if (item.sMmin) item.sAPv = Math.max(item.sMmin, res)
      if (item.sMmax) item.sAPv = Math.min(item.sMmax, res)
      break;
    case 7: // PG Min Aggregate
      vals = [item.sPGCavg, item.sPGRavg, item.sPGCqavg, item.sPGRqavg, item.sMmax];
      vals = vals.map(v => v ? v : 99999);
      res = Math.min(...vals);
      if (item.sMmin) item.sAPv = Math.max(item.sMmin, res)
      else item.sAPv = res;
      break;
    case 8: // PG Max Aggregate
      vals = [item.sPGCavg, item.sPGRavg, item.sPGCqavg, item.sPGRqavg, item.sMmin];
      vals = vals.map(v => v ? v : 0);
      res = Math.max(...vals);
      item.sAPv = res;
      if (item.sMmax) item.sAPv = Math.min(item.sMmax, res)
      break;
    case 9:
      var ap = 0;
      // var ap2 = 0;
      // if (item.sPGCuq < 20 || item.sQfs < 3) { // Max
      //   item.sAP = 8;
      //   ap = reCalcAutoPrice(item, apPrefs);
      // } else if (item.sPGCuq > 100 || item.sQfs > 99) { // Min
      //   item.sAP = 7;
      //   ap = reCalcAutoPrice(item, apPrefs);
      // } else { // Avg
      //   item.sAP = 6;
      //   ap = reCalcAutoPrice(item, apPrefs);
      // }
      // if (item.sPGCuq > 100 || item.sQfs > 99) { // Min
      //   item.sAP = 7;
      //   ap = reCalcAutoPrice(item, apPrefs);
      if (item.sPGCStq < 50 || item.sQfs < 10 || item.sPGCtq < 1 || item.sPGRtq < 1) {
        item.sAP = 5;
        ap = reCalcAutoPrice(item, apPrefs);
        // item.sAP = 8;
        // ap2 = reCalcAutoPrice(item, apPrefs);
        // ap = Math.max(ap, ap2);
      } else { // Max Aggregate
        item.sAP = 8;
        ap = reCalcAutoPrice(item, apPrefs);
      }
      item.sAP = 9;
      item.sAPv = ap;
      break;
    case 0: // Manual
    default: // default to manual
      item.sAPv = item.sP;
  }

  if (item.sAPv > 0.1) {
    return Math.round(item.sAPv * item.sAPF * 100) / 100;
  }
  return Math.round(item.sAPv * item.sAPF * 1000) / 1000;
}
