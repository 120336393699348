import React, { Component } from "react";
import { withAuthenticator } from '@aws-amplify/ui-react';

import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'

import RestApi from '../components/restapi'
import { blTypeMap } from "../components/util"
import { cleanUpLoaders } from "../components/loaders"
var bl = require('../components/bl');

var CryptoJS = require("crypto-js");
var Promise = require("bluebird");
var parseString = require('xml2js').parseString;

class Orders extends Component {
  creds = null;
  sscreds = null;

  constructor(props) {
    super(props);

    cleanUpLoaders();

    this.sscreds = sessionStorage.getItem('btg.apiCreds');
    if (this.sscreds) {
      // Decrypt
      var bytes  = CryptoJS.AES.decrypt(this.sscreds, props.user.attributes.sub);
      this.creds = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }

    this.state = {
      lists: [],
      status: 'Select a list',
      log: [],
    }
  }

  componentDidMount() {
    this.handlePullOrders();
  }

  handlePullOrders = async () => {
    if (!this.creds) {
      this.addToLog('API Credentials Not Available', 'warning');
      this.setState({ status: 'API Credentials Not Available', statusVariant: 'warning' })
      return;
    }

    if (!RestApi.hasPrivilege(this.props.activeStore.privileges, 'StoreUpdateBl')) {
      this.addToLog('You are not authorised to perform Rebrickable updates', 'warning');
      this.setState({ status: 'You are not authorised to perform Bricklink updates', statusVariant: 'warning' })
      return;
    }

    var blClient = new bl.Client(this.sscreds);
    // var inventories = bl.Order.all({ status: "-COMPLETED,-SHIPPED" });
    var orders = bl.Order.all();

    this.addToLog('Fetching orders from Bricklink', 'light');

    blClient.send(orders)
    .then(res => {

      const blRes = res.data
      this.addToLog('Got ' + blRes.length + ' orders from Bricklink...', 'light' );

      const self = this;
      var uCnt = 0;

      const storeId = this.props.activeStore.storeId
      return Promise.map(blRes, function(ord) {
        uCnt++;

        if (uCnt % 50 === 0 || uCnt === blRes.length) {
          self.addToLog('Updated ' + uCnt + ' of ' + blRes.length + ' orders', 'light' );
        }

        const ordReq = bl.Order.get(ord.order_id);

        return blClient.send(ordReq)
        .then(ordRes => {
          const order = ordRes.data;

          return RestApi.setOrder(
            storeId,
            order.order_id,
            'BL',
            (new Date(Date.parse(order.date_ordered))).toISOString().slice(0, 19).replace('T', ' '),
            (new Date(Date.parse(order.date_status_changed))).toISOString().slice(0, 19).replace('T', ' '),
            order.seller_name,
            order.store_name,
            order.buyer_name,
            order.total_count,
            order.unique_count,
            order.status,
            order.payment.method,
            order.payment.status,
            order.payment.date_paid ? (new Date(Date.parse(order.date_ordered))).toISOString().slice(0, 19).replace('T', ' ') : null,
            order.payment.currencyCode,
            order.cost.subtotal,
            order.cost.grand_total,
            order.cost.currency_code,
            JSON.stringify(order)
          )
        })
        .then(r => {
          const oiReq = bl.OrderItem.all(ord.order_id);

          return blClient.send(oiReq);
        })
        .then(oi => {
          const orderItems = oi.data;

          var mappedItems = [];

          for (var i = 0; i < orderItems.length; i++) {
            const batch = i
            mappedItems = mappedItems.concat(orderItems[batch].map(item => {
              item.batch = batch;
              return item;
            }))
          }

          return Promise.map(mappedItems, function(item) {
            return RestApi.setOrderItem(
              storeId,
              ord.order_id,
              'BL',
              item.batch,
              item.inventory_id,
              item.item.no,
              blTypeMap[item.item.type],
              item.color_id,
              item.quantity,
              item.new_or_used,
              item.completeness,
              item.unit_price,
              item.unit_price_final,
              item.disp_unit_price,
              item.disp_unit_price_final,
              item.disp_currency_code,
              item.remarks,
              item.description,
              item.weight
            )
          }, { concurrency: 3 } )
        })
      }, { concurrency: 5 } )
    })
    .then(res => {
      this.addToLog('Bricklink done!', 'light');
      return res;
    })
    .catch(err => {
      this.addToLog(err.toString(), 'danger');
      this.setState({ status: err.toString(), statusVariant: 'danger' })
      console.log(err);
    })
  }

  handleAddBOOrder = e => {
    var file = e.target.files[0];
    if (!file) {
      return;
    }
    const self = this;
    const storeId = this.props.activeStore.storeId
    var reader = new FileReader();
    reader.onload = function(e) {
      var contents = e.target.result;
      parseString(contents, function (err, result) {
        console.log(result);
        if (result.BrickStoreXML.Order[0].Service[0] !== 'BrickOwl') {
          self.addToLog(`Not a BrickOwl order. Nothing done.`, 'warning');
          return;
        }
        self.addToLog(`Importing BrickOwl order ${result.BrickStoreXML.Order[0].OrderID[0]} - ${result.BrickStoreXML.Order[0].Customer[0]}`, 'light');

        return RestApi.setOrder(
          storeId,
          result.BrickStoreXML.Order[0].OrderID[0],
          'BO',
          (new Date(parseInt(result.BrickStoreXML.Order[0].OrderDate[0])*1000)).toISOString().slice(0, 19).replace('T', ' '),
          (new Date(parseInt(result.BrickStoreXML.Order[0].OrderDate[0])*1000)).toISOString().slice(0, 19).replace('T', ' '),
          null,
          null,
          result.BrickStoreXML.Order[0].Customer[0],
          0,
          0,
          null,
          null,
          null,
          null,
          result.BrickStoreXML.Order[0].Currency[0],
          result.BrickStoreXML.Order[0].SubTotal[0],
          result.BrickStoreXML.Order[0].GrandTotal[0],
          result.BrickStoreXML.Order[0].Currency[0],
          JSON.stringify(result)
        )
        .then(res => {
          const mappedItems = result.BrickStoreXML.Inventory[0].Item;
          return Promise.map(mappedItems, function(item) {
            console.log(item);
            return RestApi.setOrderItem(
              storeId,
              result.BrickStoreXML.Order[0].OrderID[0],
              'BO',
              0,
              item.LotID[0],
              item.ItemID[0],
              blTypeMap[item.ItemTypeName[0].toUpperCase()],
              item.ColorID[0],
              item.Qty[0],
              item.Condition[0],
              item.Completeness ? item.Completeness[0] : null,
              item.Price[0],
              item.Price[0],
              item.Price[0],
              item.Price[0],
              result.BrickStoreXML.Order[0].Currency[0],
              item.remarks,
              item.Comments ? item.Comments[0] : null,
              item.weight
            )
          }, { concurrency: 5 } )
        })
        .then(res => {
          self.addToLog(`Done!`, 'light');
        })
      });
    };
    reader.readAsText(file);
  }

  // function displayContents(contents) {
  //   var element = document.getElementById('file-content');
  //   element.textContent = contents;
  //   parse(contents);
  // }
  //
  // document.getElementById('file-input')
  //   .addEventListener('change', readSingleFile, false);
  //
  // var xmlDoc;
  // parse(content)
  // {
  //   //Create a parser
  //   var parser = new DOMParser();
  //   xmlDoc = parser.parseFromString(content,"text/xml");
  //   //Parse!
  //   document.getElementById("ID").innerText = "ID: " + xmlDoc.evaluate("//ID",xmlDoc).iterateNext().textContent;
  //   document.getElementById("FirstName").innerText = "First Name: " + xmlDoc.evaluate("//FirstName",xmlDoc).iterateNext().textContent;
  //   document.getElementById("Description").innerText = "Description: " + xmlDoc.evaluate("//Description",xmlDoc).iterateNext().textContent;
  //   document.getElementById("PriceAmount").innerText = "Price: " + xmlDoc.evaluate("//PriceAmount",xmlDoc).iterateNext().textContent;
  // }

  addToLog(msg, variant) {
    this.setState(state => {
        state.log.unshift({ id: state.log.length, msg: msg, var: variant, ts: Date.now() });

      return { log: state.log }
    })
  }

  renderLog() {
    var logRows = [];

    this.state.log.forEach(entry => {
      var date = new Date(entry.ts);
      logRows.push(
      <tr key={entry.id}>
        <td><Alert className="m-0 p-0" variant={entry.var}>
          {date.toLocaleString()}: {entry.msg}
        </Alert></td>
      </tr>
      )
    });

    return (
      <>
      <Table bordered size="sm">
        <thead>
          <tr>
            <th>Log</th>
          </tr>
        </thead>
        <tbody>
          {logRows}
        </tbody>
      </Table>
      </>
    )
  }

  render() {
    return (
      <Container className='Home'>
        <Row>
          <Col>
            <Form.File accept=".bsx" id="boorder" label="Add BrickOwl Order" custom onChange={this.handleAddBOOrder} />
          </Col>
          <Col md="auto" className="text-right">
            <Alert className="mb-0" variant={this.state.statusVariant}>
              {this.state.status}
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col md="auto">
            {/*this.renderLists()*/}
          </Col>
          <Col>
            {this.renderLog()}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withAuthenticator(Orders)
